import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { ApiUrlConfig } from '../constants/common-apiurls';
import { ServicesError } from './services.error';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	baseUrl = environment.apiBaseUrl;

	constructor(private http: HttpClient, private error: ServicesError) { }

	validateToken(authToken: any) {
		let userPrepareUrl: any = { 'PrepareUrl': authToken };
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return this.http.post(this.baseUrl + ApiUrlConfig.DecryptValidateTokenUrl, userPrepareUrl, { headers }).pipe(catchError(this.error.handleError));
	}

	setEnvelopeTokenDetails(authToken: string, prepareType: string, envelopeOrTemplateId: string): void {
		let details: any = { "token": authToken, "prepareType": prepareType, "envelopeOrTemplateId": envelopeOrTemplateId };
		localStorage.setItem("envelopeTokenDetails", JSON.stringify(details));
	}

	setUserDetails(userInfo:any): void {		
		localStorage.setItem("UserDetails", JSON.stringify(userInfo));
	}

	getUserDetails() {
		let userInfo: any = localStorage.getItem("UserDetails");
		if (userInfo) {			
			return JSON.parse(userInfo);
		}
	}

	getDetails() {
		let envelopeTokenDetails: any = localStorage.getItem("envelopeTokenDetails");
		if (envelopeTokenDetails) return envelopeTokenDetails;
	}

	getToken() {
		let envelopeTokenDetails: any = localStorage.getItem("envelopeTokenDetails");
		if (envelopeTokenDetails) {
			let tokenData:any = JSON.parse(envelopeTokenDetails);
			return tokenData.token;
		}
	}

	setEnvelopeOrTemplateDetails(resp: any) {
		localStorage.setItem("envelopeOrTemplateDetails", JSON.stringify(resp));
	}

	getEnvelopeOrTemplateDetails() {
		let envelopeOrTemplateDetails: any = localStorage.getItem("envelopeOrTemplateDetails");
		if (envelopeOrTemplateDetails) return envelopeOrTemplateDetails;
	}

	setRecipientDetails(recpDetails: any) {
		localStorage.setItem("recipientDetails", JSON.stringify(recpDetails));
	}

	getRecipientDetails() {
		let recipientDetails: any = localStorage.getItem("recipientDetails");
		if (recipientDetails) return recipientDetails;
	}

	setRecipientColorDetails(recpColorDetails: any) {
		localStorage.setItem("recipientColorDetails", JSON.stringify(recpColorDetails));
	}

	getRecipientColorDetails() {
		let recpColorDetails: any = localStorage.getItem("recipientColorDetails");
		if (recpColorDetails) return recpColorDetails;
	}

	// setUserUsageRemainingDetails(userUsageRemaining:any): void {			
	// 	localStorage.setItem("UserUsageRemaining", JSON.stringify(userUsageRemaining));
	// }

	// getUserUsageRemainingDetails() {
	// 	let userUsageRemaining: any = localStorage.getItem("UserUsageRemaining");
	// 	if (userUsageRemaining) {			
	// 		return JSON.parse(userUsageRemaining);
	// 	}		
	// }

	setLanguageKeyTranslations(languageKeyTranslations:any): void {		
		sessionStorage.setItem("LanguageTransalation", JSON.stringify(languageKeyTranslations));
	}
}



