import { Component, OnInit } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants';
import { ApiUrlConfig, NavigateUrlConfig } from 'src/app/constants/common-apiurls';
import { APIService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	userName: any = '';
	commonConstants = CommonConstants;
	HomeUrl: string = '';
    SendUrl: string = '';
    EnvelopeUrl: string = '';
    TemplateUrl: string = '';
    SettingsUrl: string = '';
    TranslationsUrl: string = '';
    UserDetailsUrl: string = '';
    StatsUrl: string = '';
	showLoader:boolean = false;

	constructor(private apiService: APIService, private authService: AuthService) {
        if (this.authService.getUserDetails() && this.authService.getUserDetails().emailID) {
            let rsignUrl: any = environment.rsignUrl;
            let path: any = rsignUrl + 'Account/RSignDetailsNewSigners?ru=' + this.authService.getUserDetails().emailID + "&rpk=" + this.authService.getToken();
            this.HomeUrl = path + '&redirect=Home';
            this.SendUrl = path + '&redirect=Send';
            this.EnvelopeUrl = path + '&redirect=Envelopes';
            this.TemplateUrl = path + '&redirect=Templates';
            this.SettingsUrl = path + '&redirect=Settings';
            this.TranslationsUrl = path + '&redirect=Translations';
            this.UserDetailsUrl = rsignUrl + NavigateUrlConfig.UserDetails;
            this.StatsUrl = path + '&redirect=Stats';
		}
	}

	ngOnInit(): void {
		let userInfo: any = this.authService.getUserDetails();
		if (userInfo) {
			let uname: any = '';
			if (userInfo.lastName) uname = userInfo.firstName;
			if (userInfo.lastName) uname = uname + " " + userInfo.lastName;
			this.userName = uname;
			let divFlagIcon: any = document.getElementById('divFlagIcon');
			if (userInfo.languageCode == 'en-us') divFlagIcon.classList.add('englishIcon');
			if (userInfo.languageCode == 'es-es') divFlagIcon.classList.add('spanishIcon');
			if (userInfo.languageCode == 'de-de') divFlagIcon.classList.add('germanIcon');
			if (userInfo.languageCode == 'fr-fr') divFlagIcon.classList.add('frenchIcon');
			if (userInfo.languageCode == 'pt-pt') divFlagIcon.classList.add('portugueseIcon');
			if (userInfo.languageCode == 'nl-nl') divFlagIcon.classList.add('dutchIcon');
			if (userInfo.languageCode == 'lv-lv') divFlagIcon.classList.add('latvianIcon');
			if (userInfo.languageCode == 'lt-lt') divFlagIcon.classList.add('lithuaniaIcon');
			if (userInfo.languageCode == 'pl-pl') divFlagIcon.classList.add('polishIcon');
			if (userInfo.languageCode == 'da-dk') divFlagIcon.classList.add('danishIcon');
			if (userInfo.languageCode == 'it-it') divFlagIcon.classList.add('italianIcon');
			if (userInfo.languageCode == 'hu-hu') divFlagIcon.classList.add('hungarianIcon');
			if (userInfo.languageCode == 'no-na') divFlagIcon.classList.add('norwegianIcon');
			if (userInfo.languageCode == 'ro-ro') divFlagIcon.classList.add('romanianIcon');
		}
	}

	redirectTo(type: any) {
		// localStorage.clear();
		// sessionStorage.clear();
		let rsignURL: any = environment.rsignUrl;

		switch (type) {
			case "Home":
				window.location.href = rsignURL + NavigateUrlConfig.Home;
				break;
			case "Send":
				window.location.href = rsignURL + NavigateUrlConfig.Send;
				break;
			case "Envelopes":
				window.location.href = rsignURL + NavigateUrlConfig.Envelope;
				break;
			case "Templates":
				window.location.href = rsignURL + NavigateUrlConfig.Template;
				break;
			case "Settings":
				window.location.href = rsignURL + NavigateUrlConfig.Settings;
				break;
			case "Translations":
				window.location.href = rsignURL + NavigateUrlConfig.Translations;
				break;
			case "UserDetails":
				window.location.href = rsignURL + NavigateUrlConfig.UserDetails;
				break;
		}
	}

	logout() {
		this.showLoader = true;
		localStorage.clear();
		sessionStorage.clear();
		let rsignURL: any = environment.rsignUrl;
		window.location.href = rsignURL + NavigateUrlConfig.LogOff;
	}

	redirectCorporateURL(PageName: any, OpenType: any) {
		let getRedirectUrl = ApiUrlConfig.GetRedirectURL + "/" + PageName;
		this.apiService.get(getRedirectUrl).subscribe(
			(resp: any) => {
				// localStorage.clear();
				// sessionStorage.clear();
				if (resp && resp.StatusCode == 200) {
					if (resp.Message != null) {
						OpenType == 'self' ? window.location.href = resp.Message : window.open(resp.Message, "_blank");
					}
				}
			})
	}
}
