import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-keyboard-shortcuts',
    templateUrl: './keyboard-shortcuts.component.html',
    styleUrls: ['./keyboard-shortcuts.component.scss']
})
export class KeyboardShortcutsComponent {
    @Output() keyboardShortcutsChangesMade: EventEmitter<any> = new EventEmitter();

    constructor(
        public modalRef: BsModalRef
    ) { }

    ngOnInit(): void {
    }

    onDone(){
        this.modalRef.hide();
    }
}
