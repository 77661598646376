<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title font-w600" id="staticBackdropLabel">Add Dependencies</h5>
        <div class="modalposition" aria-label="Close" (click)="closePopUp()"> <span
                class="material-icons model-closeicon">close</span></div>
    </div>
    <div class="modal-body pb-1">
        <p class="mb-1 font16 d-flex align-items-start justify-content-between">
            <span class="mb-1 ">Specify how an action on the controlling field determines the behavior of the
                dependent field.</span>
            <span *ngIf="!isViewAll" class="material-icons text-green pointer" title="Add Row"
                (click)="addAdditionalDependentRow(1)"> add_box </span>
        </p>

        <table *ngIf="!isViewAll" class="w-100 setting-table table-space">
            <tr class="tablehead1 w-100">
                <th class="w-20">1. Select a controlling field</th>
                <th class="w-20 " ><span [ngClass]="addClass ? 'th2' : ''"> 2. Specify a dependent field</span></th>
                <th class="w-20 "> <span [ngClass]="addClass ? 'th3' : ''">3. Specify an action</span> </th>
                <th class="w-20 " colspan="2" style="padding-left: 0px;"> <span [ngClass]="addClass ? 'th4' : ''">4. Specify it's behaviour</span></th>
                <th id="thAddTextField" class="w-20 " colspan="2" style="display: none;"></th>
            </tr>
        </table>
        <div *ngIf="!isViewAll" class="w-100 table-scroll col-md-12">
            <div class="row col-lg-12 col-md-12 pb-1">
                <div class="col-md-3 pe-1" style=" width: 22%; ">
                    <table class="w-83 setting-table" [style.marginTop]="addcontrolClass ? '4px' : '1px'">
                        <tr>
                            <td class="w-20">
                                <select id="slControllingFieldSelectList" onfocus='this.size=4;' onblur='this.size=1;' onchange='this.size=1; this.blur();'
                                    class="form-select controlshadow controllingFieldSelectList"
                                    aria-label="Default select example" (change)="updateAllDependentDropdowns()">
                                    <option value="0" [selected]="isDefaultSelected == true ?'selected':''">Select</option>
                                    <option *ngFor="let eve of controllingFieldSelectList" title="{{eve.title}}"
                                        [selected]="eve.isSelected == true ?'selected':''" value="{{eve.value}}">
                                        {{eve.text}}
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="col-md-9" style="margin-left: -19px;">
                    <table class="w-100 setting-table" style=" width: 109%!important;">
                        <tr class="controlshadow" *ngFor="let rowItem of appendOtherDependentRowsList; let i=index;">
                            <td class="w-20 left-space" style="padding: 2px;width: 210px;">
                                <select id="slDependentFieldSelectList_{{i}}"  onfocus='this.size=4;' onblur='this.size=1;' onchange='this.size=1; this.blur();'
                                    class="form-select controlshadow dependentFieldSelectList"
                                    aria-label="Default select example"
                                    (change)="checkIsAlreadyAddedAsDependent($event,i)">
                                    <option value="0">Select</option>
                                    <option *ngFor="let eve of controllingDependentFieldSelectList"
                                    [selected]="eve.isSelected == true ?'selected':''"
                                        title="{{eve.title}}" value="{{eve.value}}">{{eve.text}}</option>
                                </select>
                            </td>
                            <td class="w-20 left-space" style="padding: 2px;width: 210px;">
                                <select id="slDependentConditionsSelectList_{{i}}" [ngClass]="addcontrolClass ? 'w-add96' : ''"
                                    class="form-select controlshadow dependentConditionsSelectList"
                                    aria-label="Default select example" (change)="addTextField($event,i)">
                                    <option *ngFor="let eve of controllingdependentConditionsSelectList"
                                        [disabled]="eve.isDisabled == true ?'disabled':''"
                                        [selected]="eve.isSelected == true ?'selected':''" title="{{eve.title}}"
                                        value="{{eve.value}}">{{eve.text}}</option>
                                </select>
                            </td>
                            <td class="w-20 left-space" style="padding: 2px;width: 210px;">
                                <select id="slDependentActionSelectList_{{i}}" [ngClass]="addcontrolClass ? 'w-add95' : ''"
                                    class="form-select controlshadow dependentActionSelectList"
                                    aria-label="Default select example">
                                    <option *ngFor="let eve of controllingDependentActionSelectList"
                                        [selected]="eve.isSelected == true ?'selected':''" title="{{eve.title}}"
                                        value="{{eve.value}}">{{eve.text}}</option>
                                </select>
                            </td>
                            <td id="tdAddTextField_{{i}}" style="padding:0px;" class="w-20 left-space"
                                style="text-align:center;width:100%;padding-left: 3px;padding-right: 3px;">
                                <input type="text" id="txtTextField_{{i}}" value="" class="form-control w-120px" placeholder="">
                            </td>
                            <td id="tdDeleteRow_{{i}}" style="padding:0px;" class="w-5 left-space" title="Delete Row">
                                <i class="fa fa-minus-square-o font20 text-danger" aria-hidden="true"
                                    (click)="deleteAdditionalDependentRow(i,rowItem.rIndex)"></i>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>

        <div id="divViewAll" class="w-100 table-scroll col-md-12" style="display: none;">
            <table id="divViewAllTable" class="w-100 setting-table table-space documentTable">
                <tr class="tablehead1 w-100">
                    <th>1. Controlling field</th>
                    <th>2. Dependent field</th>
                    <th>3. Action </th>
                    <th colspan="2" style="padding-left: 0px;">4. Behaviour</th>
                    <th style="display: none;"></th>
                </tr>
                <tbody id="tableViewAllBody" class="tableViewAllBody">

                </tbody>
            </table>
        </div>
    </div>

    <div class="modal-footer justify-content-between">
        <div>
            <button class="btn view-btn" (click)="viewAllDependecies()">
                <span class="material-icons me-1" title="View all dependencies">
                    visibility </span> View All</button>
        </div>
        <div class="d-flex align-items-center">
            <button id="cancelDependencyBtn" class="btn back-btn cancelbtn form-btn" (click)="closePopUp()">
                Cancel</button>
            <button id="saveDependencyBtn" role="button" class="okbtn btn back-btn form-btn ms-3 saveDependencyBtn" (click)="saveDependentFields()">
                Save</button>
        </div>
    </div>
</div>
<div *ngIf="showLoader" class="loader">
</div>