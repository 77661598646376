<header class="headerFixed">
	<div class="logo-wrapper">
		<a href="javascript:void(0);">
			<img class="rsign-logo" src="../../../assets/images/RSign.svg" />
		</a>
		<a href="javascript:void(0);" class="rcircle-logo">
			<img src="../../../assets/images/RPost-Button-White.svg" />
		</a>
	</div>
</header>

<main class="flex-shrink-0 mt7">
	<div class="container-fluid">
		<div class="row justify-content-center absolute">
			<div class="fixed bggray submenu" style="height: 42px;">
				<div class="row pt-1">
					<div class="col-lg-2 col-md-2 pe-0 ps-2 res-pr2">
						<app-recipients [receipentListArr]="receipentListArr" [receipentColorArr]="receipentColorArr"
							[recipientsObj]="recipientsObj"></app-recipients>
					</div>

					<div class="col-lg-8 col-md-8 ">
						<div class="d-flex justify-content-center align-items-center ">
								<ul class="d-flex align-items-center justify-content-center pagination" style="margin: 0px;">
									<!-- disabled -->
									<li id="first_page" class="page-item">
										<div class="first_page page-link  d-flex align-items-center bx-shadow neu-light activepage">
											<span class="first_page material-icons left-3p">first_page</span>
										</div>
									</li>
									<li id="chevron_left" class="page-item ms-1">
										<div class="chevron_left page-link  d-flex align-items-center bx-shadow neu-light activepage">
											<span class="chevron_left material-icons">chevron_left</span>
										</div>
									</li>
									<li class="page-item">
											<div class="d-flex align-items-center m-auto justify-content-center mx-2">
											<input id="paginationInput" [value]="1" autocomplete="off"
												 type="text" class="form-control bx-shadow neu-light1 res-hw" style="cursor: default;"/>
												<p class="ms-2 me-1 mb-0 font17 font-w600 text-color">/ {{6}}</p>
											</div>
									</li>
									<li class="page-item d-flex"></li>
									<li id="navigate_next" class="page-item me-1">
										<div class="navigate_next page-link  d-flex align-items-center bx-shadow neu-light activepage">
											<span class="navigate_next material-icons left-3p"> navigate_next </span>
										</div>
									</li>
									<li id="last_page" class="page-item">
										<div class="last_page page-link bx-shadow  neu-light activepage">
											<span class="last_page material-icons top-n4">last_page</span>
										</div>
									</li>
								</ul>
						</div>
					</div>
					<div class="col-lg-2 col-md-2 res-rightwidth">
						<div class="d-flex align-items-center justify-content-between">
							
								<button class="btn back-btn btnback px-3" (click)="goBackToPrevPage()">
									<i aria-hidden="true" class="fa fa-arrow-left me-2"></i>Back
								</button>

								<button class="send-button btnsend font14 d-flex align-items-center btn-disable" style="opacity: 0.5;"
									role="button">
										<i class="fa fa-paper-plane " aria-hidden="true"></i> Finish
								</button>
						</div>
					</div>
				</div>
			</div>
		</div>
      
		<div class="d-flex res-ipad">
			<div class="col-xl-2 col-lg-12 col-md-12 col-xs-12 col-sm-12 ">
			</div>
			<div class="col-xl-8 col-lg-12 col-md-12 col-xs-12 col-sm-12">
				<div class="pdfcard">
					<!-- <img class="m-auto img-fluid" src="../../../assets/images/Pdf-img.png" /> -->
					<div class="w-100">
						<div class="img-control" #container *ngFor="let item of imagesArr; let idx = index;"
								id="imgControl_{{idx+1}}">
								<img class="dispImg document-img imgControl" id="{{idx+1}}" alt="Image"
									src="{{item.path}}" [attr.page-no]="idx+1" />
							</div>
					</div>
				</div>
			</div>
		</div>

    </div>
    </main>
    <app-footer class="env-footer"></app-footer>