<div class="d-flex pagination-disabled" style="margin-top: -8px;">
	<ul class="paginationContainer pagination d-flex align-items-center justify-content-center "
		style="margin: 0px;">
		<!-- <li id="first_page" class="page-item " (click)="onPageSelection($event, 'first')">
			<div class="first_page page-link br-red2 d-flex align-items-center bx-shadow neu-light activepage res-h cursor-pointer">
				<span class="first_page material-icons">first_page</span>
			</div>
		</li> -->
		<!-- <li id="chevron_left" class="page-item ms-1" (click)="onPageSelection($event, 'prev')">
			<div class="chevron_left page-link br-red2 d-flex align-items-center bx-shadow neu-light activepage res-h cursor-pointer">
				<span class="chevron_left material-icons">chevron_left</span>
			</div>
		</li> -->
		<li class="page-item">
			<!-- <div class="d-flex align-items-center m-auto justify-content-center mx-2" style="margin-left:-5px;">
				<div class="input-group d-flex align-items-center" style="width: auto;">
					<input id="paginationInput" [value]="pageVal" autocomplete="off"
						(keyup.enter)="onPageSelection($event, 'paginationInput')"
						(ontouchstart)="onPageSelection($event, 'paginationInput')"
						(keypress)="forRestrictNumbers($event)" type="text"
						class="form-control bx-shadow neu-light1 res-hw" style="cursor: default;" />
					<div class="dropdown">
						<span class="input-group-text page-arrow" id="dropdownMenu3" data-bs-toggle="dropdown"
							aria-expanded="false">
							&nbsp; / {{documentDataLength}}
							<i class="material-icons">arrow_drop_down</i>
						</span>
						<ul class="dropdown-menu mt-5p droparrow page-list" aria-labelledby="dropdownMenu3">
							<li class="drop-border" *ngFor="let pagenum of documentData.pages; let i = index;"
								[value]="pagenum" (click)="onPaginateChange(pagenum.id)">
								<a class="dropdown-item font15 mb-1">{{pagenum.id}}</a>
							</li>
						</ul>
					</div>
				</div>
			</div> -->
			<!-- <div class="d-flex align-items-center top-n3" >
				<span class="br-nav">|</span>
				<div class="dropdown">
					<div class=" text-black top3 pointer font26 d-flex align-items-center" title="Zoom"
						id="dropdownMenu3" data-bs-toggle="dropdown" aria-expanded="false">

						<span class="material-icons top1" style="top: -4px;">arrow_drop_down</span>
				</div>
					<ul class="dropdown-menu mt-5p droparrow menu-list1" aria-labelledby="dropdownMenu3" >
						<li class="drop-border" *ngFor="let pagenum of pagenationDropdown; let i = index;" [value]="pagenum">
							<a class="dropdown-item font15 mb-1" >{{pagenum}}</a>
						</li>

					</ul>
				</div>
			</div> -->
		</li>
		<li class="page-item d-flex"></li>
		<!-- <li id="navigate_next" class="page-item me-1" (click)="onPageSelection($event, 'next')">
			<div class="navigate_next page-link br-red2 d-flex align-items-center bx-shadow neu-light activepage res-h cursor-pointer">
				<span class="navigate_next material-icons"> navigate_next </span>
			</div>
		</li> -->
		<!-- <li id="last_page" class="page-item" style="margin-left: 6px;" (click)="onPageSelection($event, 'last')">
			<div class="last_page page-link bx-shadow br-red2 neu-light activepage res-h cursor-pointer">
				<span class="last_page material-icons top-n4">last_page</span>
			</div>
		</li> -->
	</ul>

	<div class="d-flex align-items-center page-details ms-2 pe-1 ">
		<input #paginationInput id="paginationInput" [value]="pageVal" autocomplete="off"
			(keyup.enter)="onPageSelection($event, 'paginationInput')"
			(focusout)="onPageSelection($event, 'paginationInput')"
			(ontouchstart)="onPageSelection($event, 'paginationInput')"
			(input)="formatInput(paginationInput);"
			(paste)="onPaste($event)"
			type="text" class="form-control bx-shadow neu-light1 res-hw"
			min="1" max="{{documentDataLength}}"
			style="cursor: auto;border-radius: 2px 0px 0px 2px !important;border-right: 1px solid #d3d2d2 !important;box-shadow:none" />

		<div class="dropdown">
			<span class="page-arrow d-flex align-items-center" id="dropdownMenu3" data-bs-toggle="dropdown"
				aria-expanded="false">
				&nbsp; of &nbsp; {{documentDataLength}} <i class="material-icons">arrow_drop_down</i>
			</span>
			<ul class="dropdown-menu mt-5p droparrow page-list" aria-labelledby="dropdownMenu3">
				<li class="drop-border" *ngFor="let pagenum of documentDataLength | numberArray; let i = index;"
					[value]="pagenum" (click)="onPaginateChange(pagenum)">
					<a class="dropdown-item font14 mb-1">{{pagenum}}</a>
				</li>
			</ul>
			<div class="paginationLoader spinner-border"
				[ngStyle]="{'color': 'blue', 'top': '3px','left': '34px', 'width':'23px','height':'23px'}" style="position: absolute;z-index:99;">
			</div>
		</div>

	</div>

</div>