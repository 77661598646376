<div #box class="resizable-draggable control-tooltip {{ctrlObj.className | lowercase}}" [ngClass]="[ isActive ? 'control-active' : '']"
    [style.width.px]="width" [style.height.px]="height" [ngStyle]="getIsEnvelopeEditable()"
    [style.transform]="'translate3d('+ left + 'px,' + top + 'px,' + '0px)'"
    [class.active]="status === 1 || status === 2" (mousedown)="setStatus($event, 2)"
    (window:mouseup)="setStatus($event, 0)" [style.minWidth.px]="minWidth" [style.minHeight.px]="minHeight"
    style="min-height:10px;font-size: 12px;position: absolute;" [style.background]="ctrlObj.bgColor" [style.outline]="ctrlObj.outlineColor"
    id="resizable_{{ctrlObj.id}}" [attr.title]="getControlOriginalTitle(ctrlObj.recipientId,ctrlObj.id)"
    [attr.data-rcptid]="ctrlObj.recipientId" [attr.data-original-rcptid]="ctrlObj.recipientId"
    [attr.data-guid]="ctrlObj.dataGuid" [attr.data-recp-color]="ctrlObj.recpColor" [attr.pageno]="ctrlObj.pageNo"
    [attr.data-defaultdate]="ctrlObj.defaultDate" [attr.data-selected]="ctrlObj.isRequired"
    [attr.data-typography]='ctrlObj.typography' [attr.data-placeholder]='ctrlObj.placeHolderText'
    (click)="onClickDroppedControl(ctrlObj)"
    [attr.data-original-title]="getControlOriginalTitle(ctrlObj.recipientId,ctrlObj.id)">
    <!-- [ngClass]="getControllingStyle(ctrlObj.controlName)" -->
    <ng-container *ngIf="showDepencieIcons">
        <div class="bottom-tooltip" id="mouseTarget">
            <div class="d-flex align-items-center only-show-on-hover">
                <div *ngIf="ctrlObj.controlName !== 'Label' && ctrlObj.controlName.toLowerCase() !== 'hyperlink'"
                    class="dropdown ps-1" style=" width: 150px;">
                    <button class="form-select custme-p text-start d-flex align-items-center" style="
                    box-shadow: none;border: none;
                    font-size: 14px; border-radius: 3px;
                    height: 29px;" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="material-icons me-1 square-select" aria-hidden="true"
                            [ngStyle]="{ color: selectedRecipentColor }">square</i>
                      <span class="font-recipent" title="{{ selectedRecipentName }}"> {{ selectedRecipentName }}</span>
                    </button>
                    <ul class="dropdown-menu recpientlist">
                        <li *ngFor="let recipient of receipentListArr;let i = index;" (click)="
                            onRecipentSelect(
                                $event,
                                recipient,
                                receipentColorArr[i],
                                ctrlObj.id
                            )
                            ">
                            <a id="recipient_{{recipient.id}}" class="dropdown-item pointer recipientDeatilsListItem"
                                style="font-size: 14px;">
                                <!-- [ngStyle]="{'background-color':selectedRecipentName == recipient.name ? 'green': '', 'color':selectedRecipentName == recipient.name ? 'white': ''}" -->
                                <span class="material-icons square-font" aria-hidden="true"
                                    [ngStyle]="{ color: receipentColorArr[i] }">square</span>
                                <span class="text-wrap">{{ recipient.name }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
                <span *ngIf="ctrlObj.controlName !== 'Label' && ctrlObj.controlName.toLowerCase() !== 'hyperlink'"
                    class="br-nav me-2 ms-1">|</span>
                <span class="material-icons tooltip-icon" title="Duplicate this control"
                    (click)="createDuplicateControl($event,ctrlObj)"> library_add </span>
                <span class="d-flex align-items-center"
                    *ngIf="ctrlObj.controlName === 'Text' || ctrlObj.controlName === 'Checkbox' || ctrlObj.controlName === 'Radio' || ctrlObj.controlName === 'Dropdown'">
                    <span class="br-nav mx-2">|</span>
                    <i aria-hidden="true" title="Add controlling field to the document and add dependencies"
                        [ngClass]="disableDependencyIcon(ctrlObj)" (click)="openDependencyPopup($event, ctrlObj)"></i>
                </span>
                <span class="br-nav ms-2 me-1">|</span>
                <span class="material-icons tooltip-icon me-1" title="delete"
                    (click)="deleteControl($event)">delete</span>
            </div>
            <div class="box overlay"></div>
        </div>
    </ng-container>

    <div class="d-flex justify-content-start align-items-start" style="overflow: hidden;">
        <div id="div{{ctrlObj.id}}" *ngIf="ctrlObj.controlName !== 'Radio' && ctrlObj.controlName !== 'Checkbox'"
            class="resize-action {{ctrlObj.className | lowercase}}" (mousedown)="setStatus($event, 1)"
            style="font-size: 12px;">
        </div>

        <div *ngIf="ctrlObj.controlName && ctrlObj.controlName.toLowerCase() === 'hyperlink'"
            style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;display: block !important;">
            <span id="spn{{ctrlObj.id}}" style="position: relative;font-family: Courier;
            font-weight: normal;
            text-decoration: none;
            font-style: normal;letter-spacing: 1px;
            font-size: 12px;
            line-height: 12px;padding-top: 4px;padding-left: 4px;
            color: rgb(0, 0, 0);">{{ctrlObj.placeHolderText}}</span>
        </div>

        <!-- Date & Time Stamp, Title, Company and Label -->
        <span id="spn{{ctrlObj.id}}" *ngIf="ctrlObj.controlName && ctrlObj.controlName !== 'Signature' && ctrlObj.controlName !== 'NewInitials' &&
            ctrlObj.controlName !== 'Date' && ctrlObj.controlName !== 'Radio' &&
            ctrlObj.controlName !== 'Checkbox' && ctrlObj.controlName !== 'Dropdown' && ctrlObj.controlName !== 'Name' &&
            ctrlObj.controlName !== 'Text' && ctrlObj.controlName.toLowerCase() !== 'hyperlink'"
            [style.fontFamily]="ctrlObj.textFormatting.fontFamily"
            [style.fontStyle]="ctrlObj.textFormatting.fontStyle"
            [style.fontSize]="ctrlObj.textFormatting.fontSize"
            [style.color]="ctrlObj.textFormatting.color"
            [style.textDecoration]="ctrlObj.textFormatting.textDecoration"
            [style.fontWeight]="ctrlObj.textFormatting.fontWeight"
            [style.textAlign]="ctrlObj.textFormatting.textAlign"
            [style.lineHeight]="ctrlObj.textFormatting.lineHeight"

            style="position: relative;letter-spacing: 1px;
            text-wrap: nowrap;padding-top: 2px;padding-left: 2px;">{{ctrlObj.placeHolderText}}</span>

        <div class="overflowClass" style="display: flex;align-items: center;">
            <span id="spn{{ctrlObj.id}}" *ngIf="ctrlObj.controlName === 'Text'"
            [style.fontFamily]="ctrlObj.textFormatting.fontFamily"
            [style.fontStyle]="ctrlObj.textFormatting.fontStyle"
            [style.fontSize]="ctrlObj.textFormatting.fontSize"
            [style.color]="ctrlObj.textFormatting.color"
            [style.textDecoration]="ctrlObj.textFormatting.textDecoration"
            [style.fontWeight]="ctrlObj.textFormatting.fontWeight"
            [style.textAlign]="ctrlObj.textFormatting.textAlign"
            [style.lineHeight]="ctrlObj.textFormatting.lineHeight"
            style="position: relative;
            letter-spacing: 1px;
            padding-top: 2px;padding-left: 2px;
            display: flex; align-items: center;">{{ctrlObj.placeHolderText}}</span>
        </div>

        <div class="pl-5px" style="display: flex;align-items: center;">
            <span id="spn{{ctrlObj.id}}" *ngIf="ctrlObj.controlName == 'Date'"
                [style.fontFamily]="ctrlObj.textFormatting.fontFamily"
                [style.fontStyle]="ctrlObj.textFormatting.fontStyle"
                [style.fontSize]="ctrlObj.textFormatting.fontSize"
                [style.color]="ctrlObj.textFormatting.color"
                [style.textDecoration]="ctrlObj.textFormatting.textDecoration"
                [style.fontWeight]="ctrlObj.textFormatting.fontWeight"
                [style.textAlign]="ctrlObj.textFormatting.textAlign"

                style="position: relative;letter-spacing: 1px;
                padding-left: 2px;text-wrap: nowrap;">{{ctrlObj.controlDateFormat}}</span>
        </div>
        <!-- [style.lineHeight]="ctrlObj.textFormatting.lineHeight" -->

        <div class="overflowClass" style="display: flex;align-items: center;">
            <span *ngIf="ctrlObj.controlName === 'Name'" id="spn{{ctrlObj.id}}"
                [style.fontFamily]="ctrlObj.textFormatting.fontFamily"
                [style.fontStyle]="ctrlObj.textFormatting.fontStyle"
                [style.fontSize]="ctrlObj.textFormatting.fontSize"
                [style.color]="ctrlObj.textFormatting.color"
                [style.textDecoration]="ctrlObj.textFormatting.textDecoration"
                [style.fontWeight]="ctrlObj.textFormatting.fontWeight"
                [style.textAlign]="ctrlObj.textFormatting.textAlign"
                [style.lineHeight]="ctrlObj.textFormatting.lineHeight"
                style="position: relative;letter-spacing: 1px;padding-top:2px;padding-left: 2px;">{{ctrlObj.placeHolderText}}</span>
        </div>


        <span *ngIf="ctrlObj.controlName === 'Signature'" id="spn{{ctrlObj.id}}" class="set-default-signature"
            [style.color]="ctrlObj.textFormatting.color"></span>

            <span *ngIf="ctrlObj.controlName === 'NewInitials'" id="spn{{ctrlObj.id}}" class="set-default-initials"
            [style.color]="ctrlObj.textFormatting.color"></span>

        <input id="spn{{ctrlObj.id}}" *ngIf="ctrlObj.controlName === 'Radio'" type="radio" class="radio-dependencie"
            style="appearance:none;
                border-radius:50%; background-color: rgb(255 255 255); border: 1px solid; place-self: center;width: 18px;height: 18px;" />

        <!-- [style.width.px]="width" [style.height.px]="height" -->

        <input id="spn{{ctrlObj.id}}" *ngIf="ctrlObj.controlName === 'Checkbox'" type="checkbox"
            class="checkbox-dependencie checkbox-bgcolor checkbox-hover"
            style="appearance:none; background-color: rgb(255 255 255);border:1px solid #111; place-self: center;width: 18px;height: 18px;" />

            <!--width: 19px;height: 18px; margin-top: 3px; background: rgb(216 214 214 / 82%) !important   [style.width.px]="width" [style.height.px]="height"-->

        <div *ngIf="ctrlObj.controlName === 'Dropdown'" class="resize-action" (mousedown)="setStatus($event, 1)">
            <select [style.width.px]="width" [style.minWidth.px]="minWidth"
                style="border:none;letter-spacing: 1px;padding-right:2px;font-size: 14px;font-family: Courier;"
                id="spn{{ctrlObj.id}}">
            </select>
        </div>
    </div>
</div>