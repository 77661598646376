<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title font-w600" id="staticBackdropLabel">Confirmation</h5>
        <div class="modalposition" aria-label="Close"> <span
                class="material-icons model-closeicon" (click)="modalRef.hide()">close</span></div>
    </div>
    <div class="modal-body">
        <div class="d-flex align-items-center justify-content-center my-2">
            <!-- src="../../../assets/images/icons/trash2.png" -->
            <img class="text-center" style="height: 90px;" src="../../../assets/images/icons/ClearAllControls.svg"
                title="Delete">
        </div>

        <p class="mb-1 px-3 mt-3 font16 d-flex align-items-center flex-column justify-content-center">
            <span class="mb-1">This action will remove all document controls.</span>
            <span class="d-flex align-items-center">
                <!-- <i class="fa fa-hand-o-right me-2 font20" aria-hidden="true"></i> -->
                Do you want to proceed?</span>
        </p>
    </div>
    <div class="modal-footer justify-content-end">
        <button class="btn back-btn cancelbtn form-btn" (click)="modalRef.hide()">
            Cancel</button>
        <button role="button" class="okbtn btn back-btn form-btn ms-3" (click)="onOk($event)">
            Ok</button>
    </div>
</div>