<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title font-w600" id="staticBackdropLabel">Confirmation</h5>
        <div class="modalposition" aria-label="Close">
            <span class="material-icons model-closeicon" (click)="modalRef.hide()">close</span>
        </div>
    </div>
    <div class="modal-body">
        <p class="mb-1 mt-3 font16 d-flex align-items-center flex-column">
            <label class="text-center d-flex align-items-start"><span class="material-icons me-2 font-w600">
                    keyboard_backspace </span>
                You have clicked on the back button.</label>
            <label for="">There are unsaved changes on the page, choose an option to proceed.</label>
        </p>
        <p class="d-flex justify-content-center mb-1">
            <label class="mt-3 d-flex align-items-center">
                <i class="fa fa-hand-o-right me-2 font20" aria-hidden="true"></i>
                <span class="font-w600" style="color: #c3272e;">No</span>
                <span class="material-icons ms-1 ps-1"> chevron_right</span>Click on this to go to Page 1/2
                without saving current changes.</label>
        </p>
        <p class="d-flex justify-content-center left-n10">
            <label class=" d-flex align-items-center">
                <i class="fa fa-hand-o-right me-2 font20" aria-hidden="true"></i>
                <span class="font-w600" style="color: #3ab16d;">Yes</span>
                <span class="material-icons ms-1"> chevron_right</span>Click on this to go to Page 1/2 after
                saving all the changes.</label>
        </p>
    </div>
    <div class="modal-footer justify-content-end">
        <button class="btn back-btn cancelbtn form-btn" (click)="modalRef.hide()"> No</button>
        <button role="button" class="okbtn btn back-btn form-btn ms-3" (click)="onRemove()"> Yes</button>
    </div>
</div>