import { Injectable } from '@angular/core';
import { ControlPropertyService } from '../services/control.properties.service';

@Injectable({
    providedIn: 'root'
})
export class AlignControlsService {

    constructor(
        private controlPropertyService: ControlPropertyService
    ) { }

    fnRightAlign(selectedItems: any) {
        const arrLeft: number[] = [];
        let RightPoint: any = 0;
        const selectedControlElements = selectedItems;

        selectedControlElements.forEach((ele: any) => {
            let eleRef: any = document.getElementById(ele.id);
            let posObj: any = this.controlPropertyService.getElePositions(eleRef);
            // Parse the value to get the numeric value
            const left = parseFloat(posObj.Left) + eleRef.offsetWidth;
            arrLeft.push(left);
        });

        RightPoint = Math.max(...arrLeft);

        let request: any = {
            //   EnvelopeID: document.getElementById('PrepareGlobalEnvelopeID').getAttribute('value'),
            //   EnvelopeStage: document.getElementById('EnvelopeStage').getAttribute('value'),
            Type: 'RightAlign',
            Controls: []
        };

        selectedControlElements.forEach((elementRef: any) => {
            let element: any = document.getElementById(elementRef.id);
            let posObj: any = this.controlPropertyService.getElePositions(element);
            let rightValue: any = (RightPoint - element.offsetWidth)
            const newTransformValue = `translate3d(${rightValue}px, ${posObj.Top}px, 0px)`;

            // Set the new transform value to the element's style
            element.style.transform = newTransformValue;
            // element.style.left = (RightPoint - element.offsetWidth) + 'px';
            element.style.right = 'inherit';
            const control = this.getControlProperty(elementRef.id);
            request.Controls.push({
            	ID: control.DocumentContentID,
            	XCoordinate: control.XCoordinate,
            	YCoordinate: control.YCoordinate,
            	ZCoordinate: control.ZCoordinate,
            	Left: control.Left,
            	Top: control.Top,
            	Width: control.Width,
            	Height: control.Height
            });
        });
    }

    fnLeftAlign(selectedItems: any) {
        const arrLeft: number[] = [];
        let minLeft = Infinity;
        const selectedControlElements = selectedItems;

        selectedControlElements.forEach((ele: any) => {
            let eleRef: any = document.getElementById(ele.id);
            let posObj: any = this.controlPropertyService.getElePositions(eleRef);
            // Parse the value to get the numeric value
            const left = parseFloat(posObj.Left);
            arrLeft.push(left);
        });

        minLeft = Math.min(...arrLeft);

        let request: any = {
            //   EnvelopeID: this.elementRef.nativeElement.querySelector('#PrepareGlobalEnvelopeID').value,
            //   EnvelopeStage: this.elementRef.nativeElement.querySelector('#EnvelopeStage').value,
            Type: 'LeftAlign',
            Controls: []
        };

        selectedControlElements.forEach((elementRef: any) => {
            let element: any = document.getElementById(elementRef.id);
            let posObj: any = this.controlPropertyService.getElePositions(element);
            const newTransformValue = `translate3d(${minLeft}px, ${posObj.Top}px, 0px)`;

            // Set the new transform value to the element's style
            element.style.transform = newTransformValue;
            element.style.right = 'inherit';
            const control = this.getControlProperty(elementRef.id);
            request.Controls.push({
                ID: control.DocumentContentID,
                XCoordinate: control.XCoordinate,
                YCoordinate: control.YCoordinate,
                ZCoordinate: control.ZCoordinate,
                Left: control.Left,
                Top: control.Top,
                Width: control.Width,
                Height: control.Height
            });
        });
    }

    fnCenterAlign(selectedItems: any) {
        const arrLeft: number[] = [];
        const arrWidth: number[] = [];
        let minLeft = Infinity;
        let centerPoint = 0;
        let maxWidth = 0;
        const selectedControlElements = selectedItems;
        selectedControlElements.forEach((ele: any) => {
            let eleRef: any = document.getElementById(ele.id);
            let posObj: any = this.controlPropertyService.getElePositions(eleRef);
            // Parse the value to get the numeric value
            const left = parseFloat(posObj.Left);
            arrLeft.push(left);
            arrWidth.push(left + eleRef.offsetWidth);
        });

        minLeft = Math.min(...arrLeft);
        maxWidth = Math.max(...arrWidth);
        centerPoint = minLeft + ((maxWidth - minLeft) / 2);

        let request: any = {
            //   EnvelopeID: this.elementRef.nativeElement.querySelector('#PrepareGlobalEnvelopeID').value,
            //   EnvelopeStage: this.elementRef.nativeElement.querySelector('#EnvelopeStage').value,
            Type: 'CenterAlign',
            Controls: []
        };

        selectedControlElements.forEach((elementRef: any) => {
            let element: any = document.getElementById(elementRef.id);
            let posObj: any = this.controlPropertyService.getElePositions(element);
            let left: any = (centerPoint - (elementRef.offsetWidth / 2));
            const newTransformValue = `translate3d(${left}px, ${posObj.Top}px, 0px)`;

            // Set the new transform value to the element's style
            element.style.transform = newTransformValue;

            element.style.right = 'inherit';
            const control = this.getControlProperty(elementRef.id);
            request.Controls.push({
                ID: control.DocumentContentID,
                XCoordinate: control.XCoordinate,
                YCoordinate: control.YCoordinate,
                ZCoordinate: control.ZCoordinate,
                Left: control.Left,
                Top: control.Top,
                Width: control.Width,
                Height: control.Height
            });
        });
    }

    fnTopAlign(selectedItems: any) {
        const arrTop: number[] = [];
        let minTop = Infinity;
        const selectedControlElements = selectedItems;

        selectedControlElements.forEach((ele: any) => {
            let eleRef: any = document.getElementById(ele.id);
            let posObj: any = this.controlPropertyService.getElePositions(eleRef);
            // Parse the value to get the numeric value
            const top = parseFloat(posObj.Top);
            arrTop.push(top);
        });

        minTop = Math.min(...arrTop);

        selectedControlElements.forEach((elementRef: any) => {
            let element: any = document.getElementById(elementRef.id);
            let posObj: any = this.controlPropertyService.getElePositions(element);
            const newTransformValue = `translate3d(${posObj.Left}px, ${minTop}px, 0px)`;

            // Set the new transform value to the element's style
            element.style.transform = newTransformValue;
            element.style.right = 'inherit';
        });
    }

    fnBottomAlign(selectedItems: any) {
        const arrBottom: number[] = [];
        let BottomPoint = 0;
        const selectedControlElements = selectedItems;

        selectedControlElements.forEach((ele: any) => {
            let eleRef: any = document.getElementById(ele.id);
            let posObj: any = this.controlPropertyService.getElePositions(eleRef);
            // Parse the value to get the numeric value
            const top = parseFloat(posObj.Top + eleRef.offsetHeight);
            arrBottom.push(top);
        });

        BottomPoint = Math.max(...arrBottom);

        selectedControlElements.forEach((ele: any) => {
            let getdataControlname: any = document.getElementById(ele.id.replace('resizable_', ''));
            const controlName = getdataControlname.getAttribute('data-controlname');

            let element: any = document.getElementById(ele.id);
            let posObj: any = this.controlPropertyService.getElePositions(element);
            let newTransformValue: any = `translate3d(${posObj.Left}px, ${posObj.Top}px, 0px)`;

            // Set the new transform value to the element's style
            element.style.transform = newTransformValue;

            if (controlName === 'Signature') {
                //ele.style.top = (BottomPoint - ele.querySelector('.labelWrap').offsetHeight) + 'px';
                let topVal: any = (BottomPoint - ele.offsetHeight);
                newTransformValue = `translate3d(${posObj.Left}px, ${topVal}px, 0px)`;
            } else {
                let topVal: any = (BottomPoint - ele.offsetHeight);
                newTransformValue = `translate3d(${posObj.Left}px, ${topVal}px, 0px)`;
            }
            element.style.transform = newTransformValue;
            ele.style.bottom = 'inherit';
        });
    }

    fnMiddleAlign(selectedItems: any) {
        const arrTop: number[] = [];
        const arrHeight: number[] = [];
        let minTop = Infinity;
        let centerPoint = 0;
        let maxHeight = 0;
        const selectedControlElements = selectedItems;
        selectedControlElements.forEach((ele: any) => {
            let eleRef: any = document.getElementById(ele.id);
            let posObj: any = this.controlPropertyService.getElePositions(eleRef);
            const top = parseFloat(posObj.Top);
            arrTop.push(top);
            arrHeight.push(top + eleRef.offsetHeight);
        });

        minTop = Math.min(...arrTop);
        maxHeight = Math.max(...arrHeight);
        centerPoint = minTop + ((maxHeight - minTop) / 2);

        selectedControlElements.forEach((elementRef: any) => {
            let element: any = document.getElementById(elementRef.id);
            let posObj: any = this.controlPropertyService.getElePositions(element);
            let top: any = (centerPoint - (elementRef.offsetHeight / 2));
            const newTransformValue = `translate3d(${posObj.Left}px, ${top}px, 0px)`;

            // Set the new transform value to the element's style
            element.style.transform = newTransformValue;
            element.style.bottom = 'inherit';
        });
    }

    fnDistributeHorizontal(selectedItems: any) {
        let arrLeft: any[] = [];
        let arrWidth: any[] = [];
        let minLeft: any = 0;
        let minLeftWithControl: any = 0;
        let maxLeft: any = 0;
        let maxLeftWithControl: any = 0;
        let DistWidth: any = 0;
        let selectedCntrlAndWidth: any[] = [];

        let selectedControl: any = selectedItems;

        if (selectedControl.length > 2) {
            let cntrlLeft: any = 0;
            let allCntrlWidth: any = 0;
            selectedControl.forEach((ele: any, i: any) => {
                let eleRef: any = document.getElementById(ele.id);
                let posObj: any = this.controlPropertyService.getElePositions(eleRef);
                cntrlLeft = parseFloat(posObj.Left);
                selectedCntrlAndWidth.push({ "Control": ele, "Left": cntrlLeft });
                arrLeft.push(cntrlLeft);

                allCntrlWidth += eleRef.offsetWidth;
                arrWidth.push(parseFloat(cntrlLeft) + eleRef.offsetWidth);
            });

            minLeftWithControl = Math.min.apply(null, arrWidth);
            maxLeftWithControl = Math.max.apply(null, arrWidth);
            minLeft = Math.min.apply(null, arrLeft);
            maxLeft = Math.max.apply(null, arrLeft);

            if (maxLeft > minLeftWithControl) {
                DistWidth = ((maxLeftWithControl - minLeft) - allCntrlWidth) / (selectedControl.length - 1);
            }

            if (DistWidth != 0) {
                selectedCntrlAndWidth = selectedCntrlAndWidth.sort(function (x, y) { return ((x.Left > y.Left) ? 1 : (x.Left < y.Left) ? -1 : 0) });

                let controlLeft: any = 0;
                let controlLeftPos: any = 0;
                let prevCntrlLeft: any = minLeftWithControl;
                let pageWidth: any = 0;

                selectedCntrlAndWidth.forEach((ctrl: any, i: any) => {
                    let eleRef: any = document.getElementById(ctrl.Control.id);
                    if (eleRef) {
                        let posObj: any = this.controlPropertyService.getElePositions(eleRef);
                        controlLeft = parseFloat(posObj.Left);
                        let imgEle: any = document.getElementsByClassName('dispImg');
                        let getImgIndex: any = parseInt(eleRef.getAttribute('pageno'));
                        pageWidth = imgEle[getImgIndex].offsetWidth;

                        let ctrlWidth: any = document.getElementById(ctrl.Control.id);
                        if (minLeftWithControl != (controlLeft + ctrlWidth.offsetWidth)) {
                            controlLeftPos = controlLeft + (DistWidth - (controlLeft - prevCntrlLeft));
                            prevCntrlLeft = controlLeftPos + ctrlWidth.offsetWidth;

                            if (pageWidth > prevCntrlLeft) {
                                let element: any = document.getElementById(ctrl.Control.id);
                                let posObj: any = this.controlPropertyService.getElePositions(element);
                                const newTransformValue = `translate3d(${controlLeftPos}px, ${posObj.Top}px, 0px)`;

                                // Set the new transform value to the element's style
                                element.style.transform = newTransformValue;
                                element.style.right = 'inherit';
                            } else {
                                prevCntrlLeft = (controlLeft + ctrlWidth.offsetWidth);
                            }
                        }
                    }
                });
            }
        }
    }

    fnDistributeVertical(selectedItems: any) {
        let arrTop: any[] = [];
        let arrHeight: any[] = [];
        let minTop: any = 0;
        let minTopWithControl: any = 0;
        let maxTop: any = 0;
        let maxTopWithControl: any = 0;
        let DistHeight: any = 0;
        let selectedCntrlAndHeight: any[] = [];

        let selectedControl: any = selectedItems;

        if (selectedControl.length > 2) {
            let cntrlTop: any = 0;
            let allCntrlHeight: any = 0;

            selectedControl.forEach((ele: any, i: any) => {
                let eleRef: any = document.getElementById(ele.id);
                let posObj: any = this.controlPropertyService.getElePositions(eleRef);
                cntrlTop = parseFloat(posObj.Top);
                selectedCntrlAndHeight.push({ "Control": ele, "Top": cntrlTop });
                arrTop.push(cntrlTop);

                allCntrlHeight += eleRef.offsetHeight;
                arrHeight.push(parseFloat(cntrlTop) + eleRef.offsetHeight);
            });

            minTopWithControl = Math.min.apply(null, arrHeight);
            maxTopWithControl = Math.max.apply(null, arrHeight);
            minTop = Math.min.apply(null, arrTop);
            maxTop = Math.max.apply(null, arrTop);

            if (maxTop > minTopWithControl) {
                DistHeight = ((maxTopWithControl - minTop) - allCntrlHeight) / (selectedControl.length - 1);
            }

            if (DistHeight != 0) {
                selectedCntrlAndHeight = selectedCntrlAndHeight.sort(function (x, y) { return ((x.Top > y.Top) ? 1 : (x.Top < y.Top) ? -1 : 0) });

                let controlTop: any = 0;
                let controlTopPos: any = 0;
                let prevCntrlTop: any = minTopWithControl;
                let pageHeight: any = 0;
                selectedCntrlAndHeight.forEach((ctrl: any, i: any) => {
                    let eleRef: any = document.getElementById(ctrl.Control.id);
                    if (eleRef) {
                        let posObj: any = this.controlPropertyService.getElePositions(eleRef);
                        controlTop = parseFloat(posObj.Top);
                        let imgEle: any = document.getElementsByClassName('dispImg');
                        let getImgIndex: any = parseInt(eleRef.getAttribute('pageno'));
                        pageHeight = imgEle[getImgIndex].offsetHeight;

                        let ctrlHeight: any = document.getElementById(ctrl.Control.id);
                        if (minTopWithControl != (controlTop + ctrlHeight.offsetHeight)) {
                            controlTopPos = controlTop + (DistHeight - (controlTop - prevCntrlTop));
                            prevCntrlTop = controlTopPos + ctrlHeight.offsetHeight;

                            if (pageHeight > prevCntrlTop) {
                                let element: any = document.getElementById(ctrl.Control.id);
                                let posObj: any = this.controlPropertyService.getElePositions(element);
                                const newTransformValue = `translate3d(${posObj.Left}px, ${controlTopPos}px, 0px)`;

                                // Set the new transform value to the element's style
                                element.style.transform = newTransformValue;
                                element.style.bottom = 'inherit';
                            } else {
                                prevCntrlTop = (controlTop + ctrlHeight.offsetHeight);
                            }
                        }
                    }
                });
            }
        }
    }

    getControlProperty(id: string) {
		// Implement your logic to fetch control properties based on its ID
		return {
			DocumentContentID: 'sampleID',
			XCoordinate: 0,
			YCoordinate: 0,
			ZCoordinate: 0,
			Left: 0,
			Top: 0,
			Width: 0,
			Height: 0
		};
	}

    getSelectedItems(positionsObj:any, selectedDocumentImage:any,container:any) {
		// Get the boundaries of the container
		const minX = Math.min(positionsObj.startPointX, positionsObj.endPointX);
		const maxX = Math.max(positionsObj.startPointX, positionsObj.endPointX);
		const minY = Math.min(positionsObj.startPointY, positionsObj.endPointY);
		const maxY = Math.max(positionsObj.startPointY, positionsObj.endPointY);

		let selectedItems:any[] = [];

		if(maxX && maxY && selectedDocumentImage){
			let imgId:any = parseInt(selectedDocumentImage.split('_')[1]);
			let items: any = container.nativeElement.children[imgId - 1].children;

			for (let i = 0; i < items.length; i++) {
				if (i > 1) {
					let pageNo:any = items[i].childNodes[0].getAttribute('data-pageno');
					if( parseInt(pageNo) === imgId){
						let resizableEle: any = document.getElementById('resizable_' + items[i].childNodes[0].id);
						let elePos: any = this.controlPropertyService.getElePositions(resizableEle);
						// Assuming itemRect is calculated based on known positions and dimensions of the item
						// Adjust this calculation according to your specific layout
						const itemRect = {
							left: parseInt(elePos.Left), // Example: Extract left position from inline style
							top: parseInt(elePos.Top), // Example: Extract top position from inline style
							width: parseInt(resizableEle.style.width), // Example: Extract width from inline style
							height: parseInt(resizableEle.style.height) // Example: Extract height from inline style
						};

						const isInsideX = itemRect.left < maxX && itemRect.left + itemRect.width > minX;
						const isInsideY = itemRect.top < maxY && itemRect.top + itemRect.height > minY;

						if (isInsideX && isInsideY) {
							selectedItems.push(resizableEle);
							resizableEle.classList.add('mouse-selected');
							// Add selected class or apply styles as needed
						} else {
							resizableEle.classList.remove('mouse-selected');
						}
					}
				}
			}
		}

        return selectedItems;
	}

    clearSelection(selectedDocumentImage:any, container:any) {
		let imgId:any = parseInt(selectedDocumentImage.split('_')[1]);

		let items: any = container.nativeElement.children[imgId - 1].children;
		for (let i = 0; i < items.length; i++) {
			if (i > 1) {
				let resizableEle: any = document.getElementById('resizable_' + items[i].childNodes[0].id);
                if(resizableEle){
                    resizableEle.classList.remove('mouse-selected');
                }
			}
		}
	}

    applyAlignChanges(selectedOptionVal: any, selectedItems:any, selectedDocumentImage:any, container:any) {
		switch (selectedOptionVal) {
			case 'left':
				this.fnLeftAlign(selectedItems);
				break;
			case 'center':
				this.fnCenterAlign(selectedItems);
				break;
			case 'right':
				this.fnRightAlign(selectedItems);
				break;
			case 'top':
				this.fnTopAlign(selectedItems);
				break;
			case 'middle':
				this.fnMiddleAlign(selectedItems);
				break;
			case 'bottom':
				this.fnBottomAlign(selectedItems);
				break;
			case 'distributehorizontally':
				this.fnDistributeHorizontal(selectedItems);
				break;
			case 'distributevertically':
				this.fnDistributeVertical(selectedItems);
				break;
		}

		this.clearSelection(selectedDocumentImage, container);
	}

}
