import { Component } from '@angular/core';

@Component({
  selector: 'app-template-prepare',
  templateUrl: './template-prepare.component.html',
  styleUrls: ['./template-prepare.component.scss']
})
export class TemplatePrepareComponent {

}
