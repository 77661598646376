import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-goto-step-one',
	templateUrl: './goto-step-one.component.html',
	styleUrls: ['./goto-step-one.component.scss']
})
export class GotoStepOneComponent {
	@Output() gotoStepOneChangesMade: EventEmitter<any> = new EventEmitter();

	constructor(
		public modalRef: BsModalRef
	) { }

	ngOnInit(): void {
	}

	onRemove(){
		this.modalRef.hide();
	}
}
