import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxColorsModule } from 'ngx-colors';
import { AppComponent } from './app.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';

import { PaginationComponent } from './common/pagination/pagination.component';
import { ControlComponent } from './components/control/control.component';
import { ResizableDraggableComponent } from './components/resizable-draggable/resizable-draggable.component';
import { EnvelopePrepareComponent } from './components/envelope-prepare/envelope-prepare.component';
import { TemplatePrepareComponent } from './components/template-prepare/template-prepare.component';
import { PreviewViewComponent } from './components/preview-view/preview-view.component';
import { AddDropdownListComponent} from './modals/add-dropdown-list/add-dropdown-list.component';
import { AddDependenciesComponent } from './modals/add-dependencies/add-dependencies.component';
import { ViewSettingComponent } from './modals/view-setting/view-setting.component';
import { KeyboardShortcutsComponent } from './modals/keyboard-shortcuts/keyboard-shortcuts.component';
import { GotoStepOneComponent } from './modals/goto-step-one/goto-step-one.component';
import { DiscardEnvelopeComponent } from './modals/discard-envelope/discard-envelope.component';
import { ClearallControlsComponent } from './modals/clearall-controls/clearall-controls.component';
import { GlobalErrorComponent } from './components/global-error/global-error.component';
import { InitializePrepareComponent } from './components/initialize-prepare/initialize-prepare.component';
import { RecipientsComponent } from './components/recipients/recipients.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { ViewPdfComponent } from './components/view-pdf/view-pdf.component';
import { ContextualMenuComponent } from './components/contextual-menu/contextual-menu.component';
import { ContextualMenuDirective } from './components/contextual-menu/contextual-menu.directive';
import { NumberArrayPipe } from './pipe/number-array.pipe';
import { TranslationService } from './services/translation.service';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        PaginationComponent,
        ControlComponent,
        ResizableDraggableComponent,
        EnvelopePrepareComponent,
        TemplatePrepareComponent,
        PreviewViewComponent,
        AddDropdownListComponent,
        AddDependenciesComponent,
        ViewSettingComponent,
        KeyboardShortcutsComponent,
        GotoStepOneComponent,        
        DiscardEnvelopeComponent,
        ClearallControlsComponent,
        GlobalErrorComponent,
        InitializePrepareComponent,
        RecipientsComponent,
        ViewPdfComponent,
        ContextualMenuComponent,
        ContextualMenuDirective,
        NumberArrayPipe,
    ],
    imports: [
        BrowserModule, FormsModule, ReactiveFormsModule, BrowserAnimationsModule,
        AppRoutingModule, NgxColorsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            closeButton: true,
            progressBar: false,
            positionClass:'toast-top-right',
            preventDuplicates: true,
            newestOnTop:true
        }),
        DragDropModule,
        ModalModule.forRoot(),
        HttpClientModule        
    ],
    providers: [BsModalRef, AuthService, TranslationService],
    exports: [NumberArrayPipe],
    bootstrap: [AppComponent]
})
export class AppModule { }
