import { Directive, HostListener, Input } from '@angular/core';
import { ContextualMenuComponent } from './contextual-menu.component';
import { ContextualMenu } from './contextual-menu';

@Directive({
    selector: '[appContextualMenu]'
})
export class ContextualMenuDirective {

    makes: any[] = [];
    constructor() {
        this.makes = [];
    }
    @HostListener('contextmenu', ['$event'])
    public documentRClick(event: MouseEvent): void {
        event.preventDefault();
        this.appContextualMenu.show(this.contextElements);
    }

    @Input() appContextualMenu: ContextualMenuComponent;
    @Input() contextElements: ContextualMenu[];

}
