import { Component, OnInit, Input, HostListener, ChangeDetectorRef } from '@angular/core';
const FILTER_PAG_REGEX = /[^0-9]/g;
import * as $ from 'jquery';
import { CommonService } from '../../services/common.service';
import { NumberArrayPipe } from '../../pipe/number-array.pipe';

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
	page: any = 1;
	collectionSize: any = 10;
	@Input() documentData: any = {};
	documentDataLength: any = 1;
	pageVal: any = 1;
	pageCurrentVal: any = 1;
	isLastPage: boolean = false;
	previewObject:any = {};
	pagenationDropdown: any = ["1", "2", "3", "4"];
	constructor(
		private cdRef: ChangeDetectorRef,
		private commonService: CommonService
	) {
		this.commonService.previewObject$.subscribe((previewObject) => {
			this.previewObject = previewObject;
			this.pageVal = this.previewObject.pageNumber;
		});

		//In input tag, To restrict the characters when user copy the some text and paste to input tag
		document.addEventListener("DOMContentLoaded", function() {
			let numericInput:any = document.getElementById('paginationInput');

			// Listen for the paste event
			numericInput.addEventListener('paste', function(event:any) {
				// Prevent the default paste action
				event.preventDefault();

				// Get the pasted text
				var clipboardData = event.clipboardData;
				var pastedText = clipboardData.getData('text');

				// Check if the pasted text contains only numeric characters
				if (/^\d+$/.test(pastedText)) {
					// If it contains only numeric characters, allow pasting
					numericInput.value += pastedText;
				} else {
					// If it contains non-numeric characters, do not allow pasting
					//console.log("Only numeric text is allowed!");
				}
			});
		});
	}

	@HostListener('document:wheel', ['$event'])
	onDocumentWheelEvent(event: WheelEvent) {
		if (event && event.target && (event.target as HTMLElement).classList.contains('dispImg')) {
			this.pageVal = parseInt((event.target as HTMLElement).id);
			localStorage.setItem('currentPagination', this.pageVal);
			this.updatePageNumber(this.pageVal);
			this.cdRef.detectChanges();
		}
	}

	ngOnInit(): void {
		if (this.documentData && this.documentData.pages && this.documentData.pages.length) {
			this.collectionSize = this.documentData.totalImagesCount * 10; //this.documentData.pages.length * 10;
			this.documentDataLength = this.documentData.totalImagesCount ; //this.documentData.pages.length;
		} else {
			this.collectionSize = 10;
			this.documentDataLength = 1;
		}
		this.page = 1;
		localStorage.setItem('currentPagination', this.page);
		this.pageVal = this.page;
	}

	formatInput(input: HTMLInputElement) {
		input.value = input.value.replace(FILTER_PAG_REGEX, '');
	}

	// forRestrictNumbers(evt: any) {
	// 	var charCode = (evt.which) ? evt.which : evt.keyCode
	// 	if (charCode > 31 && (charCode < 48 || charCode > 57))
	// 		return false;
	// 	return true;
	// }

	onPageSelection(event: any, type: any) {
		let pageCount: any = localStorage.getItem('currentPagination')
		this.page = parseInt(pageCount);
		if (type === 'paginationInput') {
			let idValue: any = parseInt(event.target.value);
			if (idValue !== 0 && idValue <= this.documentData.pages.length) {
				localStorage.setItem('currentPagination', idValue);
				this.pageVal = idValue;
				this.onPaginateChange(this.pageVal);
			} else {
				let currentPagination: any = localStorage.getItem('currentPagination');
				let currentPageValue: any = parseInt(currentPagination);
				if (idValue === 0 || idValue > this.documentData.pages.length) {
					this.pageVal = currentPageValue;
					event.target.value = currentPageValue;
				}

				localStorage.setItem('currentPagination', this.pageVal);
				this.pageVal = this.pageVal;
				this.onPaginateChange(this.pageVal);
			}
		}
		if (type === 'first') {
			this.page = 1;
			localStorage.setItem('currentPagination', this.page);
			this.pageVal = this.page;
			this.onPaginateChange(this.pageVal);
		}
		if (type === 'prev') {
			if (this.page > 1) {
				this.page = this.page - 1;
				localStorage.setItem('currentPagination', this.page);
				this.pageVal = this.page;
				this.onPaginateChange(this.pageVal);
			}
		}
		if (type === 'next') {
			if (this.page < this.documentData.pages.length) {
				this.page = this.page + 1;
				localStorage.setItem('currentPagination', this.page);
				this.pageVal = this.page;
				this.onPaginateChange(this.pageVal);
			}
		}
		if (type === 'last') {
			this.page = this.documentData.pages.length;
			localStorage.setItem('currentPagination', this.page);
			this.pageVal = this.page;
			this.onPaginateChange(this.pageVal);
		}
	}

	onPaginateChange(id: any) {
		if (id !== undefined && (id <= this.documentData.pages.length)) {
			let firstPageElement = $('img.imgControl[page-no="1"]');
			let firstPageNoAttr = firstPageElement.offset()?.top;

			let pageNoElement = $('img.imgControl[page-no="' + id + '"]');
			let pageNoAttr = pageNoElement.offset()?.top;

			if (firstPageNoAttr !== undefined && pageNoAttr !== undefined) {
				let scrollTo = Math.abs(pageNoAttr - firstPageNoAttr) - 20;
				$('.docImages').animate({ scrollTop: scrollTo }, 'slow');
				//this.getPreviewSelected(id);
				this.updatePageNumber(id);
				this.cdRef.detectChanges();
			}
		}
	}

	updatePageNumber(id:any){
		this.commonService.updatePrevObject({
			'totalControls': this.previewObject.totalControls,
			'pageNumber': parseInt(id),
			'documentList': this.previewObject.documentList
		});
	}

	getPreviewSelected(p: any) {
		Array.from(document.getElementsByClassName("thumbnails-page-btn") as HTMLCollectionOf<HTMLElement>)
			.forEach((pcard: any, index: any) => {
				let ele: any = document.getElementById(pcard.id);
				if (ele.classList.contains('active-preview')) {
					ele.classList.remove('active-preview')
				}
			})

		let prevPageEle: any = document.getElementById('pageCountColor' + p);
		if (prevPageEle && prevPageEle.classList) {
			prevPageEle.classList.add('active-preview');
		}
	}

	onPaste(event: ClipboardEvent) {
		// Handle the paste event here
		//const pastedText = event.clipboardData?.getData('text');
		//console.log('Pasted text:', pastedText);
		let inputVal:any = document.getElementById('paginationInput');
		if(inputVal){
			inputVal.value = inputVal.value.replace(FILTER_PAG_REGEX, '');
		}
	}

}
