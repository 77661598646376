export class ContextualMenu {

    label: string;
    action: () => void;
    submenu: ContextualMenu[];
    disabled: boolean;
    visible: boolean; // only used to hide or show sub menu
    active: boolean;
    icon: string;

    constructor(
        label: string,
        action: () => void | null,
        // submenu: ContextualMenu[] = null,
        disabled = false,
        visible = false,
        active = false,
        icon = ''
    ) {
        this.label = label;
        this.action = action;
        // this.submenu = submenu;
        this.disabled = disabled;
        this.visible = visible;
        this.active = active;
        this.icon = '';
    }
}