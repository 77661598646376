import { Component, Input } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as $ from 'jquery';
@Component({
	selector: 'app-preview-view',
	templateUrl: './preview-view.component.html',
	styleUrls: ['./preview-view.component.scss']
})
export class PreviewViewComponent {
	private ngUnsubscribe = new Subject();
	previewObject: any = {};
	@Input() previewObj: any;
	// @Input() documentData:any

	constructor(
		private commonService: CommonService
	) {
		this.commonService.previewObject$.subscribe((previewObject) => {
			this.previewObject = previewObject;
		});
	}

	ngOnInit() {
		this.commonService.previewObject$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((updatedObject) => {
				// Handle the updated object
				// console.log('Received updated object:', updatedObject);
				this.previewObject = updatedObject;
			});
	}

	onPreviewImage(prevObj:any, id:any){
		this.commonService.updatePrevObject({
			'totalControls': this.previewObject.totalControls,
			'pageNumber': parseInt(id),
			'documentList': this.previewObject.documentList
		});

		if (id !== undefined) {
			let firstPageElement = $('img.imgControl[page-no="1"]');
			let firstPageNoAttr = firstPageElement.offset()?.top;

			let pageNoElement = $('img.imgControl[page-no="' + id + '"]');
			let pageNoAttr = pageNoElement.offset()?.top;

			if (firstPageNoAttr !== undefined && pageNoAttr !== undefined) {
				let scrollTo = Math.abs(pageNoAttr - firstPageNoAttr) - 20;
				$('.docImages').animate({ scrollTop: scrollTo }, 'slow');
			}
		}
	}

	ngOnDestroy(): void {
		// this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}

}
