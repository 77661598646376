import { EmbeddedViewRef, Injectable } from '@angular/core';
import { CommonConstants, Control, ControlConfig, ControlResourceKeys } from '../constants/common-constants';
import { ToastrService } from 'ngx-toastr';
import { ControlHtmlService } from './control-html.service';
import { CommonService } from './common.service';
import { AuthService } from './auth.service';
import { Guid } from "typescript-guid";
@Injectable({
    providedIn: 'root'
})
export class ControlPropertyService {
    commonConstants = CommonConstants;
    arrControlIdNumber: any = [];
    controlFieldDependenceControlArray: any = [];
    hideFixedwidthCheckbox: any;

    constructor(private toastrService: ToastrService, private controlHtmlService: ControlHtmlService, private commonService: CommonService, private authService: AuthService) {

    }

    applyCheckboxRadioGroupName(textAreaVal: any, type: any) {
        let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let resizableCurrentSelectedControl = (<HTMLInputElement>document.getElementById("resizable_" + currentSelectedControl));

        if (resizableCurrentSelectedControl) {
            if (textAreaVal == '' || textAreaVal == null) {
                let attr_id: any = resizableCurrentSelectedControl.getAttribute('data-guid');
                if (attr_id.toLowerCase() == Control.Radio.toLowerCase()) {
                    let chkRadioRequiredSelected: any = (<HTMLInputElement>document.getElementById("chkRadioRequiredSelected"));
                    if (chkRadioRequiredSelected.checked) {
                        if (type == "1") {
                            resizableCurrentSelectedControl?.setAttribute('data-groupname', '');
                            this.toastrService.warning('Please enter valid radio group value.', 'Warning');
                            return false;
                        }
                        else if (type == "2") {
                            let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl));
                            if (spnCurrentSelectedControl) {
                                spnCurrentSelectedControl?.setAttribute('name', '');
                            }
                            this.toastrService.warning('Radio group name cannot be blank.', 'Warning');
                            return false;
                        }
                    }
                }
                else if (attr_id.toLowerCase() == Control.Checkbox.toLowerCase()) {
                    let checkboxgroup: any = (<HTMLInputElement>document.getElementById("checkboxgroup"));
                    if (checkboxgroup.checked) {
                        if (type == "1") {
                            resizableCurrentSelectedControl?.setAttribute('data-groupname', '');
                            this.toastrService.warning('Please enter valid checkbox group value.', 'Warning');
                            return false;
                        }
                        else if (type == "2") {
                            let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl));
                            if (spnCurrentSelectedControl) {
                                spnCurrentSelectedControl?.setAttribute('name', '');
                            }
                            this.toastrService.warning('Checkbox group name cannot be blank.', 'Warning');
                            return false;
                        }
                    }
                }
            }
            else {
                if (type == "1") {
                    let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("resizable_" + currentSelectedControl));
                    if (spnCurrentSelectedControl) {
                        spnCurrentSelectedControl?.setAttribute('data-groupname', textAreaVal);
                    }

                    let inputGroupNameCtrl: any = (<HTMLInputElement>document.getElementById("inputGroupNameCtrl"));
                    if (inputGroupNameCtrl && inputGroupNameCtrl.value != '') {
                        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl));
                        if (spnCurrentSelectedControl) {
                            spnCurrentSelectedControl?.setAttribute('name', inputGroupNameCtrl.value);
                        }
                    }
                }
                else if (type == "2") {
                    let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl));
                    if (spnCurrentSelectedControl) {
                        spnCurrentSelectedControl?.setAttribute('name', textAreaVal);
                    }

                    let inputGroupCtrl: any = (<HTMLInputElement>document.getElementById("inputGroupCtrl"));
                    if (inputGroupCtrl && inputGroupCtrl.value != '') {
                        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("resizable_" + currentSelectedControl));
                        if (spnCurrentSelectedControl) {
                            spnCurrentSelectedControl?.setAttribute('data-groupname', inputGroupCtrl.value);
                        }
                    }
                }
            }
        }
        return true;
    }

    checkTooltipIsApplicable(cName: any, cId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + cId));
        if (controlEle) {
            let tooltip: any = controlEle.getAttribute('data-customtooltip');
            if (tooltip === null) {
                let controlTooltipEle: any = document.getElementById('controlInputTooltip');
                if (controlTooltipEle) {
                    controlTooltipEle.value = '';
                }
            }

            let guid: any = controlEle.getAttribute('data-guid');
            this.removeControlAttributes(controlEle, guid);
        }
    }

    getParentWidthAndHeight(currentEle: any) {
        let response: any;
        let imgId: any = currentEle.parentNode.parentNode.id;
        let imgControlElement: any = document.getElementById(imgId);
        let imgWidth: any = null;
        let imgHeight: any = null;
        // Access the first child element (assuming it is the img tag)
        var imgElement = imgControlElement.children[0];

        // Check if the child element is an img tag
        if (imgElement.tagName.toLowerCase() === 'img') {
            // Set the CSS style width property
            imgWidth = imgElement.offsetWidth;
            imgHeight = imgElement.offsetHeight;
        }
        response = { 'Width': imgWidth, 'Height': imgHeight };
        return response;
    }

    getElePositions(ctrlId: any) {
        if(ctrlId && ctrlId.style){
            var transformString = ctrlId.style.transform;
            // Regular expression to match the numbers within the translate3d function
            var regex = /translate3d\(([-\d.]+)px,\s+([-\d.]+)px,\s+([-\d.]+)px\)/;
            let response: any = { 'Left': 0, 'Top': 0 };
            // Match the values using the regex
            var match = transformString.match(regex);

            // Check if there is a match
            if (match) {
                // Extract the left, top, and third value (z-axis, not used in this case)
                var leftValue = parseFloat(match[1]);
                var topValue = parseFloat(match[2]);
                response = { 'Left': leftValue, 'Top': topValue };

            }
            return response;
        }
        return;
    }

    getDuplicateHeightWidth(fontFamily: any, fontSize: any) {
        var fakeEl = document.createElement('pre');
        fakeEl.id = 'hdnTextArea';
        fakeEl.style.display = 'inline-block';
        fakeEl.style.visibility = 'hidden';
        document.body.appendChild(fakeEl);

        fakeEl.textContent = 'abcdefghijklmnopqrstuvwxyz0123456789';
        fakeEl.style.fontFamily = fontFamily;
        fakeEl.style.fontSize = fontSize + 'px';

        var response = { 'Width': (fakeEl.offsetWidth / fakeEl.textContent.length), 'Height': fakeEl.offsetHeight };

        fakeEl.remove();

        if (response.Height === 260 && fontFamily === "Courier") {
            response.Height = 130;
        }

        // Now you can use 'response' object as needed.
        return response;
    }

    getDimensionBasedOnFontSizeAndStyle(fontFamily: any, fontSize: any) {
        var fakeEl = document.createElement('pre');
        fakeEl.id = 'hdnTextArea';
        fakeEl.style.display = 'inline-block';
        fakeEl.style.visibility = 'hidden';
        document.body.appendChild(fakeEl);

        fakeEl.textContent = 'ABCDEFGHIJKLMNOPQRSTUVWWWWWWWWWWWWWWWWWWWWWWWXYZ';
        fakeEl.style.fontFamily = fontFamily;
        fakeEl.style.fontSize = fontSize + 'px';

        var width = (fontSize === 60) ? 53 : (fakeEl.offsetWidth / fakeEl.textContent.length);
        var height = fakeEl.offsetHeight;

        var response = { 'Width': width, 'Height': height };

        fakeEl.remove();
        return response;
    }

    applyClickedProperty(checked: any, type: any) {
        let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let spnCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById("resizable_" + currentSelectedControl));
        let parentCtrl: any = document.getElementById(currentSelectedControl);
        let ctrlName: any = '';
        if (parentCtrl) {
            ctrlName = parentCtrl.getAttribute('data-controlname');
        }
        if (spnCurrentSelectedControl) {
            if (type == "1") {
                if (checked)
                    spnCurrentSelectedControl?.setAttribute('data-selected', 'true');
                else
                    spnCurrentSelectedControl?.setAttribute('data-selected', 'false');
            }
            else if (type == "2") {
                if (checked)
                    spnCurrentSelectedControl?.setAttribute('data-selected1', 'true');
                else
                    spnCurrentSelectedControl?.setAttribute('data-selected1', 'false');
            }
            else if (type === '3') {
                if (checked) {
                    spnCurrentSelectedControl?.setAttribute('data-isfixedwidth', 'true');
                    let maxCharLimit = '';
                    switch (ctrlName.toLowerCase()) {
                        case 'name':
                            maxCharLimit = '100';
                            break;
                        case 'title':
                            maxCharLimit = '100';
                            break;
                        case 'text':
                            maxCharLimit = spnCurrentSelectedControl.getAttribute('data-validation-type') && spnCurrentSelectedControl.getAttribute('data-validation-type').toLowerCase() === 'email' ? '100' : '4000';
                            break;
                    }

                    let charInputLimitId: string;
                    let inputLengthId: string;

                    if (ctrlName.toLowerCase() === 'text') {
                        charInputLimitId = 'textCharInputLimit';
                        inputLengthId = 'textInputLength';
                    } else {
                        charInputLimitId = ctrlName.toLowerCase() + 'CharInputLimit';
                        inputLengthId = ctrlName.toLowerCase() + 'InputLength';
                    }

                    spnCurrentSelectedControl.setAttribute('data-maxcharlimit', maxCharLimit);

                    let charInputLimit = document.getElementById(charInputLimitId);
                    if (charInputLimit) {
                        this.hideCtrlCharLimitInput(charInputLimit);
                    }

                    let inputLength: any = document.getElementById(inputLengthId);
                    if (inputLength) {
                        this.showCtrlLengthInput(inputLength);
                        inputLength.value = 20;
                        spnCurrentSelectedControl.style.width = '167px';

                        if (ctrlName.toLowerCase() === 'title') {
                            let maxlengthallowedValue: any = spnCurrentSelectedControl.getAttribute('maxlengthallowed');
                            if (maxlengthallowedValue) {
                                inputLength.value = maxlengthallowedValue;
                            } else {
                                inputLength.value = '20';
                                spnCurrentSelectedControl.style.width = '167px';
                            }
                        }
                        if (ctrlName.toLowerCase() === 'text') {
                            spnCurrentSelectedControl.style.height = '20px';
                        }
                    }

                    if (ctrlName.toLowerCase() === 'text') {
                        let dataValidationType: any = spnCurrentSelectedControl.getAttribute('data-validation-type');
                        if (dataValidationType && dataValidationType.toLowerCase() === 'ssn' || dataValidationType.toLowerCase() === 'zip' || dataValidationType.toLowerCase() === 'date' ||
                            dataValidationType.toLowerCase() === 'email') {
                            setTimeout(() => {
                                inputLength.setAttribute('disabled', true);
                            }, 0);
                        }
                    }
                }
                else {
                    spnCurrentSelectedControl?.setAttribute('data-isfixedwidth', 'false');
                    let dataValidationType: any = '';
                    if (ctrlName.toLowerCase() === 'text') {
                        dataValidationType = spnCurrentSelectedControl.getAttribute('data-validation-type');
                    }
                    let controlTypeMap: any = {
                        'name': { inputLengthId: 'nameInputLength', charInputLimitId: 'nameCharInputLimit', maxCharLimit: 100 },
                        'title': { inputLengthId: 'titleInputLength', charInputLimitId: 'titleCharInputLimit', maxCharLimit: 100 },
                        'text': { inputLengthId: 'textInputLength', charInputLimitId: 'textCharInputLimit', maxCharLimit: (dataValidationType.toLowerCase() === 'email') ? 100 : 4000 }
                    };

                    let controlType: any = ctrlName.toLowerCase();
                    const controlInfo = controlTypeMap[controlType];

                    if (controlInfo) {
                        let inputLength: any = document.getElementById(controlInfo.inputLengthId);
                        if (inputLength) {
                            this.hideCtrlLengthInput(inputLength);
                        }

                        let charInputLimit: any = document.getElementById(controlInfo.charInputLimitId);
                        if (charInputLimit) {
                            this.showCtrlCharLimitInput(charInputLimit);
                            const maxCharLimit = controlInfo.maxCharLimit;
                            charInputLimit.value = controlInfo.maxCharLimit;
                            spnCurrentSelectedControl.style.width = '167px';
                            if (controlType === 'text') {
                                spnCurrentSelectedControl.style.height = '20px';
                            }

                            if (charInputLimit.value && charInputLimit.value <= maxCharLimit) {
                                spnCurrentSelectedControl.setAttribute('data-maxcharlimit', charInputLimit.value);
                                charInputLimit.value = charInputLimit.value;
                            } else {
                                charInputLimit.value = maxCharLimit.toString();
                                spnCurrentSelectedControl.setAttribute('data-maxcharlimit', charInputLimit.value);
                            }
                        }
                    }
                }

            }

            //remove dependency if checkbox is required control
            let guid: any = spnCurrentSelectedControl.getAttribute('data-guid');
            if (guid.toLowerCase() == Control.Checkbox.toLowerCase() && type == "1") {
                let isRequired: any = spnCurrentSelectedControl?.getAttribute('data-selected');
                if (isRequired && isRequired.toLowerCase() === 'true') {
                    let spnControl = (<HTMLInputElement>document.getElementById(currentSelectedControl));
                    let docContentId: any = spnControl.getAttribute('data-document-content-id');
                    this.removeColorForItem(currentSelectedControl);
                    document.querySelector('[data-document-content-id="' + docContentId + '"]')?.setAttribute('is-child-present', '');

                    let childControlsArray: any = document.querySelectorAll('[data-parent-id="' + docContentId + '"]');
                    if (childControlsArray && childControlsArray.length > 0) {
                        Array.from(childControlsArray).forEach((item: any, v: any) => {
                            let attr_id: any = item?.getAttribute('id');
                            let childDocContentId: any = item.getAttribute('data-document-content-id');
                            this.removeColorForItem(attr_id);
                            this.removeAttributes(childDocContentId);
                        });
                    }
                }
            }
        }
    }

    removeAttributes(docContentId: any) {
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.setAttribute('data-parent-id', '');
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.classList.remove('is-parent-present');
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.setAttribute('data-parent-rule-id', '');
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.setAttribute('data-parent-support-text-content', '');
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.classList.remove('alreadyDependentField');
        document.querySelector('[data-document-content-id="' + docContentId + '"]')?.classList.remove('addedAsChild');
    }

    setAttributes(dependentItem: any, selectedControlFieldType: any, currentPanelContrlID: any) {
        document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.setAttribute('data-condition-id', dependentItem.ID);
        document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.setAttribute('data-parent-id', currentPanelContrlID);
        document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.setAttribute('is-parent-present', 'true');
        document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.setAttribute('data-parent-rule-id', dependentItem.ConditionID);
        if (selectedControlFieldType && selectedControlFieldType.toLowerCase() == Control.Text.toLowerCase()) {
            document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.setAttribute('data-parent-support-text-content', dependentItem.SupportText);
        }
        document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.classList.add('alreadyDependentField');
        document.querySelector('[data-document-content-id="' + dependentItem.ControlID + '"]')?.classList.add('addedAsChild');
    }

    updateDependecyForDependentControls(currentControlId: any, ctrlContentId: any) {
        let ControlDependencyFieldsString: any = localStorage.getItem('ControlDependencyFieldsArray');
        if (ControlDependencyFieldsString) {
            let controlDependencyFieldsArray = JSON.parse(ControlDependencyFieldsString);
            controlDependencyFieldsArray = controlDependencyFieldsArray.filter((obj: any) => { return obj.ControlId.toLowerCase() != currentControlId.toLowerCase() });
            localStorage.setItem('ControlDependencyFieldsArray', JSON.stringify(controlDependencyFieldsArray));
        }

        let allImageControlsString: any = localStorage.getItem('AllImageControls');
        if (allImageControlsString) {
            let allImageControlsArray = JSON.parse(allImageControlsString);
            allImageControlsArray = allImageControlsArray.filter((obj: any) => { return obj.ControlId.toLowerCase() != currentControlId.toLowerCase() });
            localStorage.setItem('AllImageControls', JSON.stringify(allImageControlsArray));
        }

        //todo update dependencies fot this control if it is parent to othercontrol  data-parent-id
        let conditionalControlsDetailsArray: any = document.querySelectorAll('[data-parent-id="' + ctrlContentId + '"]');
        if (conditionalControlsDetailsArray && conditionalControlsDetailsArray.length > 0) {
            Array.from(conditionalControlsDetailsArray).forEach((existingParentIdCtrl: any, v: any) => {
                let attr_docContentId: any = existingParentIdCtrl.getAttribute('data-document-content-id');
                this.removeAttributes(attr_docContentId);
                this.removeElementColor(attr_docContentId);
            });
        }
    }

    showHideDependenciesButton() {
        let displayDependenciesButton: boolean = false;
        let btnGlblAddRules: any = document.getElementById("btnGlblAddRules");
        let controllingFieldClassArray: any = document.getElementsByClassName("controllingField");
        let className: any = 'disabledCtrl';
        if (controllingFieldClassArray && controllingFieldClassArray.length > 0) {
            displayDependenciesButton = true;
            if (btnGlblAddRules) {
                btnGlblAddRules.classList.remove("disabledCtrl");
                btnGlblAddRules.setAttribute('title', "Add Dependencies");
                className = 'enabledCtrl';
            }
        }
        else {
            displayDependenciesButton = false;
            btnGlblAddRules.classList.remove("enabledCtrl");
            btnGlblAddRules.setAttribute('title', "Add controlling field to the document and add dependencies");
            className = 'disabledCtrl';
        }
        return { className: className, displayDependenciesButton: displayDependenciesButton };
    }

    updateColorCode(groupColorCode: any) {
        let controllingFieldArray: any = document.querySelectorAll('.controllingField');
        if (controllingFieldArray && controllingFieldArray.length > 0) {
            Array.from(controllingFieldArray).forEach((item: any, v: any) => {
                let controlID: any = item.getAttribute('data-document-content-id');
                let attribute_id: any = item.getAttribute('id');
                var isParentPresent = item.getAttribute('is-parent-present');
                var isChildrenPresent = item.getAttribute('is-child-present');

                if (controlID != "") {
                    if (isParentPresent == "false" && isChildrenPresent == "true") {
                        var groupCode = item.getAttribute('group-code');
                        var attr_id = item.getAttribute('id');
                        if (typeof groupCode != 'undefined') {
                            var colorForElement = this.commonConstants.DependencyColorCodes[parseInt(groupCode) % 10].Color;
                            this.applyColorCodeForParent(attr_id, colorForElement, isChildrenPresent);
                            this.getChildsForParent(controlID, colorForElement);
                            groupColorCode = parseInt(groupCode) > groupColorCode ? parseInt(groupCode) : groupColorCode;
                        }
                    }
                    this.removeParentColor(controlID, attribute_id);
                }
            });
        }
        groupColorCode = groupColorCode + 1;
        return groupColorCode;
    }

    getChildsForParent(id: any, elementColor: any) {
        let getChilds = document.querySelectorAll('[data-parent-id="' + id + '"]');
        Array.from(getChilds).forEach((item: any, v: any) => {
            var attr_id = item.getAttribute('id');
            this.applyColorCodeForChild(attr_id, elementColor);
            let isChildPresentForCurrent = item.getAttribute('is-child-present');
            if (isChildPresentForCurrent == "true") {
                let docContentId = item.getAttribute('data-document-content-id');
                this.getChildsForParent(docContentId, elementColor);
                this.removeParentColor(docContentId, attr_id);
            }
        })
    }

    applyColorCodeForParent(attr_id: any, colorCode: any, isChildExists: any) {
        if (isChildExists == "false" || isChildExists == undefined) {
            this.applyColorCodeForItem(attr_id, colorCode);
        }
    }

    applyColorCodeForChild(attr_id: any, colorCode: any) {
        this.applyColorCodeForItem(attr_id, colorCode);
    }

    applyColorCodeForItem(attr_id: any, colorCode: any) {
        let resizableItem: any = document.getElementById("resizable_" + attr_id);
        if (resizableItem) {
            let guid: any = resizableItem.getAttribute('data-guid');
            if (guid && guid.toLowerCase() != Control.Checkbox.toLowerCase() && guid.toLowerCase() != Control.Radio.toLowerCase()) {
                resizableItem.style["background-color"] = colorCode;
                resizableItem.style["border-width"] = '';
            }
        }
        let divItem: any = document.getElementById("div" + attr_id);
        if (divItem) { divItem.style["border-color"] = "transparent transparent transparent " + colorCode; }
        let spnItem: any = document.getElementById("spn" + attr_id);
        if (spnItem) { spnItem.style["background-color"] = colorCode; }
    }

    removeParentColor(docContentId: any, attr_id: any) {
        let parentElement: any = document.querySelector('[data-document-content-id="' + docContentId + '"]');
        if (docContentId != "" && parentElement) {
            var isChildrenPresent = parentElement?.getAttribute('is-child-present');
            var isParPresent = parentElement?.getAttribute('is-parent-present');
            var attr_parent_id = parentElement?.getAttribute('id');
            let data_guid: any = '';
            if ((isChildrenPresent == 'false' || typeof isChildrenPresent == 'undefined') && (isParPresent == 'false' || typeof isParPresent == 'undefined')) {
                this.removeColorForItem(attr_parent_id);
            }
        }
    }

    removeElementColor(docContentId: any) {
        let childElement: any = document.querySelector('[data-document-content-id="' + docContentId + '"]');
        if (childElement != "" && childElement) {
            var attr_id = childElement?.getAttribute('id');
            this.removeColorForItem(attr_id);
        }
    }

    removeColorForItem(attr_id: any) {
        let data_guid: any = '';
        let resizableItem: any = document.getElementById("resizable_" + attr_id);
        if (resizableItem) {
            if (resizableItem) {
                data_guid = resizableItem.getAttribute('data-guid');
                if (data_guid && data_guid.toLowerCase() != Control.Checkbox.toLowerCase() && data_guid.toLowerCase() != Control.Radio.toLowerCase()) {
                    resizableItem.style["background-color"] = 'rgb(255, 255, 255)';
                }
            }
        }

        let divItem: any = document.getElementById("div" + attr_id);
        if (divItem) {
            let divItem: any = document.getElementById("div" + attr_id);
            if (data_guid) {
                if (data_guid.toLowerCase() == Control.DropDown.toLowerCase())
                    divItem.style["border-color"] = "transparent transparent transparent #fff";
                else divItem.style["border-color"] = "transparent transparent transparent #eee";
            }
        }
        let spnItem: any = document.getElementById("spn" + attr_id);
        if (spnItem) { spnItem.style["background-color"] = ''; }
    }

    updateTextAreaText(textAreaVal: any) {
        let currentSelectedControl: any = localStorage.getItem("CurrentClickedDroppedControl");
        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl));
        if (spnCurrentSelectedControl) {
            if (textAreaVal != null && textAreaVal != "" && textAreaVal.length > 19)
                (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl)).innerHTML = textAreaVal.substring(0, 19) + "...";
            else
                (<HTMLInputElement>document.getElementById("spn" + currentSelectedControl)).innerHTML = textAreaVal;
        }
    }

    applyIdAndHtmlId(ctrlObj: any) {
        let parentEle: any = document.getElementById(ctrlObj.id);
        if (parentEle) {
            let ctrlId: any = parentEle.getAttribute('data-int-control-id');
            let dataDocumentContentVal: any = parentEle.getAttribute('data-document-content-id');

            let dataDocumentContentId: any = document.getElementById('dataDocumentContent');
            let addCtrlHtmlId: any = document.getElementById('ctrlHtmlId');

            if (dataDocumentContentId) {
                dataDocumentContentId.value = dataDocumentContentVal;
            }
            if (addCtrlHtmlId) {
                addCtrlHtmlId.value = ctrlId;
            }
        }
    }

    //byDefault required field checked, unchecked
    addDefaultRequiredField(obj: any, isRequiredChecked: any) {
        let ctrlEle: any = document.getElementById('resizable_' + obj.id);
        if (ctrlEle) {
            ctrlEle.setAttribute('data-selected', isRequiredChecked);
        }

        let chkRequiredSelectedEle: any = document.getElementById('chkRequiredSelected');
        if (obj.className.toLowerCase() === 'radio') {
            chkRequiredSelectedEle = document.getElementById('chkRadioRequiredSelected');
        }
        if (chkRequiredSelectedEle) {
            chkRequiredSelectedEle.checked = isRequiredChecked;
        }
    }

    isControlingField(currentControlId: any) {
        let isControlingField = false;
        if (currentControlId != '' && currentControlId != null && currentControlId != undefined) {
            let item: any = document.getElementById('resizable_' + currentControlId);
            if (item) {
                let data_guid: any = item.getAttribute('data-guid');
                if ((data_guid != null && data_guid != '' && data_guid != undefined) &&
                    (data_guid.toLowerCase() == Control.Text.toLowerCase() || data_guid.toLowerCase() == Control.DropDown.toLowerCase() ||
                        data_guid.toLowerCase() == Control.Checkbox.toLowerCase() || data_guid.toLowerCase() == Control.Radio.toLowerCase())) {
                    isControlingField = true;
                }
            }
        }
        return isControlingField;
    }

    // applyStylesToRecipient(recipientId: any, type: any) {
    // let slRecpClassArray: any;
    // let slRecp: any;
    // if (type == "1") {
    //     slRecpClassArray = document.getElementsByClassName('recipientDeatilsItem');
    //     slRecp = document.getElementById('recipient_' + recipientId);
    // }
    // else if (type == "2") {
    //     slRecpClassArray = document.getElementsByClassName('recipientDeatils');
    //     slRecp = document.getElementById('recp_' + recipientId);
    // }

    // if (slRecpClassArray && slRecpClassArray.length > 0) {
    //     Array.from(slRecpClassArray).forEach((rec: any) => {
    //         rec.style.color = "";
    //         rec.style['background-color'] = "";
    //     });
    // }

    // if (slRecp) {
    //     slRecp.style.color = "white";
    //     slRecp.style['background-color'] = "green"; //highlight the selected recipient in dropdown in right panel and left panel
    // }
    //}

    applyControlProperties(currentSelectedControl: any, resizableCurrentSelectedControl: any, attr_id: any) {
        let chRequiredSelected: any = (<HTMLInputElement>document.getElementById("chRequiredSelected"));
        if (chRequiredSelected) { //required
            if (resizableCurrentSelectedControl?.getAttribute('data-selected') == "true") chRequiredSelected.checked = true; else chRequiredSelected.checked = false;
        }

        let chkPreSelected: any = (<HTMLInputElement>document.getElementById("chkPreSelected"));
        if (chkPreSelected) { //preselected
            if (resizableCurrentSelectedControl?.getAttribute('data-selected1') == "true") chkPreSelected.checked = true; else chkPreSelected.checked = false;
        }

        let chkRadioRequiredSelected: any = (<HTMLInputElement>document.getElementById("chkRadioRequiredSelected"));
        if (chkRadioRequiredSelected) { //radio required
            if (resizableCurrentSelectedControl?.getAttribute('data-selected') == "true") chkRadioRequiredSelected.checked = true; else chkRadioRequiredSelected.checked = false;
        }

        let inputGroupCtrl: any = (<HTMLInputElement>document.getElementById("inputGroupCtrl"));
        let inputGroupNameCtrl: any = (<HTMLInputElement>document.getElementById("inputGroupNameCtrl"));
        if (inputGroupCtrl) {
            let attr_data_groupname: any = resizableCurrentSelectedControl.getAttribute('data-groupname');
            if (attr_data_groupname != '' && attr_data_groupname != null) {
                inputGroupCtrl.value = attr_data_groupname;
            }
            else inputGroupCtrl.value = '';
        }

        if (inputGroupNameCtrl) {
            let spnCurrentSelectedControl: any = document.getElementById("spn" + currentSelectedControl);
            if (spnCurrentSelectedControl) {
                let attr_name: any = spnCurrentSelectedControl.getAttribute('name');
                if (attr_name != '' && attr_name != null) {
                    inputGroupNameCtrl.value = attr_name;
                }
                else if (attr_id.toLowerCase() == Control.Radio.toLowerCase()) {
                    inputGroupNameCtrl.value = 'Radio';
                }
                else if (attr_id.toLowerCase() == Control.Checkbox.toLowerCase()) {
                    inputGroupNameCtrl.value = 'Checkbox';
                }
            }
        }

        if (attr_id && attr_id.toLowerCase() == Control.Date.toLowerCase() && resizableCurrentSelectedControl) {
            let prefillDateCheckbox: any = document.getElementById("prefillDateCheckbox");
            if (prefillDateCheckbox) {
                if (resizableCurrentSelectedControl.getAttribute('data-defaultdate') == "true") prefillDateCheckbox.checked = true;
                else prefillDateCheckbox.checked = false;
            }
        }

        if (attr_id && attr_id.toLowerCase() == Control.DropDown.toLowerCase() && resizableCurrentSelectedControl) {
            let drpFirstLineBlank: any = document.getElementById("drpFirstLineBlank");
            if (drpFirstLineBlank) {
                if (resizableCurrentSelectedControl.getAttribute('data-firstlineblank') == "true") drpFirstLineBlank.checked = true;
                else drpFirstLineBlank.checked = false;
            }
        }

        this.removeControlAttributes(resizableCurrentSelectedControl, resizableCurrentSelectedControl?.getAttribute('data-guid'));
    }

    //byDefault is fixed width field checked
    addDefaultFixedWidth(obj: any, hideFixedwidthCheckbox: any, isFixedWidthChecked: any) {
        let pCtrlEle: any = document.getElementById(obj.id);
        let ctrlName: any = '';
        if (pCtrlEle) {
            ctrlName = pCtrlEle.getAttribute('data-controlname');
        }
        if (hideFixedwidthCheckbox === false) {
            let ctrlEle: any = document.getElementById('resizable_' + obj.id);
            if (ctrlEle) {
                ctrlEle.setAttribute('data-isfixedwidth', isFixedWidthChecked);
                if (ctrlName.toLowerCase() === 'text') {
                    ctrlEle.setAttribute('data-maxcharlimit', '4000');
                } else {
                    ctrlEle.setAttribute('data-maxcharlimit', '100');
                }
                obj.isFixedWidth = isFixedWidthChecked;
            }

            if (ctrlName.toLowerCase() === 'name') {
                this.checkFixedWidthFalseAndMaxCharLimit(ctrlName, 'nameInputLength', 'nameCharInputLimit', 'isFixedWidthNameContainer', isFixedWidthChecked);
            }
            else if (ctrlName.toLowerCase() === 'title') {
                this.checkFixedWidthFalseAndMaxCharLimit(ctrlName, 'titleInputLength', 'titleCharInputLimit', 'isFixedWidthTitleContainer', isFixedWidthChecked);
            }
            else if (ctrlName.toLowerCase() === 'text') {
                this.checkFixedWidthFalseAndMaxCharLimit(ctrlName, 'textInputLength', 'textCharInputLimit', 'isFixedWidthTextContainer', isFixedWidthChecked);
            }
        } else {
            setTimeout(() => {
                if (ctrlName.toLowerCase() === 'name') {
                    this.checkFixedWidthTrueAndMaxCharLimit(ctrlName.toLowerCase(), 'nameInputLength', 'nameCharInputLimit', 'isFixedWidthNameContainer');
                }
                else if (ctrlName.toLowerCase() === 'title') {
                    this.checkFixedWidthTrueAndMaxCharLimit(ctrlName.toLowerCase(), 'titleInputLength', 'titleCharInputLimit', 'isFixedWidthTitleContainer');
                }
                else if (ctrlName.toLowerCase() === 'text') {
                    this.checkFixedWidthTrueAndMaxCharLimit(ctrlName.toLowerCase(), 'textInputLength', 'textCharInputLimit', 'isFixedWidthTextContainer');
                }
            }, 200);
        }
    }

    applyPrefillDefaultDateProperty(event: any, currentControlId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + currentControlId))
        if (event.target.checked) controlEle.setAttribute('data-defaultdate', "true");
        else controlEle.setAttribute('data-defaultdate', "false");
    }

    applyRadioGroup(event: any, currentControlId: any) {
        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("resizable_" + currentControlId));
        if (spnCurrentSelectedControl) {
            if (event.target.checked)
                spnCurrentSelectedControl?.setAttribute('data-selected', 'true');
            else
                spnCurrentSelectedControl?.setAttribute('data-selected', 'false');
        }
    }

    applyFirstLineBlank(event: any, currentControlId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + currentControlId))
        if (controlEle) {
            if (event.target.checked) controlEle.setAttribute('data-firstlineblank', "true");
            else controlEle.setAttribute('data-firstlineblank', "false");
        }
    }

    applyLabelName(event: any, currentControlId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById('spn' + currentControlId))
        if (controlEle) {
            if (event.target.value) {
                controlEle.innerHTML = event.target.value;
                controlEle.setAttribute('isupdated', "true");
            }
            else {
                controlEle.innerHTML = ''; controlEle.setAttribute('isupdated', "false");
                this.toastrService.warning('Please enter a label for the hyperlink control.', 'Warning'); return false;
            }
        }
        return true;
    }

    applyHyperLinkURL(event: any, currentControlId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById(currentControlId))
        if (controlEle) {
            if (event.target.value) {
                if (/^(http|https|ftp):\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i.test(event.target.value)) {
                    controlEle.setAttribute('data-hyperlink', event.target.value);
                    let spnControlEle: any = (<HTMLInputElement>document.getElementById("spn" + currentControlId));
                    if (spnControlEle) {
                        if (spnControlEle.getAttribute('isupdated') == null || spnControlEle.getAttribute('isupdated') == "false") {
                            spnControlEle.innerHTML = event.target.value;
                        }
                    }
                }
                else {
                    event.target.value = '';
                    controlEle.setAttribute('data-hyperlink', '');
                    this.toastrService.warning('Please enter a URL in the hyperlink control.', 'Warning'); return false;
                }
            }
            else {
                controlEle.setAttribute('data-hyperlink', '');
                this.toastrService.warning('Please enter a URL in the hyperlink control.', 'Warning'); return false;
            }
        }
        return true;
    }

    applyNewContentId(event: any, currentControlId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById(currentControlId))
        if (controlEle) {
            if (event.target.value) controlEle.setAttribute('data-int-control-id', event.target.value);
            else {
                this.toastrService.warning('Field cannot be blank.', 'Warning'); return false;
            }
        }
        return true;
    }

    applyRadioAndCheckBoxSizes(defaultSize: any, controlEle: any, spnControlEle: any) {
        controlEle.style["padding-top"] = "0px";
        if (defaultSize == "1") {
            controlEle.style.width = "20px";
            controlEle.style.height = "20px";
            spnControlEle.style.width = "16px";
            spnControlEle.style.height = "16px";
        }
        if (defaultSize == "2") {
            controlEle.style.width = "22px";
            controlEle.style.height = "22px";
            spnControlEle.style.width = "18px";
            spnControlEle.style.height = "18px";
        }
        if (defaultSize == "3") {
            controlEle.style.width = "24px";
            controlEle.style.height = "24px";
            spnControlEle.style.width = "20px";
            spnControlEle.style.height = "20px";
        }
        if (defaultSize == "4") {
            controlEle.style.width = "26px";
            controlEle.style.height = "26px";
            spnControlEle.style.width = "22px";
            spnControlEle.style.height = "22px";
        }
    }

    applyRadioCheckboxSizes(defaultSize: any, currentControlId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + currentControlId));
        if (controlEle) {
            controlEle.setAttribute('data-size', this.getRadioCheckBoxSize(defaultSize));
            let spnControlEle: any = (<HTMLInputElement>document.getElementById('spn' + currentControlId));
            this.applyRadioAndCheckBoxSizes(defaultSize, controlEle, spnControlEle);
        }
        return true;
    }

    getRadioCheckBoxSize(defaultSize: any) {
        if (defaultSize == "1") { return "Small"; }
        else if (defaultSize == "2") { return "Standard"; }
        else if (defaultSize == "3") { return "Large"; }
        else if (defaultSize == "4") { return "Extra Large"; }
        else return "Standard";
    }

    getRadioCheckBoxSizeVal(defaultSize: any) {
        if (defaultSize == "Small") { return "1"; }
        else if (defaultSize == "Standard") { return "2"; }
        else if (defaultSize == "Large") { return "3"; }
        else if (defaultSize == "Extra Large") { return "4"; }
        else return "2";
    }

    applyDefaultDateFormat(defaultDateFormat: any, currentControlId: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + currentControlId));
        if (controlEle) {
            controlEle.setAttribute('data-defaultFormat', this.getDateFormat(defaultDateFormat));
        }
        let spnControlEle: any = (<HTMLInputElement>document.getElementById('spn' + currentControlId))
        if (spnControlEle) {
            spnControlEle.innerHTML = this.getDateFormatInnerHtmlVal(defaultDateFormat);
        }
        return true;
    }

    getDateFormat(defaultDateFormat: any) {
        let dateformatArray: any = this.commonConstants.Dateformat;
        let dateArrayObj: any = dateformatArray.filter((obj: any) => obj.value == defaultDateFormat);
        if (dateArrayObj && dateArrayObj.length > 0)
            return dateArrayObj[0].viewValue;
        else this.commonConstants.Dateformat[0].viewValue;
    }

    getDateFormatInnerHtmlVal(defaultDateFormat: any) {
        let dateformatArray: any = this.commonConstants.DateformatValue;
        let dateArrayObj: any = dateformatArray.filter((obj: any) => obj.value == defaultDateFormat);
        if (dateArrayObj && dateArrayObj.length > 0)
            return dateArrayObj[0].viewValue;
        else this.commonConstants.Dateformat[0].viewValue;
    }

    getDateFormatVal(defaultDateFormatVal: any) {
        let dateformatArray: any = this.commonConstants.Dateformat;
        let dateArrayObj: any = dateformatArray.filter((obj: any) => obj.viewValue == defaultDateFormatVal);
        if (dateArrayObj && dateArrayObj.length > 0)
            return dateArrayObj[0].value;
        else this.commonConstants.Dateformat[0].value;
    }

    getDefaultDateOption(dateFormatID: any) {
        let dateformatArray: any = this.commonConstants.Dateformat;
        let dateArrayObj: any = dateformatArray.filter((obj: any) => obj.dateFormatId.toLowerCase() == dateFormatID.toLowerCase());
        if (dateArrayObj && dateArrayObj.length > 0)
            return dateArrayObj[0].value;
        else this.commonConstants.Dateformat[0].value;
    }

    getTextTypeValue(textType: any) {
        let returnTypeVal: any = '';
        switch (textType.toLowerCase()) {
            case 'text':
                returnTypeVal = '26C0ACEA-3CC8-43D6-A255-A870A8524A77';
                break;
            case 'numeric':
                returnTypeVal = 'B0443A47-89C3-4826-BECC-378D81738D03';
                break;
            case 'alphabet':
                returnTypeVal = '88A0B11E-5810-4ABF-A8B6-856C436E7C49';
                break;
            case 'ssn':
                returnTypeVal = 'DCBBE75C-FDEC-472C-AE25-2C42ADFB3F5D';
                break;
            case 'zip':
                returnTypeVal = '5121246A-D9AB-49F4-8717-4EF4CAAB927B';
                break;
            case 'date':
                returnTypeVal = '8348E5CD-59EA-4A77-8436-298553D286BD';
                break;
            case 'email':
                returnTypeVal = '1AD2D4EC-4593-435E-AFDD-F8A90426DE96';
                break;
        }
        return returnTypeVal;
    }

    getFontFamilyId(fontFamily: any) {
        let returnId: any = 'D4A45ECD-3865-448A-92FA-929C2295EA34'; //default courier
        switch (fontFamily.toLowerCase()) {
            case 'arial':
                returnId = '1AB25FA7-A294-405E-A04A-3B731AD795AC';
                break;
            case 'cambria':
                returnId = '1875C58D-52BD-498A-BE6D-433A8858357E';
                break;
            case 'courier':
                returnId = 'D4A45ECD-3865-448A-92FA-929C2295EA34';
                break;
            case 'times new roman':
                returnId = '956D8FD3-BB0F-4E30-8E55-D860DEABB346';
                break;
        }
        return returnId;
    }

    applyFontSizePxOrPt(currentControlId: any, selectedValue: any) {
        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentControlId));
        if (spnCurrentSelectedControl) {
            let fontSizeSelect: any = (<HTMLInputElement>document.getElementById('fontSizeSelect'));
            if (selectedValue && fontSizeSelect && fontSizeSelect.value) {
                spnCurrentSelectedControl.style.fontSize = fontSizeSelect.value + '' + selectedValue;
            }
        }
    }

    applyFontStyles(currentControlId: any, styleObj: any) {
        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentControlId));
        if (spnCurrentSelectedControl) {
            spnCurrentSelectedControl.style.fontWeight = styleObj.isBold ? 'bold' : 'normal';
            spnCurrentSelectedControl.style.fontStyle = styleObj.isItalic ? 'italic' : 'normal';
            spnCurrentSelectedControl.style.textDecoration = styleObj.isUnderlined ? 'underline' : 'none';
        }
    }

    applyTextAlign(currentControlId: any, type: any) {
        let resizableCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById("spn" + currentControlId));
        if (resizableCurrentSelectedControl) {
            resizableCurrentSelectedControl.style.textAlign = type;
        }

        let divCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById('div' + currentControlId));
        if (divCurrentSelectedControl) {
            let parentNode: any = divCurrentSelectedControl.parentNode;
            if (parentNode && parentNode.style) {
                if (type === 'center') {
                    parentNode.classList.remove('justify-content-start');
                    parentNode.classList.remove('justify-content-end');
                    parentNode.classList.add('justify-content-center');
                } else if (type === 'right') {
                    parentNode.classList.remove('justify-content-start');
                    parentNode.classList.remove('justify-content-center');
                    parentNode.classList.add('justify-content-end');
                } else if (type === 'left') {
                    parentNode.classList.remove('justify-content-center');
                    parentNode.classList.remove('justify-content-end');
                    parentNode.classList.add('justify-content-start');
                }

                // Ensure the flex display is applied
                parentNode.classList.add('d-flex');
                parentNode.classList.add('align-items-center');
            }
        }

    }

    applyColorPicker(currentControlId: any, color: any) {
        setTimeout(() => {
            let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById("spn" + currentControlId));
            if (spnCurrentSelectedControl) {
                spnCurrentSelectedControl.style.color = color;
            }
        }, 300);
    }

    //Updating Font Family Id based on selection
    updateDefaultFontFamilyId(id: any, fontFamily: any) {
        let spnCurrentSelectedControl = (<HTMLInputElement>document.getElementById('spn' + id));
        if (spnCurrentSelectedControl) {
            spnCurrentSelectedControl.style.fontFamily = fontFamily;
            spnCurrentSelectedControl.setAttribute('font-family-id', this.getFontFamilyId(fontFamily));
        }
    }

    //Numeric --> Number or Decimal
    applyTextTypeDecimal(currentControlId: any, selectedVal: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + currentControlId));
        if (controlEle) {
            const valueToFind = selectedVal;
            const additionalValidationItem = this.commonConstants.AdditionalValidation.find((item: { value: any; }) => item.value === valueToFind);
            if (additionalValidationItem) {
                controlEle.removeAttribute('data-additional-validation-option');
                controlEle.setAttribute('data-additional-validation-name', additionalValidationItem.viewname);
                if (selectedVal === '2' || selectedVal === 2) {
                    controlEle.setAttribute('data-additional-validation-option', '1');
                }
            }
        }
    }

    //Numeric --> Decimal options are Both,Comma and Period
    applyTextTypeDecimalOption(currentControlId: any, selectedVal: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + currentControlId));
        if (controlEle) {
            controlEle.setAttribute('data-additional-validation-option', selectedVal);
        }
    }

    applyTextTypeDate(currentControlId: any, selectedVal: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + currentControlId));
        if (controlEle) {
            controlEle.setAttribute('data-additional-validation-option', selectedVal);
            controlEle.setAttribute('data-texttype', this.getTextTypeValue('date'));
        }
    }

    applyTextTypeZip(currentControlId: any, selectedVal: any) {
        let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + currentControlId));
        if (controlEle) {
            controlEle.setAttribute('data-additional-validation-option', selectedVal);
            controlEle.setAttribute('data-texttype', this.getTextTypeValue('zip'));
        }
    }

    checkContolAttribuesData(type:any) {
        const regex = /^[0-9a-zA-Z\ _]+$/;
        let isValid: any = true;
        let controlArray: any = [];
        let resultObj: any = {};
        let docGuid: any;
        let attr_data_texttype_Guid: any;
        let documentImagesControlsArray: any = document.querySelectorAll('.signControl');
        if (documentImagesControlsArray && documentImagesControlsArray.length > 0) {
            let totalControlCount = documentImagesControlsArray.length;
            Array.from(documentImagesControlsArray).forEach((ele: any) => {
                if (ele && isValid == true) {
                    //Main parent div
                    let attr_id: any = ele?.getAttribute('id');
                    let attr_int_control_id: any = ele?.getAttribute('data-int-control-id');
                    let attr_contentId: any = ele?.getAttribute('data-document-content-id');
                    let attr_pageNo: any = ele?.getAttribute('data-pageno');
                    let attr_doc_pageNo: any = ele?.getAttribute('docpageno');
                    let docImgAttr: any = document.getElementById("selectionBox" + attr_pageNo);
                    let imgDocumentId: any;
                    if (docImgAttr && docImgAttr != null) {
                        imgDocumentId = docImgAttr?.getAttribute('img-document-id');
                        docGuid = Guid.parse(imgDocumentId);
                        docGuid = docGuid.value;
                        attr_doc_pageNo = docImgAttr?.getAttribute('docpageno');
                    }
                    let attr_ctrlNum: any = ele?.getAttribute('data-ctrlnum');
                    let attr_ctrlName: any = ele?.getAttribute('data-controlname');
                    let attr_group_code: any = ele?.getAttribute('group-code');
                    let attr_data_condition_id: any = ele?.getAttribute('data-condition-id');
                    let attr_data_parent_id: any = ele?.getAttribute('data-parent-id');
                    let attr_is_parent_present: any = ele?.getAttribute('is-parent-present');
                    let attr_data_parent_rule_id: any = ele?.getAttribute('data-parent-rule-id');
                    let attr_is_child_present: any = ele?.getAttribute('is-child-present');
                    let attr_supportText: any = ele?.getAttribute('data-parent-support-text-content');

                    //Resizable div
                    let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + attr_id));
                    let attr_data_guid: any = controlEle?.getAttribute('data-guid');
                    let attr_title: any = controlEle?.getAttribute('title');
                    let attr_data_rcptid: any = controlEle?.getAttribute('data-rcptid');
                    let attr_data_original_rcptid: any = controlEle?.getAttribute('data-original-rcptid');
                    let attr_controlValue: any = controlEle?.getAttribute('data-control-val');
                    // let attr_data_recp_color: any = controlEle?.getAttribute('data-recp-color');
                    // let attr_pageno: any = controlEle?.getAttribute('pageno');
                    let attr_data_typography: any = controlEle?.getAttribute('data-typography');
                    let attr_data_selected: any = controlEle?.getAttribute('data-selected');
                    let attr_data_selected1: any = controlEle?.getAttribute('data-selected1');
                    let attr_data_customtooltip: any = controlEle?.getAttribute('data-customtooltip');
                    let attr_data_original_title: any = controlEle?.getAttribute('data-original-title');
                    let attr_data_additional_validation_name: any = controlEle?.getAttribute('data-additional-validation-name');
                    let attr_data_additional_validation_option: any = controlEle?.getAttribute('data-additional-validation-option');
                    let attr_data_isfixedwidth: any = controlEle?.getAttribute('data-isfixedwidth');

                    let attr_maxlengthallowed: any = controlEle?.getAttribute('maxlengthallowed');
                    let attr_maxcharallowed: any = "";
                    let attr_maxemailcharallowed: any = "";

                    if (attr_data_isfixedwidth == false || attr_data_isfixedwidth == "false") {
                        attr_maxcharallowed = controlEle?.getAttribute('data-maxcharlimit');
                        attr_maxemailcharallowed = controlEle?.getAttribute('data-maxcharlimit');
                    }
                    else {
                        attr_maxcharallowed = controlEle?.getAttribute('maxlengthallowed');
                        attr_maxemailcharallowed = controlEle?.getAttribute('maxlengthallowed');
                    }

                    if (attr_data_additional_validation_name != '' && attr_data_additional_validation_name != null) {
                        if (attr_data_additional_validation_name == 'Zip' || attr_data_additional_validation_name == "Zip Format") {
                            if (attr_data_additional_validation_option != '' && attr_data_additional_validation_option != null) {
                                if (attr_data_additional_validation_option == '1')
                                    attr_data_additional_validation_option = 'Zip';
                                else if (attr_data_additional_validation_option == '2')
                                    attr_data_additional_validation_option = 'Zip+4';
                            }
                        }
                        else if (attr_data_additional_validation_name == 'Decimal') {
                            if (attr_data_additional_validation_option != '' && attr_data_additional_validation_option != null) {
                                if (attr_data_additional_validation_option == '1')
                                    attr_data_additional_validation_option = 'Both';
                                else if (attr_data_additional_validation_option == '2')
                                    attr_data_additional_validation_option = 'Comma';
                                else if (attr_data_additional_validation_option == '3')
                                    attr_data_additional_validation_option = 'Period';
                            }
                        }
                        else if (attr_data_additional_validation_name == 'Date Format') {
                            if (attr_data_additional_validation_option != '' && attr_data_additional_validation_option != null) {
                                if (attr_data_additional_validation_option == '1')
                                    attr_data_additional_validation_option = 'mm/dd/yyyy';
                                else if (attr_data_additional_validation_option == '2')
                                    attr_data_additional_validation_option = 'dd/mm/yyyy';
                                else if (attr_data_additional_validation_option == '3')
                                    attr_data_additional_validation_option = 'yyyy/mm/dd';
                                else if (attr_data_additional_validation_option == '4')
                                    attr_data_additional_validation_option = 'dd-mmm-yyyy';
                            }
                        }
                    }

                    let attr_style: any = controlEle?.getAttribute('style');
                    //Spn div
                    let spnControlEle: any = (<HTMLInputElement>document.getElementById("spn" + attr_id));
                    let attr_font_family_id: any = spnControlEle?.getAttribute('font-family-id');
                    //if (attr_font_family_id == null || attr_font_family_id == "null") attr_font_family_id = 'd4a45ecd-3865-448a-92fa-929c2295ea34';
                    let attr_spn_style: any = spnControlEle?.getAttribute('style');

                    let innerText: any = attr_ctrlName, name: any = '', color: any = '', senderControlValue: any = '', labelValue: any = '', groupname: any = null, localControlStyle: any = null;
                    let selectControl: any = [], dependencies: any = [];
                    let attr_data_texttype: any = '', attr_data_size: any = '', attr_data_defaultdate: any = false;

                    let style: any = {};
                    var styelAttr = attr_spn_style;
                    if (typeof styelAttr !== 'undefined' && styelAttr !== false) {
                        var styles = styelAttr.split(';');
                        for (var st in styles) {
                            var aux = (styles[st]).trim().split(':');
                            if (aux[1] && aux[1] != '' && aux[1].trim() != 'undefined')
                                style[(aux[0]).trim()] = (aux[1].trim());
                        }
                    }

                    if (!(attr_title == "Checkbox" || attr_title == "Radio" || attr_title == "Dropdown")) {
                        color = style['color'] || "#000000";
                        if (color.indexOf('rgb') > -1) {
                            var regExp = /\(([^)]+)\)/;
                            var matches: any = regExp.exec(color);
                            let result: any, rgb: any;
                            if (matches[1]) {
                                result = matches[1].replace(/\s/g, '').split(',');
                            }
                            rgb = result.map(function (x: any) { return parseInt(x); });
                            color = "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
                        }

                        localControlStyle = {
                            FontColor: color || "#000000",
                            FontID: attr_font_family_id != null && attr_font_family_id != "null" ? attr_font_family_id.toLowerCase() : '',
                            FontFamily: style['font-family'] != null && style['font-family'] != 'null' && style['font-family'] != undefined ? style['font-family'] : '',
                            FontSize: (style['font-size']) ? style['font-size'].replace('px', '') : 14,
                            IsBold: (style['font-weight']) ? (style['font-weight'] === 'bold' || style['font-weight'] === '600') ? true : false : false,
                            IsItalic: (style['font-style']) ? style['font-style'] === 'italic' ? true : false : false,
                            IsUnderline: (style['text-decoration']) ? style['text-decoration'] === 'underline' ? true : false : false,
                            AdditionalValidationName: attr_data_additional_validation_name != null ? attr_data_additional_validation_name : '',
                            AdditionalValidationOption: attr_data_additional_validation_option != null ? attr_data_additional_validation_option : '',
                            ControlHeight: (style['height']) ? style['height'].replace('px', '') : 18,
                            ControlWidth: (style['width']) ? style['width'].replace('px', '') : 18
                        };
                    }

                    var currentStyle: any = {};
                    var styelAttr = attr_style;
                    if (typeof styelAttr !== 'undefined' && styelAttr !== false) {
                        var styles = styelAttr.split(';');
                        for (var st in styles) {
                            var aux = styles[st].trim().split(':');
                            if (aux[1] && aux[1] != 'undefined')
                                currentStyle[aux[0].trim()] = aux[1].trim();
                        }
                    }

                    var controlHeight = controlEle.offsetHeight;
                    var controlWidth = controlEle.offsetWidth;
                    if ((attr_title == "Checkbox" || attr_title == "Radio") && currentStyle) {
                        controlHeight = currentStyle.height ? parseFloat(currentStyle.height.replace('px', '')) : controlHeight;
                        controlWidth = currentStyle.width ? parseFloat(currentStyle.width.replace('px', '')) : controlWidth;
                    }

                    let selControl: any = document.getElementById(attr_id);
                    let parentHeight: any = selControl.parentNode.parentNode.offsetHeight; //'imgControl_' + attr_pageNo
                    let controlPositions: any = this.getElePositions(controlEle);
                    let controlLeftPosition: any = controlPositions.Left;
                    let controlTopPosition: any = controlPositions.Top;
                    // let outerHeight: any = controlEle.style.height.split('px')[0];
                    let controlBottom: any = parseInt(parentHeight) - (controlTopPosition + parseInt(controlHeight));

                    let imgEle: any = document.getElementById(attr_pageNo);
                    var imageWidth = imgEle.width;
                    var imageHeight = imgEle.height;
                    let originalWidth: any = imgEle.naturalWidth;
                    let originalHeight: any = imgEle.naturalHeight;
                    let ratioWidth: any = 1 / (imageWidth / originalWidth);
                    let ratioHeight: any = 1 / (imageHeight / originalHeight);

                    let ZCoordinate: any; //need to check once again
                    if ((controlBottom * ratioHeight) <= 0) ZCoordinate = 1;
                    else ZCoordinate = (controlBottom * ratioHeight);

                    senderControlValue = (attr_data_selected1 == null || attr_data_selected1 == "false" || attr_data_selected1 == false) ? "false" : attr_data_selected1;
                    let controlHtml: any = '', attr_data_hyperlink: any = '', selectOptionString: any = '';
                    if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Signature.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getSignatureHtml();
                    }
                    else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Initials.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getInitialHtml();
                    }
                    else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.DateTimeStamp.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getDateTimeStampHtml();
                    }
                    else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Title.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getTitleHtml();
                    }
                    else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Company.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getCompanyHtml();
                    }
                    else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Email.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getEmailHtml();
                        let attr_data_confirm_submit: any = controlEle.getAttribute('data-confirm-submit');
                        senderControlValue = attr_data_confirm_submit;
                    }
                    else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Name.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getNameHtml();
                    }
                    else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getDropdownHtml();
                        // let attr_data_defaultdate: any = controlEle?.getAttribute('data-firstlineblank');
                        let selectDropdownControlField: any = (document.getElementById('spn' + attr_id)) as HTMLSelectElement;
                        if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length > 0) {
                            for (let index = 0; index < selectDropdownControlField.options.length; index++) {
                                var text = selectDropdownControlField.options[index].text;
                                var ruleId = selectDropdownControlField.options[index]?.getAttribute('data-select-option-id');
                                var value = selectDropdownControlField.options[index]?.getAttribute('value');
                                selectControl.push({ ID: ruleId, OptionText: text, Order: value });

                                var option = '';
                                if (index == 0) option = '<option data-select-option-id="' + ruleId + '" value="' + value + '" selected="selected">' + text + '</option>';
                                else option = '<option data-select-option-id="' + ruleId + '" value="' + value + '">' + text + '</option>';
                                selectOptionString = selectOptionString + option;
                            }

                            if (selectOptionString == '') {
                                if(type == "send"){ isValid = false; this.toastrService.warning('Please add list items for the dropdown control.', 'Warning'); return isValid; }
                            }
                        }
                        else{
                            if(type == "send"){ isValid = false; this.toastrService.warning('Please add list items for the dropdown control.', 'Warning'); return isValid; }
                        }
                    }
                    else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getCheckboxHtml();
                        let attr_data_isGroupChecked: any = controlEle?.getAttribute('data-isGroupChecked');
                        let attr_data_groupname: any = controlEle?.getAttribute('data-groupname');
                        groupname = attr_data_groupname;
                        if (spnControlEle) name = spnControlEle.getAttribute('name');
                        if (attr_data_isGroupChecked != null && attr_data_isGroupChecked == 'true') {
                            if (attr_data_groupname == null || attr_data_groupname == '') {
                                if(type == "send"){
                                isValid = false; this.toastrService.warning('Please enter checkbox control group name field value.', 'Warning'); return isValid;}
                            }
                            else if (attr_data_groupname != "" && attr_data_groupname.length > 50) {
                                if(type == "send"){
                                isValid = false; this.toastrService.warning("Group Name should not be more than 50 characters.", 'Warning'); return isValid;}
                            }
                            else if (attr_data_groupname != "" && !attr_data_groupname.match(regex)) {
                                if(type == "send"){
                                isValid = false; this.toastrService.warning("Group Name should be only characters and numbers.", 'Warning'); return isValid;}
                            }
                            else if (name == null || name == '') {
                                if(type == "send"){
                                isValid = false; this.toastrService.warning('Please enter checkbox control name field value.', 'Warning'); return isValid;}
                            }
                        }
                        attr_data_size = controlEle?.getAttribute('data-size');
                    }
                    else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Radio.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getRadioHtml();
                        attr_data_size = controlEle?.getAttribute('data-size');
                        let attr_data_groupname: any = controlEle?.getAttribute('data-groupname');
                        groupname = attr_data_groupname;
                        if (spnControlEle) name = spnControlEle.getAttribute('name');
                        if (attr_data_groupname == null || attr_data_groupname == '') {
                            if(type == "send"){isValid = false; this.toastrService.warning('Please enter radio control group name field value.', 'Warning'); return isValid;}
                        }
                        else if (attr_data_groupname != "" && attr_data_groupname.length > 50) {
                            if(type == "send"){isValid = false; this.toastrService.warning("Group Name should not be more than 50 characters.", 'Warning'); return isValid;}
                        }
                        else if (attr_data_groupname != "" && !attr_data_groupname.match(regex)) {
                            if(type == "send"){isValid = false; this.toastrService.warning("Group Name should be only characters and numbers.", 'Warning'); return isValid;}
                        }
                        else if (name == null || name == '') {
                            if(type == "send"){isValid = false; this.toastrService.warning('Please enter radio control name field value.', 'Warning'); return isValid;}
                        }
                    }
                    else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Date.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getDateHtml();
                        attr_data_defaultdate = controlEle?.getAttribute('data-defaultdate');
                        // let attr_data_defaultformat: any = controlEle?.getAttribute('data-defaultformat');
                        if (spnControlEle) innerText = spnControlEle.innerHTML;
                        senderControlValue = (attr_data_selected1 == null || attr_data_selected1 == "false" || attr_data_selected1 == false) ? "" : attr_data_selected1;
                    }
                    else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Text.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getTextHtml();
                        if (spnControlEle) innerText = spnControlEle.innerHTML;
                        if (innerText == null || innerText == '') {
                            if(type == "send"){ isValid = false; this.toastrService.warning('Please enter text control add text field value.', 'Warning'); return isValid;}
                        }
                        attr_data_texttype = Guid.parse(controlEle?.getAttribute('data-texttype'));
                        attr_data_texttype_Guid = attr_data_texttype.value;
                        // let attr_data_validation_type: any = controlEle?.getAttribute('data-validation-type');
                        // let attr_data_additional_validation_name: any = controlEle?.getAttribute('data-additional-validation-name');
                        // let attr_data_additional_validation_option: any = controlEle?.getAttribute('data-additional-validation-option');
                    }
                    else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Label.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getLabelHtml();
                        if (spnControlEle) innerText = spnControlEle.innerHTML;
                        if (spnControlEle.getAttribute('isupdated') == null || spnControlEle.getAttribute('isupdated') == "false") {
                            innerText = '';
                        }
                        if (innerText == null || innerText == '') {
                            if(type == "send"){isValid = false; this.toastrService.warning("Please enter a label in the label control.", 'Warning'); return isValid;}
                        }
                    }
                    else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Hyperlink.toLowerCase()) {
                        controlHtml = this.controlHtmlService.getHyperlinkHtml();
                        attr_data_hyperlink = ele?.getAttribute('data-hyperlink');
                        senderControlValue = attr_data_hyperlink;
                        if (spnControlEle) innerText = spnControlEle.innerHTML;
                        if (spnControlEle.getAttribute('isupdated') == null || spnControlEle.getAttribute('isupdated') == "false") {
                            innerText = '';
                        }
                        if (innerText == null || innerText == '') {
                            if(type == "send"){isValid = false; this.toastrService.warning('Please enter a label for the hyperlink control.', 'Warning'); return isValid;}
                        }
                        else if (attr_data_hyperlink == null || attr_data_hyperlink == '') {
                            if(type == "send"){isValid = false; this.toastrService.warning('Please enter a URL in the hyperlink control.', 'Warning'); return isValid;}
                        }
                    }

                    let rawDependentControlsOnDocument: any = document.querySelectorAll('[data-parent-id="' + attr_contentId + '"]');
                    if (rawDependentControlsOnDocument != undefined && rawDependentControlsOnDocument != null && rawDependentControlsOnDocument.length > 0) {
                        Array.from(rawDependentControlsOnDocument).forEach((ritem: any, index: any) => {
                            dependencies.push({
                                ControlID: ritem.getAttribute('data-document-content-id').toLowerCase(),
                                ControlParentID: attr_contentId.toLowerCase(),
                                ConditionID: ritem.getAttribute('data-parent-rule-id').toLowerCase(),
                                ConditionText: ritem.getAttribute('data-parent-support-text-content') == undefined ? null : ritem.getAttribute('data-parent-support-text-content'),
                                GroupCode: attr_group_code
                            });
                        });
                    }

                    labelValue = innerText || attr_data_original_title;
                    //todo integration type
                    let entityValue: any = '';
                    let IntegrationType: any = ''; //todo
                    if ((IntegrationType.value == "Bullhorn" || IntegrationType.value == "Vincere") && controlEle?.getAttribute("data-Entity") != "Select" && controlEle?.getAttribute("data-EntityFieldValue") != "Select") {
                        let entity: any = controlEle?.getAttribute("data-Entity");
                        let entityType: any = controlEle?.getAttribute("data-EntityField");
                        entityValue = controlEle?.getAttribute("data-EntityFieldValue");
                        senderControlValue = "";
                    }
                    senderControlValue = this.checkNull(entityValue, senderControlValue);
                    if (isValid == false) return false;
                    else {
                        controlHtml = controlHtml.replace("#id", attr_id);
                        controlHtml = controlHtml.replace("#ctrlNumber", attr_ctrlNum);
                        controlHtml = controlHtml.replace("#left", controlLeftPosition + "px");
                        controlHtml = controlHtml.replace("#top", controlTopPosition + "px");

                        if (attr_data_guid.toLowerCase() != Control.Radio.toLowerCase() && attr_data_guid.toLowerCase() != Control.Checkbox.toLowerCase()) {
                            controlHtml = controlHtml.replace("#width", controlWidth + "px");
                            controlHtml = controlHtml.replace("#height", controlHeight + "px");
                        }

                        controlHtml = controlHtml.replace("#contentId", attr_contentId);
                        controlHtml = controlHtml.replace("#controlId", attr_int_control_id);
                        controlHtml = controlHtml.replace("#groupCode", attr_group_code == null ? '' : attr_group_code);
                        controlHtml = controlHtml.replace("#conditionId", attr_data_condition_id == null ? '' : attr_data_condition_id.toLowerCase());
                        controlHtml = controlHtml.replace("#borderColor", currentStyle.outline == null ? '' : currentStyle.outline);

                        if (ele?.classList.contains('addedAsChild') == true || (attr_is_child_present != null && attr_is_child_present != "null" && attr_is_child_present != "") ||
                            (attr_is_parent_present != null && attr_is_parent_present != "null" && attr_is_parent_present != "")) {
                            if (attr_data_guid.toLowerCase() == Control.Radio.toLowerCase() || attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase())
                                controlHtml = controlHtml.replace("#backGroundColor", (style && style['background-color'] != null) ? style['background-color'] : currentStyle.background);
                            else
                                controlHtml = controlHtml.replace("#backGroundColor", currentStyle.background == null ? '' : currentStyle.background);
                        }
                        else {
                            controlHtml = controlHtml.replace("#backGroundColor", "rgba(220, 220, 220, 1)");
                        }

                        // controlHtml = controlHtml.replace("#backGroundColor", "rgba(220, 220, 220, 1)");
                        // controlHtml.replace("#backGroundColor", currentStyle.background == null ? '' : currentStyle.background);
                        controlHtml = controlHtml.replace("#originalTitle", attr_data_original_title == null || attr_data_original_title == 'null' ? '' : attr_data_original_title);
                        controlHtml = controlHtml.replace("#title", attr_title == null ? '' : attr_title);
                        controlHtml = controlHtml.replace("#parentId", attr_data_parent_id == null || attr_data_parent_id == 'null' ? '' : attr_data_parent_id.toLowerCase());
                        controlHtml = controlHtml.replace("#parentRuleId", attr_data_parent_rule_id == null || attr_data_parent_rule_id == 'null' ? '' : attr_data_parent_rule_id.toLowerCase());
                        controlHtml = controlHtml.replace("#supportText", attr_supportText == null || attr_supportText == 'null' ? '' : attr_supportText);
                        controlHtml = controlHtml.replace("#color", color == null ? "#000000" : color);
                        controlHtml = controlHtml.replace("#guid", attr_data_guid == null ? '' : attr_data_guid.toLowerCase());
                        controlHtml = controlHtml.replace("#recipientId", attr_data_rcptid == null || attr_data_rcptid == 'null' ? '' : attr_data_rcptid.toLowerCase());
                        controlHtml = controlHtml.replace("#required", (attr_data_selected == undefined || attr_data_selected == "false" || attr_data_selected == null) ? "false" : "true");

                        controlHtml = controlHtml.replace("#customTooltip", attr_data_customtooltip == null ? '' : attr_data_customtooltip);
                        controlHtml = controlHtml.replace("#originalRecipientId", attr_data_original_rcptid == null ? '' : attr_data_original_rcptid.toLowerCase());

                        if (attr_data_guid.toLowerCase() != Control.Radio.toLowerCase() && attr_data_guid.toLowerCase() != Control.Checkbox.toLowerCase() && attr_data_guid.toLowerCase() != Control.DropDown.toLowerCase()) {
                            controlHtml = controlHtml.replace("#fontFamily", localControlStyle.FontFamily);
                            controlHtml = controlHtml.replace("#fontWeight", localControlStyle.IsBold);
                            controlHtml = controlHtml.replace("#textDecoration", localControlStyle.IsUnderline);
                            controlHtml = controlHtml.replace("#fontstyle", localControlStyle.IsItalic);
                            controlHtml = controlHtml.replace("#fontsize", localControlStyle.FontSize + "px");
                            controlHtml = controlHtml.replace("#lineHeight", (style['line-height']) ? style['line-height'] : localControlStyle.FontSize + "px");
                            controlHtml = controlHtml.replace("#fontFamilyId", attr_font_family_id != null ? attr_font_family_id.toLowerCase() : '');
                            controlHtml = controlHtml.replace("#placeHolder", spnControlEle.innerHTML);
                        }

                        if (attr_data_guid.toLowerCase() != Control.Radio.toLowerCase() && attr_data_guid.toLowerCase() != Control.Checkbox.toLowerCase()) {
                            controlHtml = controlHtml.replace("#width", controlWidth + "px");
                            controlHtml = controlHtml.replace("#height", controlHeight + "px");
                        }

                        controlHtml = controlHtml.replace("#borderColor", currentStyle.outline == null ? '' : currentStyle.outline);
                        controlHtml = controlHtml.replace("#backGroundColor", currentStyle.background == null ? '' : currentStyle.background);

                        if (attr_data_guid.toLowerCase() == Control.Date.toLowerCase()) controlHtml = controlHtml.replace("#preselected", (attr_data_defaultdate == undefined || attr_data_defaultdate == "false" || attr_data_defaultdate == null) ? "false" : "true");
                        else controlHtml = controlHtml.replace("#preselected", (attr_data_selected1 == undefined || attr_data_selected1 == "false" || attr_data_selected1 == null) ? "false" : "true");

                        if (attr_data_guid.toLowerCase() == Control.Name.toLowerCase() || attr_data_guid.toLowerCase() == Control.Text.toLowerCase() || attr_data_guid.toLowerCase() == Control.Title.toLowerCase()) {
                            controlHtml = controlHtml.replace("#isfixedwidth", (attr_data_isfixedwidth == false || attr_data_isfixedwidth == "false") ? false : true);
                            if((attr_data_isfixedwidth == false || attr_data_isfixedwidth == "false")){
                                controlHtml = controlHtml.replace("#maxlengthallowed", '20');
                                controlHtml = controlHtml.replace("#maxcharallowed", attr_maxcharallowed != null && attr_maxcharallowed != 'null' ? attr_maxcharallowed : '');
                                controlHtml = controlHtml.replace("#maxemailcharallowed", attr_maxemailcharallowed != null && attr_maxemailcharallowed != 'null' ? attr_maxemailcharallowed : '');
                            } else {
                                if(attr_data_guid.toLowerCase() == Control.Name.toLowerCase() || attr_data_guid.toLowerCase() == Control.Title.toLowerCase()){
                                    controlHtml = controlHtml.replace("#maxcharallowed", '100');
                                    controlHtml = controlHtml.replace("#maxemailcharallowed", '100');
                                } else {
                                    controlHtml = controlHtml.replace("#maxcharallowed", '4000');
                                    controlHtml = controlHtml.replace("#maxemailcharallowed", '100');
                                }
                                controlHtml = controlHtml.replace("#maxlengthallowed", attr_maxlengthallowed != null && attr_maxlengthallowed != 'null' ? attr_maxlengthallowed : '');
                            }
                        }
                        else {
                            controlHtml = controlHtml.replace("#isfixedwidth", true);
                            controlHtml = controlHtml.replace("#maxlengthallowed", '20');
                            controlHtml = controlHtml.replace("#maxcharallowed", '20');
                            controlHtml = controlHtml.replace("#maxemailcharallowed", '20');
                        }

                        if (attr_data_guid.toLowerCase() == Control.Hyperlink.toLowerCase()) controlHtml = controlHtml.replace("#attr_data_hyperlink", attr_data_hyperlink);
                        if (attr_data_guid.toLowerCase() == Control.Text.toLowerCase()) {
                            controlHtml = controlHtml.replace("#textType", attr_data_texttype == null ? '' : attr_data_texttype);
                        }

                        if (attr_data_guid.toLowerCase() == Control.Radio.toLowerCase() || attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase() || attr_data_guid.toLowerCase() == Control.Text.toLowerCase()) {
                            if (ele?.classList.contains('addedAsChild') == true) controlHtml = controlHtml.replace("#addedAsChild", 'parentBorder addedAsChild');
                            else controlHtml = controlHtml.replace("#addedAsChild", '');

                            controlHtml = controlHtml.replace("#isChildPresent", attr_is_child_present == null ? "false" : attr_is_child_present);
                            controlHtml = controlHtml.replace("#isParentPresent", attr_is_parent_present == null ? "false" : attr_is_parent_present);
                        }

                        if (attr_data_guid.toLowerCase() == Control.Radio.toLowerCase() || attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                            controlHtml = controlHtml.replace("#groupName", (groupname == null || groupname == '') ? "" : groupname);
                            controlHtml = controlHtml.replace("#size", attr_data_size == null ? '' : attr_data_size);
                            controlHtml = controlHtml.replace("#name", name == null ? '' : name);
                            controlHtml = controlHtml.replace("#width", style['width']);
                            controlHtml = controlHtml.replace("#height", style['height']);
                            if (attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                                if (attr_data_selected == "true") controlHtml = controlHtml.replace("#disabled", 'disabled');
                                else controlHtml = controlHtml.replace("#disabled", '');
                                labelValue = (name == null || name == '') ? 'Checkbox' : name;
                                name = (name == null || name == '') ? 'Checkbox' : name;
                            }
                        }

                        if (attr_data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
                            controlHtml = controlHtml.replace("#isChildPresent", attr_is_child_present == null ? "false" : attr_is_child_present);
                            controlHtml = controlHtml.replace("#isParentPresent", attr_is_parent_present == null ? "false" : attr_is_parent_present);

                            if (controlEle?.classList.contains('expanded') == true) controlHtml = controlHtml.replace("#expanded", 'expanded');
                            else controlHtml = controlHtml.replace("#expanded", '');

                            if (ele?.classList.contains('addedAsChild') == true) controlHtml = controlHtml.replace("#addedAsChild1", 'parentBorder addedAsChild');
                            else controlHtml = controlHtml.replace("#addedAsChild1", '');

                            controlHtml = controlHtml.replace("#fontFamily", style['font-family']);
                            controlHtml = controlHtml.replace("#fontFamily1", style['font-family']);
                            controlHtml = controlHtml.replace("#backGroundColor1", currentStyle.background == null ? '' : currentStyle.background);
                            controlHtml = controlHtml.replace("#selectOptions", selectOptionString);
                        }

                        if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Initials.toLowerCase()) {
                            labelValue = "Initials";
                        }
                        else if (attr_data_guid && attr_data_guid.toLowerCase() == Control.DateTimeStamp.toLowerCase()) {
                            labelValue = "Date & Time Stamp";
                        }

                        if (attr_data_guid && (attr_data_guid.toLowerCase() == Control.Title.toLowerCase()
                            || attr_data_guid.toLowerCase() == Control.DateTimeStamp.toLowerCase() || attr_data_guid.toLowerCase() == Control.Label.toLowerCase() ||
                            attr_data_guid.toLowerCase() == Control.Name.toLowerCase() || attr_data_guid.toLowerCase() == Control.Signature.toLowerCase() ||
                            attr_data_guid.toLowerCase() == Control.Initials.toLowerCase() || attr_data_guid.toLowerCase() == Control.Company.toLowerCase()
                        )) {
                            senderControlValue = "";
                        }

                        let ctrlType: any = '00000000-0000-0000-0000-000000000000';
                        if (attr_data_guid && attr_data_guid.toLowerCase() == Control.Text.toLowerCase()) {
                            ctrlType = (attr_data_texttype == undefined || attr_data_texttype == null) ? '00000000-0000-0000-0000-000000000000' : attr_data_texttype_Guid;
                        }

                        if (ele?.classList.contains('addedAsChild') == true) controlHtml = controlHtml.replace("#addedAsChild", 'alreadyDependentField addedAsChild');
                        else controlHtml = controlHtml.replace("#addedAsChild", '');

                        console.log("attr_id:" + controlHtml);

                        controlArray.push({
                            ID: attr_contentId.toLowerCase(),
                            ControlID: attr_data_guid.toLowerCase(),
                            DocumentID: docGuid,
                            RecipientID: (attr_data_guid.toLowerCase() == Control.Label.toLowerCase() || attr_data_guid.toLowerCase() == Control.Hyperlink.toLowerCase()) ? '00000000-0000-0000-0000-000000000000' : attr_data_rcptid.toLowerCase(),
                            ControlHtmlID: attr_id,
                            IntControlId: attr_int_control_id,
                            Required: (attr_data_selected == undefined || attr_data_selected == "true") ? true : false,
                            DocumentPageNumber: attr_doc_pageNo,
                            PageNumber: attr_pageNo,
                            XCoordinate: parseInt((controlLeftPosition * ratioWidth).toString()),
                            YCoordinate: parseInt((controlTopPosition * ratioHeight).toString()),
                            ZCoordinate: parseInt((ZCoordinate).toString()), //need to check once again
                            ControlValue: (attr_controlValue != undefined && attr_controlValue != null && attr_controlValue != '') ? attr_controlValue : (((attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase() || attr_data_guid.toLowerCase() == Control.Radio.toLowerCase()) && senderControlValue != undefined && senderControlValue != '') ? senderControlValue : ''),
                            Label: attr_data_guid.toLowerCase() == Control.Radio.toLowerCase() ? name : attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase() ? name : labelValue,
                            Height: controlHeight,
                            Width: controlWidth,
                            GroupName: attr_data_guid.toLowerCase() != Control.Checkbox.toLowerCase() ? (groupname == undefined ? null : groupname) : (groupname != undefined && groupname != null && groupname != "" ? groupname : null),
                            ControlHtmlData: controlHtml,
                            RecipientName: (attr_data_guid.toLowerCase() == Control.Label.toLowerCase() || attr_data_guid.toLowerCase() == Control.Hyperlink.toLowerCase()) ? '' : this.getRecipientName(attr_data_rcptid),
                            IsControlDeleted: false,
                            ControlType: ctrlType,
                            Left: parseFloat(controlLeftPosition),
                            Top: parseFloat(controlTopPosition),
                            SenderControlValue: senderControlValue,
                            ControlStyle: localControlStyle,
                            Options: selectControl,
                            Dependencies: dependencies,
                            ParentHeight: parentHeight,
                            ImageOriginalHeight: originalHeight,
                            ImageHeight: imageHeight,
                            ImageOriginalWidth: originalWidth,
                            ImageWidth: imageWidth,
                            OrignalPageFormat: imageWidth > imageHeight ? "landscape" : "portrait",
                            Size: attr_data_size == null ? null : attr_data_size,
                            IsDefaultRequired: (attr_data_guid.toLowerCase() == Control.Date.toLowerCase()) ? attr_data_defaultdate == "true" ? true : false : false,
                            CustomToolTip: attr_data_customtooltip == null || attr_data_customtooltip == 0 || attr_data_customtooltip == "0" ? '' : attr_data_customtooltip,
                            FontTypeMeasurement: (attr_data_guid.toLowerCase() == Control.Radio.toLowerCase() ? name : attr_data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) ? '' : attr_data_typography,
                            IsFixedWidth: (attr_data_isfixedwidth == false || attr_data_isfixedwidth == "false") ? false : true

                            // DocumentContentID: attr_contentId.toLowerCase(),
                            // ControlID: attr_data_guid.toLowerCase(),
                            // ControlHtmlID: attr_id.toLowerCase(),
                            // ControHtmlData: '', //need to do
                            // IntControlId: attr_int_control_id.toLowerCase(),
                            // GlobalEnvelopeID: '',
                            // EnvelopeStage: "PrepareEnvelope",
                            // DocumentID: '',
                            // RecipientID: attr_data_rcptid.toLowerCase(),
                            // Required: attr_data_selected == "true" ? true : false,
                            // SenderControlValue: this.checkNull(entityValue, senderControlValue),
                            // XCoordinate: controlLeftPosition * ratioWidth,  // Control positon from left
                            // YCoordinate: controlTopPosition * ratioHeight,   // Control position from top
                            // ZCoordinate: ZCoordinate,
                            // Left: parseFloat(controlLeftPosition),
                            // Top: parseFloat(controlTopPosition),
                            // PageNo: attr_pageNo,
                            // DocumentPageNo: attr_pageNo,
                            // Label: labelValue,
                            // Height: controlHeight,
                            // Width: controlWidth,
                            // ControlStyle: localControlStyle,
                            // Color: localControlStyle != null ? localControlStyle.FontColor : '',
                            // fontSize: localControlStyle != null ? localControlStyle.FontSize : '',
                            // fontFamilyID: localControlStyle != null ? localControlStyle.FontID : '',
                            // Bold: localControlStyle != null ? localControlStyle.IsBold : '',
                            // Underline: localControlStyle != null ? localControlStyle.IsUnderline : '',
                            // Italic: localControlStyle != null ? localControlStyle.IsItalic : '',
                            // AdditionalValidationName: localControlStyle != null ? localControlStyle.AdditionalValidationName : '',
                            // AdditionalValidationOption: localControlStyle != null ? localControlStyle.AdditionalValidationOption : '',
                            // IsSubmitConfirmation: attr_data_guid.toLowerCase() == Control.Email.toLowerCase() ? controlEle.setAttribute('data-confirm-submit') : 'false',
                            // ControlOptions: selectControl,
                            // GroupName: groupname,
                            // RadioName: name,
                            // GroupNameCheckbox: groupname,
                            // CheckboxName: name,
                            // TotalControlCount: totalControlCount,
                            // MaxLength: '3AC3281B-6F6B-4C0A-BD1D-8E87FABF3130',
                            // ControlType: attr_data_texttype,
                            // IsRecipientChange: attr_data_rcptid != attr_data_original_rcptid,
                            // GroupCode: attr_group_code,
                            // Dependencies: dependencies,
                            // ParentHeight: parentHeight,
                            // ImageOriginalHeight: originalHeight,
                            // ImageHeight: imageHeight,
                            // ImageOriginalWidth: originalWidth,
                            // ImageWidth: imageWidth,
                            // OrignalPageFormat: imageWidth > imageHeight ? "landscape" : "portrait",
                            // Size: attr_data_size,
                            // IsDefaultRequired: attr_data_guid.toLowerCase() == Control.Date.toLowerCase() ? attr_data_defaultdate == "true" ? true : false : false,
                            // CustomToolTip: attr_data_customtooltip,
                            // FontTypeMeasurement: 'px',
                            // ControlValue: undefined,
                            // SignatureControlValue: undefined,
                            // IsControlDeleted: false,
                            // RecipientName: this.getRecipientName(attr_data_rcptid),
                            // IsFixedWidth: attr_data_isfixedwidth == false || attr_data_isfixedwidth == null || attr_data_isfixedwidth == undefined ? false : true
                        });
                    };
                }
            });
        }

        resultObj = {
            isValid: isValid,
            controlArray: controlArray
        };

        return resultObj;
    }

    getRecipientName(recipientId: any) {
        let name: any = '';
        if (recipientId) {
            let recpId: any = recipientId;
            let receipentLists: any = this.authService.getRecipientDetails();
            if (receipentLists) {
                let receipentListsDetails: any = JSON.parse(receipentLists);
                if (receipentListsDetails && receipentListsDetails.length > 0) {
                    let recObj: any = receipentListsDetails.filter((rec: any) => rec.id.toLowerCase() == recpId.toLowerCase());
                    if (recObj && recObj.length > 0) {
                        name = recObj[0].name;
                    }
                }
            }
        }
        return name;
    }

    getControlOriginalTitle(recipientId: any, currentSelectedControl: any) {
        let name: any = '', placeHolderText: any = '';
        let resizableCurrentControl: any = document.getElementById(currentSelectedControl);
        if (resizableCurrentControl) {
            let docControl: any = document.getElementById("resizable_" + currentSelectedControl)
            let data_guid: any = docControl?.getAttribute('data-guid');
            if (data_guid && (data_guid.toLowerCase() == Control.Label.toLowerCase() || data_guid.toLowerCase() == Control.Hyperlink.toLowerCase())) {
                resizableCurrentControl?.removeAttribute('data-original-title');
                resizableCurrentControl?.setAttribute('title', '');
                return '';
            }
            else {
                if (recipientId) {
                    let recpId: any = recipientId;
                    let receipentLists: any = this.authService.getRecipientDetails();
                    if (receipentLists) {
                        let receipentListsDetails: any = JSON.parse(receipentLists);
                        if (receipentListsDetails && receipentListsDetails.length > 0) {
                            let recObj: any = receipentListsDetails.filter((rec: any) => rec.id.toLowerCase() == recpId.toLowerCase());
                            if (recObj && recObj.length > 0) {
                                name = recObj[0].name;
                            }
                        }
                    }
                    if (resizableCurrentControl) {
                        placeHolderText = resizableCurrentControl.getAttribute('data-controlname');
                        placeHolderText = this.commonService.getHoverControlText(placeHolderText);
                    }
                }
                return placeHolderText + " control assigned to " + name;
            }
        }
        else return '';
    }

    setdefaultTextValidation() {
        let textValidationsoptions: any = (<HTMLInputElement>document.getElementById('textValidationsoptions'));
        if (textValidationsoptions) {
            textValidationsoptions.value = '1';
        }

        let textTypeDecimal: any = (<HTMLInputElement>document.getElementById('textTypeDecimal'));
        if (textTypeDecimal) {
            textTypeDecimal.value = '1';
        }

        let textTypeDecimalOptions: any = (<HTMLInputElement>document.getElementById('textTypeDecimalOptions'));
        if (textTypeDecimalOptions) {
            textTypeDecimalOptions.value = '1';
        }

        let textZipOptions: any = (<HTMLInputElement>document.getElementById('textZipOptions'));
        if (textZipOptions) {
            textZipOptions.value = '1';
        }

        let textDateOptions: any = (<HTMLInputElement>document.getElementById('textDateOptions'));
        if (textDateOptions) {
            textDateOptions.value = '1';
        }

        let textInputTypeEle: any = document.getElementById('textInputLength');
        if (textInputTypeEle) {
            textInputTypeEle.removeAttribute('disabled');
        }
    }

    checkNull(val: any, defaultVal: any) {
        if (val && val.trim() != '' && val != "undefined" && val != "null" && val != null && val != undefined) { return val; }
        else { return defaultVal; }
    }

    applyRecipientDetailsOnMouseOverChange(recipient: any, color: any, currentSelectedControl: any) {
        let resizableCurrentControl: any = document.getElementById("resizable_" + currentSelectedControl);
        if (resizableCurrentControl) {
            resizableCurrentControl.setAttribute('data-rcptid', recipient.id);
            resizableCurrentControl.setAttribute('data-original-rcptid', recipient.id);
            resizableCurrentControl.setAttribute('data-recp-color', color);
            resizableCurrentControl.style.outline = 'none';
            resizableCurrentControl.style.outline = color + ' solid 3px';
            resizableCurrentControl.setAttribute('data-original-title', this.getControlOriginalTitle(recipient.id, currentSelectedControl));
        }
    }

    getSameControlNumberCount(ctrlGuid: any, pageNum: any, id: any) {
        let arryCtrlPageNum: any = [];
        let controlPageGuidNumArray: any = localStorage.getItem('ControlPageGuidNumArray');
        if (controlPageGuidNumArray) {
            arryCtrlPageNum = JSON.parse(controlPageGuidNumArray);
        }

        if (arryCtrlPageNum && arryCtrlPageNum.length > 0) {
            let sameCtrlArray: any = arryCtrlPageNum.filter(function (obj: any) { return obj.dataGuid.toLowerCase() == ctrlGuid.toLowerCase() && obj.pageNo == pageNum });
            if (sameCtrlArray && sameCtrlArray.length > 0)
                return sameCtrlArray.length + 1;
            else return 1;
        }
        else return 1;
    }

    createControlOnDocument(newcomponentRef: any, dynamicComponentRefs: any, appRef: any, ctrlObj: any, type: any) {
        let arryCtrlPageNum: any = [];
        let imageCtrl: any = document.getElementById('imgControl_' + ctrlObj.pageNo);
        if (imageCtrl) {
            // Set input properties as needed
            let controlClassName: any = ctrlObj.controlName.toLowerCase();
            let controlId: any = controlClassName;
            let controlName: any = ctrlObj.controlName;
            let placeHolderText: any = controlName;
            let pageNum: any = ctrlObj.pageNo;

            let defaultWidth: any = 100;
            let defaultHeight: any = 20;
            let minWidth: any = 100;
            let minHeight: any = 10;
            let ctrlBgColor: any = '#fff';
            let controlDateFormat: any = '';
            let controlResourceKeys: any = '';
            let defaultDate: boolean = false;
            let originalTitle: any = '';
            let data_guid: any;
            let attr_data_rcptid: any;
            let attr_data_recp_color: any;
            let controlTopPosition: any;
            let controlLeftPosition: any;
            let controlHeight = '';
            let controlWidth = ''

            let resizableControl: any = document.getElementById('resizable_' + ctrlObj.id);
            if (resizableControl) {
                data_guid = resizableControl?.getAttribute('data-guid');
                attr_data_rcptid = resizableControl?.getAttribute('data-rcptid');
                attr_data_recp_color = resizableControl?.getAttribute('data-recp-color');
                let controlPositions: any = this.getElePositions(resizableControl);
                controlHeight = resizableControl?.offsetHeight;
                controlWidth = resizableControl?.offsetWidth;
                controlLeftPosition = parseInt(controlPositions.Left) + 15;
                controlTopPosition = parseInt(controlPositions.Top) + controlHeight + 7;
            }
            else {
                data_guid = ctrlObj.data_guid;
                attr_data_rcptid = ctrlObj.attr_data_rcptid;
                attr_data_recp_color = ctrlObj.attr_data_recp_color;
                controlTopPosition = ctrlObj.controlTopPosition;
                controlLeftPosition = ctrlObj.controlLeftPosition;
                controlHeight = ctrlObj.controlHeight;
                controlWidth = ctrlObj.controlWidth;
            }

            //Checking is the control go beyond the page boundaries
            let imageWidth: any = imageCtrl.offsetWidth;
            let imageHeight: any = imageCtrl.offsetHeight;
            // console.log("Height:" + imageHeight + " : " + (controlTopPosition + controlHeight))
            // console.log("Width:" + imageWidth + " : " + (controlLeftPosition + controlWidth))
            if (imageHeight < (controlTopPosition + controlHeight)) {
                this.toastrService.warning('The control may extend beyond the page boundary.', 'Warning'); return false;
            }
            else if (imageWidth < (controlLeftPosition + controlWidth)) {
                this.toastrService.warning('The control may extend beyond the page boundary.', 'Warning'); return false;
            }

            if (data_guid.toLowerCase() === Control.Signature.toLowerCase()) {
                controlClassName = 'sign';
                controlName = 'Signature';
                controlId = 'sign';
                defaultWidth = 215;
                defaultHeight = 37;
                minWidth = 50;
                minHeight = 37;
                placeHolderText = 'Sign';
                controlResourceKeys = ControlResourceKeys.Signature;
                originalTitle = 'Signature';
            }
            else if (data_guid.toLowerCase() === Control.Initials.toLowerCase()) {
                controlClassName = 'initials';
                controlId = 'newInitials';
                defaultWidth = 47;
                defaultHeight = 22;
                minWidth = 47;
                controlName = 'NewInitials';
                placeHolderText = 'Initials';
                controlResourceKeys = ControlResourceKeys.Initials; //translations text
                originalTitle = 'Initials';
            }
            else if (data_guid.toLowerCase() === Control.DateTimeStamp.toLowerCase()) {
                controlClassName = 'datetimestamp';
                controlName = 'DateTimeStamp';
                controlId = 'dateTimeStamp';
                defaultWidth = 150;
                ctrlBgColor = 'yellow';
                placeHolderText = 'Date & Time Stamp';
                controlResourceKeys = ControlResourceKeys.DateTimeStamp;
                originalTitle = 'Date & Time Stamp';
            }
            else if (data_guid.toLowerCase() === Control.Name.toLowerCase()) {
                defaultWidth = 167;
                defaultHeight = 20;
                minWidth = 0;
                controlClassName = 'name';
                controlResourceKeys = ControlResourceKeys.Name;
                originalTitle = 'Name';
            }
            else if (data_guid.toLowerCase() === Control.Text.toLowerCase()) {
                defaultWidth = 167;
                defaultHeight = 20;
                minWidth = 0;
                controlClassName = 'text';
                controlResourceKeys = ControlResourceKeys.Text;
                originalTitle = 'Text';
            }
            else if (data_guid.toLowerCase() === Control.DropDown.toLowerCase()) {
                controlClassName = 'dropdown';
                controlId = 'dropDown';
                defaultWidth = controlWidth;
                minWidth = controlWidth;
                controlResourceKeys = ControlResourceKeys.DropDown;
                originalTitle = 'DropDown';
            }
            else if (data_guid.toLowerCase() === Control.Date.toLowerCase()) {
                controlDateFormat = this.getDateFormatInnerHtmlVal("2"); //'mm/dd/yyyy';
                controlName = 'Date';
                controlClassName = 'date';
                defaultDate = false;
                originalTitle = 'Date';
                defaultHeight = 16;
            }
            else if (data_guid.toLowerCase() === Control.Label.toLowerCase()) {
                ctrlBgColor = 'yellow';
                controlClassName = 'label';
                controlResourceKeys = ControlResourceKeys.Label;
                originalTitle = 'Label';
            }
            else if (data_guid.toLowerCase() === Control.Radio.toLowerCase()) {
                defaultWidth = 22;
                defaultHeight = 22;
                minWidth = 18;
                minHeight = 18;
                ctrlBgColor = 'white';
                controlClassName = 'radio';
                controlResourceKeys = ControlResourceKeys.Radio;
                originalTitle = 'Radio';
            }
            else if (data_guid.toLowerCase() === Control.Checkbox.toLowerCase()) {
                defaultWidth = 22;
                defaultHeight = 22;
                minWidth = 18;
                minHeight = 18;
                ctrlBgColor = 'white';
                controlClassName = 'checkbox';
                controlResourceKeys = ControlResourceKeys.Checkbox;
                originalTitle = 'Checkbox';
            }
            else if (data_guid.toLowerCase() === Control.Company.toLowerCase()) {
                controlClassName = 'company';
                controlResourceKeys = ControlResourceKeys.Company;
                originalTitle = 'Company';
            }
            else if (data_guid.toLowerCase() === Control.Title.toLowerCase()) {
                controlClassName = 'title';
                controlResourceKeys = ControlResourceKeys.Title;
                originalTitle = 'Title';
            }
            else if (data_guid.toLowerCase() === Control.Hyperlink.toLowerCase()) {
                controlClassName = 'hyperlink';
                controlResourceKeys = ControlResourceKeys.Hyperlink;
                originalTitle = 'Hyperlink';
            }
            else if (data_guid.toLowerCase() === Control.Email.toLowerCase()) {
                controlClassName = 'email';
                controlResourceKeys = ControlResourceKeys.Email;
                originalTitle = 'Email';
            }

            let controlPageGuidNumArray: any = localStorage.getItem('ControlPageGuidNumArray');
            if (controlPageGuidNumArray) {
                arryCtrlPageNum = JSON.parse(controlPageGuidNumArray);
            }
            arryCtrlPageNum.push({ dataGuid: data_guid, pageNo: pageNum, id: ctrlObj.id });
            localStorage.setItem('ControlPageGuidNumArray', JSON.stringify(arryCtrlPageNum));

            placeHolderText = this.commonService.getTranslatedText(placeHolderText, controlResourceKeys);
            newcomponentRef.instance.ctrlObj = {
                top: controlTopPosition,
                left: controlLeftPosition,
                width: controlWidth,
                height: controlHeight,
                controlName: controlName,
                className: controlClassName,
                id: type == ControlConfig.Duplicate ? controlId + 'Control' + this.commonService.getId() : ctrlObj.id,
                dataDocumentContentId: type == ControlConfig.Duplicate ? this.commonService.generateUUID() : ctrlObj.contentId,
                dataGuid: data_guid,
                title: placeHolderText,
                pageNo: pageNum,
                minWidth: minWidth,
                minHeight: minHeight,
                bgColor: (controlClassName != "label" && controlClassName != "hyperlink") ? ctrlBgColor : ctrlBgColor,
                outlineColor: (controlClassName != "label" && controlClassName != "hyperlink") ? '2px solid ' + attr_data_recp_color : "2px solid #999",
                recipientId: (controlClassName != "label" && controlClassName != "hyperlink") ? attr_data_rcptid : "",
                recpColor: (controlClassName != "label" && controlClassName != "hyperlink") ? attr_data_recp_color : "1px solid #999",
                ctrlNumber: this.getSameControlNumberCount(data_guid, pageNum, ctrlObj.id),
                controlDateFormat: controlDateFormat,
                typography: 'px',
                placeHolderText: placeHolderText,
                recipientsObj: { selectedRecipentName: this.getRecipientName(attr_data_rcptid), selectedRecipentColor: attr_data_recp_color },
                originalTitle: originalTitle.toLowerCase(),
                defaultDate: defaultDate,
                isRequired: ctrlObj.isRequired,
                textFormatting: ctrlObj.textFormatting
            };

            dynamicComponentRefs.set(newcomponentRef.instance.ctrlObj.id, newcomponentRef);
            // Attach the component to the DOM
            appRef.attachView(newcomponentRef.hostView);
            const domElem = (newcomponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
            imageCtrl?.appendChild(domElem);
            return newcomponentRef.instance.ctrlObj;
        }
    }

    updateJsonArrays(controlName: any, controlClassName: any, ctrlObj: any, data_guid: any) {
        let ctrlName = this.commonService.getControlName(controlName, controlClassName);
        let allImageControlsString: any = localStorage.getItem('AllImageControls');
        let arrControlIdNumbers:any = [];
        if (allImageControlsString) {
            arrControlIdNumbers = JSON.parse(allImageControlsString);
        }

        arrControlIdNumbers.push({
            Control: ctrlName,
            ControlId: ctrlObj.id,
            DataDocumentContentId: ctrlObj.dataDocumentContentId,
            ConditionID: '00000000-0000-0000-0000-000000000000',
            PageNo: ctrlObj.pageNo,
            DataGuid: ctrlObj.dataGuid,
            ControlName: ctrlName,
            CtrlNumber: ctrlObj.ctrlNumber,
            RecipientId: ctrlObj.recipientId,
        });
        localStorage.setItem('AllImageControls', JSON.stringify(arrControlIdNumbers));


        let controlDependencyFieldsString: any = localStorage.getItem('ControlDependencyFieldsArray');
        let arrControlFieldDependenceControls:any = [];
        if (controlDependencyFieldsString) {
            arrControlFieldDependenceControls = JSON.parse(controlDependencyFieldsString);
        }

        if (data_guid.toLowerCase() === Control.Radio.toLowerCase() || data_guid.toLowerCase() === Control.Checkbox.toLowerCase()
            || data_guid.toLowerCase() === Control.DropDown.toLowerCase() || data_guid.toLowerCase() === Control.Text.toLowerCase()) {
                arrControlFieldDependenceControls.push({
                Control: ctrlName,
                ControlId: ctrlObj.id,
                DataDocumentContentId: ctrlObj.dataDocumentContentId,
                ConditionID: '00000000-0000-0000-0000-000000000000',
                PageNo: ctrlObj.pageNo,
                DataGuid: ctrlObj.dataGuid,
                ControlName: ctrlName,
                CtrlNumber: ctrlObj.ctrlNumber,
                RecipientId: ctrlObj.recipientId,
            });
            localStorage.setItem('ControlDependencyFieldsArray', JSON.stringify(arrControlFieldDependenceControls));
        }
    }

    updateDuplicateControlProperties(newControlId: any, oldControlId: any, data_guid: any) {
        let oldControlEle: any = (<HTMLInputElement>document.getElementById(oldControlId));
        if (!oldControlEle) return;
        //Main div
        //let old_attr_id: any = oldControlEle?.getAttribute('id');
        //let old_attr_int_control_id: any = oldControlEle?.getAttribute('data-int-control-id');
        //let old_attr_contentId: any = oldControlEle?.getAttribute('data-document-content-id');
        //let old_attr_pageNo: any = oldControlEle?.getAttribute('data-pageno');
        //let old_attr_ctrlNum: any = oldControlEle?.getAttribute('data-ctrlnum');
        //let old_attr_ctrlName: any = oldControlEle?.getAttribute('data-controlname');
        //let old_attr_group_code: any = oldControlEle?.getAttribute('group-code');
        //let old_attr_data_condition_id: any = oldControlEle?.getAttribute('data-condition-id');
        //let old_attr_data_parent_id: any = oldControlEle?.getAttribute('data-parent-id');
        //let old_attr_is_parent_present: any = oldControlEle?.getAttribute('is-parent-present');
        //let old_attr_data_parent_rule_id: any = oldControlEle?.getAttribute('data-parent-rule-id');
        //let old_attr_is_child_present: any = oldControlEle?.getAttribute('is-child-present');
        //let old_attr_supportText: any = oldControlEle?.getAttribute('data-parent-support-text-content');

        //Resizable div
        let oldResizableControlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + oldControlId));
        if (!oldResizableControlEle) return;

        let newResizableControlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + newControlId));
        if (!newResizableControlEle) return;

        //let old_attr_data_guid: any = oldResizableControlEle?.getAttribute('data-guid');
        //let old_attr_data_rcptid: any = oldResizableControlEle?.getAttribute('data-rcptid');
        //let old_attr_data_original_rcptid: any = oldResizableControlEle?.getAttribute('data-original-rcptid');
        //let old_attr_data_recp_color: any = oldResizableControlEle?.getAttribute('data-recp-color');
        //let old_attr_pageno: any = oldResizableControlEle?.getAttribute('pageno');
        //let old_attr_data_original_title: any = oldResizableControlEle?.getAttribute('data-original-title');
        let old_attr_title: any = oldResizableControlEle?.getAttribute('title');
        if (old_attr_title) newResizableControlEle?.setAttribute('title', old_attr_title);

        let old_attr_controlValue: any = oldResizableControlEle?.getAttribute('data-control-val');
        if (old_attr_controlValue) newResizableControlEle?.setAttribute('data-control-val', old_attr_controlValue);

        let old_attr_data_typography: any = oldResizableControlEle?.getAttribute('data-typography');
        if (old_attr_data_typography) newResizableControlEle?.setAttribute('data-typography', old_attr_data_typography);

        let old_attr_data_selected: any = oldResizableControlEle?.getAttribute('data-selected');
        if (old_attr_data_selected) newResizableControlEle?.setAttribute('data-selected', old_attr_data_selected);

        let old_attr_data_selected1: any = oldResizableControlEle?.getAttribute('data-selected1');
        if (old_attr_data_selected1) newResizableControlEle?.setAttribute('data-selected1', old_attr_data_selected1);

        let old_attr_data_customtooltip: any = oldResizableControlEle?.getAttribute('data-customtooltip');
        if (old_attr_data_customtooltip) newResizableControlEle?.setAttribute('data-customtooltip', old_attr_data_customtooltip);

        let old_attr_data_isfixedwidth: any = oldResizableControlEle?.getAttribute('data-isfixedwidth');
        let old_attr_maxlengthallowed: any = oldResizableControlEle?.getAttribute('maxlengthallowed');
        let old_attr_maxcharallowed: any = oldResizableControlEle?.getAttribute('maxcharallowed');
        let old_attr_maxemailcharallowed: any = oldResizableControlEle?.getAttribute('maxemailcharallowed');

        if (data_guid && (data_guid.toLowerCase() == Control.Name.toLowerCase() || data_guid.toLowerCase() == Control.Text.toLowerCase() || data_guid.toLowerCase() == Control.Title.toLowerCase())) {
            newResizableControlEle?.setAttribute('maxlengthallowed', old_attr_maxlengthallowed == null || old_attr_maxlengthallowed == 'null' ? '' : old_attr_maxlengthallowed);
            newResizableControlEle?.setAttribute('maxcharallowed', old_attr_maxcharallowed == null || old_attr_maxcharallowed == 'null' ? '' : old_attr_maxcharallowed);
            if (data_guid && data_guid.toLowerCase() == Control.Text.toLowerCase()) newResizableControlEle?.setAttribute('maxemailcharallowed', old_attr_maxemailcharallowed == null ? '' : old_attr_maxemailcharallowed);
            newResizableControlEle?.setAttribute('data-isfixedwidth', (old_attr_data_isfixedwidth == null || old_attr_data_isfixedwidth == true) ? 'true' : old_attr_data_isfixedwidth);
        }
        else {
            if (old_attr_maxlengthallowed) newResizableControlEle?.setAttribute('maxlengthallowed', old_attr_maxlengthallowed == null ? '' : old_attr_maxlengthallowed);
            if (old_attr_maxcharallowed) newResizableControlEle?.setAttribute('maxcharallowed', old_attr_maxcharallowed == null ? '' : old_attr_maxcharallowed);
            if (data_guid && data_guid.toLowerCase() == Control.Text.toLowerCase()) if (old_attr_maxemailcharallowed) newResizableControlEle?.setAttribute('maxemailcharallowed', old_attr_maxemailcharallowed == null ? '' : old_attr_maxemailcharallowed);
            if (old_attr_data_isfixedwidth) newResizableControlEle?.setAttribute('data-isfixedwidth', (old_attr_data_isfixedwidth == null || old_attr_data_isfixedwidth == true) ? 'true' : old_attr_data_isfixedwidth);
        }

        if (data_guid && data_guid.toLowerCase() == Control.Date.toLowerCase()) {
            let old_attr_data_defaultformat: any = oldResizableControlEle?.getAttribute('data-defaultformat');
            if (old_attr_data_defaultformat) newResizableControlEle?.setAttribute('data-defaultformat', old_attr_data_defaultformat);

            let old_attr_data_defaultdate = oldResizableControlEle?.getAttribute('data-defaultdate');
            if (old_attr_data_defaultdate) newResizableControlEle?.setAttribute('data-defaultdate', old_attr_data_defaultdate);
        }

        let old_attr_data_confirm_submit: any = oldResizableControlEle.getAttribute('data-confirm-submit');
        if (old_attr_data_confirm_submit) newResizableControlEle?.setAttribute('data-confirm-submit', old_attr_data_confirm_submit);

        if (data_guid && data_guid.toLowerCase() == Control.Radio.toLowerCase() || data_guid && data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
            let old_attr_data_isGroupChecked: any = oldResizableControlEle?.getAttribute('data-isGroupChecked');
            if (old_attr_data_isGroupChecked) newResizableControlEle?.setAttribute('data-isGroupChecked', old_attr_data_isGroupChecked);

            let old_attr_data_groupname: any = oldResizableControlEle?.getAttribute('data-groupname');
            if (old_attr_data_groupname) newResizableControlEle?.setAttribute('data-groupname', old_attr_data_groupname);

            let old_attr_data_size = oldResizableControlEle?.getAttribute('data-size');
            if (old_attr_data_size) newResizableControlEle?.setAttribute('data-size', old_attr_data_size);
        }

        if (data_guid && data_guid.toLowerCase() == Control.Text.toLowerCase()) {
            let old_attr_data_texttype = oldResizableControlEle?.getAttribute('data-texttype');
            if (old_attr_data_texttype) newResizableControlEle?.setAttribute('data-texttype', old_attr_data_texttype);

            let old_attr_data_validation_type: any = oldResizableControlEle?.getAttribute('data-validation-type');
            if (old_attr_data_validation_type) newResizableControlEle?.setAttribute('data-validation-type', old_attr_data_validation_type);

            let old_attr_data_additional_validation_name: any = oldResizableControlEle?.getAttribute('data-additional-validation-name');
            if (old_attr_data_additional_validation_name) newResizableControlEle?.setAttribute('data-additional-validation-name', old_attr_data_additional_validation_name);

            let old_attr_data_additional_validation_option: any = oldResizableControlEle?.getAttribute('data-additional-validation-option');
            if (old_attr_data_additional_validation_option) newResizableControlEle?.setAttribute('data-additional-validation-option', old_attr_data_additional_validation_option);
        }

        let old_attr_data_hyperlink = oldResizableControlEle?.getAttribute('data-hyperlink');
        if (old_attr_data_hyperlink) newResizableControlEle?.setAttribute('data-hyperlink', old_attr_data_hyperlink);

        let old_entity: any = oldResizableControlEle?.getAttribute("data-Entity");
        let old_entityType: any = oldResizableControlEle?.getAttribute("data-EntityField");
        let old_entityValue = oldResizableControlEle?.getAttribute("data-EntityFieldValue");

        // let old_attr_style: any = oldResizableControlEle?.getAttribute('style');
        // let new_attr_style: any = newResizableControlEle?.getAttribute('style');
        // if (typeof old_attr_style !== 'undefined' && old_attr_style !== false) {
        // 	var styles = old_attr_style.split(';');
        // 	for (var st in styles) {
        // 		var aux = styles[st].trim().split(':');
        // 		if (aux[1]) {
        // 			newResizableControlEle.style[aux[0].trim()] = aux[1].trim();
        // 		}
        // 	}
        // }

        //Spn div
        let oldSpnControlEle: any = (<HTMLInputElement>document.getElementById("spn" + oldControlId));
        if (!oldSpnControlEle) return;

        let newSpnControlEle: any = (<HTMLInputElement>document.getElementById("spn" + newControlId));
        if (!newSpnControlEle) return;

        let old_attr_font_family_id: any = oldSpnControlEle?.getAttribute('font-family-id');
        if (old_attr_font_family_id) newSpnControlEle?.setAttribute('font-family-id', old_attr_font_family_id);

        let old_name = oldSpnControlEle?.getAttribute('name');
        if (old_name) newSpnControlEle?.setAttribute('name', old_name);

        let old_innerText = oldSpnControlEle?.innerHTML;
        if (old_innerText) newSpnControlEle.innerHTML = old_innerText;

        let old_attr_spn_style: any = oldSpnControlEle?.getAttribute('style');
        newSpnControlEle?.setAttribute('style', '');
        //let new_attr_spn_style: any = newSpnControlEle?.getAttribute('style');
        if (typeof old_attr_spn_style !== 'undefined' && old_attr_spn_style !== false) {
            var styles = old_attr_spn_style.split(';');
            for (var st in styles) {
                var aux = (styles[st]).trim().split(':');
                if (aux[1] && aux[0] && (aux[0]).trim()!= "background-color")
                    newSpnControlEle.style[(aux[0]).trim()] = (aux[1].trim());
            }
        }  

        if (data_guid && data_guid.toLowerCase() == Control.DropDown.toLowerCase()) {
            let old_attr_data_firstlineblank: any = oldResizableControlEle?.getAttribute('data-firstlineblank');
            if (old_attr_data_firstlineblank) newResizableControlEle?.setAttribute('data-firstlineblank', old_attr_data_firstlineblank);

            let oldSelectDropdownControlField: any = (document.getElementById('spn' + oldControlId)) as HTMLSelectElement;
            let newSelectDropdownControlField: any = (document.getElementById('spn' + newControlId)) as HTMLSelectElement;
            newSelectDropdownControlField.options.length = 0;

            if (oldSelectDropdownControlField && oldSelectDropdownControlField.options && oldSelectDropdownControlField.options.length > 0) {
                for (let index = 0; index < oldSelectDropdownControlField.options.length; index++) {
                    var text = oldSelectDropdownControlField.options[index].text;
                    var ruleId = oldSelectDropdownControlField.options[index]?.getAttribute('data-select-option-id');
                    var value = oldSelectDropdownControlField.options[index]?.getAttribute('value');

                    var option = '';
                    if (index == 0) option = '<option data-select-option-id="' + ruleId + '" value="' + value + '" selected="selected">' + text + '</option>';
                    else option = '<option data-select-option-id="' + ruleId + '" value="' + value + '">' + text + '</option>';
                    newSelectDropdownControlField.insertAdjacentHTML('beforeend', option);
                }
            }
        }

        this.removeControlAttributes(newResizableControlEle, data_guid);
    }

    updateExistingControlProperties(newControlObj: any, oldControlObj: any) {
        let newControlId: any = newControlObj.id;
        let newControlEle: any = (<HTMLInputElement>document.getElementById(newControlId));
        if (!newControlEle) return;
        let newResizableControlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + newControlId));
        if (!newResizableControlEle) return;
        let newSpnControlEle: any = (<HTMLInputElement>document.getElementById("spn" + newControlId));
        if (!newSpnControlEle) return;

        var e = document.createElement('div');
        e.setAttribute('style', 'display: none;');
        e.innerHTML = oldControlObj.controlHtmlData.trim();
        document.body.appendChild(e);
        var htmlConvertedIntoDom: any = e.childNodes;

        let controlStyleAttr: any = '', originaltitleAttr: any = '', controlstitleAttr: any = '', groupCodeAttr: any = '', defaultdateAttr: any = '', sizeAttr: any = '',
            maxlengthallowedAttr: any = '', maxcharallowedAttr: any = '', maxemailcharallowedAttr: any = '', parentidAttr: any = '', parentruleidAttr: any = '', supporttextcontentAttr: any = '', groupnameAttr: any = '', expandedAttr: any = '',
            ischildpresentAttr: any = '', contenttitleAttr: any = '', isparentpresentAttr: any = '', isdisabledClassExists: any = '', grpNameAttr: any = '',
            contentStyleAttr: any = '', fontfamilyidAttr: any = '', innerHtmlAttr: any = '', controInfoAttr: any = '', guidAttr: any = '', rcptidAttr: any = '',
            texttypeAttr: any = '', selectedAttr: any = '', selected1Attr: any = '', customtooltipAttr: any = '', hyperlinkAttr: any = '', fontfamilyAttr: any = '', addedAsChildAttr: any = '',
            typographyAttr: any = '', confirmsubmitAttr: any = '', originalrcptidAttr: any = '', isfixedwidthAttr: any = '', controlvalAttr: any = '', mainSpnCtrlStyle: any = {}, spnControlStyle: any = {}, spnStyle: any = {};

        if (htmlConvertedIntoDom && htmlConvertedIntoDom[0]) {
            let id: any = htmlConvertedIntoDom[0]?.getAttribute('id');
            let styleAttr: any = htmlConvertedIntoDom[0].getAttribute('style');
            let controlnameAttr: any = htmlConvertedIntoDom[0].getAttribute('data-controlname');
            let ctrlnumAttr: any = htmlConvertedIntoDom[0].getAttribute('data-ctrlnum');
            let contentidAttr: any = htmlConvertedIntoDom[0].getAttribute('data-document-content-id');
            let controlidAttr: any = htmlConvertedIntoDom[0].getAttribute('data-int-control-id');
            let conditionidAttr: any = htmlConvertedIntoDom[0].getAttribute('data-condition-id');
            groupCodeAttr = htmlConvertedIntoDom[0].getAttribute('group-code');
            hyperlinkAttr = htmlConvertedIntoDom[0].getAttribute('data-hyperlink');
            groupnameAttr = htmlConvertedIntoDom[0].getAttribute('data-groupname');

            if (htmlConvertedIntoDom[0].querySelector('.controls')) {
                controlStyleAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('style');
                originaltitleAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('data-original-title');
                controlstitleAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('title');
                maxlengthallowedAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('maxlengthallowed');
                maxcharallowedAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('maxcharallowed');
                maxemailcharallowedAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('maxemailcharallowed');
                parentidAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('data-parent-id');
                parentruleidAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('data-parent-rule-id');
                supporttextcontentAttr = htmlConvertedIntoDom[0].querySelector('.controls').getAttribute('data-parent-support-text-content');
                expandedAttr = htmlConvertedIntoDom[0].classList.contains('expanded');
                addedAsChildAttr = htmlConvertedIntoDom[0].classList.contains('addedAsChild');
            }

            if (htmlConvertedIntoDom[0].querySelector('.content')) {
                contentStyleAttr = htmlConvertedIntoDom[0].querySelector('.content').getAttribute('style');
                fontfamilyidAttr = htmlConvertedIntoDom[0].querySelector('.content').getAttribute('font-family-id');
                innerHtmlAttr = htmlConvertedIntoDom[0].querySelector('.content').innerHTML;
            }

            if (htmlConvertedIntoDom[0].querySelector('.controlDrpDown')) {
                fontfamilyAttr = htmlConvertedIntoDom[0].querySelector('.controlDrpDown').getAttribute('font-family');
            }
            if (htmlConvertedIntoDom[0].querySelector('.ctnRadio')) {
                grpNameAttr = htmlConvertedIntoDom[0].querySelector('.ctnRadio').getAttribute('name');
            }
            if (htmlConvertedIntoDom[0].querySelector('.ctnCheckbox')) {
                grpNameAttr = htmlConvertedIntoDom[0].querySelector('.ctnCheckbox').getAttribute('name');
            }

            if (htmlConvertedIntoDom[0].querySelector('.conditionControl')) {
                ischildpresentAttr = htmlConvertedIntoDom[0].querySelector('.conditionControl').getAttribute('is-child-present');
                contenttitleAttr = htmlConvertedIntoDom[0].querySelector('.conditionControl').getAttribute('title');
                isparentpresentAttr = htmlConvertedIntoDom[0].querySelector('.conditionControl').getAttribute('is-parent-present');
                isdisabledClassExists = htmlConvertedIntoDom[0].querySelector('.conditionControl ').classList.contains('disabled');
            }

            if (htmlConvertedIntoDom[0].querySelector('.control-info')) {
                controInfoAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('title');
                guidAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-guid');
                rcptidAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-rcptid');
                texttypeAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-texttype');
                selectedAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-selected');
                selected1Attr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-selected1');
                defaultdateAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-defaultdate');
                customtooltipAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-customtooltip');
                typographyAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-typography');
                confirmsubmitAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-confirm-submit');
                originalrcptidAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-original-rcptid');
                isfixedwidthAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-isfixedwidth');
                controlvalAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-control-val');
                hyperlinkAttr = htmlConvertedIntoDom[0].getAttribute('data-hyperlink');
                sizeAttr = htmlConvertedIntoDom[0].querySelector('.control-info').getAttribute('data-size');
            }

            newControlEle?.setAttribute('data-int-control-id', controlidAttr != null ? controlidAttr : '');
            newControlEle?.setAttribute('group-code', groupCodeAttr != null ? groupCodeAttr : '');
            newControlEle?.setAttribute('data-condition-id', conditionidAttr != null ? conditionidAttr : '');
            newControlEle?.setAttribute('data-parent-id', parentidAttr != null && parentidAttr != "null" ? parentidAttr : '');
            newControlEle?.setAttribute('data-parent-rule-id', parentruleidAttr != null ? parentruleidAttr : '');
            newControlEle?.setAttribute('is-parent-present', isparentpresentAttr != null ? isparentpresentAttr : 'false');
            newControlEle?.setAttribute('is-child-present', ischildpresentAttr != null ? ischildpresentAttr : 'false');
            newControlEle?.setAttribute('data-parent-support-text-content', supporttextcontentAttr != null ? supporttextcontentAttr : '');
            newResizableControlEle?.setAttribute('data-selected', selectedAttr != null ? selectedAttr : 'false');
            if (newControlObj.dataGuid.toLowerCase() == Control.Radio.toLowerCase() || newControlObj.dataGuid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                newResizableControlEle?.setAttribute('data-selected1', selected1Attr != null ? selected1Attr : 'false');
            }
            newResizableControlEle?.setAttribute('data-customtooltip', (customtooltipAttr != null && customtooltipAttr != '') ? customtooltipAttr : '');
            newResizableControlEle?.setAttribute('data-control-val', controlvalAttr != null ? controlvalAttr : '');
            newResizableControlEle?.setAttribute('data-typography', typographyAttr != null ? typographyAttr : '');
            newResizableControlEle?.setAttribute('data-placeholder', controInfoAttr != null ? controInfoAttr : '');
            newResizableControlEle?.setAttribute('data-original-title', originaltitleAttr != null ? originaltitleAttr : '');
            //newResizableControlEle?.setAttribute('title', originaltitleAttr != null ? originaltitleAttr : '');
            newResizableControlEle?.setAttribute('data-confirm-submit', confirmsubmitAttr != null ? confirmsubmitAttr : '');

            if (typeof styleAttr !== 'undefined' && styleAttr !== false && styleAttr != null) {
                var stylesArray = styleAttr.split(';');
                for (var st in stylesArray) {
                    var aux = (stylesArray[st]).trim().split(':');
                    if (aux[1])
                        mainSpnCtrlStyle[(aux[0]).trim()] = (aux[1].trim());
                }
            }

            if (mainSpnCtrlStyle) {
                let left: any = mainSpnCtrlStyle['left'].replace('px', '');
                let top: any = mainSpnCtrlStyle['top'].replace('px', '');
                newResizableControlEle.style.transform = `translate3d(${left}px, ${top}px, ${0}px)`;
            }

            if (typeof controlStyleAttr !== 'undefined' && controlStyleAttr !== false && controlStyleAttr != null) {
                var styles = controlStyleAttr.split(';');
                for (var st in styles) {
                    var aux = (styles[st]).trim().split(':');
                    if (aux[1])
                        spnControlStyle[(aux[0]).trim()] = (aux[1].trim());
                }
            }

            if (spnControlStyle) {
                // If this is applied then old UI control back ground color grey color applied
                if( spnControlStyle['background-color'] == "rgba(220, 220, 220, 1)" || spnControlStyle['background-color'] == "rgba(220, 220, 220)"){
                    this.applyColorCodeForItem(newControlId, "rgb(255, 255, 255)");
                }
                else{
                    this.applyColorCodeForItem(newControlId, "rgb(255, 255, 255)");
                    this.applyColorCodeForItem(newControlId, spnControlStyle['background-color']);
                }
            }

            if (typeof contentStyleAttr !== 'undefined' && contentStyleAttr !== false && contentStyleAttr != null) {
                var styles = contentStyleAttr.split(';');

                for (var st in styles) {
                    var aux = (styles[st]).trim().split(':');
                    if (aux[1])
                        spnStyle[(aux[0]).trim()] = (aux[1].trim());
                }
            }

            if (spnStyle) {
                newSpnControlEle.style['color'] = spnStyle['color'];
                newSpnControlEle.style['font-family'] = spnStyle['font-family'] && spnStyle['font-family'] != 'null' && spnStyle['font-family'] != 'undefind' ? spnStyle['font-family'] : 'courier';
                newSpnControlEle.style['font-weight'] = spnStyle['font-weight'];
                newSpnControlEle.style['text-decoration'] = spnStyle['text-decoration']; //underline
                newSpnControlEle.style['font-style'] = spnStyle['font-style']; //italic
                newSpnControlEle.style['font-size'] = spnStyle['font-size'];
                newSpnControlEle.style['line-height'] = spnStyle['font-size'];
            }

            if (newControlObj.dataGuid.toLowerCase() == Control.DropDown.toLowerCase()) {
                newSpnControlEle?.setAttribute('font-family', 'courier');
                newSpnControlEle?.setAttribute('font-family-id', 'D4A45ECD-3865-448A-92FA-929C2295EA34')
            }
            else {
                newSpnControlEle?.setAttribute('font-family-id', fontfamilyidAttr != null && fontfamilyidAttr != 'null' ? fontfamilyidAttr : 'D4A45ECD-3865-448A-92FA-929C2295EA34');
            }
        }
        document.body.removeChild(e);

        if (newControlObj.dataGuid.toLowerCase() == Control.Name.toLowerCase() || newControlObj.dataGuid.toLowerCase() == Control.Title.toLowerCase()) {
            newResizableControlEle?.setAttribute('maxlengthallowed', maxlengthallowedAttr);
            newResizableControlEle?.setAttribute('maxcharallowed', maxcharallowedAttr);
            newResizableControlEle?.setAttribute('data-isfixedwidth', isfixedwidthAttr);
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.Text.toLowerCase()) {
            newResizableControlEle?.setAttribute('maxlengthallowed', maxlengthallowedAttr);
            newResizableControlEle?.setAttribute('maxcharallowed', maxcharallowedAttr);
            newResizableControlEle?.setAttribute('maxemailcharallowed', maxemailcharallowedAttr);
            newResizableControlEle?.setAttribute('data-isfixedwidth', isfixedwidthAttr);
            newResizableControlEle?.setAttribute('data-texttype', texttypeAttr);

            let validationType: any = '';
            let validationsTextArray: any = this.commonConstants.ValidationsText;
            let arrayObj: any = validationsTextArray.filter((obj: any) => obj.value.toLowerCase() == texttypeAttr.toLowerCase());
            if (arrayObj && arrayObj.length > 0)
                validationType = arrayObj[0].label;
            newResizableControlEle?.setAttribute('data-validation-type', validationType);

            newResizableControlEle?.setAttribute('data-additional-validation-name', oldControlObj.controlStyle.additionalValidationName);
            if (oldControlObj.controlStyle.additionalValidationName != '' && oldControlObj.controlStyle.additionalValidationName != null) {
                if (oldControlObj.controlStyle.additionalValidationName == 'Zip' || oldControlObj.controlStyle.additionalValidationName == "Zip Format") {
                    if (oldControlObj.controlStyle.additionalValidationOption != '' && oldControlObj.controlStyle.additionalValidationOption != null) {
                        if (oldControlObj.controlStyle.additionalValidationOption == 'Zip')
                            oldControlObj.controlStyle.additionalValidationOption = '1';
                        else if (oldControlObj.controlStyle.additionalValidationOption == 'Zip+4')
                            oldControlObj.controlStyle.additionalValidationOption = '2';
                    }
                }
                else if (oldControlObj.controlStyle.additionalValidationName == 'Decimal') {
                    if (oldControlObj.controlStyle.additionalValidationOption != '' && oldControlObj.controlStyle.additionalValidationOption != null) {
                        if (oldControlObj.controlStyle.additionalValidationOption == 'Both')
                            oldControlObj.controlStyle.additionalValidationOption = '1';
                        else if (oldControlObj.controlStyle.additionalValidationOption == 'Comma')
                            oldControlObj.controlStyle.additionalValidationOption = '2';
                        else if (oldControlObj.controlStyle.additionalValidationOption == 'Period')
                            oldControlObj.controlStyle.additionalValidationOption = '3';
                    }
                }
                else if (oldControlObj.controlStyle.additionalValidationName == 'Date Format') {
                    if (oldControlObj.controlStyle.additionalValidationOption != '' && oldControlObj.controlStyle.additionalValidationOption != null) {
                        if (oldControlObj.controlStyle.additionalValidationOption == 'mm/dd/yyyy')
                            oldControlObj.controlStyle.additionalValidationOption = '1';
                        else if (oldControlObj.controlStyle.additionalValidationOption == 'dd/mm/yyyy')
                            oldControlObj.controlStyle.additionalValidationOption = '2';
                        else if (oldControlObj.controlStyle.additionalValidationOption == 'yyyy/mm/dd')
                            oldControlObj.controlStyle.additionalValidationOption = '3';
                        else if (oldControlObj.controlStyle.additionalValidationOption == 'dd-mmm-yyyy')
                            oldControlObj.controlStyle.additionalValidationOption = '4';
                    }
                }
            }

            newResizableControlEle?.setAttribute('data-additional-validation-option', oldControlObj.controlStyle.additionalValidationOption);
            newSpnControlEle.innerHTML = innerHtmlAttr;
            newResizableControlEle?.setAttribute("data-Entity", '');
            newResizableControlEle?.setAttribute("data-EntityField", '');
            newResizableControlEle?.setAttribute("data-EntityFieldValue", '');
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.Radio.toLowerCase() || newControlObj.dataGuid.toLowerCase() == Control.Checkbox.toLowerCase()) {
            newResizableControlEle?.setAttribute('data-size', sizeAttr);
            if (newControlObj.dataGuid.toLowerCase() == Control.Radio.toLowerCase()) {
                newResizableControlEle?.setAttribute('data-groupname', groupnameAttr);
                newSpnControlEle?.setAttribute('name', grpNameAttr);
            }

            if (newControlObj.dataGuid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                newResizableControlEle?.setAttribute('data-isGroupChecked', '');
                if (groupnameAttr != null && groupnameAttr != '' && grpNameAttr != null && grpNameAttr != '') {
                    newResizableControlEle?.setAttribute('data-groupname', groupnameAttr);
                    newSpnControlEle?.setAttribute('name', grpNameAttr);
                    newResizableControlEle?.setAttribute('data-isGroupChecked', 'true');
                }
                else newResizableControlEle?.setAttribute('data-isGroupChecked', 'false');
            }
            newSpnControlEle?.setAttribute('font-family-id', 'D4A45ECD-3865-448A-92FA-929C2295EA34');
            let defaultSize: any = this.getRadioCheckBoxSizeVal(sizeAttr);
            this.applyRadioAndCheckBoxSizes(defaultSize, newResizableControlEle, newSpnControlEle);
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.DropDown.toLowerCase()) {
            newResizableControlEle?.setAttribute('data-firstlineblank', 'false');
            let newSelectDropdownControlField: any = (document.getElementById('spn' + newControlId)) as HTMLSelectElement;
            if (oldControlObj && oldControlObj.selectControlOptions && oldControlObj.selectControlOptions.length > 0) {
                oldControlObj.selectControlOptions = oldControlObj.selectControlOptions.sort((a: any, b: any) => a.order > b.order ? 1 : -1);
                for (let index = 0; index < oldControlObj.selectControlOptions.length; index++) {
                    var text = oldControlObj.selectControlOptions[index].optionText;
                    var ruleId = oldControlObj.selectControlOptions[index]?.id;
                    var value = oldControlObj.selectControlOptions[index]?.order;

                    if (value == "0" && text == '') {
                        newResizableControlEle?.setAttribute('data-firstlineblank', 'true');
                    }

                    var option = '';
                    if (index == 0) option = '<option data-select-option-id="' + ruleId + '" value="' + value + '" selected="selected">' + text + '</option>';
                    else option = '<option data-select-option-id="' + ruleId + '" value="' + value + '">' + text + '</option>';
                    newSelectDropdownControlField.insertAdjacentHTML('beforeend', option);
                }
            }
            if (expandedAttr == true || expandedAttr == "true")
                newResizableControlEle.classList.add('expanded');
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.Label.toLowerCase()) {
            newSpnControlEle.innerHTML = innerHtmlAttr;
            newSpnControlEle?.setAttribute('isupdated', 'true');
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.Hyperlink.toLowerCase()) {
            newControlEle?.setAttribute('data-hyperlink', hyperlinkAttr);
            newSpnControlEle.innerHTML = innerHtmlAttr;
            newSpnControlEle?.setAttribute('isupdated', 'true');
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.Date.toLowerCase()) {
            newResizableControlEle?.setAttribute('data-defaultdate', defaultdateAttr);
            newSpnControlEle.innerHTML = innerHtmlAttr;
            let dateFormatAttr: any = '';
            let dateformatArray: any = this.commonConstants.DateformatValue;
            let dateArrayObj: any = dateformatArray.filter((obj: any) => obj.viewValue == innerHtmlAttr);
            if (dateArrayObj && dateArrayObj.length > 0)
                dateFormatAttr = dateArrayObj[0].value;
            else dateFormatAttr = this.commonConstants.Dateformat[0].value;

            dateformatArray = this.commonConstants.Dateformat;
            dateArrayObj = dateformatArray.filter((obj: any) => obj.value == dateFormatAttr);
            if (dateArrayObj && dateArrayObj.length > 0)
                dateFormatAttr = dateArrayObj[0].viewValue;
            else dateFormatAttr = this.commonConstants.Dateformat[0].viewValue;

            newResizableControlEle?.setAttribute('data-defaultformat', dateFormatAttr);
        }
        if (newControlObj.dataGuid.toLowerCase() == Control.Company.toLowerCase() ||
            newControlObj.dataGuid.toLowerCase() == Control.Email.toLowerCase() ||
            newControlObj.dataGuid.toLowerCase() == Control.DateTimeStamp.toLowerCase()) {
            newResizableControlEle?.setAttribute('maxlengthallowed', '');
            newResizableControlEle?.setAttribute('maxcharallowed', '');
            newResizableControlEle?.setAttribute('maxemailcharallowed', '');
            newResizableControlEle?.setAttribute('data-isfixedwidth', 'true');
        }
        // if (newControlObj.dataGuid.toLowerCase() == Control.DateTimeStamp.toLowerCase()) {
        //     newResizableControlEle?.setAttribute('data-isfixedwidth', 'true');
        // }

        this.removeControlAttributes(newResizableControlEle, newControlObj.dataGuid);
    }

    updateExistingControlDependencyFields() {
        let controllingFieldArray: any = document.querySelectorAll('.controllingField');
        if (controllingFieldArray && controllingFieldArray.length > 0) {
            Array.from(controllingFieldArray).forEach((parentItem: any, v: any) => {
                let controlID: any = parentItem.getAttribute('data-document-content-id');
                let hasChildren: any = parentItem.getAttribute('is-child-present');
                let parent_groupCode: any = parentItem.getAttribute('group-code');

                if (hasChildren == "true") {
                    let childelementsArray: any = document.querySelectorAll('[data-parent-id="' + controlID + '"]');
                    if (childelementsArray && childelementsArray.length > 0) {
                        Array.from(childelementsArray).forEach((childItem: any, v: any) => {
                            let child_groupCode: any = childItem.getAttribute('group-code');
                            if (child_groupCode != '') {
                                //parent control
                                if (parent_groupCode == null || parent_groupCode == '') {
                                    parentItem.setAttribute('group-code', child_groupCode);
                                }
                                //child control
                                childItem?.classList.add('alreadyDependentField');
                                childItem?.classList.add('addedAsChild');
                            }
                        })
                    }
                }
            })
        }
    }

    disableRedoUndoButtons(isEnvelopeEditable: any) {
        let className: any = 'disabledCtrl';
        if (isEnvelopeEditable == false) return className;
        let undoBtn: any = document.getElementById("undoBtn");
        let redoBtn: any = document.getElementById("redoBtn");
        let arrCopyPasteCtrl: any = localStorage.getItem('ArrCopyPasteCtrl');
        if (arrCopyPasteCtrl) {
            let arrCopyPasteCtrlArray: any = JSON.parse(arrCopyPasteCtrl);
            if (arrCopyPasteCtrlArray && arrCopyPasteCtrlArray.length > 0) {
                undoBtn.classList.remove("disabledCtrl");
                redoBtn.classList.remove("disabledCtrl");
                className = 'enabledCtrl';
            }
        }
        return className;
    }

    undo() {
        let ctrlIdArr: any[] = [];
        let arrUndoRedoCtrl: any = localStorage.getItem('ArrUndoRedoCtrl');

        let arrUndoRedoCtrlArrTemp: any = localStorage.getItem('arrCopyPasteCtrlsTemp');
        let arrUndoRedoCtrlArrayTemp: any = JSON.parse(arrUndoRedoCtrlArrTemp);
        // console.log('arrUndoRedoCtrlArrayTemp', arrUndoRedoCtrlArrayTemp);
        if (arrUndoRedoCtrl) {
            let arrUndoRedoCtrlArray: any = JSON.parse(arrUndoRedoCtrl);
            if (arrUndoRedoCtrlArrayTemp && arrUndoRedoCtrlArrayTemp.length > 0) {
                if (arrUndoRedoCtrlArray && arrUndoRedoCtrlArray.length > 0) {
                    arrUndoRedoCtrlArrayTemp.forEach((ele: any, index: any) => {
                        let undoCtrl: any = arrUndoRedoCtrlArrayTemp[index];
                        ctrlIdArr.push(undoCtrl.Control.id);
                        arrUndoRedoCtrlArray = arrUndoRedoCtrlArray.filter(function (item: any) { return item.Control.id !== ele.Control.id; });
                        localStorage.setItem('ArrUndoRedoCtrl', JSON.stringify(arrUndoRedoCtrlArray));
                        // if (arrUndoRedoCtrlArray && arrUndoRedoCtrlArray.length > 0) {
                        // let copyPasteItem: any = arrUndoRedoCtrlArray[index];
                        // let arrCopyPasteCtrl: any = localStorage.getItem('ArrCopyPasteCtrl');
                        // arrCopyPasteCtrl = [];
                        // arrCopyPasteCtrl.push({ ParentControl: copyPasteItem.ParentControl, Control: copyPasteItem.Control, Action: 'P' });
                        // localStorage.setItem('ArrCopyPasteCtrl', JSON.stringify(arrCopyPasteCtrl));
                        // }
                    })

                    arrUndoRedoCtrlArrayTemp = [];
                    let temp: any = arrUndoRedoCtrlArray.reverse();
                    let selecteditemsLength: any = localStorage.getItem('selecteditemsLength');
                    if (temp && temp.length) {
                        temp.forEach((ele: any, index: any) => {
                            if (index <= parseInt(selecteditemsLength) - 1) {
                                arrUndoRedoCtrlArrayTemp.push(ele);
                            }
                        })
                    }
                    localStorage.setItem('arrCopyPasteCtrlsTemp', JSON.stringify(arrUndoRedoCtrlArrayTemp));
                }
            }
        }

        return ctrlIdArr;


        // if (arrUndoRedoCtrl) {
        //     let arrUndoRedoCtrlArray: any = JSON.parse(arrUndoRedoCtrl);
        //     if (arrUndoRedoCtrlArray && arrUndoRedoCtrlArray.length > 0) {
        //         let undoCtrl: any = arrUndoRedoCtrlArray[arrUndoRedoCtrlArray.length - 1];
        //         ctrlId = undoCtrl.Control.id;
        //         arrUndoRedoCtrlArray = arrUndoRedoCtrlArray.filter(function (item: any) { return item.Control.id !== ctrlId; });
        //         localStorage.setItem('ArrUndoRedoCtrl', JSON.stringify(arrUndoRedoCtrlArray));

        //         let arrCopyPasteCtrl: any = localStorage.getItem('ArrCopyPasteCtrl');
        //         arrCopyPasteCtrl = [];
        //         arrCopyPasteCtrl.push({ ParentControl: undoCtrl.ParentControl, Control: undoCtrl.Control, Action: 'P' });
        //         localStorage.setItem('ArrCopyPasteCtrl', JSON.stringify(arrCopyPasteCtrl));
        //     }
        // }
        // return ctrlId;
    }

    //when delete the control while doing undo redo
    updateUndoRedoCopyPasteControlArray(ctrlId: any) {
        let arrUndoRedoCtrl: any = localStorage.getItem('ArrUndoRedoCtrl');
        let arrCopyPasteCtrl: any = localStorage.getItem('ArrCopyPasteCtrl');
        let arrUndoRedoCtrlArray: any = JSON.parse(arrUndoRedoCtrl);
        if (arrUndoRedoCtrlArray && arrUndoRedoCtrlArray.length > 0) {
            arrUndoRedoCtrlArray = arrUndoRedoCtrlArray.filter(function (item: any) { return item.Control.id !== ctrlId; });
            localStorage.setItem('ArrUndoRedoCtrl', JSON.stringify(arrUndoRedoCtrlArray));
            if (arrUndoRedoCtrlArray && arrUndoRedoCtrlArray.length > 0) {
                let copyPasteItem: any = arrUndoRedoCtrlArray[arrUndoRedoCtrlArray.length - 1];
                arrCopyPasteCtrl = [];
                arrCopyPasteCtrl.push({ ParentControl: copyPasteItem.ParentControl, Control: copyPasteItem.Control, Action: 'P' });
                localStorage.setItem('ArrCopyPasteCtrl', JSON.stringify(arrCopyPasteCtrl));
            }
        }
        if (arrUndoRedoCtrlArray && arrUndoRedoCtrlArray.length == 0) {
            let arrCopyPasteCtrl: any = localStorage.getItem('ArrCopyPasteCtrl');
            arrCopyPasteCtrl = [];
            localStorage.setItem('ArrCopyPasteCtrl', JSON.stringify(arrCopyPasteCtrl));
        }
    }

    removeControlAttributes(newResizableControlEle: any, data_guid: any) {
        if (data_guid && data_guid.toLowerCase() == Control.Signature.toLowerCase() || data_guid.toLowerCase() == Control.Initials.toLowerCase() ||
            data_guid && data_guid.toLowerCase() == Control.Radio.toLowerCase() || data_guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
            newResizableControlEle?.removeAttribute('data-defaultdate');
            newResizableControlEle?.removeAttribute('data-placeholder');
            newResizableControlEle?.removeAttribute('data-typography');
            newResizableControlEle?.removeAttribute('data-confirm-submit');
            newResizableControlEle?.removeAttribute('data-isfixedwidth');
            newResizableControlEle?.removeAttribute('maxlengthallowed');
            newResizableControlEle?.removeAttribute('maxcharallowed');
            newResizableControlEle?.removeAttribute('maxemailcharallowed');
        }
        else if (data_guid && data_guid.toLowerCase() == Control.Date.toLowerCase()) {
            newResizableControlEle?.removeAttribute('maxlengthallowed');
            newResizableControlEle?.removeAttribute('maxcharallowed');
            newResizableControlEle?.removeAttribute('maxemailcharallowed');
            newResizableControlEle?.removeAttribute('data-isfixedwidth');
        }
        else if (data_guid && data_guid.toLowerCase() != Control.Email.toLowerCase()) {
            newResizableControlEle?.removeAttribute('data-confirm-submit');
        }
        else if (data_guid.toLowerCase() != Control.DropDown.toLowerCase()) {
            newResizableControlEle?.removeAttribute('data-firstlineblank');
        }
        else if (data_guid && (data_guid.toLowerCase() == Control.Label.toLowerCase() || data_guid.toLowerCase() == Control.Hyperlink.toLowerCase())) {
            newResizableControlEle?.removeAttribute('data-defaultdate');
            newResizableControlEle?.removeAttribute('data-confirm-submit');
            newResizableControlEle?.removeAttribute('data-isfixedwidth');
            newResizableControlEle?.removeAttribute('maxlengthallowed');
            newResizableControlEle?.removeAttribute('maxcharallowed');
            newResizableControlEle?.removeAttribute('maxemailcharallowed');
            newResizableControlEle?.removeAttribute('data-customtooltip');
            newResizableControlEle?.removeAttribute('data-original-title');
            newResizableControlEle?.setAttribute('title', '');
        }
        else if (data_guid && (
            data_guid.toLowerCase() != Control.Text.toLowerCase() &&
            data_guid.toLowerCase() != Control.Name.toLowerCase() &&
            data_guid.toLowerCase() != Control.Title.toLowerCase())) {
            newResizableControlEle?.removeAttribute('data-isfixedwidth');
        }
    }

    enableShowControlId(val: any) {
        if (val && val != null && (val == "True" || val == "true")) return "block";
        else return "none";
    }

    setHideFixedwidthCheckbox(val: any) {
        this.hideFixedwidthCheckbox = val;
    }

    getHideFixedwidthCheckbox() {
        return this.hideFixedwidthCheckbox;
    }

    hideCtrlCharLimitInput(charInputLimit: any) {
        if (charInputLimit) {
            charInputLimit.style.display = 'none';
        }
    }

    showCtrlCharLimitInput(charInputLimit: any) {
        if (charInputLimit) {
            charInputLimit.style.display = 'block';
        }
    }

    hideCtrlLengthInput(inputLenth: any) {
        if (inputLenth) {
            inputLenth.style.display = 'none';
        }
    }

    showCtrlLengthInput(inputLenth: any) {
        if (inputLenth) {
            inputLenth.style.display = 'block';
        }
    }

    checkFixedWidthFalseAndMaxCharLimit(ctrlName: any, ctrlInputLength: string, ctrlCharInputLimit: string, isFixedWidthContainer: string, isFixedWidthChecked: any) {
        let inputLength: any = document.getElementById(ctrlInputLength);
        if (inputLength) {
            this.hideCtrlLengthInput(inputLength);
        }
        let charInputLimit: any = document.getElementById(ctrlCharInputLimit);
        if (charInputLimit) {
            this.showCtrlCharLimitInput(charInputLimit);
            charInputLimit.value = (ctrlName.toLowerCase() === 'text') ? '4000' : '100';
        }
        let isFixedWidthNameContainer: any = document.getElementById(isFixedWidthContainer);
        if (isFixedWidthNameContainer && isFixedWidthNameContainer.children && isFixedWidthNameContainer.children[0]) {
            let isFixedWidthEle: any = document.getElementById(isFixedWidthNameContainer.children[0].id)
            if (isFixedWidthEle) {
                isFixedWidthEle.checked = isFixedWidthChecked;
            }
        }
    }

    checkFixedWidthTrueAndMaxCharLimit(ctrlName: any, ctrlInputLength: string, ctrlCharInputLimit: string, isFixedWidthContainer: string) {
        let inputLength: any = document.getElementById(ctrlInputLength);
        if (inputLength) {
            this.showCtrlLengthInput(inputLength);
            inputLength.value = '20';
        }
        let charInputLimit: any = document.getElementById(ctrlCharInputLimit);
        if (charInputLimit) {
            this.hideCtrlCharLimitInput(charInputLimit);
        }

        let isFixedWidthCont: any = document.getElementById(isFixedWidthContainer);
        if (this.hideFixedwidthCheckbox !== true) {
            if (isFixedWidthCont) {
                isFixedWidthCont.style.display = 'block';
            }
        } else {
            if (isFixedWidthCont) {
                isFixedWidthCont.style.display = 'none';
            }
        }
    }
}
