<div [ngStyle]="{'display':showSessionExpireModalDailog}" style="z-index: 9999;" id="showSessionExpireModal" class="modal session-modal"
    tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" style="width: auto;">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title font-w600" >Your session is about to expire!</h5>
            </div>

            <div class="modal-body ">
                <div class="d-flex align-items-center justify-content-center my-2">
                    <img class="text-center" style="height: 80px;" src="../../../assets/images/icons/deadline.png" title="">
                </div>

                <div class="timer-container text-center py-2">
                    <label id="timer" class="modal-timer">You will be logged out in
                        <label style="font-weight: 600;">{{idleTimerMinsValue}}</label>
                        <label style="font-weight: 600;">&nbsp;minutes &&nbsp;</label>
                        <label style="font-weight: 600;">{{idleTimerSecondsValue}}</label>
                        <label style="font-weight: 600;">&nbsp;seconds</label>.
                    </label>
                    <label class="mt-1">Do you want to stay signed in?</label>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button class="btn back-btn cancelbtn form-btn" (click)="noSignMeOut()" style="width: auto;">
                    No, Sign me out</button>
                <button role="button" class="okbtn btn back-btn form-btn ms-3" (click)="yesKeepMeSignedIn('clicked')" style="width: auto;"> Yes, Keep me signed in</button>
            </div>
        </div>
    </div>
</div>

<div>
	<router-outlet></router-outlet>
</div>

<div *ngIf="showLoader" class="loader">
</div>