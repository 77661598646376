import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EnvelopePrepareComponent } from './components/envelope-prepare/envelope-prepare.component';
import { InitializePrepareComponent } from './components/initialize-prepare/initialize-prepare.component';
import { ViewPdfComponent } from './components/view-pdf/view-pdf.component';
import { GlobalErrorComponent } from './components/global-error/global-error.component';


const routes: Routes = [	
	{ path: '', component: EnvelopePrepareComponent },
	{ path: 'envelope-prepare', component: EnvelopePrepareComponent },
	{ path: 'initialize-prepare', component: InitializePrepareComponent },	
	{ path: 'viewpdf', component: ViewPdfComponent },
	{ path: 'global-error', component: GlobalErrorComponent }		
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule { }
