<nav class="menu-nav" #nav *ngIf="visible" [ngStyle]="{ top: top, left: left }">
    <ul>
        <li class="d-flex align-items-center justify-content-between" *ngFor="let element of elements; let i = index"
            (click)="onSelectElement($event, element)" [class.active]="element.active" [ngClass]="{
                disabled4: i == 2,
                disabled5: i == 4, enabled: i == 5,
                'disabledCtrl': isDisableRedoUndoButtons()}" id="{{element.label}}_Btn">
            <label for="" class="d-flex align-items-center" style="cursor: pointer;">
                <!-- <i class="fa {{fontIconArr[i]}} me-2" aria-hidden="true"></i> -->
                <img src="{{imagesArr[i].path}}" class="w-20p pointer me-2 {{imagesArr[i].name}}"/>
                <span class="ms-0">{{ element.label }}</span>
            </label>
            <span class="ms-2 pointer">{{ keysArr[i] }}</span>
        </li>
    </ul>
</nav>