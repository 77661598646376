import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CommonConstants } from '../constants/common-constants';
import { TranslationService } from 'src/app/services/translation.service';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    commonConstants = CommonConstants;
    ControlListArr: any = this.commonConstants.ControlListArr;
    private previewObjectSubject = new Subject<any>();
    previewObject$ = this.previewObjectSubject.asObservable();

    private _previewObject: any = {};
    selectedItems: any[]=[];

    private callParentShowControlFieldsSubject = new Subject<void>();
    callParentShowControlFields$ = this.callParentShowControlFieldsSubject.asObservable();

    private callOnRecipentChangeSubject = new Subject<void>();
    callOnRecipentChange$ = this.callOnRecipentChangeSubject.asObservable();

    private callRemoveControlComponentSubject = new Subject<void>();
    callRemoveControlComponent$ = this.callRemoveControlComponentSubject.asObservable();

    private callOpenDependencyPopupSubject = new Subject<void>();
    callOpenDependencyPopup$ = this.callOpenDependencyPopupSubject.asObservable();

    private setTextOrNameAreaSettingSubject = new Subject<void>();
    setTextOrNameAreaSetting$ = this.setTextOrNameAreaSettingSubject.asObservable();

    private callOnMouseOverRecipentChangeSubject = new Subject<void>();
    callOnMouseOverRecipentChange$ = this.callOnMouseOverRecipentChangeSubject.asObservable();

    private callOpenCreateDuplicateControlSubject = new Subject<void>();
    callOpenCreateDuplicateControl$ = this.callOpenCreateDuplicateControlSubject.asObservable();

    //right click,
    private selectedRightClickOptionSubject = new Subject<{ data: any; label: any; }>();
    selectedRightClickOption$ = this.selectedRightClickOptionSubject.asObservable();

    private getLabelTranslationValCached: { [key: string]: any } = {};

    constructor(private translationService: TranslationService) { }

    get previewObject(): any {
        return this._previewObject;
    }

    updatePrevObject(updatedObject: any): void {
        this._previewObject = { ...this._previewObject, ...updatedObject };
        this.previewObjectSubject.next(this._previewObject);
    }

    callParentShowControlFields(data: any) {
        this.callParentShowControlFieldsSubject.next(data);
    }

    callRemoveControlComponent(data: any) {
        this.callRemoveControlComponentSubject.next(data);
    }

    callOpenDependencyPopup(data: any) {
        this.callOpenDependencyPopupSubject.next(data);
    }

    callOpenCreateDuplicateControl(data: any) {
        this.callOpenCreateDuplicateControlSubject.next(data);
    }

    GenerateMaskedEmail(emailAddr: any) {
        let emailArray = emailAddr.split('@');
        let arrayfirstPartstr = emailArray[0];
        let arraylastPaststr = emailArray[1];

        var firstDigits = arrayfirstPartstr.substring(0, 3);
        var maskedString1 = firstDigits + "***" + "@";

        let lastDotPosition = arraylastPaststr.lastIndexOf('.');
        let firstLastPart = arraylastPaststr.substring(0, lastDotPosition);
        let firstLastPartDigits = firstLastPart.substring(0, 3);
        var maskedString2 = firstLastPartDigits + "***";

        let lastPart = arraylastPaststr.substring(lastDotPosition + 1);
        let maskedString3 = maskedString1 + maskedString2 + "." + lastPart;
        return maskedString3;
    }

    redirectTo(type: any) {
        switch (type) {
            case "Features":
                window.open("https://rsign.com/product/electronic-signature", "_blank");
                break;
            case "CustomerSuccess":
                window.open("https://rsign.com/customer-success", "_blank");
                break;
            case "Webinars":
                window.open("https://rsign.com/webinars", "_blank");
                break;
            case "Videos":
                window.open("https://rsign.com/product-video-playlist", "_blank");
                break;
            case "Apps":
                window.open("https://rsign.com/apps", "_blank");
                break;
            case "Blogs":
                window.open("https://rsign.com/blog", "_blank");
                break;
            case "News":
                window.open("https://rsign.com/news", "_blank");
                break;
            case "ContactUs":
                window.open("https://rsign.com/contact/questions", "_blank");
                break;
            case "Support":
                window.open("https://support.rpost.com/hc/en-us", "_blank");
                break;
        }
    }

    callOnRecipentChange(data: any) {
        this.callOnRecipentChangeSubject.next(data);
    }

    callOnMouseOverRecipentChange(recipentObj: any) {
        this.callOnMouseOverRecipentChangeSubject.next(recipentObj);
    }

    generateUUID() {
        var d = new Date().getTime();
        if (window.performance && typeof window.performance.now === "function") {
            d += performance.now();; //use high-precision timer if available
        }
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    };

    getId() {
        return Math.round(1 + Math.floor(Math.random() * 100000));
    }

    getControlName(ctrlName: any, ctrlClassName: any) {
        if(this.ControlListArr && this.ControlListArr.length){
            let controlListArrayObj:any[] = this.ControlListArr.filter((c: any) => c && c.ControlClassName && c.ControlClassName.toLowerCase() == ctrlClassName && ctrlClassName.toLowerCase());
            if (controlListArrayObj && controlListArrayObj.length && controlListArrayObj.length > 0) {
                return controlListArrayObj[0].ControlName;
            }
        }
    }

    getControlNameBasedOnGuid(guid: any) {
        let controlListArrayObj = this.ControlListArr.filter((c: any) => c.ControlId.toLowerCase() == guid.toLowerCase());
        if (controlListArrayObj.length > 0) {
            return controlListArrayObj[0].ControlName;
        }
    }

    getGuid(controlName: any, controlClassName: any) {
        let controlListArrayObj = this.ControlListArr.filter((c: any) => c.ControlClassName.toLowerCase() == controlClassName.toLowerCase());
        if (controlListArrayObj.length > 0) {
            return controlListArrayObj[0].ControlId;
        }
        else return "";
    }

    getTranslatedText(controlName: any, resourceKey: any) {
        return controlName;
    }

    getHoverControlText(controlString: any) {
        switch (controlString) {
            case "Signature":
                return "Signature";
            case "Text":
                return "Text";
            case "Name":
                return "Name";
            case "Email":
                return "Email";
            case "Title":
                return "Title";
            case "Company":
                return "Company";
            case "Date":
                return "Date";
            case "Checkbox":
                return "Checkbox";
            case "Initials":
            case "NewInitials":
            case "newInitials":
                return 'Initials'; //lang_initials;
            case "Radio":
                return "Radio";
            case "DropDown":
                return "Dropdown";
            case "DateTimeStamp":
                return 'Date & Time Stamp';
            case "Hyperlink":
                return "Hyperlink";
        }
        return controlString;
    }

    setTextOrNameAreaSetting(data: any) {
        this.setTextOrNameAreaSettingSubject.next(data);
    }

    selectedRightClickOption(data: any, label:any) {
        console.log('selectedRightClickOption', data, label);
        this.selectedRightClickOptionSubject.next({ data: data, label: label });
    }

    setSelectedItems(selectedItems:any){
        this.selectedItems = selectedItems;
    }

    getSelectedItems(){
        return this.selectedItems;
    }    

    getLabelTranslationVal(resourceKeyId: any) {        
        const key = resourceKeyId;
        if (!this.getLabelTranslationValCached[key]) {
            let transVal:any = this.getLabelTranslationValCompute(resourceKeyId);
            if(transVal && transVal != ''){
                this.getLabelTranslationValCached[key] = transVal;
            }            
        }
        return this.getLabelTranslationValCached[key];
    }

    private getLabelTranslationValCompute(resourceKeyId: any) {        
        return this.translationService.getLanguageTranslationVal(resourceKeyId);
    }
}