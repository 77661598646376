<div id="{{ctrlObj.id}}" [attr.data-int-control-id]="ctrlObj.id"
    [attr.data-document-content-id]="ctrlObj.dataDocumentContentId"
    [attr.data-pageno]="ctrlObj.pageNo"
    [attr.data-ctrlnum]="ctrlObj.ctrlNumber"
    [attr.data-controlname]="getControlName(ctrlObj.controlName, ctrlObj.className)"
    class="signControl"
    [ngClass]="getControllingFieldClass(ctrlObj.dataGuid)" 
    style="position: absolute;left: 0px;top:0px;width: 0px;height: 0px;">
    <app-resizable-draggable [ctrlObj]="ctrlObj"></app-resizable-draggable>
</div>
