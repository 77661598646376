import { Component, Input } from '@angular/core';
import { CommonService } from '../../services/common.service';

@Component({
    selector: 'app-recipients',
    templateUrl: './recipients.component.html',
    styleUrls: ['./recipients.component.scss']
})
export class RecipientsComponent {
    @Input() receipentListArr: any;
    @Input() receipentColorArr: any;
    @Input() recipientsObj: any = {};

    constructor(
        private commonService: CommonService
    ) {
        this.commonService.callOnRecipentChange$.subscribe((recipientsObj) => {
            this.recipientsObj = recipientsObj;
        });
    }

    ngOnInit() { }

    onRecipentChange(recipent: any, receipentColorArr: any) {
        let obj: any = {
            'recipent': recipent,
            'selectedColor': receipentColorArr
        }
        this.commonService.callOnRecipentChange(obj);
    }

}
