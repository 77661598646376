<div class="d-flex align-items-center justify-content-between">
    <div class="dropdown" style="background: #f3f4f6; width: 100%;">
        <button class="form-select custme-p text-start d-flex align-items-center" style="
            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 1px 0px,
            rgba(27, 31, 35, 0.1) 0px 0px 0px 1px;
            font-size: 14px; border-radius: 3px;
            height: 29px;" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="material-icons me-1 square-select"  aria-hidden="true"
            [ngStyle]="{ color: recipientsObj.selectedRecipentColor }" >square</i>
            <span class="font-recipent" title="{{recipientsObj.selectedRecipentName}}"> {{ recipientsObj.selectedRecipentName }}</span>
        </button>
        <ul class="dropdown-menu recpientlist">
            <li *ngFor="let recipient of receipentListArr; let i = index;"
            (click)="onRecipentChange(recipient, receipentColorArr[i])">
                <a id="recipient_{{recipient.id}}" class="dropdown-item pointer recipientDeatilsItem" style="font-size: 14px;">
                    <span class="material-icons square-font"  [ngStyle]="{ color: receipentColorArr[i] }"> square </span> {{ recipient.name }}
                    <!-- <i class="fa fa-square me-1" aria-hidden="true" [ngStyle]="{ color: receipentColorArr[i] }"></i>&nbsp; {{ recipient.name }} -->
                </a>
            </li>
        </ul>
    </div>
</div>