import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlConfig } from 'src/app/constants/common-apiurls';
import { FinalContractOptions, PasswordType, SignatureRequestReplyAddress } from 'src/app/constants/common-constants';
import { ResourceKeys } from 'src/app/constants/resourcekeys';
import { APIService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { TranslationService } from 'src/app/services/translation.service';

@Component({
	selector: 'app-view-setting',
	templateUrl: './view-setting.component.html',
	styleUrls: ['./view-setting.component.scss']
})
export class ViewSettingComponent {
	filterBy = 'all';
	showLoader: boolean = false;
	source: any;
	currentSetting: any;
	envelopeSettingDetails: any;
	settingAdditionalDetails: any;
	envelopeAdditionalDetails: any;
	customSetting: any = {};
	labelTranslation: any;
	resourceKeys = ResourceKeys;
	passwordType = PasswordType;
	finalContractOptions = FinalContractOptions;
	signatureRequestReplyAddress = SignatureRequestReplyAddress;
	signInSequenceDesc: boolean = false;
	isResponseLoaded: boolean = false;
	additionalAttachments:any;

	constructor(
		public modalRef: BsModalRef,
		private authService: AuthService,
		private toastrService: ToastrService,
		private apiService: APIService,
		private translationService: TranslationService,
		private commonService: CommonService
	) { }

	ngOnInit(): void {
		let envelopeTokenDetails: any = this.authService.getDetails();
		if (envelopeTokenDetails) {
			let tokenDetails = JSON.parse(envelopeTokenDetails);
			let prepareType: any = '', getSettingsUrl: any, envelopeOrTemplateId: any;
			if (tokenDetails) {
				prepareType = tokenDetails.prepareType;
				envelopeOrTemplateId = tokenDetails.envelopeOrTemplateId;
				this.source = prepareType != '' && prepareType.toLowerCase() == "envelope" ? "envelope" : prepareType != '' && prepareType.toLowerCase() == "template" ? "template" : "";
				if (prepareType != '' && prepareType.toLowerCase() == "envelope") getSettingsUrl = ApiUrlConfig.GetEnvelopeSettingDetailsUrl + "/" + envelopeOrTemplateId + "/0";
				else if (prepareType != '' && prepareType.toLowerCase() == "template") getSettingsUrl = ApiUrlConfig.GetTemplateSettingDetailsUrl + "/" + envelopeOrTemplateId + "/0";
				this.showLoader = true;
				this.apiService.get(getSettingsUrl).subscribe(
					(resp: any) => {
						this.showLoader = false;
						if (resp && resp.statusCode == 200 && resp.envelopeViewSettingDetails != null) {
							//console.log(resp);
							this.currentSetting = resp.envelopeViewSettingDetails.currentSettingsDetail;
							this.envelopeSettingDetails = resp.envelopeViewSettingDetails.envelopeSettingsDetail;
							this.envelopeAdditionalDetails = resp.envelopeViewSettingDetails.envelopeAdditionalDetails;

							var signInSequenceDesc: any = '';
							if (this.envelopeSettingDetails.signInSequenceDesc != '' && this.envelopeSettingDetails.signInSequenceDesc != null) {
								if (this.envelopeSettingDetails.signInSequenceDesc == "Auto") signInSequenceDesc = " (" + this.translationService.getLanguageTranslationVal(this.resourceKeys.Auto) + ")";
								else signInSequenceDesc = " (" + this.translationService.getLanguageTranslationVal(this.resourceKeys.Manual) + ")";
							}
							else signInSequenceDesc = "";

							if (this.envelopeSettingDetails.signInSequence == true && (signInSequenceDesc != '' && signInSequenceDesc != null) &&
								(signInSequenceDesc.includes("Manual") || signInSequenceDesc.includes("Auto"))) {
								this.signInSequenceDesc = true;
							}

							let weeksVal: any = this.translationService.getLanguageTranslationVal(this.resourceKeys.Weeks);
							let daysVal: any = this.translationService.getLanguageTranslationVal(this.resourceKeys.Days);

							var reminderDays = this.envelopeSettingDetails.sendReminderIn != null ? this.envelopeSettingDetails.sendReminderIn : 0;
							let sendReminderInstr = "";
							if (this.envelopeSettingDetails.sendReminderInDropdownSelected == "08E957DB-5CA5-4F1D-AC65-EEABBB7CE6FD" ||
								this.envelopeSettingDetails.sendReminderInDropdownSelected == "08e957db-5ca5-4f1d-ac65-eeabbb7ce6fd")
								sendReminderInstr = reminderDays + " " + weeksVal;
							else
								sendReminderInstr = reminderDays + " " + daysVal;
							this.customSetting.sendReminderInstr = sendReminderInstr;

							var thenReminderDays = this.envelopeSettingDetails.thenSendReminderIn != null ? this.envelopeSettingDetails.thenSendReminderIn : 0;
							let thenReminderInstr = "";
							if (this.envelopeSettingDetails.thenSendReminderInDropdownSelected == "08E957DB-5CA5-4F1D-AC65-EEABBB7CE6FD" ||
								this.envelopeSettingDetails.thenSendReminderInDropdownSelected == "08e957db-5ca5-4f1d-ac65-eeabbb7ce6fd")
								thenReminderInstr = thenReminderDays + " " + weeksVal + " (3 max)";
							else
								thenReminderInstr = thenReminderDays + " " + daysVal + " (3 max)";
							this.customSetting.thenReminderInstr = thenReminderInstr;

							var finalReminderDays = this.envelopeSettingDetails.sendFinalReminderBeforeExp != null ? this.envelopeSettingDetails.sendFinalReminderBeforeExp : 0;
							let finalReminderDaysInstr = "";
							if (this.envelopeSettingDetails.sendFinalReminderBeforeExpDropdownSelected == "08E957DB-5CA5-4F1D-AC65-EEABBB7CE6FD" ||
								this.envelopeSettingDetails.sendFinalReminderBeforeExpDropdownSelected == "08e957db-5ca5-4f1d-ac65-eeabbb7ce6fd")
								finalReminderDaysInstr = finalReminderDays + " " + weeksVal;
							else
								finalReminderDaysInstr = finalReminderDays + " " + daysVal;
							this.customSetting.finalReminderDaysInstr = finalReminderDaysInstr;

							var finalSenderReminderDays = this.envelopeAdditionalDetails.envelopeExpirationRemindertoSenderReminderDays != 0 ? this.envelopeAdditionalDetails.envelopeExpirationRemindertoSenderReminderDays : 0;
							let SenderReminderDays = "";
							if (this.envelopeAdditionalDetails.isEnvelopeExpirationRemindertoSender == 1) {
								if (this.envelopeAdditionalDetails.envelopeExpirationRemindertoSender == "08E957DB-5CA5-4F1D-AC65-EEABBB7CE6FD" ||
									this.envelopeAdditionalDetails.envelopeExpirationRemindertoSender == "08e957db-5ca5-4f1d-ac65-eeabbb7ce6fd")
									SenderReminderDays = " " + finalSenderReminderDays + " " + weeksVal;
								else
									SenderReminderDays = " " + finalSenderReminderDays + " " + daysVal;
							}
							this.customSetting.senderReminderDays = SenderReminderDays;

							let finalSendReminderTillExpiration = "";
							if (this.envelopeAdditionalDetails.isSendReminderTillExpiration == 1)
								finalSendReminderTillExpiration = " " + this.envelopeAdditionalDetails.sendReminderTillExpiration;
							this.customSetting.finalSendReminderTillExpiration = finalSendReminderTillExpiration;

							if (this.envelopeSettingDetails.includeSignerAttachFile == 2 || this.envelopeSettingDetails.includeSignerAttachFile == 0) {
								this.additionalAttachments = "";
							}
							else if(this.envelopeAdditionalDetails.isAdditionalAttachement != null && this.envelopeAdditionalDetails.isAdditionalAttachement == true){
								this.additionalAttachments = "true";
							}
							else if(this.envelopeAdditionalDetails.isAdditionalAttachement != null && this.envelopeAdditionalDetails.isAdditionalAttachement == false){
								this.additionalAttachments = "false";
							}
							this.isResponseLoaded = true;
						}
						else {
							this.showLoader = false;
							this.toastrService.warning('Unable to get envelope settings.', 'Warning');
							this.isResponseLoaded = false;
						}
					});
			}
			else {
				this.showLoader = false;
				this.toastrService.warning("Unable to get envelope settings.", 'Warning');
				this.isResponseLoaded = false;
			}
		}
		else {
			this.showLoader = false;
			this.toastrService.warning("Unable to get envelope settings.", 'Warning');
			this.isResponseLoaded = false;
		}
	}

	onFilterApply(event: any) {
		let searchInputTextField: any = document.getElementById("searchtext");
		let searchtext: string = searchInputTextField.value;
		let tblSettings: any = document.querySelectorAll("tr.controlshadow");
		let tRowCount = tblSettings.length;
		if (searchtext != '' && searchtext && searchtext.length > 0) {
			if (tRowCount > 0) {
				Array.from(tblSettings).forEach((element: any, index: any) => {
					let ele = tblSettings[index];
					let firstTd = ele.children[0] != undefined ? ele.children[0].innerText : "";
					let secondTd = ele.children[1] != undefined ? ele.children[1].innerText : "";
					if ((firstTd && firstTd.toLowerCase().includes(searchtext.toLowerCase())) || (secondTd && secondTd.toLowerCase().includes(searchtext.toLowerCase())))
						element.style.display = "table-row";
					else
						element.style.display = "none";
				});
			}
		}
		else {
			Array.from(tblSettings).forEach((element: any, index: any) => {
				element.style.display = "";
				element.style.display = "table-row";
			});
		}
	}

	applyFilterCondition(event: any) {
		let selectedVal: any = event.target.value;
		this.filterBy = selectedVal;
	}

	getLanguageTranslationValue(resourcekeyId: any) {
		//return this.translationService.getLanguageTranslationVal(resourcekeyId);
		return this.commonService.getLabelTranslationVal(resourcekeyId);
		// return this.styleService.getCtrlDisplayStyle(controlingFieldId, '', 'signer');
	}
}
