export const environment = {
    production: false,
    uiAngularBaseUrl: 'https://send22.d1.rpost.info/',
    apiBaseUrl: 'https://sendapi22.d1.rpost.info/',   
    brandUrl: 'https://webapi.s1.rpost.info/api/v1/brand',
    sessionTimeOut: 1200,
    sessionInActivity: 300,
    rsignUrl: 'https://app22.d1.rpost.info/' 
};

