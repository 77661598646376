import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild, AfterViewInit, ViewChildren, QueryList, } from '@angular/core';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';

@Component({
	selector: 'app-add-dropdown-list',
	templateUrl: './add-dropdown-list.component.html',
	styleUrls: ['./add-dropdown-list.component.scss']
})
export class AddDropdownListComponent {
	@Input() dropdownListObj: any = {};
	@Output() dropdownListChangesMade: EventEmitter<any> = new EventEmitter();
	@ViewChild("controlFocus") inputControl: ElementRef;
	// @ViewChild('userName') userName: ElementRef;
	@ViewChildren('userName') searchInput: QueryList<ElementRef>;


	dropDownList: any[] = [];
	inputValue: string = '';
	finalCurrentControlDropDownOptions: any;
	dropDownListOptions: any[] = [];
	currentDropDownControlId: any;

	constructor(
		public modalRef: BsModalRef,
		private toastrService: ToastrService,
		private commonService: CommonService
	) { }

	ngOnInit(): void {
		if (this.dropdownListObj) {
			this.currentDropDownControlId = this.dropdownListObj.currentDropDownControlId;
		}
		if (this.dropdownListObj && this.dropdownListObj.option && this.dropdownListObj.option !== '' && Array.isArray(this.dropdownListObj.option)) {
			for (let index = 0; index < this.dropdownListObj.option.length; index++) {
				const element = this.dropdownListObj.option[index];
				this.dropDownList.push({ option: element.value, editMode: false, editValue: '' });
			}
		}
		let element: any = (document.getElementById('dropDownItemList')) as HTMLSelectElement;
		element.classList.add("mystyle");
		element.focus();
		if (this.inputControl && this.inputControl.nativeElement) {
			this.inputControl.nativeElement.focus();
		}

	}
	// ngAfterViewInit() {
	// 	this.userName.nativeElement.focus();
	// }


	@HostListener('window:load')
	onLoad() {
		let element1: any = (document.getElementById('dropDownItemList')) as HTMLSelectElement;
		element1.setAttribute("autofocus", "");
	}

	addOption() {
		if (this.inputValue.trim() !== '') {
			if (this.dropDownList && this.dropDownList.length > 0) {
				let arrayObj: any = this.dropDownList.filter((obj: any) => obj.option.toLowerCase() == this.inputValue.toLowerCase());
				if (arrayObj && arrayObj.length > 0) {
					this.toastrService.warning('Value already exists.', 'Warning'); return false;
				}
			}
			let element1: any = (document.getElementById('dropDownItemList')) as HTMLSelectElement;
			element1.setAttribute("autofocus", "true");
			element1.focus();

			this.dropDownList.push({ option: this.inputValue.trim(), editMode: false, editValue: '' });
			this.inputValue = ''; // Clear the input field
			return true;
		}
		else {
			this.toastrService.warning('Please provide a value', 'Warning'); return false;
		}

	}

	deleteOption(option: any): void {
		// Find the index of the option in the array
		const index = this.dropDownList.indexOf(option);

		// Check if the option is found
		if (index !== -1) {
			// Remove the option from the array
			this.dropDownList.splice(index, 1);
		}
	}

	enableEditMode(list: any): void {
		list.editMode = true;
		list.editValue = list.option;
	}

	saveOption(list: any): void {
		if (list.editValue != '') {
			list.option = list.editValue;
			list.editMode = false;
		}
		else {
			this.toastrService.warning('Please provide a value', 'Warning');
		}
	}

	cancelEdit(list: any): void {
		list.editMode = false;
	}

	onSave(obj: any) {
		if (this.dropDownList && this.dropDownList.length > 0) {
			let optionArray: any = [];
			this.dropDownListOptions = [];
			for (let index = 0; index < this.dropDownList.length; index++) {
				let element: any = this.dropDownList[index];
				this.dropDownListOptions.push({ optionVal: element.option, id: this.commonService.generateUUID() });
				optionArray.push(element.option);
			}

			optionArray = optionArray.sort((a: any, b: any) => a.length < b.length ? 1 : -1);

			this.finalCurrentControlDropDownOptions = {
				controlId: this.currentDropDownControlId,
				dropDownListOptions: this.dropDownListOptions,
				optionLongText: optionArray && optionArray.length > 0 ? optionArray[0] : ''
			}
		}

		this.dropdownListChangesMade.emit(this);
		this.modalRef.hide();
	}

	onClose() {
		this.dropdownListChangesMade.emit(this);
		this.modalRef.hide();
	}
	drop(event: CdkDragDrop<string[]>): void {

		let controlEle1: any = (<HTMLInputElement>document.getElementById('resizable_' + this.currentDropDownControlId))
		if (event.currentIndex == 0 && controlEle1.getAttribute('data-firstlineblank') == "true") {
			
		}
		else{
			moveItemInArray(this.dropDownList, event.previousIndex, event.currentIndex);
		}

		// let arrayObj: any = this.dropDownList.filter((obj: any) => obj.option.trim().toLowerCase() == "");
		// let controlEle: any = (<HTMLInputElement>document.getElementById('resizable_' + this.currentDropDownControlId))
		// if (controlEle.getAttribute('data-firstlineblank') == "true") {
		// 	let newOrderDropDownListOptions = [];
		// 	newOrderDropDownListOptions.push({ option: '', editMode: false, editValue: '' });
		// 	for (let index = 0; index < this.dropDownList.length; index++) {
		// 		let element: any = this.dropDownList[index];
		// 		if (element.option != '') {
		// 			newOrderDropDownListOptions.push({ option: element.option, editMode: element.editMode, editValue: element.editValue });
		// 		}
		// 	}

		// 	this.dropDownList = [];
		// 	for (let index = 0; index < newOrderDropDownListOptions.length; index++) {
		// 		let element: any = newOrderDropDownListOptions[index];
		// 		this.dropDownList.push({ option: element.option, editMode: element.editMode, editValue: element.editValue });
		// 	}
		// 	//moveItemInArray(this.dropDownList, event.previousIndex, event.currentIndex);
		// }
	}

	ngAfterViewInit() {

	}

}
