export class CommonConstants {
    public static TokenType: any = 'Bearer ';
    public static DiscardEnvelopeFailed: any = 'Error while discarding envelope.';
    public static UserPlanFailed: any = 'Unable to get user plan details.';
    public static TokenValidatedMsg: any = 'Token not validated.';
    public static PrefillNotation:any = "(P)";

    public static DragItems: any = [
        {
            title: 'Signature',
            originalTitle: 'Signature',
            id: 1,
            guid: 'E294C207-13FD-4508-95FC-90C5D9C555FA',
            viewBox: '0 0 16 16',
            svgPath1: 'M16 16H0v-1h16zM13.8.3c.4.4.4 1 0 1.4L11 4.5c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L12.4.3c.3-.4 1-.4 1.4 0zM1.4 9.9 0 14.1l4.2-1.4z',
            svgPath2: 'M4.9 12 11 5.9l.5-.5.9-.9c.4-.4.4-1 0-1.4L11 1.7c-.4-.4-1-.4-1.4 0l-.9.9-6.6 6.6L4.9 12z',
            svgPath3: ''
        },

        {
            title: 'Initials', originalTitle: 'Initials',
            id: 2,
            guid: 'BBB41561-0426-4D1F-9887-15D7DFC7C5A2',
            viewBox: '0 0 134 132',
            svgPath1: 'M18.5 63c0 22.3.1 31.4.2 20.3.2-11.2.2-29.4 0-40.5-.1-11.2-.2-2.1-.2 20.2z',
            svgPath2: 'M17.4 108.4c-.3.8-.4 2.7-.2 4.3l.3 2.8h99v-8l-49.3-.3c-41.9-.2-49.3 0-49.8 1.2zm98.6 3.1v3.5H18v-7h98v3.5z',
            svgPath3: 'M19 63.5V103h9V66h11.8l6.8 18.5 6.9 18.5h9.3l-6-15.3c-9.8-24.9-9.4-21.8-4.1-26.2 11.7-9.7 10.1-28.4-2.9-34.9-4.8-2.4-6.2-2.6-18-2.6H19v39.5zm26.8-30.6c3.6 1.9 6.2 7 6.2 12.1 0 9.6-5.3 14-16.6 14H28V31.1l4.3-.3c5.2-.4 10.4.4 13.5 2.1zm39.7-7.2C74.8 31.3 71 43.8 77.1 53.6c1.1 1.8 6.4 6.5 11.7 10.4 15.9 11.6 16.1 11.7 16.8 15.6 1.7 9.1-3.7 15.4-13.1 15.4C84.7 95 80 90.1 80 82c0-2.9-.2-3-4.1-3h-4.1l.4 5.5c1.7 20.7 32.3 25.2 40.3 6 4.6-10.8 1.6-18.5-10-26.3-19.4-13.1-23-18.2-18.9-26.8 4.7-9.9 18.6-7.6 20.1 3.3.6 4.3.6 4.3 4.9 4.3 4.3 0 4.3 0 3.7-3.8-1.9-13.6-15.5-21.5-26.8-15.5zM18 111.5v3.5h98v-7H18v3.5z'
        },
        {
            title: 'Date & Time Stamp', originalTitle: 'Date & Time Stamp',
            id: 3,
            guid: '6AFCF199-51CB-4938-B106-AF8F24674C99',
            // viewBox: '0 0 16 16',
            viewBox: '0 0 512 512',
            // svgPath1: 'M14 2H2C.9 2 0 2.9 0 4v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM2 14V6h12v8H2zM3 0h2v2H3zm8 0h2v2h-2z',
            svgPath1: 'M236 230h40v40h-40z',
            svgPath2: 'M452 40h-24V0h-40v40H124V0H84v40H60C26.916 40 0 66.916 0 100v352c0 33.084 26.916 60 60 60h205.762a176.385 176.385 0 0 1-35.663-40H60c-11.028 0-20-8.972-20-20V188h432v42.099a176.43 176.43 0 0 1 40 35.663V100c0-33.084-26.916-60-60-60zm20 108H40v-48c0-11.028 8.972-20 20-20h24v40h40V80h264v40h40V80h24c11.028 0 20 8.972 20 20v48z',
            svgPath3: 'M377 242c-74.439 0-135 60.561-135 135s60.561 135 135 135 135-60.561 135-135-60.561-135-135-135zm0 230c-52.383 0-95-42.617-95-95s42.617-95 95-95 95 42.617 95 95-42.617 95-95 95z'
        },
        {
            title: 'Name', originalTitle: 'Name',
            id: 4,
            guid: '582F9ACF-353F-4B99-90F4-333189D2D5C9',
            viewBox: '0 0 16 16',
            svgPath1: 'M8 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1c4 0 8 2 8 4v3H0v-3c0-2 4-4 8-4z',
            svgPath2: '',
            svgPath3: ''
        },
        {
            title: 'Title', originalTitle: 'Title',
            id: 5,
            guid: '82ECB881-E947-473C-BC93-E7D378928975',
            viewBox: '0 0 16 16',
            svgPath1: 'M14.5 4H12V2c0-1.1-.9-2-2-2H6C4.9 0 4 .9 4 2v2H1.5C.7 4 0 4.7 0 5.5v8c0 .8.7 1.5 1.5 1.5h13c.8 0 1.5-.7 1.5-1.5v-8c0-.8-.7-1.5-1.5-1.5zM6 2h4v2H6V2z',
            svgPath2: '',
            svgPath3: ''
        },
        {
            title: 'Company', originalTitle: 'Company',
            id: 6,
            guid: '7EFDBC3E-9F56-4532-9B73-16934C5CD0DD',
            viewBox: '0 0 16 16',
            svgPath1: 'M2 0v16h4v-4h4v4h4V0H2zm4 10H4V8h2v2zm0-3H4V5h2v2zm0-3H4V2h2v2zm3 6H7V8h2v2zm0-3H7V5h2v2zm0-3H7V2h2v2zm3 6h-2V8h2v2zm0-3h-2V5h2v2zm0-3h-2V2h2v2z',
            svgPath2: '',
            svgPath3: ''
        },
        {
            title: 'Email', originalTitle: 'Email',
            id: 7,
            guid: 'BB801D80-4EC8-4506-B080-A9980CE0AB1A',
            viewBox: '0 0 16 16',
            svgPath1: 'M8 7.8 16 3c0-1.1-.9-2-2-2H2C.9 1 0 1.9 0 3v.1l8 4.7z',
            svgPath2: 'M8 10.2 0 5.4V13c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V5.3l-8 4.9z',
            svgPath3: ''
        },
        {
            title: 'Dropdown', originalTitle: 'Dropdown',
            id: 8,
            guid: 'DE56E81A-89AE-4D1B-8194-37D9A68A5191',
            viewBox: '0 0 16 16',
            svgPath1: 'M4.5 5.6h7L8 10.9z',
            svgPath2: 'M13.7 2c.2 0 .3.1.3.3v11.4c0 .2-.1.3-.3.3H2.3c-.2 0-.3-.1-.3-.3V2.3c0-.2.1-.3.3-.3h11.4m0-2H2.3C1 0 0 1 0 2.3v11.4C0 15 1 16 2.3 16h11.4c1.3 0 2.3-1 2.3-2.3V2.3C16 1 15 0 13.7 0z',
            svgPath3: ''
        },
        {
            title: 'Checkbox', originalTitle: 'Checkbox',
            id: 9,
            guid: '2CE0EF43-5736-44B4-AC5C-08B2008DBEB9',
            viewBox: '0 0 16 16',
            svgPath1: 'M13.7 2c.2 0 .3.1.3.3v11.4c0 .2-.1.3-.3.3H2.3c-.2 0-.3-.1-.3-.3V2.3c0-.2.1-.3.3-.3h11.4m0-2H2.3C1 0 0 1 0 2.3v11.4C0 15 1 16 2.3 16h11.4c1.3 0 2.3-1 2.3-2.3V2.3C16 1 15 0 13.7 0z',
            svgPath2: 'm13 5.5-1.4-1.4L6.7 9 4.4 6.8 3 8.2l3.7 3.7z',
            svgPath3: ''
        },
        {
            title: 'Radio', originalTitle: 'Radio',
            id: 10,
            guid: '2B02B483-B876-4483-92E4-63E6A5740A37',
            viewBox: '0 0 16 16',
            svgPath1: 'M8 2c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6m0-2C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z',
            svgPath2: '',
            svgPath3: ''
        },
        {
            title: 'Date', originalTitle: 'Date',
            id: 11,
            guid: '27A278AE-F886-4420-B0D1-0DCBAC62A867',
            viewBox: '0 0 34 34',
            // svgPath1: 'M14 2H2C.9 2 0 2.9 0 4v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM2 14V6h12v8H2zM3 0h2v2H3zm8 0h2v2h-2z',
            svgPath1: 'M29.6 2h-3v3c0 .6-.5 1-1 1s-1-.4-1-1V2h-16v3c0 .6-.5 1-1 1s-1-.4-1-1V2h-3C2.1 2 1 3.3 1 5v3.6h32V5c0-1.7-1.8-3-3.4-3zM1 10.7V29c0 1.8 1.1 3 2.7 3h26c1.6 0 3.4-1.3 3.4-3V10.7zm8.9 16.8H7.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8H10c.4 0 .8.3.8.8v2.5c-.1.5-.4.8-.9.8zm0-9H7.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8H10c.4 0 .8.3.8.8v2.5c-.1.5-.4.8-.9.8zm8 9h-2.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c0 .5-.3.8-.8.8zm0-9h-2.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c0 .5-.3.8-.8.8zm8 9h-2.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c0 .5-.3.8-.8.8zm0-9h-2.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c0 .5-.3.8-.8.8z',
            svgPath2: '',
            svgPath3: ''
        },

        {
            title: 'Text', originalTitle: 'Text',
            id: 12,
            guid: '76597505-F0DE-47EE-AF59-F721BE198242',
            viewBox: '0 0 16 16',
            svgPath1: 'M7 2h2v12H7z',
            svgPath2: 'M2 2h12v2H2zm3 10h6v2H5z',
            svgPath3: 'M2 2h2v4H2zm10 0h2v4h-2z'
        },
        {
            title: 'Label', originalTitle: 'Label',
            id: 13,
            guid: '9A0B1230-CDC4-49E7-AEEA-990F415FB89E',
            viewBox: '0 0 16 16',
            svgPath1: 'M14 1H2C.9 1 0 1.9 0 3v10c0 1.1.9 2 2 2h10l4-4V3c0-1.1-.9-2-2-2zm-5 9H4V9h5v1zm3-2H4V7h8v1zm0-2H4V5h8v1z',
            svgPath2: '',
            svgPath3: ''
        },
        {
            title: 'HyperLink', originalTitle: 'Hyperlink',
            id: 14,
            guid: 'B731C98D-74C3-4983-BC06-A0DEF7C306F7',
            viewBox: '0 0 16 16',
            svgPath1: 'M8 14.8c-1.6 1.6-4.2 1.6-5.8 0S.6 10.6 2.2 9L10.3.9c1.2-1.2 3.1-1.2 4.2 0s1.2 3.1 0 4.2l-7 7c-.7.7-1.9.7-2.7 0s-.7-1.9 0-2.7L11.3 3l.8.8-6.5 6.5c-.3.3-.3.8 0 1.1s.8.3 1.1 0l7-7c.7-.7.7-1.9 0-2.7s-1.9-.7-2.7 0L3 9.8C1.8 11 1.8 12.9 3 14s3.1 1.2 4.2 0l7.5-7.5.8.8L8 14.8z',
            svgPath2: '',
            svgPath3: ''
        }
    ];

    public static ControlsObj: any = {
        signatureProperties: false,
        initialsProperties: false,
        datetimeProperties: false,
        nameProperties: false,
        titleProperties: false,
        companyProperties: false,
        emailProperties: false,
        dropdownProperties: false,
        checkboxProperties: false,
        radioProperties: false,
        dateProperties: false,
        textProperties: false,
        labelProperties: false,
        hyperlinkProperties: false
    };

    public static ZoomPercentage: any = {
        zoomVal50: false,
        zoomVal75: false,
        zoomVal100: true,
        zoomVal125: false,
        zoomVal150: false,
        zoomVal175: false,
        zoomVal200: false,
    };

    public static Checkboxoptions: any = [
        { label: 'Small 16px X 16px', value: 1, viewValue: 'Small' },
        { label: 'Standard 18px X 18px', value: 2, viewValue: 'Standard' },
        { label: 'Large 20px X 20px', value: 3, viewValue: 'Large' },
        { label: 'Extra Large 22px X 22px', value: 4, viewValue: 'Extra Large' }
    ];

    public static Validationsoptions: any = [
        { label: 'Text', value: 1 },
        { label: 'Numeric', value: 2 },
        { label: 'Alphabet', value: 3 },
        { label: 'SSN', value: 4 },
        { label: 'Zip', value: 5 },
        { label: 'Date', value: 6 },
        { label: 'Email', value: 7 }
    ];

    public static ValidationsText: any = [
        { id:'1', label: 'Text', value: '26C0ACEA-3CC8-43D6-A255-A870A8524A77' },
        { id:'2', label: 'Numeric', value: 'B0443A47-89C3-4826-BECC-378D81738D03' },
        { id:'3', label: 'Alphabet', value: '88A0B11E-5810-4ABF-A8B6-856C436E7C49' },
        { id:'4', label: 'SSN', value: 'DCBBE75C-FDEC-472C-AE25-2C42ADFB3F5D' },
        { id:'5', label: 'Zip', value: '5121246A-D9AB-49F4-8717-4EF4CAAB927B' },
        { id:'6', label: 'Date', value: '8348E5CD-59EA-4A77-8436-298553D286BD' },
        { id:'7', label: 'Email', value: '1AD2D4EC-4593-435E-AFDD-F8A90426DE96' }
    ];

    public static AdditionalValidation: any = [
        { value: '1', viewname: 'Number' },
        { value: '2', viewname: 'Decimal' }
    ];

    public static AdditionalValidationType: any = [
        { value: '1', viewValue: 'Both' },
        { value: '2', viewValue: 'Comma' },
        { value: '3', viewValue: 'Period' }
    ];

    public static ValidationsZipOptions: any = [
        { label: 'Zip', value: '1', viewname:'Zip' },
        { label: 'Zip + 4', value: '2', viewname:'Zip+4' }
    ];

    public static ValidationsDateOptions: any = [
        { label: 'mm/dd/yyyy', value: '1' },
        { label: 'dd/mm/yyyy', value: '2' },
        { label: 'yyyy/mm/dd', value: '3' },
        { label: 'dd-mmm-yyyy', value: '4' }
    ];

    public static Dateformat: any = [
        { value: '1', viewValue: 'US: mm/dd/yyyy', dateFormatId: 'E3DAD8D9-E16F-40F5-B112-BBA204538136' },
        { value: '2', viewValue: 'US: mm-dd-yyyy', dateFormatId: '9FC73C2B-34D7-42A1-B2A6-702ED2FD312B' },
        { value: '3', viewValue: 'US: mm.dd.yyyy', dateFormatId: 'BDA0023F-AFC1-46E5-A134-884EDCA48799' },
        { value: '4', viewValue: 'US: dd-mmm-yyyy', dateFormatId: '7F472F00-CD12-443E-B38D-085F8872115F' },
        { value: '5', viewValue: 'EU: dd/mm/yyyy', dateFormatId: '577D1738-6891-45DE-8481-E3353EB6A963' },
        { value: '6', viewValue: 'EU: dd-mm-yyyy', dateFormatId: '6685D1ED-60D2-4028-94E9-BC875C2E551D' },
        { value: '7', viewValue: 'EU: dd.mm.yyyy', dateFormatId: '374FE10E-4313-4541-B3CB-627310A14499' },
        { value: '8', viewValue: 'EU: yyyy.mm.dd.', dateFormatId: '8FAC284C-AB19-456C-BC73-1CE0D66D7220' }
    ];

    public static DateformatValue: any = [
        { value: '1', viewValue: 'mm/dd/yyyy' },
        { value: '2', viewValue: 'mm-dd-yyyy' },
        { value: '3', viewValue: 'mm.dd.yyyy' },
        { value: '4', viewValue: 'dd-mmm-yyyy' },
        { value: '5', viewValue: 'dd/mm/yyyy' },
        { value: '6', viewValue: 'dd-mm-yyyy' },
        { value: '7', viewValue: 'dd.mm.yyyy' },
        { value: '8', viewValue: 'yyyy.mm.dd.' }
    ];

    public static DefaultColorPicker: string = '#172b4d';

    public static ReceipentColorArr: any = ['#3ab16d', '#2486fc', '#ff9c16', '#9b870c', '#0000cd', '#002e63', '#7f00ff', '#367588', '#800080', '#00FF00', '#008080', '#0000FF',
        '#800000', '#FF0000', '#FFA500', '#FFFF00', '#808000', '#008000', '#FF7F50', '#BDB76B', '#FF4500', '#FFD700', '#8B008B', '#4B0082', '#000080', '#D2691E', '#A52A2A', '#87CEFA', '#4682B4',
        '#7FFFD4', '#00008B', '#BA55D3', '#FFDAB9', '#566D7E', '#AA6C39', '#3B9C9C', '#00CED1', '#8467D7', '#737CA1', '#045D5D', '#827839', '#728FCE', '#1F6357', '#A0522D', '#2F539B', '#123456',
        '#3090C7', '#14A3C7', '#659EC7', '#87AFC7', '#EED202', '#DA70D6', '#95B9C7', '#6495ED', '#EE9A4D', '#967BB6', '#6698FF', '#6667AB', '#C25283', '#56A5EC', '#AFDCEC', '#FCDFFF', '#9AFEFF',
        '#50EBEC', '#F0E68C', '#8EEBEC', '#22CE83', '#81D8D0', '#7BCCB5', '#FFE87C', '#66CDAA', '#93E9BE', '#01F9C6', '#8E7618', '#728C00', '#FBB117', '#08A04B', '#B5A642', '#FBD5AB', '#B1907F',
        '#EDC9AF', '#B041FF', '#E0B0FF', '#F3E8EA', '#FFF9E3', '#E238EC', '#FFCBA4', '#7E354D', '#E78A61', '#CC6600', '#654321', '#C58917', '#C8B560', '#FED8B1', '#B5EAAA', '#ADF802', '#00827F',
        '#93FFE8', '#3BB9FF', '#34282C']; //100 count

    //FFDAB9 -33
    public static ControlNameArray: any = {
        Sign: "Sign", Initials: "newInitials", DateTimeStamp: "DateTimeStamp", Name: "Name", Title: "Title", Company: "Company",
        Email: "Email", Dropdown: "Dropdown", Checkbox: "Checkbox", Radio: "Radio", Date: "Date", Text: "Text", Label: "Label", Hyperlink: "Hyperlink"
    }; //14 count

    public static ControlProperty: any = {
        Required: "Required", Bold: "Bold", Underline: "Underline", Italics: "Italics", FontType: "FontType", FontSize: "FontSize",
        FontColor: "FontColor", DateFormat: "DateFormat", ValidationType: "ValidationType", Length: "Length", NameLength: "NameLength",
        CheckRequired: "CheckRequired", PreSelected: "PreSelected", Size: "Size", IsDefaultRequired: "IsDefaultRequired",
        AdlnName: "AdlnName", AdlnOption: "AdlnOption", CheckboxGroup: "CheckboxGroup", FontTypeMeasurement: "FontTypeMeasurement", LabelControlValue: "LabelControlValue"
    };

    // public static DependencyColorCodes: any = [
    //     { ID: 0, Color: '#00FFFF' }, { ID: 1, Color: '#9900CC' }, { ID: 2, Color: '#FF00FF' }, { ID: 3, Color: '#FFFF33' },
    //     { ID: 4, Color: '#FF6666' }, { ID: 5, Color: '#E18942' }, { ID: 6, Color: '#009999' },
    //     { ID: 7, Color: '#99CCFF' }, { ID: 8, Color: '#993300' }, { ID: 9, Color: '#9966CC' },
    //     { ID: 10, Color: '#008000' }, { ID: 11, Color: '#FF7F50' }, { ID: 12, Color: '#BDB76B' },
    //     { ID: 13, Color: '#FF4500' }, { ID: 14, Color: '#FFD700' }, { ID: 15, Color: '#8B008B' },
    //     { ID: 16, Color: '#4B0082' }, { ID: 17, Color: '#000080' }, { ID: 18, Color: '#D2691E' },
    //     { ID: 19, Color: '#A52A2A' }, { ID: 20, Color: '#87CEFA' }, { ID: 21, Color: '#4682B4' },
    //     { ID: 22, Color: '#7FFFD4' }, { ID: 23, Color: '#00008B' }, { ID: 24, Color: '#BA55D3' },
    //     { ID: 25, Color: '#FFDAB9' }, { ID: 26, Color: '#FFFF00' }, { ID: 27, Color: '#FFA500' },
    //     { ID: 28, Color: '#FF0000' }, { ID: 29, Color: '#800000' }, { ID: 30, Color: '#0000FF' },
    //     { ID: 31, Color: '#008080' }, { ID: 32, Color: '#00FF00' }, { ID: 33, Color: '#800080' },
    //     { ID: 34, Color: '#367588' }, { ID: 35, Color: '#7f00ff' }];


    public static DependencyColorCodes: any = [
        { ID: 0, Color: '#00FFFF' }, { ID: 1, Color: '#9900CC' }, { ID: 2, Color: '#FF00FF' }, { ID: 3, Color: '#FFFF33' }, { ID: 4, Color: '#FF6666' },
        { ID: 5, Color: '#E18942' }, { ID: 6, Color: '#009999' }, { ID: 7, Color: '#99CCFF' }, { ID: 8, Color: '#993300' }, { ID: 9, Color: '#9966CC' }];

    // Toastr Messages
    public static CopiedTtoClipboard: string = 'Copied to clipboard.';
    public static DataIsSavedSuccessfully: string = 'Default values saved successfully.';
    public static DataIsRemovedSuccessfully: string = 'Default values removed successfully.';

    public static ReceipentListArr: any = [
        { id: '725CE971-7D65-4739-B7CB-863234267731', name: 'Smith Emily', order: 1 },
        { id: 'BB8C26BF-43B9-4FA6-8830-6F7C6F8CFD46', name: 'Johnsan Valle', order: 2 },
        { id: '179A3391-20F2-4C0D-AC22-BC8478537111', name: 'Diana Graft', order: 3 },
        { id: '785F6E8F-5A3D-4232-BDEB-665505824F00', name: 'Anderson lab', order: 4 },
        { id: 'C297FB2B-4707-4AD2-A929-F7E6160295BC', name: 'Butler Jus', order: 5 },
        { id: 'E76A9A14-80E0-40B7-83BF-DD64E5AF99C0', name: 'Smith Stevin', order: 6 },
        { id: '854F5C64-56A4-4C28-8DF1-8640EEA4F59A', name: 'Robinson Kale', order: 7 },
        { id: '73472421-A0BD-4455-B34F-0EF117384C1A', name: 'Jemes Chen', order: 8 }
    ];

    public static RuleConfig: any = [
        { ID: '1765014E-6611-4C9A-9268-833E7D853D1A', RuleText: 'Checked' },
        { ID: 'E6FC0258-FBD1-4B11-99A7-138193AF2E64', RuleText: 'Unchecked' },
        { ID: '2380C0B0-4A55-4B33-9E22-A93C55A78CC3', RuleText: 'Initialed' },
        { ID: '7B108AA4-B5F9-489D-930B-2AEB90811518', RuleText: 'Notinitialed' },
        { ID: 'B7101FBE-F33B-4FCD-A65F-9A44E5A8A354', RuleText: 'Signed' },
        { ID: '11B6EDCC-70A4-4EC9-AA57-BD7C59ABF9F8', RuleText: 'Unsigned' },
        { ID: '8E2B3EC5-6CD1-4F3E-8025-4FDC7D6E35E3', RuleText: 'SpecificText' },
        { ID: 'E8351007-1A7E-449C-B3FD-AFE619CFFA8D', RuleText: 'AnyText' },
        { ID: '68569338-7AEA-481F-988C-B70D13607E81', RuleText: 'ContainsText' },
        { ID: '0B069009-E271-4534-8C8B-21D876CC31DE', RuleText: 'Empty' },
    ];

    public static TextRuleConfig: any = [
        { ID: 'E8351007-1A7E-449C-B3FD-AFE619CFFA8D', RuleText: 'Any Text' },
        { ID: '8E2B3EC5-6CD1-4F3E-8025-4FDC7D6E35E3', RuleText: 'Specific Text' },
        { ID: '68569338-7AEA-481F-988C-B70D13607E81', RuleText: 'Contains Text' },
        { ID: '0B069009-E271-4534-8C8B-21D876CC31DE', RuleText: 'Empty' },
    ];

    public static CheckBoxRuleConfig: any = [
        { ID: '1765014E-6611-4C9A-9268-833E7D853D1A', RuleText: 'Checked' },
        { ID: 'E6FC0258-FBD1-4B11-99A7-138193AF2E64', RuleText: 'Unchecked' },
    ];

    public static ControlListArr: any = [
        { ControlId: 'E294C207-13FD-4508-95FC-90C5D9C555FA', ControlClassName: 'sign', ControlName: 'Signature' },
        { ControlId: '76597505-F0DE-47EE-AF59-F721BE198242', ControlClassName: 'text', ControlName: 'Text' },
        { ControlId: '582F9ACF-353F-4B99-90F4-333189D2D5C9', ControlClassName: 'name', ControlName: 'Name' },
        { ControlId: '82ECB881-E947-473C-BC93-E7D378928975', ControlClassName: 'title', ControlName: 'Title' },
        { ControlId: '7EFDBC3E-9F56-4532-9B73-16934C5CD0DD', ControlClassName: 'company', ControlName: 'Company' },
        { ControlId: '27A278AE-F886-4420-B0D1-0DCBAC62A867', ControlClassName: 'date', ControlName: 'Date' },
        { ControlId: 'BB801D80-4EC8-4506-B080-A9980CE0AB1A', ControlClassName: 'email', ControlName: 'Email' },
        { ControlId: '2CE0EF43-5736-44B4-AC5C-08B2008DBEB9', ControlClassName: 'checkbox', ControlName: 'Checkbox' },
        { ControlId: '9A0B1230-CDC4-49E7-AEEA-990F415FB89E', ControlClassName: 'label', ControlName: 'Label' },
        { ControlId: '2B02B483-B876-4483-92E4-63E6A5740A37', ControlClassName: 'radio', ControlName: 'Radio' },
        { ControlId: 'DE56E81A-89AE-4D1B-8194-37D9A68A5191', ControlClassName: 'dropdown', ControlName: 'Dropdown' },
        { ControlId: '6AFCF199-51CB-4938-B106-AF8F24674C99', ControlClassName: 'datetimestamp', ControlName: 'DateTimeStamp' },
        { ControlId: 'B731C98D-74C3-4983-BC06-A0DEF7C306F7', ControlClassName: 'hyperlink', ControlName: 'Hyperlink' },
        { ControlId: 'BBB41561-0426-4D1F-9887-15D7DFC7C5A2', ControlClassName: 'initials', ControlName: 'NewInitials' }
    ];

    public static controllingFieldArray: any = ["DE56E81A-89AE-4D1B-8194-37D9A68A5191", "2CE0EF43-5736-44B4-AC5C-08B2008DBEB9", "2B02B483-B876-4483-92E4-63E6A5740A37", "76597505-F0DE-47EE-AF59-F721BE198242",]
}
export class Control {
    public static Text: string = '76597505-F0DE-47EE-AF59-F721BE198242';
    public static Label: string = '9A0B1230-CDC4-49E7-AEEA-990F415FB89E';
    public static Hyperlink: string = 'B731C98D-74C3-4983-BC06-A0DEF7C306F7';
    public static DateTimeStamp: string = '6AFCF199-51CB-4938-B106-AF8F24674C99';
    public static Checkbox: string = '2CE0EF43-5736-44B4-AC5C-08B2008DBEB9';
    public static DropDown: string = 'DE56E81A-89AE-4D1B-8194-37D9A68A5191';
    public static Radio: string = '2B02B483-B876-4483-92E4-63E6A5740A37';
    public static Date: string = '27A278AE-F886-4420-B0D1-0DCBAC62A867';
    public static Signature: string = 'E294C207-13FD-4508-95FC-90C5D9C555FA';
    public static Initials: string = 'BBB41561-0426-4D1F-9887-15D7DFC7C5A2';
    public static Company: string = '7EFDBC3E-9F56-4532-9B73-16934C5CD0DD';
    public static Name: string = '582F9ACF-353F-4B99-90F4-333189D2D5C9';
    public static Email: string = 'BB801D80-4EC8-4506-B080-A9980CE0AB1A';
    public static Title: string = '82ECB881-E947-473C-BC93-E7D378928975';
}
export class BehaviourRuleConfig {
    public static VisibleRequired: string = 'Visible & Required';
    public static VisibleNotRequired: string = 'Visible & Not Required';
}
export class TextRuleCondtionsConfig {
    public static AnyText: string = 'E8351007-1A7E-449C-B3FD-AFE619CFFA8D';
    public static SpecificText: string = '8E2B3EC5-6CD1-4F3E-8025-4FDC7D6E35E3';
    public static ContainsText: string = '68569338-7AEA-481F-988C-B70D13607E81';
    public static Empty: string = '0B069009-E271-4534-8C8B-21D876CC31DE';
}

export class ControlResourceKeys {
    public static Text: string = 'CB5F06CB-FFD6-4E4B-AD47-D2DE3944DFDB';
    public static Label: string = '735BF0E9-FC01-40A6-82A3-5A037A3FF9B5';
    public static Hyperlink: string = '93D87429-C34A-4810-B3F1-570F6511BB26';
    public static DateTimeStamp: string = 'A022376B-9495-4EAA-8CFE-B3293618BEBE';
    public static Checkbox: string = 'B5D83695-8B5B-4475-BAE0-0FC873C77DA1';
    public static DropDown: string = '81C0A7D6-88B4-4BB2-85C8-91F88022BB71';
    public static Radio: string = '582A741D-F1E4-4E2C-A81A-CA3D1407F0CF';
    public static Date: string = '0EAB0BE2-0420-4D2D-AC8E-34FF54A73A3A';
    public static Signature: string = 'D6BA71C2-91A4-41CE-ABE8-6AD9CD5F757A';
    public static Initials: string = '0F001FA1-C71F-490B-93CF-6E5A55ABAAD5';
    public static Company: string = '78348D80-2C99-4C2A-9A19-3483966A5AD6';
    public static Name: string = '391FF1F4-6A14-4782-A7C9-0FA49F36A104';
    public static Email: string = '982A95AB-6FE0-4764-A8E1-B5760121CCBC';
    public static Title: string = 'A17FC9D6-CA82-4E80-9F6B-9974AA1709F7';
}

export class ControlConfig {
    public static Existing: string = 'Existing';
    public static Duplicate: string = 'Duplicate';
}

export class PasswordType {
    public static Select: string = '3702FE94-D7DB-45F4-86D7-8CC4791F7677';
    public static Endtoend: string = 'FC14F65B-6FE9-4211-90FB-A03F241A55BE';
    public static RequiredToOpenSigned: string = '5D8162DA-C7A7-40BE-9CF1-442FB2524CA3';
    public static SignerIdentity: string = '6F95605F-AC41-4423-99EB-4FA9B1C24EDE';
}

export class FinalContractOptions {
    public static Aspose: Number = 1;
    public static FromImages: Number = 2;
    public static iText: Number = 3;
}

export class SignatureRequestReplyAddress {
    public static SenderMailRpostNet: string = "Sender's Email (@rsign.rpost.net)";
    public static NoReply: string = 'no-reply@r1.rsign.net';
}

export class RecipientType {
    public static CC: string = "63ea73c2-4b64-4974-a7d5-0312b49d29d0";
    public static Signer: string = 'c20c350e-1c6d-4c03-9154-2cc688c099cb';
    public static Sender: string = '26e35c91-5ee1-4abf-b421-3b631a34f677';
    public static Prefill: string = '712f1a0b-1ac6-4013-8d74-aac4a9bf5568';
}

export class ActionTypes {
    public static Sign: string = "Sign";
    public static Review: string = 'Review';
}

export class RSignStage {
   //Envelope
   public static  InitializeEnvelope: string  = "InitializeEnvelope";
   public static  PrepareEnvelope: string  = "PrepareEnvelope";
   //Draft
   public static  InitializeDraft: string  = "InitializeDraft";
   public static  PrepareDraft: string  = "PrepareDraft";
   //TemplateCreate
   public static  InitializeTemplate: string  = "InitializeTemplate";
   public static  PrepareTemplate: string  = "PrepareTemplate";
   //RuleCreate
   public static  InitializeRule: string  = "InitializeRule";
   public static  PrepareRule: string  = "PrepareRule";
   //TemplateEdit
   public static  InitializeEditTemplate: string  = "InitializeEditTemplate";
   public static  PrepareEditTemplate: string  = "PrepareEditTemplate";
   //RuleEdit
   public static  InitializeEditRule: string  = "InitializeEditRule";
   public static  PrepareEditRule: string  = "PrepareEditRule";
   //TemplateUse
   public static  InitializeUseTemplate: string  = "InitializeUseTemplate";
   public static  PrepareUseTemplate: string  = "PrepareUseTemplate";
   //RuleUse
   public static  InitializeUseRule: string  = "InitializeUseRule";
   public static  PrepareUseRule: string  = "PrepareUseRule";
   //Signing
   public static  SignEnvelope: string  = "SignEnvelope";
   public static  UpdateAndResend: string  = "UpdateAndResend";
   public static  ProcessGroup: string  = "ProcessGroup";
}

