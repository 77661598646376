import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ServicesError } from './services.error';
import { ApiUrlConfig } from '../constants/common-apiurls';
import { CommonConstants } from '../constants/common-constants';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class APIService {
  baseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private error: ServicesError, private authService: AuthService) { }

  get requestOptions() {
    return {
      headers: new HttpHeaders({
        'Authorization': CommonConstants.TokenType + this.authService.getToken(),
        //'Authorization': CommonConstants.TokenType + 'Fuo9pFlVnqOn8Tg03fkKrkHcs7nUnQ0sQ14__4zDmQ0XTujNaNMnh-fMFUjEJ36brv6D4obs0o4dHM7moYvJE0CgEFv_qmTkg305GBX3clX9xP9qarbq39xpaTEEGezXbdhodQIqNBFYLaWoNuTBIJ8QGQJFE0tZiWcc7PgjUQbRQIUuDNi1Qbv7ZdvjesG9kNdKSGMIcXQdIAJ60vocSgsLS8aj6tUmBWh5hH-T8r-uvOyGZBstWEntyr-tFkF-VgCYfYC1GRSSISCcLb8P1XmJvBElcdTEuGLV5gfOnmjaP-dNEdx7HemhAcOvzfYWDlw5V3KNqyaTBdt36qxn2Ht8AdIKcC2zXcTIXFi-E6eVekKovo2zjUGMHOmP9Ulb6BCoejNRIKoZwTITGST820UIGBSmFvd3qAw7lmw7xXZG5KoIBTVFKNHDZwb5hhAxssYYOpXyQmIVQtCR64Fw7YPqLGnUEVXRK0-RhfDP_eK11Wzdrytv3e1LdJ6B5EKL7UPtcUTNJ_QVgAwtqI0qsmy3TTo',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': 'application/json;'
      })
    };
  }

  get(url: any) {
    return this.http.get(this.baseUrl + url, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  viewPdf(apiEnvelopeControls:any) {
    let viewPdfUrl = ApiUrlConfig.ViewPdf;   
    return this.http.post(this.baseUrl + viewPdfUrl,apiEnvelopeControls, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  SendEnvelope(apiEnvelopeControls:any){
    let sendEnvelopeUrl=ApiUrlConfig.SendEnvelope;
    return this.http.post(this.baseUrl + sendEnvelopeUrl, apiEnvelopeControls, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  delete(url: any) {
    return this.http.delete(this.baseUrl + url, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  SaveAsDraftEnvelope(apiEnvelopeControls:any){   
    let saveAsDraftEnvelopeUrl=ApiUrlConfig.SaveAsDraftEnvelope;
    return this.http.post(this.baseUrl + saveAsDraftEnvelopeUrl, apiEnvelopeControls, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

  GetRefreshToken(tokenModel:any){    
    let getRefreshTokenUrl=ApiUrlConfig.RefreshToken;
    return this.http.post(this.baseUrl + getRefreshTokenUrl, tokenModel, { ...this.requestOptions }).pipe(catchError(this.error.handleError));
  }

}
