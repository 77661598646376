<app-header></app-header>
<main class="container-login100">
    <div class="wrapperIndex">
        <div class="dvRadius">
            <div class="divMainWrapper user_card">
                <div id="formContent" class="dvglobalInfo_2">
                    <div class="dvdevices">
                        <img src="../../../assets/images/I-Icon.svg" class="i-icon"/>
                        <div class="d-flex flex-column mt-3">
                            <h5 class="GlobalApperror_1 mb-0 font-w600">Oops! We are having trouble processing your
                                request. Please try again.
                            </h5>

                                <h5 class="Globalinfo_1 mb-0" style="font-size: 15px; font-weight: bold;">If the problem
                                    persists, please share the error
                                    code shown below with <span style="cursor: pointer;color:#2486fc !important" (click)="redirectTo('Support')">RSign Support</span> </h5>
                            
                            <div id="formContent" class="dvglobalInfo_2">
                                <p class="ptextAlign d-flex flex-column mb-0" style="font-size: 13px;">
                                    <!-- {{this.DateTimeMessage1}} <br>
                                    {{this.DateTimeMessage2}} -->
                                    <span class="">{{this.DateTimeMessage1}}</span>
                                    <span class="">{{this.DateTimeMessage2}}</span>
                                    <!-- <span class=""> ::1|gir***@myy***.com||envelope/envelopeindex</span> -->

                                </p>
                            </div>
                            <h5 class="GlobalApperror_1 mb-0 font-w600">
                                In the meantime, you may want to check the following resources.
                            </h5>
                        </div>
                    </div>

                    <div class="row mb-5"
                        style="justify-content: space-between;max-width: 780px;margin: 10px 10px 10px 10px;">

                        <div class="block-404__item" (click)="redirectTo('Features')">
                            <div class="block-404__item_icon"><i class="fa fa-th-large fa-4x"></i></div>
                            <div class="block-404__item_name">Features </div>
                            <a target="_blank" class="block-404__item_link">Learn more</a>
                        </div>

                        <div class="block-404__item" (click)="redirectTo('CustomerSuccess')">
                            <div class="block-404__item_icon"><i class="fa fa-group fa-4x"></i></div>
                            <div class="block-404__item_name">Success stories</div>
                            <a target="_blank" class="block-404__item_link">Learn more</a>
                        </div>

                        <div class="block-404__item" (click)="redirectTo('Webinars')">
                            <div class="block-404__item_icon"><i class="fa fa-tv fa-4x"></i></div>
                            <div class="block-404__item_name">Webinars </div>
                            <a target="_blank" class="block-404__item_link">Learn more</a>
                        </div>

                        <div class="block-404__item" (click)="redirectTo('Videos')">
                            <div class="block-404__item_icon"><i class="fa fa-file-video-o fa-4x"></i></div>
                            <div class="block-404__item_name">Product videos</div>
                            <a target="_blank" class="block-404__item_link">Learn
                                more</a>
                        </div>

                        <div class="block-404__item" (click)="redirectTo('Apps')">
                            <div class="block-404__item_icon"><i class="fa fa-desktop fa-4x"></i></div>
                            <div class="block-404__item_name">Apps </div>
                            <a target="_blank" class="block-404__item_link">Let’s go</a>
                        </div>

                        <div class="block-404__item" (click)="redirectTo('Blogs')">
                            <div class="block-404__item_icon"><i class="fa fa-th-list fa-4x"></i></div>
                            <div class="block-404__item_name">Blogs </div>
                            <a target="_blank" class="block-404__item_link">Read the Blogs</a>
                        </div>

                        <div class="block-404__item" (click)="redirectTo('News')">
                            <div class="block-404__item_icon"><i class="fa fa-list-alt fa-4x"></i></div>
                            <div class="block-404__item_name">News </div>
                            <a target="_blank" class="block-404__item_link">Read the News</a>
                        </div>

                        <div class="block-404__item" (click)="redirectTo('ContactUs')">
                            <div class="block-404__item_icon"><i class="fa fa-envelope-o fa-4x"></i></div>
                            <div class="block-404__item_name">Contact Us</div>
                            <a class="block-404__item_link">Ask a question</a>
                        </div>
                    </div>

                </div>
               
            </div>
        </div>
    </div>
</main>

<footer style="background: #eee;" class="default-footer mt-auto pt-2 pb-2">
    <div style="font-size: 10px;">
        <div class="fotertext" [innerHTML]="htmlFooterString">
        </div>
    </div>
</footer>

<div *ngIf="showLoader" class="loader">
</div>