import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-clearall-controls',
    templateUrl: './clearall-controls.component.html',
    styleUrls: ['./clearall-controls.component.scss']
})
export class ClearallControlsComponent {
    @Output() clearallChangesMade: EventEmitter<any> = new EventEmitter();

    constructor(
		public modalRef: BsModalRef
	) {}

	ngOnInit(): void {
	}

    onOk(event:any){
        this.clearallChangesMade.emit(this);
        this.modalRef.hide();
    }
}
