import { HttpErrorResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
@Injectable({
    providedIn: 'root'
})
export class ServicesError {

    constructor() { }

     handleError(err: HttpErrorResponse): Observable<AppError> {
        const appError = new AppError(err);
        const errorStatus = err.status;
        const errorStatusText = err.statusText;
        const error: any = err.error;
        let errorMsg = '';
        let msgId = '';

        if (error && error.Message !== undefined && Array.isArray(error.Message)) {
            errorMsg = err.error.Message[0].Message;
            msgId = err.error.Message[0].MessageId;
        } else {
            if (error && error.error_description) {
                errorMsg = error.error_description;
            } else {
                if (error && error.Message) {
                    errorMsg = error.Message;
                    msgId = (error.MessageId) ? error.MessageId : '';
                } else {
                    errorMsg = errorStatusText;
                }
            }
        }

        if(errorMsg != ''){
            errorMsg = appError.originalError?.message;
        }

        const retMsg = 'Error (${errorStatus}): ${errorMsg}';
        appError.Message = retMsg;
        appError.MessageId = msgId;

        return of(appError);
    }
}
export class AppError {
    constructor(public originalError?: any) { }  
    Message: any;
    MessageId: any;
  }
