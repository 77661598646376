<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title font-w600" id="staticBackdropLabel">Confirmation</h5>
        <div class="modalposition" aria-label="Close">
            <span class="material-icons model-closeicon"
                (click)="onDiscardCancel()">close</span>
        </div>
    </div>
    <div class="modal-body">
        <div class="d-flex align-items-center justify-content-center my-2">
            <img class="text-center" style="height: 90px;" src="../../../assets/images/icons/Discard-Envelope.svg" title="">
        </div>

        <p class="mb-1 px-3 mt-3 font16 d-flex align-items-center flex-column justify-content-center">
            <span class="text-center mb-1">This action will permanently delete this envelope.</span>
            <span> Do you want to proceed?</span>
            <!-- <span class="mt-3 d-flex align-items-center">
            </span> -->
        </p>
    </div>
    <div class="modal-footer justify-content-end">
        <button class="btn back-btn cancelbtn form-btn" (click)="onDiscardCancel()">
            Cancel</button>
        <button role="button" class="okbtn btn back-btn form-btn ms-3" (click)="onDiscardEnvelope()"> Ok</button>
    </div>
</div>
<div *ngIf="showLoader" class="loader">
</div>
