export class ResourceKeys {
    public static GeneralSettings: string = "1DFC1FA9-FC98-4ED1-B421-2D1C291BDFB6";
    public static AdvancedSettings: string = "C374F9B7-D23D-4E69-985D-C561B345EADA";
    public static AdminSettings: string = "91886562-AF78-481C-9B80-F3FCE23B53AC";
    public static SystemSettings: string = "117692A5-B579-4D1F-9AD9-C3F34101FCF5";
    public static PrivacySettings: string = "F19EBF72-F694-4565-B008-25CFA06657B4";
    public static DateFormat: string = "DB6ECFE0-ABE6-44E2-AA3B-AE8819F0C5C3";
    public static ExpiresIn: string = "ACE132A6-3F58-4B3B-BE82-691446C0A0B8";
    public static SendFirstReminderIn: string = "120F50FA-FDAB-4D8D-99E3-540E462B3BBF";
    public static ThenSendFollowUpRemindersEvery: string = "363D9101-8682-41FC-8F6B-2DF61BD78984";
    public static SendFinalReminderBeforeExpiration: string = "BF9B7DCC-950E-4A00-8877-38D06770E2E1";
    public static EnvelopeExpirationRemindertoSender: string = "7E70886B-54D8-4BC4-B059-A1FA0593F28A";
    public static lang_Y: string = "D3C56440-6A50-4259-B24E-24BD9A0693BE";
    public static lang_N: string = "A5F19D7F-FB5C-4451-9DB2-F499FE9AC583";
    public static SendReminderTillExpiration: string = "B31655E5-E726-4EB8-81D8-ABBD612110FA";
    public static AccessAuthentication: string = "4FC99518-EF53-44F3-8BB5-A893C574C7DD";
    public static lang_none: string = "23EE52A0-41EB-485C-AEC3-B446768C8F8C";
    public static EmailAccessCode: string = "FE1C80C7-E56B-4EF3-A15A-607E63D9652F";
    public static Unchecked: string = "EA688CD4-86A5-4E82-A6EE-C9C88A15FBEE";
    public static Checked: string = "ED57F769-EA90-473F-9DFE-E87773385026";
    public static IncludeStaticTemplates: string = "77299E86-3ACE-4386-94E5-866986307E40";
    public static RequiresSignersConfirmationonFinalSubmit: string = "34775C2F-431A-4655-A7B4-BA217FDD8A72";
    public static SendIndividualNotifications: string = "c222c3d9-6c2c-4b9c-8e9b-4809c103c40d";
    public static AddSignatureCertificate: string = "700361D4-36E6-434A-8925-87B68710395C";
    public static Sequence: string = "3747D0FE-1021-4BCF-B22A-590779C19799";
    public static DownloadLinkOnManage: string = "AECAE4AC-3DFF-476C-84F7-4EA9160D18E3";
    public static IncludeTransparencyDocument: string = "EA0FF30F-6545-4D0D-A66B-BB96A99BCA4B";
    public static SignerAttachmentOptions: string = "9E6663BD-92E9-4194-8822-4E61AF07FACA";
    public static AdditionalAttachments: string = "A7FF94BC-0836-4AEA-9DAD-F072F866DD00";
    public static IsDefaultSignatureRequiredForStaticTemplate: string = "6021D655-0062-4198-A24F-DFF809C7DE51";
    public static SendConfirmationEmail: string = "06E9C7F1-C1EB-438A-95CE-2ECA5E4D1C11";
    public static SeparateMultipleDocumentsAfterSigning: string = "95266884-7C92-46FC-8A32-6C31D078EF6D";
    public static AttachXMLDocument: string = "4AAE5631-B7A0-4659-95DF-C7FAAB6B680A";
    public static StoreSignatureCertificate: string = "1DA95824-EE7F-4DA5-8E3D-48322B8F2D88";
    public static DeleteOriginalDocument: string = "05166F76-B258-476C-908A-2CC76CACC89E";
    public static StoreOriginalDocument: string = "24EC9EA9-BCD0-40C6-BF3F-F603541AD285";
    public static EnableFileReview: string = "A1921EC5-2708-4163-9B04-518611042F57";
    public static EnableAutoFillTextControls: string = "D300901A-F359-455A-BD7E-F01C575478E1";
    public static FinalContractConversion: string = "B35AC153-4DDE-4E98-91A5-5492E1762AAC";
    public static AttachSignedPdf: string = "11F8B179-0D81-4E2E-B697-580F7AE3199A";
    public static EnableCcOptions: string = "00D85A80-EA48-4A0E-BD36-5F3C699FBB8C";
    public static ElectronicSignatureIndication: string = "BFA01C85-A0F8-4341-AFDC-CCCE660C1D30";
    public static EnableRecipientLanguage: string = "68fd5876-629c-49ed-ac72-0468dfeb0dbf";
    public static EnableSendingMessagesToMobile: string = "C9E845A4-ABF5-4D37-B82B-68C87015308D";
    public static TimeZone: string = "7D5EBF97-49DD-4A70-8D2B-620EE152EDA9";
    public static PrivateMode: string = "1E29E18D-44C0-49F8-A92E-9910E4525927";
    public static DataMasking: string = "D6B591B8-6DE3-4460-B48A-1A7C42FFB764";
    public static DeleteData: string = "ECAA2CD8-358C-4637-80EB-5027140B083F";
    public static CompanySettings: string = "DC9184CC-A094-4768-8E25-F33647007490";
    public static SignatureCapture: string = "E67C9564-988D-4A6E-A4A4-F75C7A06D448";
    public static TypeSignature: string = "72C42EB0-F203-4456-83DB-14EDB767DBDC";
    public static HandDrawn: string = "191DDB81-C976-4381-B40B-B76D42A506BE";
    public static UploadSignature: string = "F09ABA05-C4BB-4383-B382-30AE01F812A4";
    public static HeaderFooterSettings: string = "3A271DB4-C2F1-43A8-81E7-A7EF5BC220B7";
    public static DeleteSignedContracts: string = "6B4027A8-072C-4529-BB82-02315F27CACE";
    public static ReferenceCode: string = "C9CC6C6E-6F8E-4A88-8549-C95BD6EFCA2F";
    public static FormFieldAlignment: string = "A2AB70D9-CBC9-4F21-A110-B46DEC8D4F77";
    public static EnvelopesPostSigningUrl: string = "0162FD3C-957D-410F-97F4-497103BA2A8B";
    public static SetAsDefault: string = "5F1D3C69-7092-4C44-898A-9E77A413524D";
    public static StoreEmailBody: string = "2E3D2D8A-89E7-4C11-A244-E553E66F26E0";
    public static DeleteEmailBody: string = "BFB9B9BF-D468-49C4-BC41-A3D0447F0AE4";
    public static SignatureControlDefaultRequired: string = "DC0D8411-06EB-4F0D-8D1D-F98F2CB6C78A";
    public static SendingConfirmationEmail: string = "13AF4ECE-C831-4E7B-A89D-518D58F0CA8F";
    public static TermsOfService: string = "626C7F9E-B167-424F-B195-43C99C4BCC3E";
    public static TermsServiceOncertificate: string = "D296AC02-7D15-48C8-830E-36BF5B24ED91";
    public static DocumentsCombinedInOneEnvelope: string = "F154A9DD-2DE9-4ED4-821F-FFED4EC90D62";
    public static SendInvitationEmailToSigner: string = "D9C0BCDA-B359-4A67-97D7-128BAB99A523";
    public static SigningCertificatePaperSize: string = "025BBD2F-4048-4E45-ABE7-9E2069C471D1";
    public static SignatureRequestReplyAddress: string = "03301BE2-DAE7-49DE-851E-44E441BEAE38";
    public static SenderEmail: string = "B698C905-7766-4DCD-B2A2-C5C8F333AAA0";
    public static StampWatermarkSetting: string = "45AD3108-2229-4F74-BA93-077088F0D585";
    public static SelectDigitalCertificate: string = "E566D1E3-D9B3-4529-A739-41BB1C3346A7";
    public static Auto: string = "A79AAC55-CD5C-4B18-8CDD-5A86F5AA0409";
    public static Manual: string = "83A42615-9388-4849-A5C0-06474E5E5AC8";
    public static Weeks: string = "e3f79a7b-469d-456a-9a1e-76f2ea11af35";
    public static Days: string = "096DD340-6003-4770-90CB-DAC0A0001541";

}