import { Component, OnInit, Output,HostListener  } from '@angular/core';
import { CommonConstants } from '../../constants/common-constants';
import { Location } from '@angular/common';
import { CommonService } from '../../services/common.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'app-view-pdf',
	templateUrl: './view-pdf.component.html',
	styleUrls: ['./view-pdf.component.scss']
})
export class ViewPdfComponent implements OnInit {
	commonConstants = CommonConstants;
	@Output() receipentListArr: any = this.commonConstants.ReceipentListArr;
	@Output() receipentColorArr: any = this.commonConstants.ReceipentColorArr;
	@Output() recipientsObj: any = {};


	showLoader: boolean = false;
	selectedRecipentId: string = '';
	selectedPanelRecipentId: string = '';
	selectedRecipentName: string = '';
	selectedRecipentColor: string = '';
	selectedPanelRecipentName: string = '';
	selectedPanelRecipentColor: string = '';

	imagesArr: any[] = [];
	bodyClick: boolean = true;
	private ngUnsubscribe = new Subject<void>();

	constructor(private location: Location, private commonService: CommonService) { }

	ngOnInit(): void {
		this.selectedRecipentName = this.receipentListArr[0].name;
		this.selectedRecipentColor = this.receipentColorArr[0];
		this.selectedPanelRecipentName = this.receipentListArr[0].name;
		this.selectedPanelRecipentColor = this.receipentColorArr[0];
		this.selectedRecipentId = this.receipentListArr[0].id;
		this.selectedPanelRecipentId = this.receipentListArr[0].id;
		this.recipientsObj = {
			selectedRecipentName: this.selectedRecipentName,
			selectedRecipentColor: this.selectedRecipentColor
		}
		this.imagesArr = [
			{
				path: '../assets/images/1.jpg',
				isActive: false,
				id: 1
			}

			, {
				path: '../assets/images/2.jpg',
				isActive: false,
				id: 2
			},
			{
				path: '../assets/images/3.jpg',
				isActive: false,
				id: 3
			},
			{
				path: '../assets/images/4.jpg',
				isActive: false,
				id: 4
			},
			{
				path: '../assets/images/5.jpg',
				isActive: false,
				id: 5
			},
			{
				path: '../assets/images/6.jpg',
				isActive: false,
				id: 6
			}
		];
		// Getting recipent obj
		this.commonService.callOnRecipentChange$
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((data: any) => {
				this.onRecipentChange(data);
			});
	}

	goBackToPrevPage(): void {
		this.location.back();
	}

	@HostListener('window:load')
	onLoad() {
		let element: any = (document.getElementById('body-view')) as HTMLSelectElement;
		element.classList.add("mystyle");
	}

	onRecipentChange(recipentObj: any) {
		this.selectedRecipentName = recipentObj.recipent.name;
		this.selectedRecipentId = recipentObj.recipent.id;
		this.selectedRecipentColor = recipentObj.selectedColor;
		this.recipientsObj = {
			selectedRecipentName: this.selectedRecipentName,
			selectedRecipentColor: this.selectedRecipentColor
		}
	}

}
