import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, EventEmitter, Directive, HostBinding, HostListener, ComponentRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ControlPropertyService } from 'src/app/services/control.properties.service';
import { CommonConstants, Control } from '../../constants/common-constants';
import { ControlComponent } from '../control/control.component';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';

const enum Status {
    OFF = 0,
    RESIZE = 1,
    MOVE = 2
}

@Component({
    selector: 'app-resizable-draggable',
    templateUrl: './resizable-draggable.component.html',
    styleUrls: ['./resizable-draggable.component.scss']
})
export class ResizableDraggableComponent implements OnInit, AfterViewInit {
    commonConstants = CommonConstants;
    @Input('width') public width: number = 0;
    @Input('height') public height: number = 0;
    @Input('left') public left: number = 0;
    @Input('top') public top: number = 0;
    @Input('bottom') public bottom: number = 0;
    @ViewChild('box') public box: ElementRef<any>;
    @Input('ctrlObj') public ctrlObj: any = {};
    @Input('minWidth') public minWidth: number = 0;
    @Input('minHeight') public minHeight: number = 0;

    private boxPosition!: { left: number, top: number };
    private containerPos!: { left: number, top: number, right: number, bottom: number };
    public mouse!: { x: number, y: number }
    public status: Status = Status.OFF;
    private mouseClick!: { x: number, y: number, left: number, top: number }
    private dynamicComponentRefs: Map<string, ComponentRef<ControlComponent>> = new Map();

    borderWidthDiff:any = 10;
    borderHeightDiff:any = 7;
    containerWidth: any = 0;
    containerHeight: any = 0;

    recipientsObj: any;
    selectedRecipentName: string = '';
    selectedRecipentColor: string = '';
    receipentColorArr: any = [];
    receipentListArr: any = [];
    arrCopyPasteCtrl: any = [];
    arrUndoRedoCtrl: any = [];

    changeText: any;
    isHidden = false;
    showDepencieIcons: boolean = false;
    isActive: boolean = false;
    constructor(private commonService: CommonService, private controlPropertyService: ControlPropertyService, private toastrService: ToastrService, private authService: AuthService) {
        this.box = new ElementRef(null);
    }

    ngOnInit() {
        if (this.ctrlObj) {
            this.width = this.ctrlObj.width || 0;
            this.height = this.ctrlObj.height || 0;
            this.left = this.ctrlObj.left || 0;
            this.top = this.ctrlObj.top || 0;
            this.minWidth = this.ctrlObj.minWidth || 0;
            this.minHeight = this.ctrlObj?.minHeight || 0;
            this.recipientsObj = this.ctrlObj.recipientsObj;
        }

        const recipientDetails = this.authService.getRecipientDetails();
        if (recipientDetails) {
            try {
                const parsedRecipientDetails = JSON.parse(recipientDetails);
                this.receipentListArr = parsedRecipientDetails;
            }
            catch (error) {
                console.error('Error parsing recipient details:', error);
            }
        }

        const recipientColorDetails = this.authService.getRecipientColorDetails();
        if (recipientColorDetails) {
            try {
                const parsedRecipientColorDetails = JSON.parse(recipientColorDetails);
                this.receipentColorArr = parsedRecipientColorDetails;
            }
            catch (error) {
                console.error('Error parsing recipient color details:', error);
            }
        }
    }

    ngAfterViewInit() {
        let imageControlEle: any = document.getElementById('imgControl_'+parseInt(this.ctrlObj.pageNo));
        if(imageControlEle){
            this.containerHeight = (imageControlEle.offsetHeight - this.borderHeightDiff);
            this.containerWidth = (imageControlEle.offsetWidth - this.borderWidthDiff);
        }
        this.loadBox();
        this.loadContainer();
    }

    private loadBox() {
        if (this.box && this.box.nativeElement) {
            const { left, top } = this.box?.nativeElement.getBoundingClientRect();
            this.boxPosition = { left, top };
        }
    }

    private loadContainer() {
        const left = this.boxPosition.left - this.left;
        const top = this.boxPosition.top - this.top;
        const right = left + this.containerWidth;
        const bottom = top + this.containerHeight;
        this.containerPos = { left, top, right, bottom };
    }

    setStatus(event: MouseEvent, status: number) {
        if (status === 1) event.stopPropagation();
        else if (status === 2) this.mouseClick = { x: event.clientX, y: event.clientY, left: this.left, top: this.top };
        else this.loadBox();
        this.status = status;
    }

    @HostListener('window:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (this.showHideContextMenu() == true) {
            let resizableCurrentControl: any = document.getElementById("resizable_" + this.ctrlObj.id);

            if (resizableCurrentControl) {
                let leftAndTopPositions: any = this.controlPropertyService.getElePositions(resizableCurrentControl);
                this.ctrlObj.left = leftAndTopPositions.Left;
                this.ctrlObj.top = leftAndTopPositions.Top;
                this.left = leftAndTopPositions.Left;
                this.top = leftAndTopPositions.Top;

            }

            this.mouse = { x: event.clientX, y: event.clientY };

            if (this.status === Status.RESIZE) {
                this.resize();
            }
            else if (this.status === Status.MOVE) {

                let selectionBoxs: any = document.querySelectorAll('.selection-box');
                Array.from(selectionBoxs).forEach((ele: any) => {
                    let selectionBox: any = document.getElementById(ele.id);
                    if (selectionBox) {
                        selectionBox.style.display = 'none';
                    }
                })

                this.move();
            }
            this.updateDependenciesToolTips(resizableCurrentControl);
        }
    }

    @HostListener('mouseover', ['$event'])
    onMouseOver(event: MouseEvent) {

        this.showDepencieIcons = true;
        let ctrlEle: any = document.getElementById(this.ctrlObj.id);

        if (ctrlEle) {
            ctrlEle.style.zIndex = 999;
            this.hideSelectionBox();
            let resizableCurrentControl: any = document.getElementById("resizable_" + this.ctrlObj.id);
            if (resizableCurrentControl) {
                let recId: any = resizableCurrentControl.getAttribute('data-rcptid');
                this.selectedRecipentName = this.controlPropertyService.getRecipientName(recId);
                this.selectedRecipentColor = resizableCurrentControl.getAttribute('data-recp-color');

                this.updateDependenciesToolTips(resizableCurrentControl);
                // let isRequired: any = resizableCurrentControl.getAttribute('data-selected');
                // let guid: any = resizableCurrentControl.getAttribute('data-guid');
                // setTimeout(() => {
                //     if (guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                //         let addDependencyIcon: any = document.getElementById("addDependencyIcon");
                //         if (isRequired == true || isRequired == "true") addDependencyIcon.classList.add('disabledCtrl');
                //         else addDependencyIcon.classList.remove('disabledCtrl');
                //         resizableCurrentControl.getAttribute('data-guid');
                //     }
                // }, 10);

            }
        }
    }

    @HostListener('mouseleave', ['$event'])
    onMouseLeave(event: MouseEvent) {
        this.showDepencieIcons = false;
        let ctrlEle: any = document.getElementById(this.ctrlObj.id);
        if (ctrlEle) {
            ctrlEle.style.zIndex = 0;
        }
    }

    @HostListener('click') toActive() {
        this.isActive = !this.isActive;
    }

    private resize() {
        let imgWidth: any = this.containerWidth;
        let imgHeight: any = this.containerHeight;
        let scale: any = this.getTransformScale();

        let maxControlWidth:any = imgWidth - this.left;
        if (this.ctrlObj.controlName !== 'Checkbox' && this.ctrlObj.controlName !== 'Radio') {
            if (this.ctrlObj.controlName === 'Text' || this.ctrlObj.controlName === 'Signature' || this.ctrlObj.controlName === 'NewInitials' || this.ctrlObj.controlName === 'Label') {
                //horizontal and vertical drag
                let ctrlEle: any = document.getElementById('resizable_' + this.ctrlObj.id);
                if (this.ctrlObj.controlName === 'Text') {
                    let getTextType: any = ctrlEle.getAttribute('data-validation-type');
                    if (getTextType !== 'SSN' && getTextType !== 'Zip' && getTextType !== 'Date') {
                        this.commonResizeControl(scale);
                    }
                }
                else {
                    this.commonResizeControl(scale);
                }

                if (this.ctrlObj.controlName === 'Text') {
                    this.updateNameOrTextLength();
                }

                if (this.ctrlObj.controlName === 'Label') {
                    this.labelAutoAlignment(this.ctrlObj);
                }

            } else {
                //only horizontal drag
                let newWidth = (this.mouse.x - this.boxPosition.left) / scale;
                if ((this.left + this.width) <= imgWidth && newWidth >= 0) {
                    //this.width = Number(this.mouse.x > this.boxPosition.left) ? (this.mouse.x - this.boxPosition.left) / scale : 0;
                    this.width = Math.min(maxControlWidth, newWidth);
                } else if ((this.left + this.width) > imgWidth) {
                    setTimeout(() => {
                        let width: any = (this.left + this.width);
                        let diffWidth: any = width - imgWidth;
                        this.width = this.width - diffWidth;
                    }, 200);
                } else {
                    if (this.resizeCondMeet()) {
                        this.width = Number(this.mouse.x > this.boxPosition.left) ? (this.mouse.x - this.boxPosition.left) / scale : 0;
                    }
                }

                if (this.ctrlObj.controlName === 'Name' || this.ctrlObj.controlName === 'Title') {
                    this.updateNameOrTextLength();
                }
            }
        }
    }

    private commonResizeControl(scale: any) {
        let imgWidth: any = this.containerWidth;
        let imgHeight: any = this.containerHeight;

        let maxControlWidth:any = imgWidth - this.left;
        let newWidth = (this.mouse.x - this.boxPosition.left) / scale;

        if ((this.left + this.width) <= imgWidth && newWidth >= 0) {
            // this.width = Number(this.mouse.x > this.boxPosition.left) ? (this.mouse.x - this.boxPosition.left) / scale : 0;
            this.width = Math.min(maxControlWidth, newWidth);
        }
        else if ((this.left + this.width) > imgWidth) {
            setTimeout(() => {
                let width: any = (this.left + this.width);
                let diffWidth: any = width - imgWidth;
                this.width = this.width - diffWidth;
            }, 200);
        }
        else {
            if (this.resizeCondMeet()) {
                this.width = Number(this.mouse.x > this.boxPosition.left) ? (this.mouse.x - this.boxPosition.left) / scale : 0;
                this.height = Number(this.mouse.y > this.boxPosition.top) ? (this.mouse.y - this.boxPosition.top) / scale : 0;
            }
        }
        if ((this.top + this.height) <= (imgHeight - 5)) {
            this.height = Number(this.mouse.y > this.boxPosition.top) ? (this.mouse.y - this.boxPosition.top) / scale : 0;
        } else {
            if (this.resizeCondMeet()) {
                this.width = Number(this.mouse.x > this.boxPosition.left) ? (this.mouse.x - this.boxPosition.left) / scale : 0;
                this.height = Number(this.mouse.y > this.boxPosition.top) ? (this.mouse.y - this.boxPosition.top) / scale : 0;
            }
        }
    }

    private resizeCondMeet() {
        return (this.mouse.x < this.containerPos.right && this.mouse.y < this.containerPos.bottom);
    }

    private move() {
        // const newLeft = this.mouseClick.left + (this.mouse.x - this.mouseClick.x);
        // const newTop = this.mouseClick.top + (this.mouse.y - this.mouseClick.y);

        let scale: any = this.getTransformScale();

        // Adjust mouse movement by scale factor
        const adjustedXMovement = (this.mouse.x - this.mouseClick.x) / scale;
        const adjustedYMovement = (this.mouse.y - this.mouseClick.y) / scale;

        // Calculate new position with adjusted movement
        const newLeft = this.mouseClick.left + adjustedXMovement;
        const newTop = this.mouseClick.top + adjustedYMovement;

        let imageControlEle: any = document.getElementById('imgControl_'+parseInt(this.ctrlObj.pageNo));
        this.hideSelectionBox();
        if(imageControlEle){
            this.containerHeight = (imageControlEle.offsetHeight - this.borderHeightDiff);
            this.containerWidth = (imageControlEle.offsetWidth - this.borderWidthDiff);
        }

        const containerWidth = this.containerWidth; // Replace with your actual container width
        const containerHeight = this.ctrlObj.controlName === 'Signature' ? (this.containerHeight - 2) : this.containerHeight; // Replace with your actual container height

        //current element width and height
        let ctrlEle: any = document.getElementById('resizable_' + this.ctrlObj.id);
        let ctrlEleWidth: any = parseInt(ctrlEle.style.width.split('px')[0]);
        let ctrlEleHeight: any = parseInt(ctrlEle.style.height.split('px')[0]);

        // Calculate boundaries
        const leftBoundary = 10;
        const topBoundary = 8;
        const rightBoundary = (containerWidth - ctrlEleWidth);
        const bottomBoundary = (containerHeight - ctrlEleHeight);

        // Apply boundaries
        this.left = Math.max(leftBoundary, Math.min(newLeft, rightBoundary));
        this.top = Math.max(topBoundary, Math.min(newTop, bottomBoundary));
    }

    onClickDroppedControl(ctrlObj: any) {
        setTimeout(() => {
            localStorage.setItem('CurrentClickedDroppedControl', ctrlObj.id);
            let controlTooltipEle: any = document.getElementById('controlInputTooltip');
            let controlEle: any = (<HTMLInputElement>document.getElementById("resizable_" + ctrlObj.id));
            if (controlEle) {
                //checking tooltip field
                if (controlTooltipEle) {
                    controlTooltipEle.value = '';
                }
                let isTooltipValue: any = controlEle.getAttribute('data-customtooltip');
                if (isTooltipValue !== '') {
                    if (controlTooltipEle) {
                        controlTooltipEle.value = isTooltipValue;
                    }
                }

                //checking required field
                let isRequired: any = controlEle.getAttribute('data-selected');
                let chkRequiredSelectedEle: any = document.getElementById('chkRequiredSelected');
                if (chkRequiredSelectedEle) {
                    chkRequiredSelectedEle.checked = false;
                    if (isRequired === 'true' || isRequired === true) {
                        chkRequiredSelectedEle.checked = isRequired;
                    }
                }

                //check Name/text control length fields
                let isLength: any = controlEle.getAttribute('maxlengthallowed');
                let nameorTextInputLength: any = (<HTMLInputElement>document.getElementById('nameInputLength'));
                if (ctrlObj.controlName === 'Text') {
                    nameorTextInputLength = (<HTMLInputElement>document.getElementById('textInputLength'));
                }
                if (isLength) {
                    if (nameorTextInputLength) {
                        nameorTextInputLength.value = isLength;
                    }
                }

                // check Name/text control is-fixed-width
                let isFixedWidth: any = controlEle.getAttribute('data-isfixedwidth');
                let isfixedwidthEle: any = (<HTMLInputElement>document.getElementById('isFixedWidth'));
                if (isfixedwidthEle) {
                    isfixedwidthEle.checked = false;
                    if (isFixedWidth === 'true' || isFixedWidth === true) {
                        isfixedwidthEle.checked = isFixedWidth;
                    }
                }

                this.checkIsFixedWidth(ctrlObj, isFixedWidth);
            }
        }, 300);
        this.commonService.callParentShowControlFields(ctrlObj);
    }

    getControllingStyle(ctrlname: any) {
        if (ctrlname == "Text" || ctrlname == 'Name') {
            return 'overflowClass';
        }
        else return '';
    }

    getControlOriginalTitle(recipientId: any, currentSelectedControl: any) {
        let resizableCurrentControl: any = document.getElementById("resizable_" + currentSelectedControl);
        if (resizableCurrentControl) {
            recipientId = resizableCurrentControl.getAttribute('data-rcptid');
        }
        return this.controlPropertyService.getControlOriginalTitle(recipientId, currentSelectedControl);
    }

    onRecipentSelect(event: any, recipient: any, selectedColor: any, ctrlId: any) {
        this.selectedRecipentName = recipient.name;
        this.selectedRecipentColor = selectedColor;
        let recipent: any = { name: recipient.name, id: recipient.id };
        let recipentObj: any = { recipent: recipent, selectedColor: selectedColor };
        this.commonService.callOnMouseOverRecipentChange(recipentObj);
    }

    deleteControl(event: any) {
        this.commonService.callRemoveControlComponent(event);
    }

    openDependencyPopup(event: any, ctrlObj: any) {
        let resizableCurrentControl: any = document.getElementById("resizable_" + ctrlObj.id);
        if (resizableCurrentControl) {
            let isRequired: any = resizableCurrentControl.getAttribute('data-selected');
            let guid: any = resizableCurrentControl.getAttribute('data-guid');
            if (guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                if (isRequired != true && isRequired != "true") this.commonService.callOpenDependencyPopup(event);
            }
            else {
                this.commonService.callOpenDependencyPopup(event);
            }
        }
    }

    updateNameOrTextLength() {
        this.commonService.setTextOrNameAreaSetting(this.ctrlObj);
    }

    createDuplicateControl(event: any, ctrlObj: any) {
        if (ctrlObj.className == "dropdown") {
            let selectDropdownControlField: any = (document.getElementById('spn' + ctrlObj.id)) as HTMLSelectElement;
            if (selectDropdownControlField && selectDropdownControlField.options && selectDropdownControlField.options.length == 0) {
                this.toastrService.warning('No option added for dropdown.', 'Warning');
                return false;
            }
        }

        let arrCopyPasteCtrl: any = localStorage.getItem('ArrCopyPasteCtrl');
        let childCtrlObj: any = ctrlObj;
        if (arrCopyPasteCtrl) {
            this.arrCopyPasteCtrl = JSON.parse(arrCopyPasteCtrl);
            if (this.arrCopyPasteCtrl && this.arrCopyPasteCtrl.length > 0) {
                if (this.arrCopyPasteCtrl[0].ParentControl.dataGuid == ctrlObj.dataGuid) {
                    childCtrlObj = this.arrCopyPasteCtrl[0].Control;
                    this.arrCopyPasteCtrl = [];
                    this.arrCopyPasteCtrl.push({ ParentControl: ctrlObj, Control: childCtrlObj, Action: 'P' });
                    localStorage.setItem('ArrCopyPasteCtrl', JSON.stringify(this.arrCopyPasteCtrl));
                }
                else {
                    this.arrUndoRedoCtrl = [];
                    localStorage.setItem('ArrUndoRedoCtrl', JSON.stringify(this.arrUndoRedoCtrl));
                }
            }
        }

        this.commonService.callOpenCreateDuplicateControl(childCtrlObj); return true;
    }

    disableDependencyIcon(ctrlObj: any) {
        let resizableCurrentControl: any = document.getElementById("resizable_" + this.ctrlObj.id);
        if (resizableCurrentControl) {
            let recId: any = resizableCurrentControl.getAttribute('data-rcptid');
            this.selectedRecipentName = this.controlPropertyService.getRecipientName(recId);
            let isRequired: any = resizableCurrentControl.getAttribute('data-selected');
            let guid: any = resizableCurrentControl.getAttribute('data-guid');
            if (guid.toLowerCase() == Control.Checkbox.toLowerCase()) {
                if (isRequired == true || isRequired == "true") return "fa fa-puzzle-piece font21 text-black top-n2 px-5p disabledCtrl";
                else return "fa fa-puzzle-piece font21 text-black top-n2 px-5p pointer";
            }
            else return "fa fa-puzzle-piece font21 text-black top-n2 px-5p pointer";
        }
        return "fa fa-puzzle-piece font21 text-black top-n2 px-5p disabledCtrl";
    }

    labelAutoAlignment(ctrlObj: any) {
        let currentLabel: any = document.getElementById(ctrlObj.id);
        if (currentLabel) {
            if (currentLabel) {
                let currentLabelSpn: any = document.getElementById('spn' + ctrlObj.id);
                let currentLabelResize: any = document.getElementById('resizable_' + ctrlObj.id);
                let labelSpanHeight = currentLabelSpn.offsetHeight;
                let labelSpanWidth = currentLabelSpn.offsetWidth;
                let labelDivWidth = parseFloat(currentLabelResize.style.width.split('px')[0]);
                let labelDivHeight = parseFloat(currentLabelResize.style.height.split('px')[0]);
                if (labelSpanHeight > labelDivHeight) {
                    setTimeout(() => {
                        currentLabelResize.style.height = (labelSpanHeight + 3) + 'px';
                    }, 200);
                }

                if (labelSpanWidth > labelDivWidth) {
                    setTimeout(() => {
                        currentLabelResize.style.width = (labelSpanWidth + 3) + 'px';
                    }, 200);
                }
            }
        }
    }

    getTransformScale() {
        const element = document.querySelector('.scroll-style');
        if (!element) return { scaleX: 1, scaleY: 1 }; // Default scale values if element not found

        const style = window.getComputedStyle(element);
        const transform = style.transform; //|| style.webkitTransform || style.mozTransform;

        let mat = transform.match(/^matrix\((.+)\)$/);
        let scaleX, scaleY;

        if (mat) {
            const values = mat[1].split(', ').map(parseFloat);
            scaleX = values[0];
            scaleY = values[3];
        } else {
            scaleX = 1; // No scaling applied, default to 1
            scaleY = 1; // No scaling applied, default to 1
        }

        return scaleX;
    }

    checkIsFixedWidth(ctrlObj: any, isFixedWidth: any) {
        let hideFixedwidthCheckbox: any = this.controlPropertyService.getHideFixedwidthCheckbox();
        let spnCurrentSelectedControl: any = (<HTMLInputElement>document.getElementById('resizable_' + ctrlObj.id));
        let dataValidationType: any = spnCurrentSelectedControl.getAttribute('data-validation-type');
        const controlName = ctrlObj.controlName.toLowerCase();
        const inputLengthId = controlName + 'InputLength';
        const charInputLimitId = controlName + 'CharInputLimit';
        let inputLength: any = document.getElementById(inputLengthId);
        let charInputLimit: any = document.getElementById(charInputLimitId);
        if (inputLength) {
            inputLength.removeAttribute('disabled', true);
        }
        if (isFixedWidth === 'false' || isFixedWidth === false) {
            if (charInputLimit) {
                if (controlName === 'text' && dataValidationType && (dataValidationType.toLowerCase() === 'ssn' || dataValidationType.toLowerCase() === 'zip' || dataValidationType.toLowerCase() === 'date')) {
                    this.controlPropertyService.hideCtrlCharLimitInput(charInputLimit);
                } else {
                    this.controlPropertyService.showCtrlCharLimitInput(charInputLimit);
                    if (spnCurrentSelectedControl) {
                        let mxCharVal = spnCurrentSelectedControl.getAttribute('data-maxcharlimit');
                        if (mxCharVal) {
                            if (controlName === 'text' && dataValidationType && (dataValidationType.toLowerCase() === 'email')) {
                                if (mxCharVal <= 100) {
                                    charInputLimit.value = mxCharVal;
                                } else {
                                    charInputLimit.value = '100';
                                }
                            } else {
                                charInputLimit.value = mxCharVal;
                            }
                        }
                    }
                    let isFixedWidthCont: any = document.getElementById('isFixedWidthTextContainer');
                    if (isFixedWidthCont) {
                        isFixedWidthCont.style.display = 'block';
                    }
                }
            }
            if (controlName === 'text' && dataValidationType && (dataValidationType.toLowerCase() === 'ssn' || dataValidationType.toLowerCase() === 'zip' || dataValidationType.toLowerCase() === 'date')) {
                if (inputLength) {
                    this.controlPropertyService.showCtrlLengthInput(inputLength);
                    setTimeout(() => {
                        inputLength.setAttribute('disabled', true);
                    }, 0);
                    let isFixedWidthCont: any = document.getElementById('isFixedWidthTextContainer');
                    if (isFixedWidthCont) {
                        isFixedWidthCont.style.display = 'none';
                    }
                }
            } else {
                if (inputLength) {
                    this.controlPropertyService.hideCtrlLengthInput(inputLength);
                }
            }
        } else {
            if (inputLength) {
                if (controlName === 'text' && dataValidationType && (dataValidationType.toLowerCase() === 'ssn' || dataValidationType.toLowerCase() === 'zip' || dataValidationType.toLowerCase() === 'date' || dataValidationType.toLowerCase() === 'email')) {
                    setTimeout(() => {
                        inputLength.setAttribute('disabled', true);
                    }, 0);
                }
                this.controlPropertyService.showCtrlLengthInput(inputLength);
                if (controlName === 'title') {
                    let maxlengthallowedValue: any = spnCurrentSelectedControl.getAttribute('maxlengthallowed');
                    if (maxlengthallowedValue) {
                        inputLength.value = maxlengthallowedValue;
                    } else {
                        inputLength.value = '20';
                    }
                }
            }
            if (charInputLimit) {
                this.controlPropertyService.hideCtrlCharLimitInput(charInputLimit);
            }
            if (hideFixedwidthCheckbox === true) {
                let isFixedWidthCont: any = document.getElementById('isFixedWidthTextContainer');
                if (isFixedWidthCont) {
                    isFixedWidthCont.style.display = 'none';
                }
            } else {
                let isFixedWidthCont: any = document.getElementById('isFixedWidthTextContainer');
                if (isFixedWidthCont) {
                    isFixedWidthCont.style.display = 'block';
                }
            }
        }
    }

    showHideContextMenu() {
        let isEnvelopeEditable: any = localStorage.getItem("isEnvelopeEditable");
        if (isEnvelopeEditable == "true") return true;
        else return false;
    }

    getIsEnvelopeEditable() {
        let isEnvelopeEditable: any = localStorage.getItem("isEnvelopeEditable");
        if (isEnvelopeEditable == "true") return { 'pointer-events': 'normal' };
        else return { 'pointer-events': 'none' };
    }

    hideSelectionBox(){
        let selectionBox:any = document.getElementsByClassName('selection-box');
        if(selectionBox && selectionBox.length){
            Array.from(document.getElementsByClassName('selection-box') as HTMLCollectionOf<HTMLElement>)
            .forEach((sbox: any, index: any) => {
                sbox.style.display = 'none';
            })
        }
    }

    //hover the dropped control, adjusting to left, right and top positions for tooltips elements
    updateDependenciesToolTips(resizableCurrentControl:any){
        let ctrlPositions:any = this.controlPropertyService.getElePositions(resizableCurrentControl);
        let containerWidthVal:any = this.containerWidth/2;
        let containerHeightVal:any = 50;
        if(containerWidthVal > ctrlPositions.Left){
            //params are control element, left and right
            this.updateLeftAndRightValues(resizableCurrentControl, '-3px', 'auto');
        }
        else if(containerWidthVal < ctrlPositions.Left){
            this.updateLeftAndRightValues(resizableCurrentControl, 'auto', '-3px');
        }

        if(containerHeightVal > ctrlPositions.Top){
            let height:any = resizableCurrentControl.style.height.split('px')[0];
            if(resizableCurrentControl && resizableCurrentControl.childNodes[0] && resizableCurrentControl.childNodes[0].style){
                resizableCurrentControl.childNodes[0].style.top = parseInt(height)+4+'px';
            }
        }
        else if(containerHeightVal < ctrlPositions.Top){
            if(resizableCurrentControl && resizableCurrentControl.childNodes[0] && resizableCurrentControl.childNodes[0].style){
                resizableCurrentControl.childNodes[0].style.top = '-40px';
            }
        }
    }

    updateLeftAndRightValues(resizableCurrentControl:any, left:any, right:any){
        if(resizableCurrentControl && resizableCurrentControl.childNodes[0] && resizableCurrentControl.childNodes[0].style){
            resizableCurrentControl.childNodes[0].style.left = left;
            resizableCurrentControl.childNodes[0].style.right = right;
        }
    }
}
