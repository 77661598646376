import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiUrlConfig, NavigateUrlConfig } from 'src/app/constants/common-apiurls';
import { APIService } from './services/api.service';
import { AuthService } from './services/auth.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	idleTimer: any;
	idleMaxTimerCount: any = environment.sessionTimeOut;
	idleCounter: any = environment.sessionTimeOut;
	sessionTimeoutVal: any = (Math.floor(this.idleMaxTimerCount / 60)).toString() + ' M';
	popupShowCounter: any = environment.sessionInActivity;
	AllowSignersDownloadFinalContract: boolean = false;
	sessionTimeOutMinutes: any = (Math.floor(this.idleMaxTimerCount / 60)).toString();
	idleTimerMinsValue: any = (Math.floor(this.idleMaxTimerCount / 60)).toString();
	idleTimerSecondsValue: any = '00';
	timer = 0;
	timerWorker: any = new Worker('../../../assets/workers/timer-worker.js');
	showSessionExpireModalDailog: string = 'none';
	showLoader: boolean = false;
	isSessionPopupOpen: boolean = false;
	noSignMeOutBtnClicked: boolean = false;

	constructor(private apiService: APIService, private authService: AuthService,) {
		//check idle time
		this.sessionTimeoutVal = (Math.floor(this.idleMaxTimerCount / 60)).toString() + " M";
		this.handleIdleTime();

		// Reset the idle timer when the user interacts.
		document.addEventListener('mousemove', () => {
			if (this.showSessionExpireModalDailog === 'none') {
				//reset Timer
				this.yesKeepMeSignedIn('move');
			}
		});

		// let timeOutValue: any = 6000; //120000 (It means 2 mins)
		// setTimeout(() => {
		// 	this.getRefreshToken();
		// }, timeOutValue);

		setInterval(() => {
			console.log("RefreshToken:" + new Date().toLocaleString());
			this.getRefreshToken();
		}, 120000);
	}

	handleIdleTime() {
		let sessionInActivity: any = environment.sessionInActivity;
		this.timerWorker.postMessage({ action: 'startTimer', value: (this.idleMaxTimerCount + sessionInActivity) });

		// Listen for messages from the Web Worker
		if (this.timerWorker && this.noSignMeOutBtnClicked === false) {
			this.timerWorker.onmessage = (e: any) => {
				this.timer = e.data.value;
				// console.log('11111111', this.timer);
				if (this.timer && this.noSignMeOutBtnClicked === false) {
					if (this.timer === -1 || this.timer < 0) {
						this.showSessionExpireModalDailog = 'none';
						this.isSessionPopupOpen = false;
						this.timerWorker.postMessage({ action: 'stopTimer' });
						this.sessionTimeoutVal = '';
						this.noSignMeOut();
					}
					else if (this.timer <= this.popupShowCounter) {
						this.showSessionExpireModalDailog = 'block';
						this.isSessionPopupOpen = true;
					}

					if (this.timer < 0) {
						this.showSessionExpireModalDailog = 'none';
						this.isSessionPopupOpen = false;
					}
					else if (this.timer >= this.idleMaxTimerCount) {
						this.sessionTimeoutVal = (Math.floor(this.idleMaxTimerCount / 60)).toString() + ' M';
						this.isSessionPopupOpen = false;
					}
					else {
						this.idleTimerMinsValue = (Math.floor(this.timer / 60)).toString();
						this.idleTimerSecondsValue = this.addZero(Math.floor((this.timer % 60))).toString();
						this.sessionTimeoutVal = (Math.floor(this.timer / 60)).toString() + ':' + this.addZero(Math.floor((this.timer % 60))).toString() + " M";
						this.isSessionPopupOpen = true;
					}
				}
			};
		}
	}

	addZero(i: any) {
		if (i < 10) {
			i = '0' + i;
		}
		return i;
	}

	yesKeepMeSignedIn(type: any) {
		this.showSessionExpireModalDailog = 'none';
		this.idleTimerMinsValue = (Math.floor(this.idleMaxTimerCount / 60)).toString();
		this.idleTimerSecondsValue = '00';
		this.sessionTimeReset();
		this.resumeTimer();
		if (type == 'clicked') {
			this.isSessionPopupOpen = false;
			this.getRefreshToken();
		}
	}

	noSignMeOut() {
		this.noSignMeOutBtnClicked = true;
		this.showLoader = true;
		this.showSessionExpireModalDailog = 'none';
		this.isSessionPopupOpen = true;
		this.idleTimerMinsValue = (Math.floor(this.idleMaxTimerCount / 60)).toString();
		this.idleTimerSecondsValue = '00';
		this.sessionTimeReset();
		localStorage.clear();
		sessionStorage.clear();
		let rsignURL: any = environment.rsignUrl;
		window.location.href = rsignURL + NavigateUrlConfig.LogOff;
		this.showLoader = false;
	}

	sessionTimeReset() {
		this.sessionTimeoutVal = '';
		this.sessionTimeoutVal = (Math.floor(this.idleMaxTimerCount / 60)).toString() + ' M';
	}

	resumeTimer() {
		// Send a message to the Web Worker to reset the timers
		this.timerWorker.postMessage({ action: 'resetTimer' });
		// Start the timer again
		this.handleIdleTime();
	}

	getRefreshToken() {
		console.log("Is session popup open:" + this.showSessionExpireModalDailog);
		if (this.showSessionExpireModalDailog === 'none' && this.isSessionPopupOpen == false) {
			let getUserDetails: any = this.authService.getUserDetails();
			let refreshTokenModelDetails: any = localStorage.getItem("RefreshTokenModel");
			refreshTokenModelDetails = JSON.parse(refreshTokenModelDetails);
			if (getUserDetails && getUserDetails.emailID) {
				let tokenModelObj: any = {
					EmailAddress: getUserDetails.emailID,
					AuthToken: this.authService.getToken(),
					RefreshToken: refreshTokenModelDetails != null ? refreshTokenModelDetails.refreshToken : "",
					RefreshExpires: refreshTokenModelDetails != null ? refreshTokenModelDetails.refreshExpires : "",
					AccessTokenExpires: refreshTokenModelDetails != null ? refreshTokenModelDetails.accessTokenExpires : "",
					BrowserName: this.getBrowserName(),
					IsFirstTime: refreshTokenModelDetails != null ? false : true
				};

				let isSendEnvelopeClicked:any = localStorage.getItem("isSendEnvelope");
				if(isSendEnvelopeClicked != "1"){
					this.apiService.GetRefreshToken(tokenModelObj).subscribe((data: any) => {
						if (data && data.statusCode == 200) {
							console.log("Calling GetRefreshToken api");
							if (data.userTokenModel && data.userTokenModel != "") {
								localStorage.setItem("RefreshTokenModel", JSON.stringify(data.userTokenModel));
							}
						}
					});
				}
			}
		}
	}

	public getBrowserName() {
		const agent = window.navigator.userAgent.toLowerCase();
		switch (true) {
			case agent.indexOf('edge') > -1:
				return 'edge';
			case agent.indexOf('opr') > -1 && !!(<any>window).opr:
				return 'opera';
			case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
				return 'chrome';
			case agent.indexOf('trident') > -1:
				return 'ie';
			case agent.indexOf('firefox') > -1:
				return 'firefox';
			case agent.indexOf('safari') > -1:
				return 'safari';
			default:
				return 'other';
		}
	}
}
