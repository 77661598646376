import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { APIService } from 'src/app/services/api.service';
import { ApiUrlConfig } from 'src/app/constants/common-apiurls';
import { ControlPropertyService } from 'src/app/services/control.properties.service';
import { Router } from '@angular/router';
import { CommonConstants } from 'src/app/constants/common-constants';

@Component({
	selector: 'app-initialize-prepare',
	templateUrl: './initialize-prepare.component.html',
	styleUrls: ['./initialize-prepare.component.scss']
})
export class InitializePrepareComponent implements OnInit {
	preparePageUrl: any;
	urlToken: any;
	authToken: any;
	showLoader: boolean = false;

	constructor(private router: Router, private authService: AuthService, private toastrService: ToastrService, private apiService: APIService, private controlPropertyService: ControlPropertyService) { }

	ngOnInit(): void {
		this.showLoader = true;
		localStorage.clear();
		sessionStorage.clear();
		this.preparePageUrl = window.location.href;
		this.urlToken = this.preparePageUrl.substring(this.preparePageUrl.indexOf("?") + 1);
		this.authToken = this.urlToken.substring(0, this.urlToken.length - 1);
		localStorage.setItem('PreparePageUrl', this.preparePageUrl);
		localStorage.removeItem("AllImagesArray");
		localStorage.removeItem("AllImagesProcessed");
		localStorage.removeItem("RefreshTokenModel");
		this.validateToken(this.authToken);
	}

	validateToken(authToken: any) {
		this.authService.validateToken(authToken).subscribe(
			(resp: any) => {
				console.log('Token api response:', resp);

				if (resp && resp.statusCodeResult == 200) {
					let authToken: any = resp.authToken;
					let envelopeOrTemplateId: any = resp.envelopeId;
					let prepareType: string = resp.prepareType;
					let userID: any = '';
					let userEmail: any = '';
					if (resp.userViewModel) {
						userID = resp.userViewModel.userID;
						userEmail = resp.userViewModel.emailID;						
						this.authService.setUserDetails(resp.userViewModel);
					}

					this.authService.setEnvelopeTokenDetails(authToken, prepareType, envelopeOrTemplateId);					

					if(resp.translationDetails != null && resp.translationDetails.languageKeyTranslation != null){
						this.authService.setLanguageKeyTranslations(resp.translationDetails.languageKeyTranslation);
					}

					//call envelope details API
					if (this.controlPropertyService.checkNull(envelopeOrTemplateId, '') != '') {
						let getDetailsUrl: any = '';						
						if (prepareType != '' && prepareType.toLowerCase() == "envelope") getDetailsUrl = ApiUrlConfig.GetEnvelopeDetailsUrl + "?envelopeId=" + envelopeOrTemplateId;
						else if (prepareType != '' && prepareType.toLowerCase() == "template") getDetailsUrl = ApiUrlConfig.GetTemplateDetailsUrl + "?templateId=" + envelopeOrTemplateId;

						setTimeout(() => {
							this.apiService.get(getDetailsUrl).subscribe(
								(resp: any) => {
									this.showLoader = false;
									if (resp && resp.statusCode == 200) {
										console.log('GetEnvelopeDetails api reponse:', resp);
										this.authService.setEnvelopeOrTemplateDetails(resp);
										if (prepareType.toLowerCase() == "envelope")
											this.router.navigate(['/envelope-prepare']);
										else if (prepareType.toLowerCase() == "template")
											this.router.navigate(['/template-prepare']);
									}
									else {
										this.showLoader = false;
										if(resp.authMessage)this.toastrService.warning(resp.authMessage, 'Warning');
										else this.toastrService.warning(CommonConstants.TokenValidatedMsg, 'Warning');
										localStorage.setItem("EnvelopeID", envelopeOrTemplateId);
										localStorage.setItem("EmailId", userEmail);
										localStorage.setItem("PageName", prepareType.toLowerCase() == "envelope" ? "initialize prepare envelope" : "initialize prepare template");
										this.router.navigate(['/global-error']);
									}
								});
						}, 2000);
					}
				}
				else {
					this.showLoader = false;
					if(resp.authMessage)this.toastrService.warning(resp.authMessage, 'Warning');
					else this.toastrService.warning(CommonConstants.TokenValidatedMsg, 'Warning');
					localStorage.setItem("EnvelopeID", '');
					localStorage.setItem("EmailId", '');
					localStorage.setItem("PageName", "validating token");
					this.router.navigate(['/global-error']);
				}
			})
	}

}



