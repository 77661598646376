import { Component, Input } from '@angular/core';
import { CommonConstants } from 'src/app/constants/common-constants';
import { CommonService } from 'src/app/services/common.service';

@Component({
	selector: 'app-control',
	templateUrl: './control.component.html',
	styleUrls: ['./control.component.scss']
})
export class ControlComponent {
	@Input() htmlContent: string = '';
	@Input() width: number = 100;
	@Input() height: number = 100;
	@Input() left: number = 0;
	@Input() top: number = 0;
	@Input() minwidth: number = 100;
	@Input() placeholder: any;
	@Input() ctrlObj: any = {};
	mouse!: { x: number; y: number; };
	commonConstants = CommonConstants;

	constructor(private commonService: CommonService) { }

	ngOnInit(): void {
		this.ctrlObj = this.ctrlObj;
	}

	getControllingFieldClass(dataGuid:any) {
		let controllingFieldClass = "nonControllingField";
		let controllingFieldArray:any[] = this.commonConstants.controllingFieldArray;
		let controllingArrayObj:any[] = [];

		if(controllingFieldArray && controllingFieldArray.length){
			controllingArrayObj = controllingFieldArray.filter((c: any) => c && c.toLowerCase() === (dataGuid && dataGuid.toLowerCase()));
		}

		if (controllingArrayObj && controllingArrayObj.length > 0) {
			controllingFieldClass = "controllingField";
		}

		if(controllingArrayObj && controllingArrayObj.length > 0 && controllingArrayObj[0] && controllingArrayObj[0].toLowerCase() == "de56e81a-89ae-4d1b-8194-37d9a68a5191" ){
			controllingFieldClass = "controllingField dropdownItem";
		}
		return controllingFieldClass;
	}

	getControlName(ctrlName: any, className:any){
		return this.commonService.getControlName(ctrlName, className);
	}
}
