<header class="headerFixed">
	<div class="logo-wrapper">
		<a (click)="redirectTo('Home')">
			<img class="rsign-logo liNav" src="../../../assets/images/RSign.svg" />
		</a>
		<a class="rcircle-logo" (click)="redirectTo('Home')">
			<img class="liNav" src="../../../assets/images/RPost-Button-White.svg" />
		</a>
	</div>
	<nav class="navbar navbar-default py-0" role="navigation">
		<div class="collapse navbar-collapse d-flex align-items-center">
			<ul class="nav navbar-nav align-items-center">
				<li class="text-center liNav">
					<a class="nav-link d-flex flex-column text-white" href="{{HomeUrl}}">
						<span class="home"></span>						
						Home
					</a>
				</li>
				<li class="active text-center liNav">
					<a class="nav-link d-flex flex-column text-white" href="{{SendUrl}}">
						<span class="send"></span>
						Send
					</a>
				</li>
				<li class="text-center liNav">
					<a class="nav-link d-flex flex-column text-white" href="{{EnvelopeUrl}}">
						<span class="manage"></span>
						Envelopes
					</a>
				</li>
				<li class="text-center liNav">
					<a class="nav-link d-flex flex-column text-white" href="{{TemplateUrl}}">
						<span class="managetemplate"></span>
						Templates
					</a>
				</li>
				<li class="text-center liNav">
					<a class="nav-link d-flex flex-column text-white" href="{{StatsUrl}}">
						<span class="managestats"></span>
						Stats
					</a>
				</li>
				<li class="text-center liNav">
					<a class="nav-link d-flex flex-column text-white" href="{{SettingsUrl}}">
						<span class="settingsAdmin"></span>
						Settings
					</a>
				</li>
				<li class="text-center liNav">
					<a class="nav-link d-flex flex-column text-white" href="{{TranslationsUrl}}">
						<span class="translationsAdmin"></span>
						Translations
					</a>
				</li>
			</ul>
			<div class="navLogout">
				<div id="usernamelogout" class="text-right">
					<a target="_blank" title="Click on this link to upgrade the plan"
						href="https://www.rmail.com/upgrade" class="upgrade-link">						
					</a>
					<a title="Click here to edit profile" id="alink" class="profEdit" href="{{UserDetailsUrl}}">
						<span class="hello-text">Hello, <strong> {{userName}}</strong></span>
						<span class="langFlagIcon" id="divFlagIcon"></span>
					</a>
					<a title="RPost Help Center" id="pageHelpInfo" style="color: #ffffff;"
						(click)="redirectCorporateURL('RSignSettings','new')" class="ques-icon">
						<i class="fa fa-question-circle fa-2x" aria-hidden="true"></i>
					</a>
					<span class="logoutbtn active" (click)="logout()">
						<a id="logouttap" class="logout rsignuserlogout" style="z-index: 500;">
							Sign out</a>
					</span>
				</div>
			</div>
		</div>
	</nav>
</header>
<div *ngIf="showLoader" class="loader"></div>