<app-header></app-header>
<main class="flex-shrink-0">
    <div class="container-fluid">
        <div class="row justify-content-center absolute">
            <div class="fixed bggray submenu">
                <div class="row pt-1">
                    <div class="col-lg-2 col-md-2 pe-0 ps-2 res-pr2">
                        <app-recipients [receipentListArr]="receipentListArr" [receipentColorArr]="receipentColorArr"
                            [recipientsObj]="recipientsObj"></app-recipients>
                    </div>

                    <div class="col-lg-8 col-md-8 res-midlewidth">
                        <div class="d-flex justify-content-center align-items-center mt-4px">
                            <div class="d-flex align-items-center top-n3">
                                <!-- <i title="Keyboard Shortcuts" class="fa fa-keyboard-o text-black font18 mx-1 ps-1 pointer" aria-hidden="true"></i> -->
                                <!-- <span class="material-icons pointer" title="Keyboard Shortcuts"
									(click)="openModal('keybordKeys', '')">
									keyboard_hide
								</span> -->
                                <div class="d-flex align-items-center top-n3">
                                    <div class="dropdown">
                                        <img class="plandetils px-3p" id="dropdownMenu3" data-bs-toggle="dropdown"
                                            aria-expanded="false" src="../../../assets/images/icons/Plan-Details-I.svg"
                                            title="Plan Details" (click)="openDropdownPicker()" />

                                        <ul class="dropdown-menu mt-5p droparrow planmenu-list"
                                            aria-labelledby="dropdownMenu3" style="padding-top: 2px;">
                                            <p class="my-1 ps-3 font13"
                                                *ngIf="isDataLoaded == true && showUpgradeLink == false">
                                                <strong>Plan Name:</strong>
                                                <span class="ms-1" title="{{ fullPlanName }}">
                                                    {{ planName }}
                                                </span>
                                            </p>
                                            <p class="my-1 ps-3 font13"
                                                *ngIf="isDataLoaded == true && showUpgradeLink == false">
                                                <strong>Units Remaining: </strong>
                                                <span class="ms-1"> {{ unit }} </span>
                                            </p>
                                            <p class="my-1 ps-3 font13"
                                                *ngIf="isDataLoaded == true && showUpgradeLink == true">
                                                <strong>Plan Name:</strong>
                                                <span class="ms-1"> {{ planType }} </span>
                                            </p>
                                            <p *ngIf="showUpgradeLink == true" class="mb-0 ps-3 font13">
                                                <a style="text-decoration: none; font-size: 13px;"
                                                    href="{{ upgradeLink }}" target="_blank">
                                                    <strong>Units Remaining:</strong>
                                                    <span class="ms-1">{{ unitsRemaining }},
                                                        <span class="ms-1"><strong>Reset:</strong> {{ planRange
                                                            }}</span>
                                                    </span>
                                                </a>
                                            </p>
                                        </ul>
                                    </div>
                                </div>
                                <span class="br-nav mx-custome">|</span>

                                <img class="h-26p pointer" src="../../../assets/images/icons/Keyboard-Shortcuts.svg"
                                    title="Keyboard Shortcuts" (click)="openModal('keybordKeys', '')" />
                                <span class="br-nav mx-2">|</span>
                                <img [ngClass]="disableRedoUndoButtons()" class="h-19p pointer me-1"
                                    src="../../../assets/images/icons/Undo-Enable.svg" (click)="undo($event)"
                                    title="Undo selected controls" id="undoBtn" />

                                <!-- <i style="margin-left: 2px;" title="Undo selected controls" id="undoBtn"
									class="fa fa-undo text-black me-2 pe-1 font18 pointer" aria-hidden="true"
									[ngClass]="disableRedoUndoButtons()" (click)="undo($event)"></i> -->

                                <img [ngClass]="disableRedoUndoButtons()" class="h-19p pointer ms-2"
                                    src="../../../assets/images/icons/Redo-Enable.svg" (click)="redo()"
                                    title="Redo selected controls" id="redoBtn" />

                                <!-- <i title="Redo selected controls" class="fa fa-repeat text-black px-5p font18 pointer"
									id="redoBtn" aria-hidden="true" [ngClass]="disableRedoUndoButtons()"
									(click)="redo()"></i> -->

                                <span class="br-nav">|</span>
                                <img [ngClass]="showHideDependenciesButton()" class="h-22p pointer px-3p"
                                    src="../../../assets/images/icons/Dependencies-Enable.svg"
                                    title="Add controlling field to the document and add dependencies"
                                    (click)="openModal('adddependencies', '1')" id="btnGlblAddRules" />

                                <!-- <i id="btnGlblAddRules" (click)="openModal('adddependencies', '1')"
									[ngClass]="showHideDependenciesButton()"
									class="fa fa-puzzle-piece font21 text-black top-n2 px-5p pointer" aria-hidden="true"
									title="Add controlling field to the document and add dependencies"></i> -->
                                <span class="br-nav">|</span>
                            </div>
                            <div class="d-flex align-items-center">
                                <app-pagination [documentData]="documentData"></app-pagination>
                            </div>
                            <div class="btn-toolbar mx-1" role="toolbar" aria-label="Toolbar with button groups">
                                <div class="d-flex align-items-center">
                                    <span class="br-nav left-n5">|</span>
                                    <!-- <select class="form-select form-select-sm zoom-dropdown"
										aria-label=".form-select-sm example" [(ngModel)]="zoomValue"
										(change)="applyZoomChanges($event)">
										<option *ngFor="let zoom of zoomList; let i = index;" [value]="zoom">
											{{zoom}}
										</option>
									</select>
									<i class="material-icons dropicon">arrow_drop_down</i> -->
                                    <div class="dropdown">
                                        <a href="#!" class="dropdown-toggle zoomtext" role="button" id="zoomOption"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            {{ zoomValue }}
                                            <span>%</span>
                                            <i class="material-icons ms-1">arrow_drop_down</i>
                                        </a>
                                        <ul class="dropdown-menu zoom-list mt-5p droparrow"
                                            aria-labelledby="zoomOption">
                                            <li *ngFor="let zoom of zoomList; let i = index"
                                                (click)="applyZoomChanges($event)">
                                                <a class="dropdown-item font14" style="padding-bottom: 1px;">{{ zoom
                                                    }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center top-n3">
                                <span class="br-nav">|</span>
                                <img [ngClass]="isEnvEditable()" class="h-24p pointer px-3p"
                                    src="../../../assets/images/icons/ClearAllControls.svg" title="Clear All Controls"
                                    (click)="openModal('clearallcontrols', '')" />

                                <!-- <span class="br-nav">|</span>
                                <img [ngClass]="showHideDiscardButton()" class="h-26p pointer px-3p"
                                    src="../../../assets/images/icons/Discard-Envelope.svg" title="Discard Envelope"
                                    (click)="openModal('discardenvelope', '')" /> -->
                                <!-- <img class="h-20p pointer px-3p" src="../../../assets/images/indent.png" title="Alignment "> -->

                                <span class="br-nav">|</span>
                                <div class="dropdown" [ngClass]="enableFieldAlignment()">
                                    <img [ngClass]="enableFieldAlignment()" class="more-action px-3p"
                                        src="../../../assets/images/icons/More Actions.svg" title="Controls Alignment"
                                        data-bs-toggle="dropdown" aria-expanded="false" id="dropdownMenu2" />
                                    <ul [ngClass]="enableFieldAlignment()"
                                        class="dropdown-menu mt-5p droparrow menu-list" aria-labelledby="dropdownMenu2">
                                        <li [ngClass]="enableFieldAlignment()" (click)="applyAlignChanges('left')">
                                            <a class="dropdown-item d-flex align-items-center font15">
                                                <i class="fa fa-align-left me-2 font-w300" aria-hidden="true"></i>
                                                Align Left</a>
                                        </li>
                                        <li [ngClass]="enableFieldAlignment()" (click)="applyAlignChanges('center')">
                                            <a class="dropdown-item d-flex align-items-center font15">
                                                <i class="fa fa-align-center me-2 font-w300" aria-hidden="true"></i>
                                                Align Center</a>
                                        </li>
                                        <li [ngClass]="enableFieldAlignment()" (click)="applyAlignChanges('right')">
                                            <a class="dropdown-item d-flex align-items-center font15 mb-1">
                                                <i class="fa fa-align-right me-2 font-w300" aria-hidden="true"></i>
                                                Align Right</a>
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider" />
                                        </li>

                                        <li [ngClass]="enableFieldAlignment()" (click)="applyAlignChanges('top')">
                                            <a class="dropdown-item d-flex align-items-center font15 mt-1">
                                                <i class="fa fa-align-left fa-rotate-90 me-2 font-w300"
                                                    aria-hidden="true"></i>
                                                Align Top</a>
                                        </li>
                                        <li [ngClass]="enableFieldAlignment()" (click)="applyAlignChanges('middle')">
                                            <a class="dropdown-item d-flex align-items-center font15">
                                                <i class="fa fa-align-center fa-rotate-90 me-2 font-w300"
                                                    aria-hidden="true"></i>
                                                Align Middle</a>
                                        </li>
                                        <li [ngClass]="enableFieldAlignment()" (click)="applyAlignChanges('bottom')">
                                            <a class="dropdown-item d-flex align-items-center font15 mb-1">
                                                <i class="fa fa-align-right fa-rotate-90 me-2 font-w300"
                                                    aria-hidden="true"></i>
                                                Align Bottom</a>
                                        </li>

                                        <li>
                                            <hr class="dropdown-divider" />
                                        </li>

                                        <li [ngClass]="enableFieldAlignment()"
                                            (click)="applyAlignChanges('distributehorizontally')">
                                            <a class="dropdown-item d-flex align-items-center font15 mt-1">
                                                <img class="align-img"
                                                    src="../../../assets/images/Distribute_Horizontal.png" title="" />
                                                Distribute Horizontally</a>
                                        </li>
                                        <li [ngClass]="enableFieldAlignment()"
                                            (click)="applyAlignChanges('distributevertically')">
                                            <a class="dropdown-item d-flex align-items-center font15 mb-1">
                                                <img class="align-img"
                                                    src="../../../assets/images/Distribute_Vertical.png" title="" />
                                                Distribute Vertically</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <span class="br-nav top-n3">|</span>
                            <div class="d-flex align-items-center top-n3">
                                <div class="dropdown">
                                    <!-- <span class="material-icons text-black top3 pointer font26" title="More Actions"
                                        id="dropdownMenuFileReview" data-bs-toggle="dropdown" aria-expanded="false">
                                        more_horiz
                                    </span> -->
                                    <img class="more-items"  id="dropdownMenuFileReview" data-bs-toggle="dropdown" aria-expanded="false" src="../../../assets/images/icons/More-ThreeDots.svg" title="More Actions" />
                                    <ul class="dropdown-menu mt-5p droparrow menu-list moreoptions" aria-labelledby="dropdownMenuFileReview"
                                        style="padding-top: 2px;padding-bottom: 2px;">
                                        <div *ngIf="enableFileReview == true && filesReviewCount > 0"
                                            class="my-1 ps-3 font15 d-flex flex-column subdropdown">
                                            <div class="d-flex align-items-center justify-content-start">
                                                <strong>Files for Review:</strong>
                                                <span class=" ms-3 dropbtn">{{filesReviewCount}}</span>
                                            </div>
                                            <div class="dropdown-content review-files" style="left: -7px;">
                                                <ul class="list-group fileslist">
                                                    <li class="list-group-item"
                                                        *ngFor="let reviewItem of filesReview; let i = index">
                                                        <span class="material-icons me-2 font18">description</span>
                                                       <span style="width:160px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;"
                                                        title="{{reviewItem}}">
                                                        {{showReviewItem(reviewItem)}} </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <li *ngIf="enableFileReview == true && filesReviewCount > 0">
                                            <hr class="dropdown-divider" />
                                        </li>
                                        <li class="drop-border">
                                            <a class="dropdown-item d-flex align-items-center font15 py-1em"
                                            (click)="openModal('discardenvelope', '')">
                                                <!-- <img class=" pointer more-img top1"
                                                    src="../../../assets/images/icons/More Actions_SaveasDraft.svg"
                                                    title="" /> -->
                                                    <img [ngClass]="showHideDiscardButton()" class="pointer more-img top1"
                                                     src="../../../assets/images/icons/Discard-Envelope.svg" style="height: 20px;" />
                                                <span style="margin-top: 2px; margin-left: -2px;" [ngClass]="showHideDiscardButton()">Discard Envelope</span>
                                            </a>
                                        </li>
                                        <li class="drop-border">
                                            <a class="dropdown-item d-flex align-items-center font15 py-1em"
                                                (click)="onSaveDraft(0)">
                                                <img class=" pointer more-img top1"
                                                    src="../../../assets/images/icons/More Actions_SaveasDraft.svg"
                                                    title="" />
                                                <span style="margin-top: 2px;">Save as Draft</span>
                                            </a>
                                        </li>
                                        <li class="drop-border" style="padding-top: 4px;">
                                            <a class="dropdown-item d-flex align-items-center font15 mb-1"
                                                (click)="viewPdf()">
                                                <!-- <img class="h-20p pointer mr-10" style="padding-left: 3px;"
                          src="../../../assets/images/project.png" title="" /> -->
                                                <img (click)="viewPdf()" class=" pointer more-img top1" height="20px"
                                                    style="height: 20px;
                        margin-left: -2px;" src="../../../assets/images/icons/More Actions_View PDF.svg" title="" />
                                                <span style="margin-top: 2px;">View Pdf</span>
                                            </a>
                                        </li>
                                        <li class="">
                                            <a class="dropdown-item d-flex align-items-center font15 py-1em"
                                                (click)="openModal('viewsetting', '')">
                                                <!-- <span class="material-icons me-2 font-w300">settings_suggest </span>View Settings -->
                                                <img class=" pointer more-setting top1"
                                                    src="../../../assets/images/icons/More Actions_View Settings.svg"
                                                    title="" />
                                                <span style="margin-top: 2px;">View Settings</span>
                                            </a>
                                        </li>
                                        <!-- <li>
                                            <a class="dropdown-item d-flex align-items-center font15 py-1em">
                                                <img class="pointer more-branding top1"
                                                src="../../../assets/images/icons/More Actions_Branding.svg" title=""
                                                />
                                                <span style="margin-top: 2px;">Branding</span>
                                                </a>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 res-rightwidth">
                        <div class="d-flex align-items-center justify-content-between">
                            <!-- <span class="fa fa-files-o pointer preview-icon" (click)="onPreviewPagesView()"
								title="Document Preview">
							</span> -->
                            <div class="d-flex align-items-center">
                                <img class="h-26p pointer" src="../../../assets/images/icons/Document-Preview.svg"
                                    title="Document Preview" (click)="onPreviewPagesView()" />
                                <!-- <span class="br-nav" style="margin: 0 6px;margin-right: 2px;">|</span> -->
                            </div>
                            <!-- send-btn -->
                            <div class="d-flex align-itmes">
                                <button class="btn back-btn btnback px-3" (click)="openModal('backModal', '')">
                                    <i aria-hidden="true" class="fa fa-arrow-left me-2"></i>Back
                                </button>

                                <button *ngIf="isPrefillExists == true"
                                    class="send-button btnsend top1 font14 d-flex align-items-center ms-2" role="button"
                                    (click)="sendEnvelope()">
                                    Next
                                    <span class="fa fa-chevron-right ms-1" aria-hidden="true"></span>
                                    <!-- <span class="fa fa-paper-plane ms-1" aria-hidden="true"></span> -->
                                </button>
                                <button *ngIf="isEnvelopeEdited != true && isPrefillExists != true"
                                    class="send-button btnsend top1 font14 d-flex align-items-center ms-2" role="button"
                                    (click)="sendEnvelope()">
                                    Send
                                    <span class="fa fa-paper-plane ms-1" aria-hidden="true"></span>
                                </button>
                                <button *ngIf="isEnvelopeEdited == true && isPrefillExists != true"
                                    class="send-button resend-btn top1 font14 d-flex align-items-center ms-2"
                                    role="button" (click)="sendEnvelope()">
                                    Resend
                                    <span class="fa fa-paper-plane resendicon" aria-hidden="true"></span>
                                </button>
                            </div>

                            <!-- <img class="h-20p pointer px-5p" src="../../../assets/images/documenthide.png" > -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mt-9rem body-bg">
            <div class="col-lg-2 ps-0">
                <div class="side-navbar d-flex flex-wrap flex-column leftsidebaar" id="sidebar">
                    <div class="mx-1 ps-1 pt-1">
                        <div class="input-group mt-2 mb-1">
                            <input type="search" autocomplete="off" class="form-control search" id="search-text"
                                aria-describedby="search-text" placeholder="Search Fields" [(ngModel)]="searchText"
                                [ngModelOptions]="{ standalone: true }" (keyup.enter)="onFilterApply($event)"
                                (keyup)="onFilterApply($event)" (search)="onFilterApply($event)" />
                        </div>
                        <span class="search-icon"><i class="fa fa-search"></i></span>
                    </div>

                    <div class="scrollbar">
                        <!-- <div class="or_text" *ngIf="dragItems.length !== 0">
							<span style="font-size: 15px;">Standard Controls </span>
						</div> -->
                        <ul class="menu" cdkDropList #divBoard [cdkDropListData]="dragItems"
                            [cdkDropListConnectedTo]="[tableList]" (cdkDropListDropped)="drop($event)"
                            (cdkDropListEntered)="entered()" (cdkDropListExited)="exited($event)"
                            cdkDropListSortingDisabled>
                            <li *ngFor="let item of dragItems;let dragIndex = index;"
                                class="selector item draggable-item list-group-item" cdkDrag [cdkDragData]="item"
                                [cdkDragConstrainPosition]="computeDragRenderPos"
                                (cdkDragStarted)="onDragStarted($event)"
                                [ngClass]="{ controlactive: dragIndex+1 === activeCtrlId }">
                                <ng-container>
                                    <div class="d-flex align-items-center pointer w-100 control-box">
                                        <div class="rect-box" [style.background]="dragItemBgColor"
                                            [ngClass]="item.title.toLowerCase() === 'initials' ? 'rs-padding' : ''">
                                            <svg class="SVGInline-svg" fill="currentColor"
                                                style="filter: hue-rotate(45deg);"
                                                [ngClass]="item.title.toLowerCase() === 'initials' ? 'rs-storke' : ''"
                                                xmlns="http://www.w3.org/2000/svg" [attr.viewBox]="item.viewBox"
                                                focusable="false">
                                                <path [attr.d]="item.svgPath1" fill="#ffffff" opacity="1"
                                                    data-original="#000000">
                                                </path>
                                                <path [attr.d]="item.svgPath2" fill="#ffffff">
                                                </path>
                                                <path [attr.d]="item.svgPath3" fill="#ffffff">
                                                </path>

                                                <path *ngIf="item.title.toLowerCase() === 'date & time stamp'"
                                                    d="M396 310h-40v87h74v-40h-34zM156 310h40v40h-40zM76 310h40v40H76zM76 230h40v40H76zM76 390h40v40H76zM156 230h40v40h-40zM156 390h40v40h-40z"
                                                    fill="#ffffff" opacity="1" data-original="#000000"></path>

                                                <!-- {{item.viewBox}} -->
                                                <circle *ngIf="item.title.toLowerCase() === 'radio'" cx="8" cy="8"
                                                    r="3.5"></circle>

                                            </svg>
                                            <!-- date Time stamp -->

                                        </div>
                                        <label>{{ item.title }}</label>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div *ngIf="item.title.toLowerCase() === 'signature'">
                                        <div *cdkDragPreview class="set-default-signature"
                                            style="width:220px;height:35px;border:1px solid #212529;background: #eee;font-size: 12px;">
                                            <div class="signature"></div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div *ngIf="item.title.toLowerCase() === 'initials'">
                                        <div *cdkDragPreview class="set-default-initials"
                                            style="width:52px;height:23px;border:1px solid #212529;background: #eee;font-size: 12px;">
                                            <div class="newInitial"></div>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div *ngIf="item.title.toLowerCase() === 'date & time stamp'">
                                        <div *cdkDragPreview
                                            style="color:#000;min-width:135px;height:20px;border:1px solid #212529;background: yellow;font-size: 12px;word-spacing: 3px;padding-left: 5px;">
                                            {{item.title}}</div>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div
                                        *ngIf="item.title.toLowerCase() === 'name' || item.title.toLowerCase() === 'title'">
                                        <div *cdkDragPreview
                                            style="width:167px;height:22px;border:1px solid #212529;background: #eee;font-size: 12px; text-align: center; padding-top: 2px;">
                                            {{item.title}}</div>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div
                                        *ngIf="item.title.toLowerCase() === 'company' || item.title.toLowerCase() === 'email'">
                                        <div *cdkDragPreview
                                            style="width:100px;height:22px;border:1px solid #212529;background: #eee;font-size: 12px; text-align: center; padding-top: 2px;">
                                            {{item.title}}</div>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div *ngIf="item.title.toLowerCase() === 'checkbox'">
                                        <input style="height: 18px;width:18px;"  *cdkDragPreview class="checkbox" type="checkbox">
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div *ngIf="item.title.toLowerCase() === 'radio'">
                                        <input style="height: 18px;width:18px;" *cdkDragPreview class="checkbox" type="radio">
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div *ngIf="item.title.toLowerCase() === 'date'">
                                        <div *cdkDragPreview
                                            style="width:100px;height:22px;border:1px solid #212529;background: #eee;font-size: 12px;padding-top: 2px;text-align: center;">
                                            dd/mm/yyyy</div>
                                    </div>
                                </ng-container>

                                <ng-container>
                                    <div *ngIf="item.title.toLowerCase() === 'text'">
                                        <div *cdkDragPreview
                                            style="width:167px;height:22px;border:1px solid #212529;background: #eee;font-size: 12px;padding-top: 2px;text-align: center;">
                                            {{item.title}}</div>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div *ngIf="item.title.toLowerCase() === 'dropdown'">
                                        <div *cdkDragPreview
                                            style="width:100px;height:22px;border:1px solid #212529;background: #eee;font-size: 12px;text-align: center;">
                                            {{item.title}}</div>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div *ngIf="item.title.toLowerCase() === 'label'">
                                        <div *cdkDragPreview
                                            style="color:#000;width:100px;height:22px;border:1px solid #212529;background: yellow;font-size: 12px;text-align: center; padding-top: 1px;">
                                            {{item.title}}</div>
                                    </div>
                                </ng-container>
                                <ng-container>
                                    <div *ngIf="item.title.toLowerCase() === 'hyperlink'">
                                        <div *cdkDragPreview
                                            style="width:100px;height:22px;border:1px solid #212529;background: #eee;font-size: 12px;text-align: center;">
                                            {{item.title}}</div>
                                    </div>
                                </ng-container>
                            </li>

                        </ul>

                        <div *ngIf="dragItems.length === 0">
                            <div class="no-fields">
                                <div class="d-flex flex-column align-items-center ">
                                    <img class="mb-2" src="../../../assets/images/icons/No Fields Found.svg" width="30%" />
                                    <p class="text-center font-w600"> No fields found</p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
            <div class="col-12 col-md-12 col-lg-8 pe-1" id="middle-section">
                <div class="middle-scrollbar">
                    <!--  [className]="isActive ? 'd-flex docImages' : ' docImages'" -->
                    <div class=" docImages"
                        [ngClass]="{'zoomflex-50': ZoomPercentageObj.zoomVal50, 'zoomflex-75': ZoomPercentageObj.zoomVal75, 'zoomflex-100': ZoomPercentageObj.zoomVal100, 'zoomflex-125' : ZoomPercentageObj.zoomVal125, 'zoomflex-150' : ZoomPercentageObj.zoomVal150, 'zoomflex-175' : ZoomPercentageObj.zoomVal175, 'zoomflex-200' : ZoomPercentageObj.zoomVal200 }">
                        <div #documentPageContainer (scroll)="onScroll($event)" class="scroll-style" cdkDropList
                            #tableList="cdkDropList" [cdkDropListData]="table" cdkDropListSortingDisabled="false"
                            style="position: absolute;" (cdkDropListDropped)="drop($event)"
                            [ngClass]="{'zoomclass-50': ZoomPercentageObj.zoomVal50, 'zoomclass-75': ZoomPercentageObj.zoomVal75, 'zoomclass-100': ZoomPercentageObj.zoomVal100, 'zoomclass-125' : ZoomPercentageObj.zoomVal125, 'zoomclass-150' : ZoomPercentageObj.zoomVal150, 'zoomclass-175' : ZoomPercentageObj.zoomVal175, 'zoomclass-200' : ZoomPercentageObj.zoomVal200 }">
                            <div id="zoompage" #container>
                                <div [appContextualMenu]="context" [contextElements]="elements" tabindex="1"
                                    class="img-control" *ngFor="let item of imagesArr; let idx = index;"
                                    id="imgControl_{{idx+1}}" (mousedown)="handleMouseDown($event)"
                                    (mousemove)="handleMouseMove($event)" (mouseup)="handleMouseUp($event)">
                                    <img class="dispImg document-img imgControl imgDocumentId_{{idx+1}}" id="{{idx+1}}"
                                        alt="Image" [ngStyle]="getDisplayStyle(item)" src="{{item.path}}"
                                        [attr.page-no]="idx+1" [attr.docPageNo]="item.docPageNo"
                                        [attr.img-document-id]="item.documentId" loading="lazy" />
                                    <div id="selectionBox{{idx+1}}" [ngClass]="returnSelectionBoxClass()"
                                        style="z-index:999;" [attr.img-document-id]="item.documentId"
                                        [ngStyle]="getSelectionBoxStyles()" [attr.docPageNo]="item.docPageNo">
                                    </div>
                                </div>
                                <app-contextual-menu #context [elements]="elements"></app-contextual-menu>
                            </div>
                        </div>
                    </div>
                </div>
                <app-footer class="env-footer"></app-footer>
            </div>

            <div class="col-lg-2 pe-0" [hidden]="hidePagesViewSection">
                <app-preview-view [previewObj]="previewObj"></app-preview-view>
            </div>
            <!------------------ End the preview------------- -->

            <div class="col-lg-2 pe-0">
                <ng-container *ngIf="
					controlsObj.signatureProperties ||
					controlsObj.initialsProperties ||
					controlsObj.datetimeProperties ||
					controlsObj.nameProperties ||
					controlsObj.titleProperties ||
					controlsObj.hyperlinkProperties ||
					controlsObj.labelProperties ||
					controlsObj.textProperties ||
					controlsObj.dateProperties ||
					controlsObj.radioProperties ||
					controlsObj.checkboxProperties ||
					controlsObj.dropdownProperties ||
					controlsObj.emailProperties ||
					controlsObj.companyProperties">
                    <!-- data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasScrolling" aria-labelledby="offcanvasScrollingLabel" -->
                    <div class="offcanvas offcanvas-end custome-offcanvas show fadein-right" [hidden]="isClose"
                        [class.fadeInleft]="isClose == true">
                        <div class="offcanvas-header">
                            <div class="d-flex align-items-center" id="offcanvasScrollingLabel">
                                <ng-container *ngIf="controlsObj.signatureProperties">
                                    <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" focusable="false">
                                        <path
                                            d="M16 16H0v-1h16zM13.8.3c.4.4.4 1 0 1.4L11 4.5c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L12.4.3c.3-.4 1-.4 1.4 0zM1.4 9.9 0 14.1l4.2-1.4z">
                                        </path>
                                        <path
                                            d="M4.9 12 11 5.9l.5-.5.9-.9c.4-.4.4-1 0-1.4L11 1.7c-.4-.4-1-.4-1.4 0l-.9.9-6.6 6.6L4.9 12z">
                                        </path>
                                    </svg>
                                    <label class="ms-2 font-w600 font15">Signature</label>
                                </ng-container>

                                <ng-container *ngIf="controlsObj.initialsProperties">
                                    <svg style="height: 22px; margin-left: 0px;" class="SVGInline-svg in-storke"
                                        fill="currentColor" xmlns="http://www.w3.org/2000/svg" focusable="false"
                                        viewBox="0 0 134 132">
                                        <path fill-opacity=".6"
                                            d="M18.5 63c0 22.3.1 31.4.2 20.3.2-11.2.2-29.4 0-40.5-.1-11.2-.2-2.1-.2 20.2z" />
                                        <path fill-opacity=".5"
                                            d="M17.4 108.4c-.3.8-.4 2.7-.2 4.3l.3 2.8h99v-8l-49.3-.3c-41.9-.2-49.3 0-49.8 1.2zm98.6 3.1v3.5H18v-7h98v3.5z" />
                                        <path
                                            d="M19 63.5V103h9V66h11.8l6.8 18.5 6.9 18.5h9.3l-6-15.3c-9.8-24.9-9.4-21.8-4.1-26.2 11.7-9.7 10.1-28.4-2.9-34.9-4.8-2.4-6.2-2.6-18-2.6H19v39.5zm26.8-30.6c3.6 1.9 6.2 7 6.2 12.1 0 9.6-5.3 14-16.6 14H28V31.1l4.3-.3c5.2-.4 10.4.4 13.5 2.1zm39.7-7.2C74.8 31.3 71 43.8 77.1 53.6c1.1 1.8 6.4 6.5 11.7 10.4 15.9 11.6 16.1 11.7 16.8 15.6 1.7 9.1-3.7 15.4-13.1 15.4C84.7 95 80 90.1 80 82c0-2.9-.2-3-4.1-3h-4.1l.4 5.5c1.7 20.7 32.3 25.2 40.3 6 4.6-10.8 1.6-18.5-10-26.3-19.4-13.1-23-18.2-18.9-26.8 4.7-9.9 18.6-7.6 20.1 3.3.6 4.3.6 4.3 4.9 4.3 4.3 0 4.3 0 3.7-3.8-1.9-13.6-15.5-21.5-26.8-15.5zM18 111.5v3.5h98v-7H18v3.5z" />
                                    </svg>
                                    <label class="ms-1 font-w600 font15">Intials</label>
                                </ng-container>

                                <ng-container *ngIf="controlsObj.datetimeProperties">
                                    <!-- <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" focusable="false">
                                        <path
                                            d="M14 2H2C.9 2 0 2.9 0 4v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM2 14V6h12v8H2zM3 0h2v2H3zm8 0h2v2h-2z">
                                        </path>
                                    </svg>   -->

                                    <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" focusable="false" class="SVGInline-svg" style="filter: hue-rotate(45deg);" viewBox="0 0 512 512">
                                        <path fill="#212529" opacity="1" data-original="#000000" d="M236 230h40v40h-40z"></path>
                                        <path fill="#212529" d="M452 40h-24V0h-40v40H124V0H84v40H60C26.916 40 0 66.916 0 100v352c0 33.084 26.916 60 60 60h205.762a176.385 176.385 0 0 1-35.663-40H60c-11.028 0-20-8.972-20-20V188h432v42.099a176.43 176.43 0 0 1 40 35.663V100c0-33.084-26.916-60-60-60zm20 108H40v-48c0-11.028 8.972-20 20-20h24v40h40V80h264v40h40V80h24c11.028 0 20 8.972 20 20v48z"></path>
                                        <path fill="#212529" d="M377 242c-74.439 0-135 60.561-135 135s60.561 135 135 135 135-60.561 135-135-60.561-135-135-135zm0 230c-52.383 0-95-42.617-95-95s42.617-95 95-95 95 42.617 95 95-42.617 95-95 95z"></path>
                                        <path d="M396 310h-40v87h74v-40h-34zM156 310h40v40h-40zM76 310h40v40H76zM76 230h40v40H76zM76 390h40v40H76zM156 230h40v40h-40zM156 390h40v40h-40z" fill="#212529" opacity="1" data-original="#000000"></path>
                                    </svg>
                                    <label class="ms-2 font-w600 font15">Date & Time Stamp</label>
                                </ng-container>

                                <ng-container *ngIf="controlsObj.nameProperties">
                                    <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" focusable="false">
                                        <path d="M8 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0 1c4 0 8 2 8 4v3H0v-3c0-2 4-4 8-4z">
                                        </path>
                                    </svg>
                                    <label class="ms-2 font-w600 font15">Name</label>
                                </ng-container>

                                <ng-container *ngIf="controlsObj.titleProperties">
                                    <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" focusable="false">
                                        <path
                                            d="M14.5 4H12V2c0-1.1-.9-2-2-2H6C4.9 0 4 .9 4 2v2H1.5C.7 4 0 4.7 0 5.5v8c0 .8.7 1.5 1.5 1.5h13c.8 0 1.5-.7 1.5-1.5v-8c0-.8-.7-1.5-1.5-1.5zM6 2h4v2H6V2z">
                                        </path>
                                    </svg>
                                    <label class="ms-2 font-w600 font15">Title</label>
                                </ng-container>

                                <ng-container *ngIf="controlsObj.companyProperties">
                                    <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" focusable="false">
                                        <path
                                            d="M2 0v16h4v-4h4v4h4V0H2zm4 10H4V8h2v2zm0-3H4V5h2v2zm0-3H4V2h2v2zm3 6H7V8h2v2zm0-3H7V5h2v2zm0-3H7V2h2v2zm3 6h-2V8h2v2zm0-3h-2V5h2v2zm0-3h-2V2h2v2z">
                                        </path>
                                    </svg>
                                    <label class="ms-2 font-w600 font15">Company</label>
                                </ng-container>

                                <ng-container *ngIf="controlsObj.emailProperties">
                                    <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" focusable="false">
                                        <path d="M8 7.8 16 3c0-1.1-.9-2-2-2H2C.9 1 0 1.9 0 3v.1l8 4.7z"></path>
                                        <path d="M8 10.2 0 5.4V13c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V5.3l-8 4.9z"></path>
                                    </svg>
                                    <label class="ms-2 font-w600 font15">Email</label>
                                </ng-container>

                                <ng-container *ngIf="controlsObj.dropdownProperties">
                                    <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" focusable="false">
                                        <path d="M4.5 5.6h7L8 10.9z"></path>
                                        <path
                                            d="M13.7 2c.2 0 .3.1.3.3v11.4c0 .2-.1.3-.3.3H2.3c-.2 0-.3-.1-.3-.3V2.3c0-.2.1-.3.3-.3h11.4m0-2H2.3C1 0 0 1 0 2.3v11.4C0 15 1 16 2.3 16h11.4c1.3 0 2.3-1 2.3-2.3V2.3C16 1 15 0 13.7 0z">
                                        </path>
                                    </svg>
                                    <label class="ms-2 font-w600 font15">Dropdown</label>
                                </ng-container>

                                <ng-container *ngIf="controlsObj.checkboxProperties">
                                    <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" focusable="false">
                                        <path
                                            d="M13.7 2c.2 0 .3.1.3.3v11.4c0 .2-.1.3-.3.3H2.3c-.2 0-.3-.1-.3-.3V2.3c0-.2.1-.3.3-.3h11.4m0-2H2.3C1 0 0 1 0 2.3v11.4C0 15 1 16 2.3 16h11.4c1.3 0 2.3-1 2.3-2.3V2.3C16 1 15 0 13.7 0z">
                                        </path>
                                        <path d="m13 5.5-1.4-1.4L6.7 9 4.4 6.8 3 8.2l3.7 3.7z"></path>
                                    </svg>
                                    <label class="ms-2 font-w600 font15">Checkbox</label>
                                </ng-container>

                                <ng-container *ngIf="controlsObj.radioProperties">
                                    <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" focusable="false">
                                        <path
                                            d="M8 2c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6m0-2C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z">
                                        </path>
                                        <circle cx="8" cy="8" r="3.5"></circle>
                                    </svg>
                                    <label class="ms-2 font-w600 font15">Radio</label>
                                </ng-container>

                                <ng-container *ngIf="controlsObj.dateProperties">
                                    <!-- <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" focusable="false">
                                        <path
                                            d="M14 2H2C.9 2 0 2.9 0 4v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM2 14V6h12v8H2zM3 0h2v2H3zm8 0h2v2h-2z">
                                        </path>
                                    </svg> -->

                                    <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" focusable="false" class="SVGInline-svg" style="filter: hue-rotate(45deg);" viewBox="0 0 34 34">
                                        <path fill="#212529" opacity="1" data-original="#000000" d="M29.6 2h-3v3c0 .6-.5 1-1 1s-1-.4-1-1V2h-16v3c0 .6-.5 1-1 1s-1-.4-1-1V2h-3C2.1 2 1 3.3 1 5v3.6h32V5c0-1.7-1.8-3-3.4-3zM1 10.7V29c0 1.8 1.1 3 2.7 3h26c1.6 0 3.4-1.3 3.4-3V10.7zm8.9 16.8H7.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8H10c.4 0 .8.3.8.8v2.5c-.1.5-.4.8-.9.8zm0-9H7.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8H10c.4 0 .8.3.8.8v2.5c-.1.5-.4.8-.9.8zm8 9h-2.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c0 .5-.3.8-.8.8zm0-9h-2.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c0 .5-.3.8-.8.8zm8 9h-2.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c0 .5-.3.8-.8.8zm0-9h-2.5c-.4 0-.8-.3-.8-.8v-2.5c0-.4.3-.8.8-.8h2.5c.4 0 .8.3.8.8v2.5c0 .5-.3.8-.8.8z"></path>
                                        <path fill="#212529" d=""></path>
                                        <path fill="#212529" d=""></path>
                                    </svg>
                                    <label class="ms-2 font-w600 font15">Date</label>
                                </ng-container>

                                <ng-container *ngIf="controlsObj.textProperties">
                                    <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" focusable="false">
                                        <path d="M7 2h2v12H7z"></path>
                                        <path d="M2 2h12v2H2zm3 10h6v2H5z"></path>
                                        <path d="M2 2h2v4H2zm10 0h2v4h-2z"></path>
                                    </svg>
                                    <label class="ms-2 font-w600 font15">Text</label>
                                </ng-container>

                                <ng-container *ngIf="controlsObj.labelProperties">
                                    <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" focusable="false">
                                        <path
                                            d="M14 1H2C.9 1 0 1.9 0 3v10c0 1.1.9 2 2 2h10l4-4V3c0-1.1-.9-2-2-2zm-5 9H4V9h5v1zm3-2H4V7h8v1zm0-2H4V5h8v1z">
                                        </path>
                                    </svg>
                                    <label class="ms-2 font-w600 font15">Label</label>
                                </ng-container>

                                <ng-container *ngIf="controlsObj.hyperlinkProperties">
                                    <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 16 16" focusable="false">
                                        <path
                                            d="M8 14.8c-1.6 1.6-4.2 1.6-5.8 0S.6 10.6 2.2 9L10.3.9c1.2-1.2 3.1-1.2 4.2 0s1.2 3.1 0 4.2l-7 7c-.7.7-1.9.7-2.7 0s-.7-1.9 0-2.7L11.3 3l.8.8-6.5 6.5c-.3.3-.3.8 0 1.1s.8.3 1.1 0l7-7c.7-.7.7-1.9 0-2.7s-1.9-.7-2.7 0L3 9.8C1.8 11 1.8 12.9 3 14s3.1 1.2 4.2 0l7.5-7.5.8.8L8 14.8z">
                                        </path>
                                    </svg>
                                    <label class="ms-2 font-w600 font15">Hyperlink</label>
                                </ng-container>
                            </div>

                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                                aria-label="Close" (click)="fadeInOut($event)"></button>
                        </div>
                        <div class="offcanvas-body px-0 pt-0" style="overflow-x: hidden;">
                            <div class="accordion" id="accordionExample">
                                <div class="accordion-item p-10p d-flex" *ngIf="!controlsObj.datetimeProperties &&
										!controlsObj.radioProperties &&
										!controlsObj.labelProperties &&
										!controlsObj.hyperlinkProperties">
                                    <div class="d-flex align-items-center">
                                        <input class="check-box" id="chRequiredSelected" type="checkbox"
                                            [checked]="isRequiredChecked" (click)="applyClickedProperty($event, '1')" />
                                        <label for="chRequiredSelected" class="top1">Required</label>
                                    </div>
                                </div>


                                <!--------------- New checkbox styles design ------------------>
                                <!-- <div class="accordion-item p-10p d-flex" *ngIf="!controlsObj.datetimeProperties &&
								!controlsObj.radioProperties &&
								!controlsObj.labelProperties &&
								!controlsObj.hyperlinkProperties">
									  <div class="form-group">
										<input type="checkbox" id="chRequiredSelected" [checked]="isRequiredChecked" (click)="applyClickedProperty($event, '1')">
										<label for="chRequiredSelected">Required</label>
									  </div>

								</div> -->

                                <!-- <div class="accordion-item p-10p d-flex" *ngIf="controlsObj.textProperties">
									<div class="d-flex align-items-center">
										<input class="check-box" id="wp-comment-cookies-consent"
											name="wp-comment-cookies-consent" type="checkbox" value="Required" />
										<label for="wp-comment-cookies-consent" class="top1">Read Only</label>
									</div>
								</div> -->
                                <div class="accordion-item p-10p d-flex" *ngIf="controlsObj.dropdownProperties">
                                    <input class="check-box" id="drpFirstLineBlank" name="drpFirstLineBlank"
                                        type="checkbox" value="First Line Blank" [checked]="isdrpFirstLineBlank"
                                        (change)="applyFirstLineBlank($event)" />
                                    <label for="drpFirstLineBlank" class="top1">First Line Blank</label>
                                </div>

                                <div class="accordion-item p-10p d-flex" *ngIf="controlsObj.radioProperties">
                                    <input class="check-box" id="chkRadioRequiredSelected" name="pre-selected"
                                        type="checkbox" [checked]="isRadioRequiredChecked" value="radio-group"
                                        (change)="applyRadioGroup($event)" />
                                    <label style="display: contents;" for="chkRadioRequiredSelected" class="top1">Required (Template
                                        Group)</label>
                                </div>

                                <div class="accordion-item p-10p" *ngIf="controlsObj.checkboxProperties ||
										controlsObj.radioProperties">
                                    <input class="check-box" id="chkPreSelected" name="pre-selected" type="checkbox"
                                        value="First Line Blank" [checked]="isChkPreSelected"
                                        (click)="applyClickedProperty($event,'2')" />
                                    <label for="wp-comment-cookies-consent" class="top1">Pre-selected</label>
                                </div>

                                <div class="accordion-item p-10p d-flex" *ngIf="controlsObj.checkboxProperties">
                                    <input class="check-box" id="checkboxgroup" name="checkbox-group" type="checkbox"
                                        value="Checkbox Groups" [checked]="ischeckboxGroup"
                                        (change)="applyCheckboxGroup($event)" />
                                    <label for="checkboxgroup">Checkbox Groups (optional)</label>
                                </div>

                                <div class="accordion-item p-10p d-flex" *ngIf="controlsObj.dateProperties">
                                    <input class="check-box" id="prefillDateCheckbox" name="checkbox-group"
                                        type="checkbox" value="Checkbox Groups"
                                        (click)="applyPrefillDefaultDateProperty($event)" />
                                    <label for="checkboxgroup">
                                        Prefill Current Date for the Signer</label>
                                </div>

                                <div class="accordion-item" *ngIf="!controlsObj.labelProperties &&
										!controlsObj.hyperlinkProperties ">
                                    <h2 class="accordion-header font14" id="headingOne">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne" aria-expanded="true"
                                            aria-controls="collapseOne">
                                            Signer
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show"
                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="dropdown pl-3p" style="background: #ffff; width: 100%;">
                                                <button
                                                    class="form-select custme-p text-start d-flex align-items-center"
                                                    style="box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 1px 0px,
													rgba(27, 31, 35, 0.1) 0px 0px 0px 1px;
													font-size: 14px;border-radius: 1px;
													height: 29px;" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="material-icons me-1 square-select" aria-hidden="true"
                                                        [ngStyle]="{ color: selectedPanelRecipentColor }">square</i>
                                                    <span class="font-recipent" title="{{selectedPanelRecipentName}}">
                                                        {{ selectedPanelRecipentName }}</span>
                                                </button>
                                                <ul class="dropdown-menu recpientlist">
                                                    <li *ngFor="let recipent of receipentListArr;let i = index;"
                                                        (click)="onRecipentSelect(recipent, receipentColorArr[i])">
                                                        <a id="recp_{{recipent.id}}"
                                                            class="dropdown-item pointer recipientDeatils d-flex align-items-center"
                                                            style="font-size: 14px;padding-left: 12px;">
                                                            <i class="material-icons square-font me-0"
                                                                aria-hidden="true"
                                                                [ngStyle]="{ color: receipentColorArr[i] }">square</i>&nbsp;
                                                            {{ recipent.name }}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item" *ngIf="controlsObj.textProperties">
                                    <h2 class="accordion-header font14" id="headingOne90">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseOne90"
                                            aria-expanded="true" aria-controls="collapseOne90">
                                            Add Text
                                        </button>
                                    </h2>
                                    <div id="collapseOne90" class="accordion-collapse collapse "
                                        aria-labelledby="headingOne90" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="pl-3p">
                                                <textarea id="addTextTextarea" class="form-control mb-3"
                                                    maxlength="4000" (focusout)="updateTextAreaText($event)"
                                                    placeholder="Enter Text" name="text"
                                                    rows="3">{{selectedPanelRecipentTextAreaText}}</textarea>
                                            </div>
                                            <!-- <div class="mb-2 row pl-3p">
                                                <div class="col-sm-4 pe-0">
                                                    <input type="text" id="inputPassword" value="4000" maxlength="4"
                                                        min="1" max="9999" class="form-control form-control-sm" />
                                                </div>
                                                <label for="inputPassword"
                                                    class="col-sm-8 ps-2 col-form-label">Character
                                                    Limit</label>
                                            </div> -->

                                            <div class="mt-2 pl-3p" style="line-height: 12px;">
                                                <span class="note">Field length is resizable by dragging the bottom
                                                    right corner to size.
                                                </span>
                                            </div>

                                            <!-- <div class="d-flex align-items-center ps-1">
												<input class="check-box" id="wp-comment-cookies-consent"
													name="wp-comment-cookies-consent" type="checkbox"
													value="Required" />
												<label for="wp-comment-cookies-consent" class="top1">Fixed Width</label>
											</div> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item" *ngIf="
                    !controlsObj.labelProperties &&
                    !controlsObj.hyperlinkProperties
                  ">
                                    <h2 class="accordion-header font14" id="headingTwo">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                            aria-expanded="false" aria-controls="collapseTwo">
                                            Tooltip
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                                        <div class="accordion-body">
                                            <div class="pl-3p">
                                                <input type="text" autocomplete="off" class="form-control"
                                                    id="controlInputTooltip" placeholder="Enter Tooltip"
                                                    (focusout)="updateControlTooltip($event)"
                                                    (keyup.enter)="updateControlTooltip($event)" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item" *ngIf="controlsObj.labelProperties">
                                    <h2 class="accordion-header font14" id="headingThree4">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree4" aria-expanded="false"
                                            aria-controls="collapseThree4">
                                            Label
                                        </button>
                                    </h2>
                                    <div id="collapseThree4" class="accordion-collapse collapse show"
                                        aria-labelledby="headingThree4" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="pl-3p">
                                                <input type="text" class="form-control" id="inputLabel"
                                                    autocomplete="off" placeholder="Enter Label"
                                                    (focusout)="applyLabelName($event, 'label')" maxlength="400" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item" *ngIf="controlsObj.hyperlinkProperties">
                                    <h2 class="accordion-header font14" id="headingThree6">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree6" aria-expanded="false"
                                            aria-controls="collapseThree6">
                                            Label
                                        </button>
                                    </h2>
                                    <div id="collapseThree6" class="accordion-collapse collapse show"
                                        aria-labelledby="headingThree6" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="pl-3p">
                                                <input type="text" class="form-control" id="inputHyperlinkText"
                                                    autocomplete="off" placeholder="Enter Text"
                                                    (focusout)="applyLabelName($event, 'hyperlink')" maxlength="120" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item" *ngIf="controlsObj.hyperlinkProperties">
                                    <h2 class="accordion-header font14" id="headingThree7">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree7"
                                            aria-expanded="false" aria-controls="collapseThree7">
                                            URL
                                        </button>
                                    </h2>
                                    <div id="collapseThree7" class="accordion-collapse collapse"
                                        aria-labelledby="headingThree7" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="pl-3p">
                                                <input type="text" class="form-control" id="inputHyperLinkURL"
                                                    placeholder="Enter URL" (focusout)="applyHyperLinkURL($event)"
                                                    maxlength="300" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item" *ngIf="
                    controlsObj.signatureProperties ||
                    controlsObj.initialsProperties
                  ">
                                    <h2 class="accordion-header font14" id="headingThree8">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseThree8"
                                            aria-expanded="false" aria-controls="collapseThree8">
                                            Text Color
                                        </button>
                                    </h2>
                                    <div id="collapseThree8" class="accordion-collapse collapse"
                                        aria-labelledby="headingThree8" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="d-flex align-items-center me-2">
                                                <form [formGroup]="exampleForm">
                                                    <ngx-colors class="suffix" matSuffix ngx-colors-trigger
                                                        [(ngModel)]="defaultColorPicker" formControlName="pickerCtrl"
                                                        style="position: relative; z-index: 1;"></ngx-colors>
                                                </form>
                                                <input class="form-control form-control-sm ms-1"
                                                    [(ngModel)]="defaultColorPicker" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item" id="checkbox-groupname"
                                    *ngIf="controlsObj.checkboxProperties ||
                                        controlsObj.radioProperties">
                                    <div *ngIf="displayGroupCheckBox || displayGroupRadio">
                                        <h2 class="accordion-header font14" id="headingThree9">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseThree9"
                                                aria-expanded="false" aria-controls="collapseThree9">
                                                Group Name
                                            </button>
                                        </h2>
                                        <div id="collapseThree9" class="accordion-collapse collapse"
                                            aria-labelledby="headingThree9" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <small class="smalltext pl-3p" style="color: #000;">Group Name should be
                                                    only characters and
                                                    numbers</small>
                                                <div class="pl-3p">
                                                    <input type="text" class="form-control" id="inputGroupCtrl" autocomplete="off"
                                                        placeholder="Enter Group Name"
                                                        (focusout)="applyCheckboxRadioGroupName($event, '1')" />
                                                </div>

                                                <div class="mt-3 pl-3p">
                                                    <label *ngIf="controlsObj.checkboxProperties"
                                                        for="exampleFormControlInput1" class="form-label">Checkbox
                                                        Name</label>
                                                    <label *ngIf="controlsObj.radioProperties"
                                                        for="exampleFormControlInput1" class="form-label">Radio
                                                        Name</label>
                                                    <input type="text" class="form-control" id="inputGroupNameCtrl" autocomplete="off"
                                                        placeholder="Enter Name" (focusout)="applyCheckboxRadioGroupName($event, '2')" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item" *ngIf="
                    controlsObj.checkboxProperties ||
                    controlsObj.radioProperties
                  ">
                                    <h2 class="accordion-header font14" id="headingTwo2">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo2"
                                            aria-expanded="false" aria-controls="collapseTwo2">
                                            Size
                                        </button>
                                    </h2>
                                    <div id="collapseTwo2" class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo2" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="pl-3p">
                                                <select [(ngModel)]="selectDefaultSizeValue"
                                                    (ngModelChange)="radiocheckboxSizes()" class="form-select"
                                                    aria-label="Default select example" name="checkbox-size">
                                                    <option *ngFor="let item of checkboxoptions" [value]="item.value">
                                                        {{ item.label }}
                                                    </option>
                                                </select>
                                            </div>

                                            <div *ngIf="selectDefaultSizeValue === '1' || selectDefaultSizeValue === 1">
                                                <div class="d-flex align-items-center mt-3">
                                                    <label for="wp-comment-cookies-consent"
                                                        class="top1 ps-1 me-3">Preview:</label>
                                                    <input *ngIf="controlsObj.checkboxProperties"
                                                        style="height: 16px; width: 16px;" class="check-box disable-box"
                                                        id="wp-comment-cookies-consent" name="preview" type="checkbox"
                                                        value="Preview" disabled />
                                                    <span *ngIf="controlsObj.radioProperties"
                                                        class="radio-design rounded-circle"
                                                        style="height: 16px; width: 16px;"></span>
                                                </div>
                                            </div>
                                            <div *ngIf="selectDefaultSizeValue === '2' || selectDefaultSizeValue === 2">
                                                <div class="d-flex align-items-center mt-3">
                                                    <label for="wp-comment-cookies-consent"
                                                        class="top1 ps-1 me-3">Preview:</label>
                                                    <input *ngIf="controlsObj.checkboxProperties"
                                                        style="height: 18px; width: 18px;" class="check-box disable-box"
                                                        id="wp-comment-cookies-consent" name="preview" type="checkbox"
                                                        value="Preview" disabled />
                                                    <span *ngIf="controlsObj.radioProperties"
                                                        class="radio-design rounded-circle"
                                                        style="height: 18px; width: 18px;"></span>
                                                </div>
                                            </div>
                                            <div *ngIf="selectDefaultSizeValue === '3' || selectDefaultSizeValue === 3">
                                                <div class="d-flex align-items-center mt-3">
                                                    <label for="wp-comment-cookies-consent"
                                                        class="top1 ps-1 me-3">Preview:</label>
                                                    <input *ngIf="controlsObj.checkboxProperties"
                                                        style="height: 20px; width: 20px;" class="check-box disable-box"
                                                        id="wp-comment-cookies-consent" name="preview" type="checkbox"
                                                        value="Preview" disabled />
                                                    <span *ngIf="controlsObj.radioProperties"
                                                        class="radio-design rounded-circle"
                                                        style="height: 20px; width: 20px;"></span>
                                                </div>
                                            </div>
                                            <div *ngIf="selectDefaultSizeValue === '4' || selectDefaultSizeValue === 4">
                                                <div class="d-flex align-items-center mt-3">
                                                    <label for="wp-comment-cookies-consent"
                                                        class="top1 ps-1 me-3">Preview:</label>
                                                    <input *ngIf="controlsObj.checkboxProperties"
                                                        style="height: 22px; width: 22px;" class="check-box disable-box"
                                                        id="wp-comment-cookies-consent" name="preview" type="checkbox"
                                                        value="Preview" disabled />
                                                    <span *ngIf="controlsObj.radioProperties"
                                                        class="radio-design rounded-circle"
                                                        style="height: 22px; width: 22px;"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item" *ngIf="controlsObj.dropdownProperties">
                                    <h2 class="accordion-header font14" id="headingTwo3">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo3"
                                            aria-expanded="false" aria-controls="collapseTwo3">
                                            List Items
                                        </button>
                                    </h2>
                                    <div id="collapseTwo3" class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo3" data-bs-parent="#accordionExample">
                                        <div class="accordion-body" style="padding-bottom: 1px;">
                                            <div class="d-flex align-items-center justify-content-between pl-3p">
                                                <input type="text" class="form-control" id="dropdownList"
                                                    placeholder="Enter Options" autocomplete="off"
                                                    (keyup.enter)="openModal('dropdownlist', '')" maxlength="40" />
                                                <span (click)="openModal('dropdownlist', '')"
                                                    class="material-icons add-option ms-2">
                                                    add_box
                                                </span>
                                            </div>
                                            <div *ngIf="slDropDownControlItems.length > 0">
                                                <div
                                                    class="d-flex align-items-center justify-content-between mt-2 py-1 mx-1">
                                                    <label class="form-label font-w600">Manage List Items</label>
                                                    <i class="fa fa-edit pointer"
                                                        (click)="openModal('dropdownlist', '')"
                                                        style="color: #1d4ed8; font-size: 1.2rem;"></i>
                                                </div>
                                                <ul class="option-list scroll-style">
                                                    <li class="liDrpdownListItems list-unstyled mb-1"
                                                        *ngFor="let drpItem of slDropDownControlItems;let i = index"
                                                        id="{{ drpItem.value }}">
                                                        <div
                                                            class="d-flex align-items-center justify-content-between me-1">
                                                            <label class="list-truncate" title="{{ drpItem.text }}">
                                                                {{drpItem.text }}</label>
                                                            <span *ngIf="drpItem.text != ''"
                                                                class="material-icons pointer"
                                                                style="color: #bc0001; font-size: 21px;" title="Delete"
                                                                (click)="deleteDropdownOption($event, drpItem.value)">delete_outline</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- location start -->
                                <!-- <div class="accordion-item"
									*ngIf="controlsObj.signatureProperties || controlsObj.initialsProperties">
									<h2 class="accordion-header font14" id="headingTwo4">
										<button class="accordion-button collapsed" type="button"
											data-bs-toggle="collapse" data-bs-target="#collapseTwo4"
											aria-expanded="false" aria-controls="collapseTwo4">
											Location
										</button>
									</h2>
									<div id="collapseTwo4" class="accordion-collapse collapse"
										aria-labelledby="headingTwo4" data-bs-parent="#accordionExample">
										<div class="accordion-body">
											<div class="mb-2 row">
												<div class="col-sm-4">
													<input type="text" class="form-control form-control-sm"
														id="inputPassword1" value="360" />
												</div>
												<label for="inputPassword1" class="col-sm-8 col-form-label">Pixels from
													Left</label>
											</div>

											<div class="row">
												<div class="col-sm-4">
													<input type="text" class="form-control form-control-sm"
														id="inputPassword2" value="50" />
												</div>
												<label for="inputPassword2" class="col-sm-8 col-form-label">Pixels from
													Top</label>
											</div>
										</div>
									</div>
								</div> -->

                                <div class="accordion-item" *ngIf="controlsObj.nameProperties">
                                    <h2 class="accordion-header font14" id="headingTwo5">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo5"
                                            aria-expanded="false" aria-controls="collapseTwo5">
                                            {{ lengthOrMaxCharLimitLabel }}
                                        </button>
                                    </h2>
                                    <div id="collapseTwo5" class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo5" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="me-2 ms-1 row">
                                                <input type="number" autocomplete="off" class="form-control"
                                                    id="nameInputLength" placeholder="Enter Length" min="1"
                                                    max="100" (input)="restrictInputLengthToThree($event)"
                                                    (focusout)="updateControlNameOrTextLength($event, 'name')" />
                                                <input type="number" autocomplete="off" class="form-control"
                                                    #nameCharInputLimit id="nameCharInputLimit"
                                                    placeholder="Enter Length" min="1" value="100"
                                                    max="100" (input)="checkNumberLength( 'name', nameCharInputLimit)"
                                                    (focusout)="updateCharInputLimit('nameCharInputLimit', 'name')" />
                                            </div>
                                        </div>
                                        <div *ngIf="hideFixedwidthCheckbox !== true" id="isFixedWidthNameContainer"
                                            class="accordion-item p-10p" style="padding-top:0px;">
                                            <input type="checkbox" id="isFixedWidth" class="check-box"
                                                [checked]="isFixedWidthChecked"
                                                (click)="applyClickedProperty($event, '3')" />
                                            <label for="isFixedWidth" class="top1">Fixed Width</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item"
                                    *ngIf="hideFixedwidthCheckbox === false && controlsObj.titleProperties">
                                    <h2 class="accordion-header font14" id="headingTwo5">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo55"
                                            aria-expanded="false" aria-controls="collapseTwo55">
                                            {{ lengthOrMaxCharLimitLabel }}
                                        </button>
                                    </h2>
                                    <div id="collapseTwo55" class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo5" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="me-2 ms-1 row">
                                                <input type="number" autocomplete="off" class="form-control"
                                                    id="titleInputLength" placeholder="Enter Length" min="1"
                                                    max="100" (input)="restrictInputLengthToThree($event)"
                                                    (focusout)="updateControlNameOrTextLength($event, 'title')" />
                                                <input type="number" autocomplete="off" class="form-control"
                                                    #titleCharInputLimit id="titleCharInputLimit"
                                                    placeholder="Enter Length" min="1" value="100" maxlength="3"
                                                    max="100" (input)="checkNumberLength('title', titleCharInputLimit)"
                                                    (focusout)="updateCharInputLimit('titleCharInputLimit', 'title')" />
                                            </div>
                                        </div>
                                        <div *ngIf="hideFixedwidthCheckbox !== true" id="isFixedWidthTitleContainer"
                                            class="accordion-item p-10p" style="padding-top:0px;">
                                            <input type="checkbox" id="isFixedWidth" class="check-box"
                                                [checked]="isFixedWidthChecked"
                                                (click)="applyClickedProperty($event, '3')" />
                                            <label for="isFixedWidth" class="top1">Fixed Width</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item"
                                    *ngIf="controlsObj.textProperties && externalIntegrationType != ''">
                                    <h2 class="accordion-header font14" id="headingTwo88">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo88"
                                            aria-expanded="false" aria-controls="collapseTwo88">
                                            Custom Entity - {{externalIntegrationType}}
                                        </button>
                                    </h2>
                                    <div id="collapseTwo88" aria-labelledby="headingTwo88"
                                        data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                                        <div class="accordion-body">
                                            <div class="pl-3p">
                                                <select id="externalTextoptions" class="form-select"
                                                    aria-label="Default select example" name="checkbox-size"
                                                    #externalTextoptions
                                                    (change)="applyExternalTextOptions($event, externalTextoptions.value)">
                                                    <option *ngFor="let option of externalEntityList"
                                                        [value]="option.Value">
                                                        {{ option.Key }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item"
                                    *ngIf="controlsObj.textProperties && externalIntegrationType != ''">
                                    <h2 class="accordion-header font14" id="headingTwo89">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo89"
                                            aria-expanded="false" aria-controls="collapseTwo89">
                                            Entity Fields
                                        </button>
                                    </h2>
                                    <div id="collapseTwo89" aria-labelledby="headingTwo89"
                                        data-bs-parent="#accordionExample" class="accordion-collapse collapse">
                                        <div class="accordion-body">
                                            <div class="pl-3p">
                                                <select [disabled]="userSelectedEntity" id="entityFields"
                                                    class="form-select" aria-label="Default select example"
                                                    name="checkbox-size" #entityFields
                                                    (change)="applyExternalTextOptionsFields($event, entityFields.value)">
                                                    <option *ngFor="let option of externalEntityFieldList"
                                                        [value]="option.Value">
                                                        {{ option.Value }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item" *ngIf="controlsObj.textProperties">
                                    <h2 class="accordion-header font14" id="headingTwo6">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo6"
                                            aria-expanded="false" aria-controls="collapseTwo6">
                                            Validation Type
                                        </button>
                                    </h2>
                                    <div id="collapseTwo6" class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo6" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="pl-3p">
                                                <select [disabled]="externalIntegrationType != ''"
                                                    id="textValidationsoptions" [(ngModel)]="selectValidation"
                                                    (ngModelChange)="validationType()" class="form-select"
                                                    aria-label="Default select example" name="checkbox-size"
                                                    (change)="applyTextTypeControl(selectValidation)">
                                                    <option *ngFor="let itemlist of validationsoptions"
                                                        [value]="itemlist.value">
                                                        {{ itemlist.label }}
                                                    </option>
                                                </select>
                                            </div>

                                            <div class="mt-3 pl-3p">
                                                <label for="exampleFormControlInput11"
                                                    class="form-label">{{lengthOrMaxCharLimitLabel}}</label>
                                                <!-- <input *ngIf="selectValidation === '1' ||
														selectValidation === '2' ||
														selectValidation === '3'" type="text" id="exampleFormControlInput11" placeholder="Enter Name"
													class="form-control" value="20" />
												<input *ngIf="selectValidation === '4' ||
														selectValidation === '5' ||
														selectValidation === '6'" type="text" readonly id="exampleFormControlInput11" placeholder="Enter Name"
													class="form-control" value="10" style="cursor: no-drop;" />
												<input *ngIf="selectValidation === '7'" type="text" readonly
													id="exampleFormControlInput11" placeholder="Enter Text"
													class="form-control" value="20" style="cursor: no-drop;" /> -->

                                                <input type="number" autocomplete="off" class="form-control"
                                                    id="textInputLength" placeholder="Enter Length" min="1"
                                                    #textInputLength min="1" value="1000" maxlength="4"
                                                    max="1000" (input)="checkNumberLength('text', textInputLength)"
                                                    (focusout)="updateControlNameOrTextLength($event, 'text')" />
                                                <input type="number" autocomplete="off" class="form-control"
                                                    #textCharInputLimit id="textCharInputLimit"
                                                    placeholder="Enter Length" min="1" value="textCtrlMaxValidation" maxlength="4"
                                                    max="{{textCtrlMaxValidation}}" (input)="checkNumberLength('text', textCharInputLimit)"
                                                    (focusout)="updateCharInputLimit('textCharInputLimit', 'text')" />
                                            </div>
                                        </div>
                                        <div *ngIf="hideFixedwidthCheckbox !== true" id="isFixedWidthTextContainer"
                                            class="accordion-item p-10p" style="padding-top: 0px;">
                                            <input type="checkbox" id="isFixedWidth" class="check-box"
                                                [checked]="isFixedWidthChecked"
                                                (click)="applyClickedProperty($event, '3')" />
                                            <label for="isFixedWidth" class="top1">Fixed Width</label>
                                        </div>
                                    </div>
                                </div>

                                <ng-container *ngIf="controlsObj.textProperties || userSelectedEntity === false">
                                    <div class="accordion-item" [ngStyle]="{ display: showTextFieldsContainer }">
                                        <h2 class="accordion-header font14" id="headingTwo7">
                                            <button class="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo7"
                                                aria-expanded="false" aria-controls="collapseTwo7">
                                                Additional Validation Name
                                            </button>
                                        </h2>
                                        <div id="collapseTwo7" class="accordion-collapse collapse"
                                            aria-labelledby="headingTwo7" data-bs-parent="#accordionExample">
                                            <div class="accordion-body">
                                                <div [ngStyle]="{ display: showTextDecimalType }">
                                                    <select id="textTypeDecimal" [(ngModel)]="selectAddtionalValidation"
                                                        class="form-select" aria-label="Default select example"
                                                        name="checkbox-size" #selectedDecimal
                                                        (change)="applyTextTypeDecimal(selectedDecimal.value)">
                                                        <option *ngFor="let itemoption of additionalValidation"
                                                            [value]="itemoption.value">
                                                            {{ itemoption.viewname }}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div [ngStyle]="{ display: showTextZipFormat }">
                                                    <select class="form-select" aria-label="Default select example"
                                                        name="checkbox-size">
                                                        <option selected>Zip Format</option>
                                                    </select>

                                                    <div class="d-flex flex-column mt-3">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label">Additional Validation
                                                            Option</label>
                                                        <select id="textZipOptions" class="form-select"
                                                            aria-label="Default select example" name="checkbox-size"
                                                            #selectedZip (change)="applyTextTypeZip(selectedZip.value)">
                                                            <option *ngFor="let itemoption of validationsZipOptions"
                                                                [value]="itemoption.value">
                                                                {{ itemoption.label }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div [ngStyle]="{ display: showTextDateFormat }">
                                                    <select class="form-select" aria-label="Default select example"
                                                        name="checkbox-size">
                                                        <option selected>Date Format</option>
                                                    </select>

                                                    <div class="d-flex flex-column mt-3">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label">Additional Validation
                                                            Option</label>

                                                        <select id="textDateOptions" class="form-select"
                                                            aria-label="Default select example" #selectedDate
                                                            (change)="applyTextTypeDate(selectedDate.value)">
                                                            <option *ngFor="let itemoption of validationsDateOptions" [value]="itemoption.value">
                                                                {{ itemoption.label }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div [ngStyle]="{ display: showTextDecimalOptions }">
                                                    <div class="d-flex flex-column mt-3">
                                                        <label for="exampleFormControlInput1"
                                                            class="form-label">Additional Validation
                                                            Option</label>
                                                        <select id="textTypeDecimalOptions" class="form-select"
                                                            aria-label="Default select example" #selectedDecimalOption
                                                            (change)="applyTextTypeDecimalOption(selectedDecimalOption.value)">
                                                            <option *ngFor="let addtionaloption of additionalValidationType" [value]="addtionaloption.value">
                                                                {{ addtionaloption.viewValue }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="accordion-item"
                                    *ngIf="controlsObj.datetimeProperties ||
                                        controlsObj.nameProperties ||
                                        controlsObj.titleProperties ||
                                        controlsObj.companyProperties ||
                                        controlsObj.emailProperties ||
                                        controlsObj.dateProperties ||
                                        controlsObj.textProperties ||
                                        controlsObj.labelProperties ||
                                        controlsObj.hyperlinkProperties">
                                    <h2 class="accordion-header font14" id="headingTwo8">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo8"
                                            aria-expanded="false" aria-controls="collapseTwo8">
                                            Formatting
                                        </button>
                                    </h2>
                                    <div id="collapseTwo8" class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo8" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="text-formatting flex-column">
                                                <div class="d-flex align-items-center justify-content-between">
                                                    <div class="select-box w-100 mx-1">
                                                        <select class="form-select form-select-sm"
                                                            aria-label=".form-select-sm example"
                                                            (change)="applyFontFamily($event)"
                                                            [(ngModel)]="defaultFontFamily">
                                                            <option value="Arial">Arial</option>
                                                            <option value="Cambria">Cambria</option>
                                                            <option value="Courier" selected>Courier</option>
                                                            <option value="Times New Roman">
                                                                Times New Roman
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div
                                                    class="d-flex align-items-center justify-content-between mt-2 ms-1 me-2">
                                                    <div class="select-box w-50">
                                                        <select id="fontSizeSelect" class="form-select form-select-sm"
                                                            aria-label=".form-select-sm example" #fontSizeSelect
                                                            [(ngModel)]="defaultFontSize"
                                                            (change)="applyFontSize(fontSizeSelect.value)">
                                                            <option value="8">8</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12" selected>12</option>
                                                            <option value="14">14</option>
                                                            <option value="16">16</option>
                                                            <option value="18">18</option>
                                                            <option value="24">24</option>
                                                            <option value="36">36</option>
                                                            <option value="48">48</option>
                                                            <option value="60">60</option>
                                                        </select>
                                                    </div>
                                                    <div class="select-box w-50 me-1">
                                                        <select id="fontSizePtOrPxSelect"
                                                            class="form-select form-select-sm mx-2"
                                                            aria-label=".form-select-sm example" #fontSizePtOrPxSelect
                                                            (change)="applyFontSizePxOrPt(fontSizePtOrPxSelect.value)">
                                                            <option value="px" selected>px</option>
                                                            <!-- <option value="pt">pt</option> -->
                                                        </select>
                                                    </div>
                                                </div>
                                                <div
                                                    class="d-flex align-items-center justify-content-between mt-2 ms-1">
                                                    <div class="box25" [class.visited]="isBold == true"
                                                        (click)="checkBold()">
                                                        <span class="material-icons"> format_bold </span>
                                                    </div>
                                                    <div class="box25" [class.visiteditalic]="isItalic == true"
                                                        (click)="checkItalic()">
                                                        <span class="material-icons"> format_italic </span>
                                                    </div>
                                                    <div class="box25" [class.visited]="isUnderlined == true"
                                                        (click)="checkUnderline()">
                                                        <span class="material-icons">
                                                            format_underlined
                                                        </span>
                                                    </div>
                                                    <div class="box25" [class.visited]="isAlignleft == true"
                                                        (click)="checkAlignLeft()">
                                                        <span class="material-icons">
                                                            format_align_left
                                                        </span>
                                                    </div>

                                                    <div class="box25" [class.visited]="isAlignmiddle == true"
                                                        (click)="checkAlignMiddle()">
                                                        <span class="material-icons">
                                                            format_align_justify
                                                        </span>
                                                    </div>
                                                </div>

                                                <div
                                                    class="d-flex align-items-center justify-content-between mt-2 ms-1">
                                                    <div class="box25" [class.visited]="isAlignright == true"
                                                        (click)="checkAlignRight()">
                                                        <span class="material-icons">
                                                            format_align_right
                                                        </span>
                                                    </div>
                                                    <div class="d-flex align-items-center me-1 ml-9">
                                                        <form [formGroup]="exampleForm">
                                                            <ngx-colors class="suffix" matSuffix ngx-colors-trigger
                                                                [(ngModel)]="defaultColorPicker"
                                                                formControlName="pickerCtrl"
                                                                style="position: relative; z-index: 1;"></ngx-colors>
                                                        </form>
                                                        <input class="form-control form-control-sm ml-15"
                                                            [(ngModel)]="defaultColorPicker" />
                                                    </div>
                                                </div>

                                                <!-- <div class="p-10p d-flex mt-1 pb-0" style="padding-left: 6px;"
                                                    *ngIf="controlsObj.textProperties">
                                                    <div class="d-flex align-items-center">
                                                        <input id="wp-comment-cookies-consent" class="check-box"
                                                            name="wp-comment-cookies-consent" type="checkbox"
                                                            value="Required" />
                                                        <label for="wp-comment-cookies-consent" class="top1">Hide text
                                                            with asterisks</label>
                                                    </div>
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item" *ngIf="controlsObj.dateProperties">
                                    <h2 class="accordion-header font14" id="headingTwo9">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo9"
                                            aria-expanded="false" aria-controls="collapseTwo9">
                                            Date Format
                                        </button>
                                    </h2>
                                    <div id="collapseTwo9" class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo9" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="pl-3p">
                                                <select class="form-select" aria-label="Default select example"
                                                    [(ngModel)]="selectDefaultDateFormat"
                                                    (ngModelChange)="applyDefaultDateFormat($event)">
                                                    <option *ngFor="let dateoption of dateformat"
                                                        value="{{ dateoption.value }}">
                                                        {{ dateoption.viewValue }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="accordion-item" *ngIf="isStoreControlId == true">
                                    <h2 class="accordion-header font14" id="headingTwo44">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo44"
                                            aria-expanded="false" aria-controls="collapseTwo44">
                                            Control ID
                                        </button>
                                    </h2>
                                    <div id="collapseTwo44" class="accordion-collapse collapse"
                                        aria-labelledby="headingTwo44" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div class="mb-2 d-flex align-items-center justify-content-between">
                                                <label for="dataDocumentContent"
                                                    class="col-form-label pl-3p">ID:</label>
                                                <input #dataDocumentContent type="text"
                                                    class="form-control form-control-sm mx-2" id="dataDocumentContent"
                                                    readonly title="{{ dataDocumentContent.value }}"
                                                    style="cursor: no-drop; background-color: var(--bs-secondary-bg);" />
                                                <span class="material-icons mx-1 pointer"
                                                    (click)="copyInputMessage(dataDocumentContent)">
                                                    content_copy
                                                </span>
                                            </div>

                                            <div class="mb-2 d-flex d-flex align-items-center justify-content-between">
                                                <label for="ctrlHtmlId" class="col-form-label text-nowrap pl-3p">HTML
                                                    ID:
                                                </label>
                                                <input #ctrlHtmlId type="text" class="form-control form-control-sm mx-2"
                                                    id="ctrlHtmlId" title="{{ ctrlHtmlId.value }}"
                                                    (focusout)="applyNewContentId($event)" />
                                                <span class="material-icons mx-1 pointer"
                                                    (click)="copyInputMessage(ctrlHtmlId)">
                                                    content_copy
                                                </span>
                                            </div>
                                            <div class="mt-2">
                                                <span class="note pl-3p">Only characters and numbers. No spaces.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item p-10p d-flex">
                                    <input class="check-box" id="wp-comment-cookies-consent"
                                        name="wp-comment-cookies-consent" type="checkbox" value="Save as Default"
                                        (change)="saveAsDefault($event, controlsObj)" checked
                                        [(ngModel)]="updateSaveAsDefaultChecked" />
                                    <label for="wp-comment-cookies-consent" class="top1">{{
                                        checkboxText
                                        }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="offcanvas-footer bottom-style">
                            <div class="d-flex flex-column justify-content-between mb-2">
                                <!-- send-btn -->
                                <!-- <div class="d-flex align-items-center justify-content-center mb-2 footer-bottom">
									<button style="width: 100px;" class="btn back-btn deletebtn form-btn" (click)="removeControlComponent($event)">
										<i class="fa fa-trash me-1" title="Delete" aria-hidden="true" style="font-size: 16px;"></i>	Delete
									</button>
									<button class="savbtn btn back-btn form-btn" role="button">
										Save
									</button>
								</div> -->
                                <div class="saveas-styles d-flex align-items-center justify-content-center mx-2 px-1">
                                    <!-- <div [hidden]="!showSave">
										<button (click)="showSuccess()" style="column-gap: 1px;"
											class="send-button top1 font14 d-flex align-items-center justify-content-center"
											role="button">
											<span class="material-icons me-1 font17"> save </span>
											Save as Default
										</button>
									</div>
									<div [hidden]="!showRemove">
										<button (click)="onShowRemove()" style="column-gap: 1px;"
											class="send-button top1 font14 d-flex align-items-center justify-content-center"
											role="button">
											<span class="material-icons me-1 font17"> delete </span>
											Remove Default
										</button>
									</div>
									<div>
										<button (click)="onControlIdView()"
											style="column-gap: 1px;padding: 0.775rem 0.95rem;background: #3278b0;"
											class="send-button top1 font14" role="button">
											Id
										</button>
										<i class="fa fa-trash deleteicon" title="Delete" aria-hidden="true" (click)="removeControlComponent($event)"></i>
									</div> -->
                                    <button style="width: 100px;" class="btn back-btn deletebtn form-btn"
                                        (click)="removeControlComponent($event)">
                                        <i class="fa fa-trash me-1" title="Delete" aria-hidden="true"
                                            style="font-size: 16px;"></i>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</main>
<div *ngIf="showLoader" class="loader"></div>
