import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlConfig, NavigateUrlConfig } from 'src/app/constants/common-apiurls';
import { CommonConstants } from 'src/app/constants/common-constants';
import { APIService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-discard-envelope',
    templateUrl: './discard-envelope.component.html',
    styleUrls: ['./discard-envelope.component.scss']
})
export class DiscardEnvelopeComponent {
    @Output() discardEnvelopChangesMade: EventEmitter<any> = new EventEmitter();
    showLoader: boolean = false;
    public onCheckDiscardEnvelope: EventEmitter<any> = new EventEmitter();

    constructor(
        public modalRef: BsModalRef,
        private toastrService: ToastrService,
        private authService: AuthService,
        private apiService: APIService
    ) { }

    ngOnInit(): void {
    }

    onDiscardEnvelope() {
        let popUpStatus = {
            status: 'ok'
        };
        this.onCheckDiscardEnvelope.next(popUpStatus);
        let getUserDetails: any = this.authService.getDetails();
        if (getUserDetails) {
            let envelopeDetail: any = JSON.parse(getUserDetails);
            if (envelopeDetail) {
                this.showLoader = true;
                let apiUrl = ApiUrlConfig.DiscardEnvelope + "?envelopeId=" + envelopeDetail.envelopeOrTemplateId;
                this.apiService.delete(apiUrl).subscribe((resp: any) => {
                    this.showLoader = false;
                    if (resp && resp.statusCode == 200) {
                        this.modalRef.hide();
                        this.toastrService.success(resp.message, 'Success');
                        localStorage.clear();
                        sessionStorage.clear();
                        setTimeout(() => {
                            window.location.href = environment.rsignUrl + NavigateUrlConfig.Home;
                        }, 1000);
                    }
                    else {
                        if (resp.message != null && resp.message != '')
                            this.toastrService.warning(resp.message, 'Warning');
                        else
                            this.toastrService.warning(CommonConstants.DiscardEnvelopeFailed, 'Warning');
                    }
                });
            }
            else {
                this.toastrService.warning(CommonConstants.DiscardEnvelopeFailed, 'Warning');
            }
        }
        else {
            this.toastrService.warning(CommonConstants.DiscardEnvelopeFailed, 'Warning');
        }
    }

    onDiscardCancel(){
        let popUpStatus = {
            status: 'cancel'
        };
        this.onCheckDiscardEnvelope.next(popUpStatus);
        this.modalRef.hide();
    }
}
