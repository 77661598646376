<div class="side-navbar d-flex justify-content-between flex-wrap flex-column w-16" id="sidebar">
    <div class="mb-0">
        <div class="d-flex flex-column mt-3 px-3 document-name">
            <h6 class="font-w600 doc-head" style="width: 160px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
                title="{{previewObj?.documentName}}">
                <!-- <i class="fa fa-file-text-o me-2 font-bold" aria-hidden="true"></i> -->
                <img class="h-18p" src="../../../assets/images/icons/Sample-Document.svg"/>
                    {{previewObj?.documentName}}
            </h6>
            <p class="d-flex align-items-center justify-content-between mb-1 font13">
                <span *ngIf="previewObject && previewObject.documentList"> Pages: {{previewObject.documentList.length}}</span>
                <span> Fields: {{previewObject.totalControls}}</span>
            </p>
        </div>
        <div class="right-scrollbar">
            <ul class="nav flex-column force-overflow">
                <li *ngFor="let prev of previewObject.documentList;let prevInd = index;"
                    class="nav-link p-0 mb-2" (click)="onPreviewImage(prev, prevInd+1)">
                    <div class="mt-2 d-flex flex-column align-items-center"
                        [ngClass]="{ active: prevInd+1 === previewObject.pageNumber}">
                        <div class="thumbnail" id="rotate{{prevInd+1}}">
                            <div class="thumbnails-page-btn"
                                style="position: relative; top: 174px; left: 2px;" id="pageCountColor{{prevInd+1}}">
                                <span class="pagenum">{{prevInd+1}}</span>
                                <!-- <div class="d-flex align-items-center pre-icons">
                                    <span class="material-icons font20">
                                        crop_rotate
                                    </span>
                                    <span class="material-icons font20 ms-2">delete_outline
                                    </span>
                                </div> -->
                            </div>
                            <div class="d-flex justify-content-center page-box">
                                <img class="preview-img" src="{{prev.path}}" />
                            </div>
                            <span *ngIf="prev.isActive" class="material-icons badge-icon">
                                filter_frames
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
